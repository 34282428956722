<template> 
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1>Historial de cambios</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>
            <div class="card-body">

              <!-- Carga -->
              <div v-if="cargandoHistorial" class="my-4">
                <b-spinner></b-spinner>
                <h4 >Cargando datos...</h4>
              </div>

              <!-- Contenido -->
              <div>
                  <div class="d-flex justify-content-between">
                    <!-- <button v-if="numRegistros || filtrado" class="btn btn-secondary text-white fw-bold fit-content m-3 filtrar" type="button" v-b-toggle="'filtros'">
                      <i class="fa-solid fa-filter"></i>
                      Filtrar ({{numRegistros}})
                    </button> -->
                    <Paginador v-if="numRegistros" class="m-3" @recargar="cambiarPagina" :page="page" :numRegistros="numRegistros" :registrosPorPagina="registrosPorPagina"></Paginador>
                  </div>
                  <!-- Historial -->
                  <div v-if="numRegistros" class="table-responsive shadow">
                    <table class="table table-striped table-bordered">
                        <thead class="text-start">
                          <tr class="noselect">
                            <th scope="col" class="d-table-cell line">USUARIO</th>
                            <th scope="col" class="d-table-cell line">OPERACION</th>
                            <th scope="col" class="d-table-cell line">DESCRIPCIÓN</th>
                            <th scope="col" class="d-table-cell line">CREADOS</th>
                            <th scope="col" class="d-table-cell line">EDITADOS</th>
                            <th scope="col" class="d-table-cell line">BORRADOS</th>
                            <th scope="col" class="d-table-cell line sorting" @click="ordenarTabla($event, 'FECHA')">FECHA</th>
                            <th scope="col" class="d-table-cell line">DETALLES</th>
                          </tr>
                        </thead>
                        <tbody class="align-middle text-start">
                          <template v-for="(item, index) in registros">
                              <tr :key="index" :id="item.index" class="accordion" role="listaRegistros">
                                <!-- OPERACION -->
                                <td scope="d-table-cell">
                                    <span>{{item.USUARIO}}</span>
                                </td>
                                <!-- OPERACION -->
                                <td class="d-table-cell">
                                    <label>{{item.OPERACION}}</label>
                                </td>
                                <!-- DESCRIPCIÓN -->
                                <td class="d-table-cell">
                                    <label>{{item.DESCRIPCION}}</label>
                                </td>
                                <!-- ITEMS CREADOS -->
                                <td class="d-table-cell">
                                    <label>{{item.itemsCreados}}</label>
                                </td>
                                <!-- ITEMS EDITADOS -->
                                <td class="d-table-cell">
                                    <label>{{item.itemsEditados}}</label>
                                </td>                                
                                <!-- ITEMS BORRADOS -->
                                <td class="d-table-cell">
                                    <label>{{item.itemsBorrados}}</label>
                                </td>                                                
                                <!-- FECHA -->
                                <td class="d-table-cell">{{fechaComputada(item.FECHA)}}</td>
                                <!-- DETALLES -->
                                <td class="d-table-cell text-center">
                                    <b-button type="button" variant="secondary" class="btn btn-secondary border-0 shadow-sm mx-3" @click="buscarCambio(item, 'acordeon' + index);"><i class="fa-solid fa-circle-info fs-6" :id="'boton'+index"></i></b-button>
                                </td>
                              </tr>
                              <!-- COLLAPSABLE INFO EXTRA -->
                              <tr :key="'c'+index">
                                <td colspan="8" class="p-0 overflow-hidden">
                                  <div v-if="item.DATOS && (item.OPERACION == 'CAMBIO HUELLA' || item.OPERACION == 'CREAR HUELLA')">
                                    <DatatableCambiosHogares :item="item.DATOS" :indice="index" style="max-width: 95vw; width: 100%;"></DatatableCambiosHogares>
                                  </div>
                                  <div v-if="item.DATOS && (item.OPERACION == 'CAMBIO TRAZADOS' || item.OPERACION == 'CREAR TRAZADOS')">
                                    <DatatableCambiosTrazados :item="item.DATOS" :indice="index" style="max-width: 95vw; width: 100%;"></DatatableCambiosTrazados>
                                  </div>
                                </td>
                              </tr>
                      
                          </template>
                        
                        </tbody>
                    </table>
                  </div>
                  <Paginador class="d-flex justify-content-center" v-if="numRegistros" @recargar="cambiarPagina" :page="page" :numRegistros="numRegistros" :registrosPorPagina="registrosPorPagina"></Paginador>

              </div>
              <!-- Error cargando -->
              <div v-if="registros.length < 1 && !cargandoHistorial" class="alert alert-warning d-flex align-items-center" role="alert">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>  
                  <div class="d-flex flex-column text-start mx-2">
                    <div v-if="mensajedeError" class="text-danger">
                      {{mensajedeError}}
                    </div>  
                    <div v-if="registros.length < 1" class="text-danger align-self-center">
                      No se han encontrado registros
                    </div>           
                  </div> 
                </div>
              </div>
            </div>

          </div>

      </div>

      
  </div>

</template>

<script>
import { animarCollapse, desexpandirTodo, toggleIcon } from '../../helpers/utils';
import axios from "axios";
import { fecha } from "../../classes/fecha.class"
import Paginador from "@/components/Paginador"
import DatatableCambiosHogares from "./DatatableCambiosHogares"
import DatatableCambiosTrazados from "./DatatableCambiosTrazados"
import { errorAlert, infoAlert, serverErrorAlert } from '../../helpers/fastAlert';

export default {
  /*

  */
  name: 'HistorialEditor',
  components: {
    Paginador,
    DatatableCambiosHogares,
    DatatableCambiosTrazados
  },
  data() {
    return {     

      //Historial
      cargandoHistorial: false,
      page: 1,
      numRegistros: 0,
      registrosPorPagina: 20,
      mensajedeError: '',
      registros: [],  //Lista de operaciones
      
      orden: 'DESC',
    } 
  },
  methods: {
    infoPage(){
      infoAlert('Historial de cambios', 'En este historial puedes ver todos los <strong>cambios</strong> que se han realizado tanto en la <strong>huella</strong> como en los <strong>trazados</strong>. Además puedes expandir cambios en especifico para visualizar los detalles de la operación.')
    },
    ordenarTabla(event, columna){
      let elemento = event.target;
      let ascOrder = true;
      if(elemento.classList.contains('sorting_asc')){
        ascOrder = false;
      }
      if(elemento && elemento.parentElement){
        let ordenables = elemento.parentElement.querySelectorAll('.sorting');
        for (const iterator of ordenables) {
          iterator.classList.remove('sorting_desc', 'sorting_asc');
        }
        if(ascOrder){
          elemento.classList.add('sorting_asc');
          this.orden = 'ASC';
        }else{
          elemento.classList.add('sorting_desc');
          this.orden = 'DESC';
        }  
        this.ordenColumna = columna;
        this.page = 1;
        desexpandirTodo();
        this.cargarRegistros();
      }
      
    },
    //Cargar de la API las operaciones realizadas
    async cargarRegistros(){
      try {
        this.cargandoHistorial = true;
        //Filtros
        // let filtrosParam = { ...this.filtrosEstablecidos };
        // if(filtrosParam.HASTA) filtrosParam.HASTA += ' 23:59:59' //Se añaden tiempo en el parametro hasta para que sea el dia completo y no desde el principio
        
        //Petición
        let respuestaPandora = await axios.get('/historial/influencia', {
          method: 'GET',
          params: {page: this.page, registrosPorPagina: this.registrosPorPagina, orden: this.orden},
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.registros = respuestaPandora.data.data.registrosList;
        this.numRegistros = respuestaPandora.data.data.numRows;
        this.cargandoHistorial = false;
      } catch (error) {
         this.cargandoHistorial = false;
          console.log(error);
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },
    async cambiarPagina(page){
      this.page = page;
      await this.cargarRegistros();
    },
    async buscarCambio(registro, acordeon){
      try{
        //Cerramos los registros buscados hasta el momento
        if(registro.DATOS){
          registro.DATOS = ''
          this.$forceUpdate()
          return;
        }
        for (const iterator of this.registros) {
          iterator.DATOS = ''
        }
        
        let respuestaPandora = await axios.get('/historial/cambioInfluencia', {
          method: 'GET',
          params: { uid: registro.uid },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        let { uid, cambio } = respuestaPandora.data.data
        console.log(cambio);
        if(!uid || !cambio){
          errorAlert("No se pudo buscar el cambio en el historial")
          return;
        }
        registro.DATOS = cambio;
        this.$forceUpdate()
      } catch (error) {
          console.log(error);
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          errorAlert("No se pudo buscar el cambio en el historial", error.response.data.msg)
        } else if (error.request) {
          // The request was made but no response was received
          serverErrorAlert("Error contactando con el servidor")
        } else {
          // Something happened in setting up the request that triggered an Error
          errorAlert("Se produjo un error genérico", error.message || error)
        }
      }
    },
    animarCollapse(id){
      animarCollapse(id);
    },
  },
  created(){
    this.cargarRegistros();
  },
  computed: {
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },
  }
}
</script>

<style scoped>
.firstColumn{
  width: 17rem;
}
.acciones {
  width: 9rem;
}
.accion-btn {
  width: 13rem;
}

</style>
