<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline justify-content-start">
                <h1>Historial completo</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn fw-bold mx-2 accion-btn" :class="[tipoHistorial=='operadores' ? 'btn-primary' : 'btn-secondary']" @click="setModoOperadores()">
                  Historial operadores
              </button>
              <button type="button" class="btn fw-bold mx-2 accion-btn"  :class="[tipoHistorial=='administracion' ? 'btn-primary' : 'btn-secondary']" @click="setModoAdministracion()">
                  Historial administración
              </button>
            </div>
            <!-- <ul class="nav nav-tabs noselect justify-content-center">
              <li class="nav-item">
                <a class="nav-link fs-6" :class="{ active: tipoHistorial=='operadores' }" @click="setModoOperadores()">Historial operadores</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-6" :class="{ active: tipoHistorial=='administracion' }" @click="setModoAdministracion()">Historial administración</a>
              </li>
            </ul> -->
            <HCHistorialTodosOperadores v-if="tipoHistorial=='operadores'"></HCHistorialTodosOperadores>
            <HCHistorialAdministracion v-if="tipoHistorial=='administracion'"></HCHistorialAdministracion>

          </div>

      </div>

      
  </div>
</template>

<script>
import { infoAlert } from '../helpers/fastAlert';
import HCHistorialTodosOperadores from "@/components/HCHistorialTodosOperadores"
import HCHistorialAdministracion from "@/components/HCHistorialAdministracion"

export default {
  /*

  */
  name: 'HistorialAdmin',
  components: {
    HCHistorialTodosOperadores,
    HCHistorialAdministracion
  },
  data() {
    return {
      tipoHistorial: 'operadores'
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Historial completo', 'En esa página podrás encontrar una lista de registros con todas las operaciones que ha realizado tu operador.')
    },
    setModoOperadores(){
      this.tipoHistorial = 'operadores';
    },
    setModoAdministracion(){
      this.tipoHistorial = 'administracion';
    }
  },
}
</script>

<style scoped>

.accion-btn {
  width: fit-content;
}

</style>
