<template>
  <div>
    <b-navbar toggleable="xs" variant="white" class="px-2 my-navbar shadow-lg">


      <div class="w-100 d-flex flex-row">       
        <div class="d-flex flex-fill justify-content-between">
          
          <div class="d-flex">
            <b-navbar-toggle target="my-sidebar" class="nav-toggle"></b-navbar-toggle>
            <!-- Buscador -->
            <div v-if="searchPage" class="d-flex my-1" >
              <div class="input-group">
                  <input id="buscador" type="search" class="form-control bg-light border-0 mt-0" placeholder="Buscar..." aria-label="Search" v-model="busqueda" @input="buscar()">
              </div>
            </div>
          </div>

          <!-- Perfil desplegable del usuario -->
          <div class="profile-menu"> 
            <i class="fa-solid fa-headset align-middle clickable fa-lg" @click="abrirTicket()" title="Abrir ticket"></i>
            <div class="vr align-middle mx-3"></div>
            <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none ps-0 noselect" no-caret>
              <template #button-content>
                <span>{{usuario}}</span> <i class="fa-solid fa-user"></i>
              </template>
                <!-- Contenido del desplegable -->
                <b-dropdown-item @click="cambiarPassword()"><i class="fa-solid fa-key me-1 "></i>Cambiar contraseña</b-dropdown-item>
                <b-dropdown-item @click="disconect()"><i class="fa-solid fa-right-from-bracket me-1"></i>Desconectar</b-dropdown-item>
            </b-dropdown>
          </div>

 
        </div>

      </div>
      

      <b-collapse id="nav-collapse" is-nav>
      
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
const searchablePages = ['Reservas', 'Operadores']
import { mapState, mapActions } from 'vuex';
import { enviarTicket } from '../helpers/enviarTicket';
import { desconectar, getJWTpayload } from '../helpers/JWT';
import { recalcularAltura} from '../helpers/utils'

export default {
  name: 'Navbar',
  data() {
    return {
      busqueda: '',
    }
  },
  methods: {
    ...mapActions(['setUsuario']),
    disconect(){
      desconectar();
    },
    cambiarPassword(){
      this.$router.push({name: 'Password'})
    },
    /*
      El buscador busca todos los elementos con clase searchable, dentro de ese elemento los hijos que tienen la clase
      searchable-text es el texto que hay que comprobar. Por lo tanto si alguno de los searchable-text coincide con el
      texto de busqueda se mantiene y sino se oculta.
    */
    buscar(){
      recalcularAltura();
      let searchables = document.querySelectorAll('.searchable');
      for (const item of searchables) {
        let valido = false;
        item.classList.remove('hidden');
        let textElements = item.querySelectorAll('.searchable-text');
        for (const e of textElements) {
          let texto = e.innerText.toLowerCase();
          let busqueda = this.busqueda.toLowerCase();
          if(texto.includes(busqueda)) valido = true;
        }
        if(!valido) item.classList.add('hidden');
      }
    },
    abrirTicket(){
      let email = getJWTpayload().email;
      let operador = getJWTpayload().operador;
      enviarTicket('', this.usuario,'', operador);
    }
  },
  computed: {
    ...mapState(['usuario']),
    /*
      Solo mostramos el buscador si la página está en el array searchablePages
    */
    searchPage(){
      return searchablePages.includes(this.$route.name) ? true : false;
    }

  },
  created() {
    this.setUsuario();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.nav-logo {
  width: 8rem;
  border-right: solid 1px;
  border-color: lavenderblush;
}

.nav-toggle {
  box-shadow: 0 0 0 0 !important;
  border: inherit !important;
  transition: all .5s ease-in-out !important;
}
.nav-toggle[aria-expanded=true] {
  transform: rotate(180deg);
}

.profile-menu button {
  font-size: 1rem !important;
 color: inherit !important;
 box-shadow: 0 0 0 0 !important;
}

  .profile-menu button:hover {
   opacity: 0.90;
  }


.profile-menu ul {
 right: 0;
 top: 3rem;
}

.my-navbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 600;
}

</style>
