<template>
 <div class="row justify-content-center loginForm">
            
            <b-overlay :show="cargando" rounded="sm">

            <div class="card border-0 shadow-lg my-5">
                  
                    <div class="p-4">
                        <h1 class="h4 mb-4">Reinicio de contraseña</h1>

                        <form class="user d-flex flex-column text-start gap-3" @submit.prevent="passChange" novalidate>
                                <!-- Contraseña -->
                                <input type="password" required maxlength="65" v-model="$v.newpassword.$model" placeholder="Nueva contraseña" class="form-control" :class="{ 'is-invalid': $v.newpassword.$error}" >
                                  <small v-show="$v.newpassword.$dirty && !$v.newpassword.required" class="text-danger">
                                    Debe de introducir una contraseña nueva
                                  </small>
                                  <small v-show="$v.newpassword.$dirty && !$v.newpassword.minLength" class="text-danger">
                                    La nueva contraseña debe de tener como mínimo 6 caracteres
                                  </small>
                                  <small v-show="$v.newpassword.$dirty && !$v.newpassword.maxLength" class="text-danger">
                                    No puede superar los 65 caracteres
                                  </small>

                                <!-- Repetir Contraseña -->
                                <input type="password" required maxlength="65" v-model="$v.newpassword2.$model" placeholder="Repita la nueva contraseña" class="form-control" :class="{ 'is-invalid': $v.newpassword2.$error}" >
                                  <small v-show="$v.newpassword2.$dirty && !$v.newpassword2.required" class="text-danger">
                                    Debe de repetir la nueva contraseña
                                  </small>
                                  <small v-show="$v.newpassword2.$dirty && !$v.newpassword2.minLength" class="text-danger">
                                    La nueva contraseña debe de tener como mínimo 6 caracteres
                                  </small>
                                  <small v-show="$v.newpassword2.$dirty && !$v.newpassword2.sameAs" class="text-danger">
                                    Las contraseñas no coinciden
                                  </small>                                  
                                  <small v-show="$v.newpassword2.$dirty && !$v.newpassword2.maxLength" class="text-danger">
                                    No puede superar los 65 caracteres
                                  </small>
                            <!-- Botones -->
                            <div class="d-flex justify-content-evenly align-content-center">
                                <button type="button" v-if="!$route.params.firstTime" :disabled="cargando" class="btn btn-danger mt-3 pass-btn" @click="$router.push({name: 'Login'})">
                                  Cancelar
                                </button>     
                                <button type="submit" :disabled="$v.$invalid || cargando" class="btn btn-primary mt-3 pass-btn">
                                  <i v-if="!cargando" type="button" class="fa-solid fa-key fa-1x mx-1" title="Password"></i>
                                  <div v-else class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Cargando cambio de contraseña...</span>
                                  </div>
                                  Cambiar
                                </button>
                            </div>
                        </form>
                    </div>

            </div>
            </b-overlay>

 
    </div>
</template>


<script>

import axios from "axios";
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert } from '../helpers/fastAlert';
import { verificarJWT, desconectar } from '../helpers/JWT';

import Swal from 'sweetalert2'

export default {
  
  name: 'PassReset',
  data() {
    return {
      cargando: false,
      newpassword: '',
      newpassword2: '',
    }
  },
  validations: {
    newpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(65)
    }, 
      newpassword2: {
      required,
      sameAs: sameAs('newpassword'),
      minLength: minLength(6),
      maxLength: maxLength(65)
    }
  },
  methods: {
    async passChange() {
       if(!this.$route.query.token || !this.$route.query.email){
          this.tokenInvalido();
       }
          try {
            this.cargando = true;
            
            let respuesta = await axios({
              method: 'PUT',
              url: '/auth/pwdReset',
              data: { 
                'token': this.$route.query.token,
                'email': this.$route.query.email,
                'newpassword': this.newpassword,
                'newpassword2': this.newpassword2
            }
            });
            Swal.fire({
              title: "Contraseña cambiada",
              html: respuesta.data.msg,
              icon: 'success',
              confirmButtonText: 'Vale'
            }).then( () => {
              this.$router.push({name: 'Login'})
            })
            this.cargando = false;
          } catch (error) {
            this.cargando = false;
              if (error.response && error.response.data && error.response.data.msg) {
                //Request made and server responded
                errorAlert('No se pudo cambiar la contraseña', error.response.data.msg)
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                noResponseAlert();
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                serverErrorAlert(error.message);
              }
              this.newpassword = '';
              this.newpassword2 = '';
              this.$v.$reset();
          }
    },
    async tokenInvalido(){
        await Swal.fire({
          title: "Token inválido",
          text: "El token para cambiar la contraseña es inválido o ha caducado, por favor inténtelo de nuevo.",
          icon: 'error',
          confirmButtonText: 'Vale'
        }).then( () => {
          this.$router.push({name: 'Login'})
        })
    }
    
  },
  created() {
    //Si la ruta tiene el parametro firstTime significa que es un nuevo usuario y tiene que cambiar la contraseña obligatoriamnete
    if(!this.$route.query.token || !this.$route.query.email){
      this.tokenInvalido();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.pass-btn{
  width: 8rem;
}

</style>
