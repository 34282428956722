
<template>
  <Transition name="modal" >
    <div v-if="show" class="modal-mask" >

      <div class="modal-wrapper">
      <b-overlay :show="cargando_consulta" class="m-2 p-2 shadow-lg">
        <div class="modal-container">
          <div class="modal-header">
            <div class="d-flex align-items-baseline mx-4 my-4" title="Cambio CTO">
                <h4 name="header">Lista de CTOs disponibles</h4>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoCambioCTO()"></i>
            </div> 
            <i type="button" class="close xxl-font" title="Cerrar" data-dismiss="modal" aria-label="Close" @click="cerrarModal()" >
              <span aria-hidden="true">&times;</span>
            </i>
          </div>
          <!-- Motivo cambio -->
          <div class="row d-flex align-items-center flex-column flex-md-row m-3">
            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex">
              <label for="MOTIVO_DE_CAMBIO" class="line mx-1 d-flex">Motivo de cambio:</label>
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
              <div class="d-flex flex-row m-1">
                  <select id="MOTIVO_DE_CAMBIO" class="form-control" maxlength="255" v-model="MOTIVO_DE_CAMBIO">                                
                    <option value='código cto incorrecto' selected>CODIGO_CTO incorrecto</option>
                    <option value='ocupado'>CTO ocupada</option>
                    <option value='averiado'>CTO averiada</option>
                    <option value='cto lejana/cercana'>CTO lejana/cercana</option>
                    <option value='cto inaccesible'>CTO inaccesible</option>
                    <option value='cto ilocalizable'>CTO ilocalizable</option>
                    <option value='cto sin potencia'>CTO sin potencia</option>
                    <option value='cto saturada'>CTO saturada</option>
                    <option value='último puerto de cto averiado'>Último puerto de cto averiado</option>
                  </select>
              </div>
            </div>
          </div>
          <!-- Mostrar puertos CTO o mostrar todos los de la poblacion -->
          <div class="row d-flex align-items-center m-3" @click="mostrarSoloCTOItem($event)">
            <div class="col-12 d-flex justify-content-center justify-content-md-start">
              <label checked for="mostrar_solo_cto_cliente" class="form-control-label mx-1 clickable ">Mostrar solo CTO del cliente: </label>
              <input v-model="filtrar_por_misma_cto" checked type="checkbox" name="selectAll" value="all" id="mostrar_solo_cto_cliente">        
            </div>
          </div>          
          <div class="modal-body">
                <!-- Contenido -->

                <div class="table-responsive">
                  <table id="datatable" class="table table-striped table-tiny">
                      <thead>
                        <tr class="noselect">
                          <th scope="col" class="align-middle text-center">CODIGO_CTO</th>
                          <th scope="col" class="align-middle">PUERTO_CTO</th>
                          <th scope="col" class="align-middle">CODIGO_SPLITTER</th>
                          <th scope="col" class="align-middle">PUERTO_SPLITTER</th>
                          <th scope="col" class="align-middle">ESTADO_PUERTO</th>
                          <th scope="col" class="align-middle">ACCION</th>
                        </tr>
                      </thead>
                      <tbody class="align-middle">                   
                      </tbody>
                  </table>
                </div>
          </div>
        </div>
      </b-overlay>                
      </div>
    </div>
  </Transition>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert'
import axios from "axios";
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';

export default {
  name: "ModalSincronizacion",
  props: ['cambiar_cto_padre'],
  data() {
    return {
      show: false,
      item: null,
      cargando_consulta: false,
      lista_ctos: [],
      lista_ctos_filtrada: [],
      tabla: null,
      filtrar_por_misma_cto: false,
      servicio_actual_sincronizado: null,
      MOTIVO_DE_CAMBIO: "código cto incorrecto"
    }
  },
  methods: {
    infoCambioCTO(){
      infoAlert('Cambio de CTO', 'El cambio de CTO se realiza cuando hay un problema con la CTO de un cliente. Se debe de elegir el <strong>motivo del cambio</strong> y la nueva CTO con un puerto libre. El cambio solo afectará al cliente y <strong>no modificará la huella.</strong> <br>Se puede filtrar para mostrar todas las CTO de la población o solo los puertos libres de la CTO del cliente.')
    },
    cerrarModal(){
      this.show = false;
    },
    async abrirModal(item){
      this.show = true;
      this.item = item;
      await this.consulta_cto()
      if(this.lista_ctos.length) {
        this.$nextTick(() => {
          this.initDatatable();
          this.ponerDatosDatatable();
        })
      }
    },
    async consulta_cto(){
        try{
          this.cargando_consulta = true;
          let response = await axios({
            method: 'POST',
            url: '/consulta_cto',
            data: { 'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });            
          this.lista_ctos = response.data.data.LISTA_CTOS.CTO;
          this.lista_ctos_filtrada = response.data.data.LISTA_CTOS.CTO;
          this.cargando_consulta = false;

          
            
        } catch (error) {
            this.cerrarModal()
            this.cargando_consulta = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log(error.response)
              errorAlert('No sincronizando', error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              noResponseAlert();
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              serverErrorAlert(error.message);
            }
        }
    },
    mostrarSoloCTOItem(event){
      if(event.target && event.target.checked){
        this.lista_ctos_filtrada = this.lista_ctos.filter(e => e.CODIGO_CTO == this.item.CODIGO_CTO);
      }else{
        this.lista_ctos_filtrada = this.lista_ctos;
      }
      this.ponerDatosDatatable()
    },
    initDatatable(){
      this.tabla = $('#datatable').DataTable({
              autoWidth: false,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                { title: 'Código CTO', data: 'CODIGO_CTO' },
                { title: 'Puerto CTO', data: 'PUERTO_CTO' },
                { title: 'Código SP2', data: 'CODIGO_SP2' },
                { title: 'Puerto SP2', data: 'PUERTO_SP2' },
                { title: 'Estado', data: 'ESTADO_PUERTO' },
                {
                  title: 'Acción',
                  data: null,
                  defaultContent:  `
                    <i type="button" class="fa-solid fa-screwdriver fa-2x mx-1 icon-btn text-primary btn-accion" title="Cambiar a esta CTO y puertos"></i>
                  `,
                  className: 'dt-body-center',
                  width: '3rem',
                }                
              ]    
        });        
        // Agregar un evento clic para cambiar la cto
        $('#datatable tbody').on('click', 'i.btn-accion', (event) => {
          const rowData = this.tabla.row($(event.target).parents('tr')).data();
          this.cambiar_cto(rowData);
        });       

    },
    ponerDatosDatatable(){
      this.tabla.clear();
      this.tabla.rows.add(this.lista_ctos_filtrada).draw();
    },
    async sincronizar_cliente(){
        try{
          this.cargando_consulta = true;
          let response_sync = await axios({
            method: 'PUT',
            url: '/servicios/sync',
            params: { 'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });            
          console.log('Servicio actual: ', response_sync);
          
          let { codigo_cto: CODIGO_CTO, splitter_id: CODIGO_SPLITTER, puerto_cto: PUERTO_CTO,  } = response_sync.data.cliente.coverage.network_trace;
          this.servicio_actual_sincronizado = {
            CODIGO_CTO,
            CODIGO_SPLITTER,
            PUERTO_CTO
          }
          this.cargando_consulta = false;
          
            
        } catch (error) {
            this.cargando_consulta = false;
            if (error.response_sync && error.response_sync.data && error.response_sync.data.msg) {
              //Request made and server responded
              console.log(error.response_sync)
              errorAlert('Error cambiando CTO', error.response_sync.data.msg)
            } else if (error.request) {
              // The request was made but no response_sync was received
              console.log(error.request);
              noResponseAlert();
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              serverErrorAlert(error.message);
            }
        }
    },
    async cambiar_cto(cto_destino){

        //Si se pulsa cuando se está haciendo un cambio no se hace nada
        if(this.cargando_consulta) return;
        if(!this.MOTIVO_DE_CAMBIO) {
          errorAlert("Error cambiando CTO", "No se ha elegido MOTIVO_DE_CAMBIO");
          return
        }

        await this.sincronizar_cliente()
        try{
          if (!this.servicio_actual_sincronizado) return;
          console.log("Se ha sincronizado el servicio");

          this.cargando_consulta = true;
          let response = await axios({
            method: 'POST',
            url: '/cambio_cto',
            data: { 
              'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO,
              "CODIGO_CTO_ORIGEN": this.servicio_actual_sincronizado.CODIGO_CTO,
              "CODIGO_SP2_ORIGEN": this.servicio_actual_sincronizado.CODIGO_SPLITTER,
              "PUERTO_SP2_ORIGEN": parseInt(this.servicio_actual_sincronizado.PUERTO_CTO),
              "CODIGO_CTO_FINAL":  cto_destino.CODIGO_CTO,
              "PUERTO_CTO_FINAL":  parseInt(cto_destino.PUERTO_CTO),
              "CODIGO_SP2_FINAL":  cto_destino.CODIGO_SP2,
              "PUERTO_SP2_FINAL":  parseInt(cto_destino.PUERTO_SP2),
              "MOTIVO_DE_CAMBIO":  this.MOTIVO_DE_CAMBIO
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });            

          successAlert("Proceso ejecutado", response.data.msg)
          this.cargando_consulta = false;
          this.cambiar_cto_padre(cto_destino.CODIGO_CTO)
          this.cerrarModal()
            
        } catch (error) {
            this.cargando_consulta = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log(error.response)
              errorAlert('Error cambiando CTO', error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              noResponseAlert();
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              serverErrorAlert(error.message);
            }
        }
    }

  },
  created() {
  },


}
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  max-height: 90vh;
  margin: 2rem auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>