<template>
    <b-sidebar 
      id="my-sidebar"
      no-header
      visible
      shadow
      aria-label="Asteo-sidebar"
      bg-variant="dark"
      text-variant="light"
      backdrop v-model="visible"  width="18rem">
          <b-container>
              <router-link :to="{ name: 'Welcome' }"><img alt="Logo Asteo" src="../assets/LOGO-ASTEO.svg" class="w-75 pe-2 pt-2"></router-link>
              <hr>
              
              <div class="text-start px-2">
                <!-- Usuario -->
                <div>
                  <span class="fs-4">Usuario</span>
                  <ul class="list-unstyled ul-group"> 
                      <router-link v-for="(item, index) in filteredUserLinks" :key="index" :to="{ name: item.routerName }" class="text-decoration-none fs-6 list-group-item border-0 my-1 text-light li-link animated" :class="actual(item.routerName)">
                        <div>
                          <i  :class="item.icon" class="me-1"></i>
                          {{item.name}}
                        </div>
                      </router-link>
                  </ul>
                </div>    
                <!-- ADMIN -->
                <!-- TODO asignar por roles -->
                <div v-if="includesAllowedRols(['INTERNOASTEO', 'CREADORINFLUENCIA', 'EDITORINFLUENCIA'])">
                  <span class="fs-4">Administrador</span>
                  <ul class="list-unstyled ul-group"> 
                      <router-link v-for="(item, index) in filteredAdminLinks" :key="index" :to="{ name: item.routerName }" class="text-decoration-none fs-6 list-group-item border-0 my-1 text-light li-link animated" :class="actual(item.routerName)">
                        <div >
                          <i :class="item.icon" class="me-1"></i>
                          {{item.name}}
                        </div>
                      </router-link>
                  </ul>
                </div>                        
              </div>
          </b-container>  
    </b-sidebar>
</template>


<script>
import { contieneAlgunRolPermitido, getJWTpayload, isEditorInluencia, isInternoAsteo, isOperadorAdmin, isOperadorAdministrativo, isOperadorTecnico, isSuperAdmin } from '../helpers/JWT';

export default {
  name: 'Sidebar',
  data() {
    return { 
      visible: false,
      sidebarElements: {
        userLinks: {
          name: "Usuario", 
          links: [ 
            { name: "Dashboard", routerName: "Welcome", icon: "fa-solid fa-house-chimney", rolesPermitidos: ['ALL']}, 
            { name: "Auto Provisión", routerName: "AutoProvisionar", icon: "fa-solid fa-house-laptop", rolesPermitidos: ['SUPERADMINISTRADOR', 'OPERADORADMIN', 'OPERADORTECNICO']}, 
            { name: "Reservar", routerName: "Reservar", icon: "fa-solid fa-download", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO']}, 
            { name: "Mis reservas", routerName: "Reservas", icon: "fa-solid fa-box-archive", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO', 'OPERADORTECNICO']}, 
            { name: "Clientes serviciados", routerName: "Servicios", icon: "fa-solid fa-coins", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO', 'OPERADORTECNICO']},
            { name: "Reportes", routerName: "ReportesOperador", icon: "fa-solid fa-file-lines", rolesPermitidos: ['OPERADORADMIN']},
            { name: "Cambio de perfil masivo", routerName: "CambioPerfilMasivo", icon: "fa-solid fa-gauge-high", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN']},
            { name: "Buscar cobertura", routerName: "BuscarCobertura", icon: "fa-solid fa-map", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO']},
            { name: "Usuarios", routerName: "UsuariosOperador", icon: "fa-solid fa-user-gear", rolesPermitidos: ['OPERADORADMIN']},
            { name: "Historial", routerName: "Historial", icon: "fa-solid fa-clock-rotate-left", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO', 'OPERADORTECNICO']}]
        },
        adminLinks: {
          name: "Administrador",
          links: [ 
            { name: "Nuevo usuario", routerName: "NuevoUsuario", icon: "fa-solid fa-user-plus", rolesPermitidos: ['SUPERADMINISTRADOR']}, 
            { name: "Operadores", routerName: "Operadores", icon: "fa-solid fa-users-gear", rolesPermitidos: ['SUPERADMINISTRADOR']},
            { name: "Notificar", routerName: "Notificar", icon: "fa-solid fa-bullhorn", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
            { name: "Servicios y test", routerName: "ServiciosTest", icon: "fa-solid fa-rocket", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
            { name: "Reportes", routerName: "Reportes", icon: "fa-solid fa-file-lines", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
            { name: "Gestión AI", routerName: "GestionAI", icon: "fa-solid fa-map-location-dot", rolesPermitidos: ['SUPERADMINISTRADOR', 'CREADORINFLUENCIA', 'EDITORINFLUENCIA']},
            { name: "Municipios y OLTs", routerName: "MunicipiosYOlts", icon: "fa-solid fa-mountain-city", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'CREADORINFLUENCIA', 'EDITORINFLUENCIA']},
            { name: "Panel de administración", routerName: "PanelAdmin", icon: "fa-solid fa-cogs", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
            { name: "Historial completo", routerName: "HistorialAdmin", icon: "fa-solid fa-clock-rotate-left", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
          ]
        }
      }
    
      
    }
  },
  methods: {
    actual(navActual) {
      return this.$route.name == navActual ? 'active' : '';
    },
    esSuperAdmin(){
      return isSuperAdmin()
    },
    includesAllowedRols(allowedRols){
      return contieneAlgunRolPermitido(allowedRols)
    },
    esOperadorAdministrativo(){
      return isOperadorAdministrativo()
    },
    esOperadorTecnico(){
      return isOperadorTecnico()
    }
  },
  computed: {
    filteredUserLinks(){
        let payload = getJWTpayload();
        let links = this.sidebarElements.userLinks.links;
        if (payload.roles) {
          links = links.filter(e => e.rolesPermitidos.some(r => payload.roles.indexOf(r) >= 0) || e.rolesPermitidos.includes("ALL"))
        }
        return links;
    },
    filteredAdminLinks(){
        let payload = getJWTpayload();
        let links = this.sidebarElements.adminLinks.links;
        if (payload.roles) {
          links = links.filter(e => e.rolesPermitidos.some(r => payload.roles.indexOf(r) >= 0) || e.rolesPermitidos.includes("ALL"))
        }
        return links;
    }
  }
}
</script>

<style>


.ul-group .li-link{
  background: var(--dark);
  transition: all .15s ease-in;
  border-radius: 5px !important;
  opacity: 1;
}


.ul-group .li-link:hover {
  background: hsla(0,0%,100%,.13);
}

.ul-group .li-link.active{
  background: hsla(0,0%,100%,.23);
}


</style>
