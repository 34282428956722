<template>

    <div class="card page-card border-0 shadow-lg my-5">
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Importador de AI</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>
      <!-- ############### -->
      <!-- Huella Importer -->
      <!-- ############### -->
      <div class="m-3 card shadow">
          <div class="card-body"> 
            <!-- header -->
            <div class="d-flex align-items-baseline justify-content-start">
                <h4>Importador huella</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoHuellaImporter()"></i>
            </div>
            <!-- Body -->
            <div class="d-flex justify-content-center my-4">
              <input id="csvfile" name="csvfile" type="file" accept=".csv" ref="csvInput" @input="startImport()" hidden>
              <button for="csvfile" type="button" class="btn fw-bold mx-2 btn-primary accion-btn" :disabled="cargandoPeticion" @click="clickHiddenInput()" >
                <i v-if="!cargandoPeticion" type="button" class="fa-solid fa-file-import fa-1x mx-1" title="Importar csv"></i>
                <div v-else class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Importar huella
              </button>
              <!-- Botón Descargar Plantilla -->
              <button type="button" class="btn fw-bold mx-2 btn-success accion-btn" @click="downloadTemplate()">
                <i type="button" class="fa-solid fa-file-csv fa-1x mx-1" title="Descargar plantilla de huella Asteo"></i>
                Descargar plantilla
              </button>
              <button type="button" class="btn fw-bold mx-2 btn-danger accion-btn" :disabled="cargandoPeticion || !csvFile" @click="cleanHuellaInputFile()">
                  <i type="button" class="fa-solid fa-trash-alt fa-1x mx-1" title="Limpiar archivo"></i>
                  Limpiar
              </button>
            </div>
          </div>
      </div>

      <!-- ################ -->
      <!-- Previsualización -->
      <!-- ################ -->
      <!-- Omitida por incompatibilidad -->

    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert, warningAlert } from '../../helpers/fastAlert';
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import axios from "axios";
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from 'sweetalert2'
import { montarNombreCalle, scrollToTop } from '../../helpers/utils';
import { fecha } from '../../classes/fecha.class';
const expectedHeaders = [
        "ID_DOMICILIO", "CODIGO_POSTAL", "PROVINCIA", "POBLACION", "TIPO_VIA", 
        "NOMBRE_VIA", "ID_TECNICO", "NUMERO", "BIS", "BLOQUE_FINCA", 
        "IDENTIFICADOR_FINCA", "LETRA_FINCA", "ESCALERA", "PLANTA", "MANO_1", 
        "MANO_2", "OBSERVACIONES", "FLAG_DUMMY", "CODIGO_INE", "CODIGO_CENSAL", 
        "CODIGO_PAI", "CODIGO_OLT", "CODIGO_CTO", "TIPO_CTO", "DIRECCION_CTO", 
        "TIPO_PERMISO", "TIPO_CAJA_DERIVACION", "NUMERO_UI", "NUMERO_VIVIENDAS", 
        "FECHA_ALTA"
      ]
export default {
  name: 'AIImporter',
  data() {
    return {
      // CSV File
      csvFile: null,

      //Nuevo municipio
      mensajedeError: '',
      cargandoPeticion: false,
      cargandoDatatable: false,

      cargandoPrevisualizacion: false,
      lista_previsualizacion: [],
      tablaPrevisualizacion: '',

      descripcionPeticionRevision: '',
      cargandoEnvioPeticionRevision: false,

      revision: ''
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Importador de AI', 'Desde el importador de AI podrás hacer importaciones masivas de huella y de trazados.')
    },
    infoHuellaImporter(){
      infoAlert('Importador de huella', 'Crea o actualiza registros de huella automáticamente. Si el <strong>ID_DOMICILIO</strong> no existe, se creará una nueva UUII, si existe, se actualizará la UUII.')
    },
    infoPrevisualizacion(){
      infoAlert('Previsualización', 'La previsualización es un resumen donde podrás ver todos los domicilios que has creado y además editarlos o borrarlos. Cuando consideres que la propuesta está lista, podrás enviarla para que se valide y se añada a la huella.')
    },      
    infoPeticionRevision(){
      infoAlert('Enviar petición de revisión', 'Cuando tengas una aportación a la huella podrás enviar una petición de revisión. Un técnico de Asteo la <strong>validará y confirmará</strong> si procede. Escribe una descripción clara como por ejemplo: <br> "<i>Nuevo municipio añadido, Aljucén</i>".')
    },  
    async startImport() {

      // Load CSV locally
      this.loadCSV();

      // Make an initial validation
      if(!await this.validateCSV()) return;

      // Send CSV to backend and import
      this.sendImportRequest()
    },
    loadCSV(){
      const input = this.$refs.csvInput;
      if (input.files && input.files[0]) {
        this.csvFile = input.files[0];
      }
    },
    async validateCSV(){    
      return new Promise((resolve, reject) => {
        // File exists
        if (!this.csvFile) {
          errorAlert("No se ha encontrado ningún archivo.");
          return resolve(false);
        }
        // File is CSV
        const fileName = this.csvFile.name;
        if (!fileName.endsWith('.csv')) {
          errorAlert("El archivo seleccionado no es un archivo CSV.");
          return resolve(false);
        }
        // File headers
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          const lines = content.toUpperCase().split('\n');
          const firstLine = lines[0];
          const semicolonCount = (firstLine.match(/;/g) || []).length;
          if (semicolonCount < 5) {
            errorAlert('El delimitador del CSV debe ser punto y coma (;)');
            return resolve(false);
          }
          const headers = firstLine.split(";").map(header => header.trim());
          const missingHeaders = expectedHeaders.filter(header => !headers.includes(header));
          if (missingHeaders.length > 0) {
            errorAlert(`Faltan las siguientes cabeceras obligatorias:`, `${missingHeaders.join(', ')}`);
            return resolve(false);
          }
          resolve(true);
        }
        reader.onerror = () => {
          errorAlert("Error leyendo el archivo.");
          reject(false);
        };
  
        reader.readAsText(this.csvFile);      
      })  
    },    
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      const headers = Object.keys(array[0]).join(';');
      const rows = array.map(obj => Object.values(obj).join(';'));

      return [headers, ...rows].join('\n');
    },
    async sendImportRequest(){
      try {        
          this.cargandoPeticion = true;
          const formData = new FormData();
          formData.append('file', this.csvFile);

          const respuesta = await axios({
            method: 'POST',
            url: '/hogares/importer',
            data: formData,
            headers: { 
              'Content-Type': 'multipart/form-data', 
              'x-token': localStorage.getItem('x-token') 
            }
          });
          // SUCCESS
          const { result, processed_huella: processedHuella } = respuesta.data.data;

          // Crear CSV para 'processed_huella'
          let processedCsvContent = '';
          let processedUrl = '';
          if (processedHuella) {
            processedCsvContent = this.convertToCSV(processedHuella);
            const processedBlob = new Blob([processedCsvContent], { type: 'text/csv;charset=utf-8;' });
            processedUrl = URL.createObjectURL(processedBlob);
          }

          // Generar tabla para mostrar los resultados (keys y values de 'result')
          let htmlTable = '';
          if (result && typeof result === 'object') {
              htmlTable += '<table class="table table-bordered text-start">';
              htmlTable += '<tbody>';
              for (const key in result) {
                  if (result.hasOwnProperty(key)) {
                      htmlTable += `
                          <tr>
                              <th class="table-dark w-50">${key}</th>
                              <td class="table-light w-50">${result[key]}</td>
                          </tr>
                      `;
                  }
              }
              htmlTable += '</tbody>';
              htmlTable += '</table>';
          }

          // Mostrar Swal con los resultados y botón de descarga para 'processed_huella'
          Swal.fire({
            title: 'Importación completada',
            icon: 'success',
            html: `
              ${htmlTable}
            `,
            showCancelButton: true,
            cancelButtonText: 'Cerrar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Descargar CSV validado',
            preConfirm: () => {
              // Descargar el CSV procesado cuando se hace clic en confirmar
              if (processedUrl) {
                const processedLink = document.createElement('a');
                processedLink.href = processedUrl;
                processedLink.setAttribute('download', 'processed_huella_import.csv');
                document.body.appendChild(processedLink);
                processedLink.click();
                document.body.removeChild(processedLink);
              }
            }
          });

          this.cleanHuellaInputFile()
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            if (error.response.data.invalid_csv || error.response.data.processed_huella) {
              
              // CSV para registros
              let invalidCsvContent = '';
              let processedCsvContent = '';
              let invalidUrl = '';
              let processedUrl = '';

              // CSV para registros inválidos
              if (error.response.data.invalid_csv) {
                invalidCsvContent = this.convertToCSV(error.response.data.invalid_csv);
                const invalidBlob = new Blob([invalidCsvContent], { type: 'text/csv;charset=utf-8;' });
                invalidUrl = URL.createObjectURL(invalidBlob);
              }

              // CSV para registros procesados completos
              if (error.response.data.processed_huella) {
                processedCsvContent = this.convertToCSV(error.response.data.processed_huella);
                const processedBlob = new Blob([processedCsvContent], { type: 'text/csv;charset=utf-8;' });
                processedUrl = URL.createObjectURL(processedBlob);
              }

              // Alerta con dos botones para descargar ambos archivos CSV
              Swal.fire({
                title: '¡Validación no superada!',
                icon: 'error',
                html: `
                <span class="text-center">${error.response.data.msg || 'El formato del CSV no es correcto.'}</span>
                <div class="d-flex justify-content-center mt-4 mb-0">            
                  <button id="download-processed" class="swal2-confirm swal2-styled" style="background-color: #28a745; min-width: 12rem;">
                    <i class="fas fa-download"></i>&nbspCSV procesado
                  </button>
                  <button id="download-invalid" class="swal2-confirm swal2-styled" style="background-color: #FFA500; min-width: 12rem;">
                    <i class="fas fa-download"></i>&nbspCSV errores
                  </button>
                </div>
                `,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'Cerrar',
                allowOutsideClick: false, // Evitar que se cierre al hacer clic fuera
                allowEscapeKey: false,   // Evitar que se cierre con la tecla Esc
                customClass: {
                  cancelButton: 'mt-0' // Clase personalizada
                },                
                didRender: () => {
                  // Manejar el clic en "Descargar CSV inválido"
                  document.getElementById('download-invalid').addEventListener('click', () => {
                    if (invalidUrl) {
                      const invalidLink = document.createElement('a');
                      invalidLink.href = invalidUrl;
                      invalidLink.setAttribute('download', 'invalid_huella_import.csv');
                      document.body.appendChild(invalidLink);
                      invalidLink.click();
                      document.body.removeChild(invalidLink);
                    }
                  });

                  // Manejar el clic en "Descargar CSV procesado"
                  document.getElementById('download-processed').addEventListener('click', () => {
                    if (processedUrl) {
                      const processedLink = document.createElement('a');
                      processedLink.href = processedUrl;
                      processedLink.setAttribute('download', 'processed_huella_import.csv');
                      document.body.appendChild(processedLink);
                      processedLink.click();
                      document.body.removeChild(processedLink);
                    }
                  });
                },
              });

            } else {
              // Mostrar solo la alerta de error si no hay CSV
              errorAlert('Error importando', error.response.data.msg || '');
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
          }
        }
    },
    clickHiddenInput() {
      Swal.fire({
                title: 'Importar huella',
                html: 
                `
                  <div class="text-start">
                  <span>Estás a punto de importar huella de manera masiva, la importación funciona de la siguiente manera:</span>
                  <br><br>
                  <span><b>ID_DOMICILIO Nuevo:</b> Creación de nueva UUII.</span>
                  <br>
                  <span><b>ID_DOMICILIO Existente:</b> Se actualizan todos los campos de esa UUII.</span>
                  <br><br>
                  <span>Necesitarás cargar un archivo CSV con las UUIIs que quieres importar.</span>
                  <br><br>
                    <span>Deberá validar el siguiente formato: </span>
                    <ul>
                      <li><strong>Formato CSV.</strong></li>
                      <li><strong>Delimitador: </strong>";".</li>
                      <li><strong>Caracteres prohibidos: </strong> [ . , ª, º, ", ', \` ]</li>
                    </ul>
                  <br>
                  <span>Estructura fichero: </span>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped text-start small-font" style="width: max-content">
                      <thead>
                        <tr>
                          <th class="w-auto" scope="col">ID_DOMICILIO</th>
                          <th class="w-auto" scope="col">CODIGO_POSTAL</th>
                          <th class="w-auto" scope="col">PROVINCIA</th>
                          <th class="w-auto" scope="col">POBLACION</th>
                          <th class="w-auto" scope="col">TIPO_VIA</th>
                          <th class="w-auto" scope="col">NOMBRE_VIA</th>
                          <th class="w-auto" scope="col">ID_TECNICO</th>
                          <th class="w-auto" scope="col">NUMERO</th>
                          <th class="w-auto" scope="col">BIS</th>
                          <th class="w-auto" scope="col">BLOQUE_FINCA</th>
                          <th class="w-auto" scope="col">IDENTIFICADOR_FINCA</th>
                          <th class="w-auto" scope="col">LETRA_FINCA</th>
                          <th class="w-auto" scope="col">ESCALERA</th>
                          <th class="w-auto" scope="col">PLANTA</th>
                          <th class="w-auto" scope="col">MANO_1</th>
                          <th class="w-auto" scope="col">MANO_2</th>
                          <th class="w-auto" scope="col">OBSERVACIONES</th>
                          <th class="w-auto" scope="col">FLAG_DUMMY</th>
                          <th class="w-auto" scope="col">CODIGO_INE</th>
                          <th class="w-auto" scope="col">CODIGO_CENSAL</th>
                          <th class="w-auto" scope="col">CODIGO_PAI</th>
                          <th class="w-auto" scope="col">CODIGO_OLT</th>
                          <th class="w-auto" scope="col">CODIGO_CTO</th>
                          <th class="w-auto" scope="col">TIPO_CTO</th>
                          <th class="w-auto" scope="col">DIRECCION_CTO</th>
                          <th class="w-auto" scope="col">TIPO_PERMISO</th>
                          <th class="w-auto" scope="col">TIPO_CAJA_DERIVACION</th>
                          <th class="w-auto" scope="col">NUMERO_UI</th>
                          <th class="w-auto" scope="col">NUMERO_VIVIENDAS</th>
                          <th class="w-auto" scope="col">FECHA_ALTA</th>
                          <th class="w-auto" scope="col">CODIGO_CAJA_DERIVACION</th>
                          <th class="w-auto" scope="col">UBICACION_CAJA_DERIVACION</th>
                          <th class="w-auto" scope="col">GESCAL_37</th>
                          <th class="w-auto" scope="col">COD_POLIGONO</th>
                          <th class="w-auto" scope="col">REF_CATASTRAL</th>
                          <th class="w-auto" scope="col">LATITUD (opcional)</th>
                          <th class="w-auto" scope="col">LONGITUD (opcional)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> Identificador UUII </td>
                          <td> Código postal </td>
                          <td> Provincia </td>
                          <td> Población </td>
                          <td> Tipo de vía </td>
                          <td> Nombre de la vía </td>
                          <td> Gescal 12 </td>
                          <td> Número </td>
                          <td> Bis (opcional) </td>
                          <td> Bloque de la finca (opcional) </td>
                          <td> Identificador de la finca (opcional) </td>
                          <td> Letra de la finca (opcional) </td>
                          <td> Escalera (opcional) </td>
                          <td> Planta </td>
                          <td> Mano 1 (opcional) </td>
                          <td> Mano 2 (opcional) </td>
                          <td> Observaciones (opcional) </td>
                          <td> "0" o "1" </td>
                          <td> Código INE </td>
                          <td> Código Censal </td>
                          <td> Código PAI </td>
                          <td> Código OLT </td>
                          <td> Código CTO </td>
                          <td> Tipo CTO </td>
                          <td> Dirección CTO </td>
                          <td> Tipo de permiso </td>
                          <td> Tipo de caja de derivación </td>
                          <td> Número UI </td>
                          <td> Número de viviendas </td>
                          <td> Fecha de alta </td>
                          <td> Código de caja de derivación </td>
                          <td> Ubicación de caja de derivación </td>
                          <td> GESCAL 37 </td>
                          <td> Código de polígono </td>
                          <td> Referencia catastral </td>
                          <td> Latitud </td>
                          <td> Longitud </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                `,
              icon: 'info',
              confirmButtonText: 'Cargar archivo',
              showCancelButton: true,
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar'
        }).then( result => {
          if(result.isConfirmed && this.$refs.csvInput){
              this.$refs.csvInput.value = null;
              this.$refs.csvInput.click();
          }
        });
    },
    downloadTemplate() {
      const link = document.createElement('a');
      link.href = '/plantilla_huella_asteo.csv';  // Asume que el archivo está en public/
      link.setAttribute('download', 'plantilla_huella_asteo.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    cleanHuellaInputFile() {
      this.csvFile = null;
      this.$refs.csvInput.value = "";
    },      
  },
  created() {
  },
  mounted(){
  },
}
</script>

<style scoped>
.action-column{
  width: 2rem;
}
.tabla-olts{
    max-width: 30rem;
}
</style>
