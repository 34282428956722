<template>
    <div>
      <div class="d-flex justify-content-center mt-4">
        <button type="button" class="btn fw-bold mx-2 accion-btn" :class="[estado=='municipios' ? 'btn-primary' : 'btn-secondary']" @click="setModo('municipios')">
            Municipios
        </button>
        <button type="button" class="btn fw-bold mx-2 accion-btn"  :class="[estado=='olts' ? 'btn-primary' : 'btn-secondary']" @click="setModo('olts')">
            OLTs
        </button>
      </div>
      <Municipios v-if="estado=='municipios'" ref="Municipios"></Municipios>
      <Olts v-if="estado=='olts'" ref="Olts"></Olts>
    </div>
</template>

<script>
import Municipios from "../components/MunicipiosyOlts/Municipios.vue"
import Olts from "../components/MunicipiosyOlts/Olts.vue"

export default {
  name: 'MunicipiosYOlts',
  components: {
    Municipios,
    Olts,
  },
  data() {
    return {
      estado: 'municipios',
    } 
  },
  methods: {
    setModo(nuevoEstado){
      this.estado = nuevoEstado;
    },

  },

}
</script>

<style scoped>
.boton-generar{
  min-width: 10rem;
}

.alert .notification-items{
  max-height: 10rem;
  overflow: auto;
}
@media (min-width: 1200px) {
  .boton-generar {
    max-width: 12rem;
    inline-size: auto;
  }
}
</style>
