<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Test y diagnósticos</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Volver a mis Servicios-->
              <div>
                  <router-link :to="{name: item.editorAdmin ? 'ServiciosTest' : 'Servicios'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fa-solid fa-coins mx-1"></i> Mis servicios</router-link>
              </div>
            </div>
            
            
            <div class="card-body">
              <!-- Tarjeta informativa -->
              <TarjetaServicio :item="item"></TarjetaServicio>

            <!-- Mensaje ticket -->
            <div class="alert alert-info d-flex align-items-center my-2" role="alert">
              <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center w-100">
                
                <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                  <i class="fa-solid fa-circle-exclamation text-info fs-3 mx-2 mb-2 mb-sm-0"> </i>   
                  <div class="align-self-center text-center text-sm-start">
                    ¿Algún problema con este cliente? Abre un ticket y lo solucionaremos.
                  </div>           
                </div>
                <button  type="button" :disabled="haciendoTestParaTicket" class="btn btn-primary btn-sm line mx-2 mt-3 mt-sm-0 w-a" @click="abrirTicket()">
                  {{haciendoTestParaTicket ? 'Cargando datos' : 'Abrir Ticket'}}
                  <div v-if="haciendoTestParaTicket" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Cargando Test...</span>
                  </div>
                </button>
              </div>
            </div>

              <!-- Gestor de test -->
              <div class="m-3 card shadow">
                <div class="card-body"> 
                  <!-- Action card header -->
                  <div class="d-flex align-items-baseline">
                    <h4>Gestor de Tests</h4>
                    <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoGestorTest()"></i>
                  </div>
                  <!-- Select TEST -->
                  <form @submit.prevent="realizarTest()">
                    <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                        <div class="col-12 col-sm-8 col-md-9 col-lg-10">
                          <select id="testid" :disabled="cargandoTest" class="form-select my-1" aria-label="Selector de test" maxlength="128" v-model.lazy="$v.testElegido.$model" @change="resetTest()">                                
                            <option value="" disabled selected>Seleccione Test</option>
                            <option v-for="(testItem, index) in testPermitidos" :key="index" :value="testItem.codigo">{{testItem.descripcion}}</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                            <button type="submit" :disabled="$v.$invalid || cargandoTest" class="btn btn-warning text-white w-100 launch-btn">
                              <i v-if="!cargandoTest" type="button" class="fa-solid fa-rocket fa-1x mx-1" title="Test"></i>
                              <div v-else class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Cargando Test...</span>
                              </div>
                              Lanzar
                            </button>
                        </div>
                    </div>
                  </form>
                  <!-- Action card -->
                  <div v-if="resultadoTest">
                    <b-overlay :show="cargandoTest">
                    <div class="card border-dark border-3 mx-1 mt-2 bg-light action-card flex-shrink-1 justify-content-center">
                        <div class="mt-2 mb-1">
                            <span class="text-black-50 fw-bold ">{{testElegidoEntero.descripcion}} - {{testElegido}}</span>
                        </div>
                        <div class="m-2">                            
                          <!-- Tabla resultado test -->
                          <div class="table-responsive">
                            <table class="table table-striped table-bordered text-start">
                              <!-- Cabeceras tabla -->
                              <thead>
                                <tr>
                                  <th scope="col" class="w-50">Clave</th>
                                  <th scope="col" class="w-50">Valor</th>
                                </tr>
                              </thead>
                              <!-- Cuerpo tabla -->
                              <tbody>
                                <tr v-for="(item, index) in resultadoTest" :key="index">
                                  <th scope="row">{{item.key}}</th>
                                  <td :class="[item.color ? `${item.color} fw-bold` : '']">{{item.value ? item.value : ''}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                    </b-overlay>
                        <!-- Descargar resultados -->
                        <div v-if="resultadoTest && (csv  || excel)" class="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-3">
                          <button type="button" v-if="csv" class="btn btn-secondary text-white m-2 dwnld-btn line"  title="Descargar csv" @click="downloadCSV()">
                            <i type="button" class="fa-solid fa-file-csv fa-1x mx-1"></i>
                            Descargar CSV
                          </button>
                          <button type="button" v-if="csv" class="btn btn-secondary text-white m-2 dwnld-btn line"  title="Descargar Excel" @click="downloadExcel()">
                            <i type="button" class="fa-solid fa-file-excel fa-1x mx-1"></i>
                            Descargar EXCEL
                          </button>
                        </div>
                  </div>
                </div>
              </div>

            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, waitAlert } from '../helpers/fastAlert';
import { necesitaEsperar, empezarEsperaResetONT } from '../helpers/bloqueosPorTiempo';
import Swal from 'sweetalert2'
import axios from "axios";
import { required, maxLength } from 'vuelidate/lib/validators';
import TarjetaServicio from '@/components/TarjetaServicio';
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';
import { getJWTpayload } from '../helpers/JWT';
import { fecha } from '../classes/fecha.class';
import { enviarTicket } from '../helpers/enviarTicket';
import converter from 'json-2-csv';
import { convertCsvToExcelBuffer, crearYdescargar } from '../helpers/utils';

export default {
  /*
    Realiza test y diagnosticos sobre un servicio y muestra los resultados en una tabla.
  */
  name: 'Test',
  components: {
    TarjetaServicio
  },
  data() {
    return {
      testElegido: '',          //Template del test
      testElegidoEntero: '',    //Template del test y la descripción
      resultadoTest: '',        //datos resultado
      cargandoTest: false,  
      //Lista de test permitidos
      testPermitidos: [
        { codigo: 'TST_FTTH_STATE_ONT', descripcion: 'Estado ONT', datosDescartables: ['ont_vendor']},
        { codigo: 'TST_FTTH_DATOS', descripcion: 'Datos ONT'},
        { codigo: 'TST_FTTH_RESET_ONT', descripcion: 'Reseteo ONT' },
        { codigo: 'TST_FTTH_FACTORY_RESET_ONT', descripcion: 'Reseteo de fabrica ONT' },
        { codigo: 'TST_FTTH_CONF_EMP', descripcion: 'Test de configuración' },
        { codigo: 'TST_FTTH_OSTATE', descripcion: 'Estado óptico' },
        { codigo: 'TST_FTTH_ONT_OLT', descripcion: 'Verificación de configuración ONT en OLT' },
        { codigo: 'TST_ESTADO_PON', descripcion: 'Estado PON' },
        { codigo: 'TST_FTTH_PON', descripcion: 'Diagnóstico PON' }
      ],
      //Test en los que hay que solicitar una confirmación antes de hacerlos
      testCriticos: ['TST_FTTH_RESET_ONT', 'TST_FTTH_FACTORY_RESET_ONT'],
      haciendoTestParaTicket: false,
      datosTicket: '',

      //Descarga resultados
      excel: '',
      csv: '',
    } 
  },
  //Validaciones
  validations: {
    testElegido: {
      required,
      maxLength: maxLength(128)
    }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Test y diagnósticos', 'En esta página se puede realizar una serie de pruebas a los recursos serviciados.')
    },   
    infoGestorTest(){
      infoAlert('Gestor de Tests', 'En este apartado puede ejecutar los diagnósticos que necesite, seleccione el test en el desplegable y pulse <strong>Lanzar</strong>. <br><br> Se mostrará una tabla con el resultado del diagnóstico.')
    },
    //Iniciar test
    async realizarTest(){
      try {
        this.cargandoTest = true;

        if(necesitaEsperar(this.item.ID_ADMINISTRATIVO)) return;
        
        //Pedir confirmación para test críticos
        if(this.testCriticos.includes(this.testElegido)){
          let result = await Swal.fire({
            title: '¿Está seguro?',
            html: `Tenga en cuenta que lanzar el test <strong>${this.testElegidoEntero.descripcion}</strong> realizará cambios importantes.`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
          })
          if(!result.isConfirmed) return;
        }
        
        let respuesta = await axios({
          method: 'POST',
          url: '/test',
          data: { 'IUA': this.item.ID_ADMINISTRATIVO, 'Test': this.testElegido },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        let datos = ''
        console.log('Respuesta:', respuesta);
        try {
          datos = respuesta.data.data.Resultado.Datos.OutputParam;
        } catch (error) {
          throw { response: {data: {msg: 'Resultado no encontrado'}}}
        }
        this.resultadoTest = this.tratarResultadoTest(datos);
        if((this.testElegido == 'TST_FTTH_RESET_ONT' || this.testElegido == 'TST_FTTH_FACTORY_RESET_ONT')){
          let testResult = this.resultadoTest.find(dato => dato.key == 'Verificacion');
          if(testResult.value == "OK"){
            empezarEsperaResetONT(this.item.ID_ADMINISTRATIVO)
          }
        }
        //Se procesan los datos para registrarlo en el historial
        let datosRegistro = {};
        for (const iterator of datos) {
          datosRegistro[iterator.key] = iterator.value
        }
        //CSV
        converter.json2csv(datosRegistro, (err, csv) => {          
          if(err){
            console.error(err);
            return;
          }
          this.excel = convertCsvToExcelBuffer(csv);
          this.csv = csv;
        })
        //Registrar operación
        await registrarEnHistorial(
            'TEST/DIAGNÓSTICO',
            this.item.ID_ADMINISTRATIVO,
            { "TEST O DIAGNÓSTICO": this.testElegidoEntero.descripcion + ' - '+ this.testElegido },
            datosRegistro
        );
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
      } finally {
        this.cargandoTest = false;
      }
    },
    //reiniciar pagina
    resetTest(){
      this.resultadoTest = '';
      this.testElegidoEntero = this.testPermitidos.find(iterator => iterator.codigo == this.testElegido)
    },
    //Recuperar servicio activo
    getServicio(){
      if(this.$route.params.item){
        localStorage.setItem('servicioActivo', JSON.stringify(this.$route.params.item));
      }
      if(localStorage.getItem('servicioActivo')){
        this.item = JSON.parse(localStorage.getItem('servicioActivo'));
      }      
      if(!this.item){
        this.$router.push({ name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});
      }
      localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
    },
    //Formatea el resultado del test poniendo color a datos importantes
    tratarResultadoTest(datos){
      let datosNuevos = datos;
      if(this.testElegidoEntero.datosDescartables){
        datosNuevos = datos.filter( dato => !this.testElegidoEntero.datosDescartables.includes(dato.key));
      }
      for (const iterator of datosNuevos) {
        if(iterator.key == 'ont_estado_operacional' && iterator.value == 'down'){
          iterator.color = 'text-danger';
        }
        if(iterator.key == 'ont_potencia_rx' || iterator.key == 'olt_potencia_rx'){
          let valor = parseFloat(iterator.value);
          if(valor>-14.00 || valor<-30.00){
            iterator.color = 'text-danger';
          }else if(valor<-18){
            iterator.color = 'text-warning';
          }else{
            iterator.color = 'text-success';
          }
        }
      }
      return datosNuevos;
    },

    async hacerTestparaTicket(){
      try{
        let respuesta = await axios({
          method: 'POST',
          url: '/test',
          data: { 'IUA': this.item.ID_ADMINISTRATIVO, 'Test': 'TST_FTTH_STATE_ONT' },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        let datos = respuesta.data.data.Resultado.Datos.OutputParam;
        let datosRegistro = {};
        for (const iterator of datos) {
          datosRegistro[iterator.key] = iterator.value
        }
        let testMSG = `ont_estado_operacional: ${datosRegistro.ont_estado_operacional}\r
          ont_estado_administrativo: ${datosRegistro.ont_estado_administrativo}\r
          ont_serial_number: ${datosRegistro.ont_serial_number}\r
          ont_last_time: ${datosRegistro.ont_last_time}\r
          ont_trafico_in: ${datosRegistro.ont_trafico_in}\r
          ont_trafico_out: ${datosRegistro.ont_trafico_out}\r
          ont_potencia_rx: ${datosRegistro.ont_potencia_rx}\r
          ont_potencia_tx: ${datosRegistro.ont_potencia_tx}\r
          olt_descripcion: ${datosRegistro.olt_descripcion}\r
          olt_potencia_rx: ${datosRegistro.olt_potencia_rx}\r
          olt_potencia_tx: ${datosRegistro.olt_potencia_tx}\r
          olt_trafico_in: ${datosRegistro.olt_trafico_in}\r
          olt_trafico_out: ${datosRegistro.olt_trafico_out}\r
          pon_estado_operacional: ${datosRegistro.pon_estado_operacional}\r
          pon_estado_administrativo: ${datosRegistro.pon_estado_administrativo}\r`
        return testMSG;
      } catch (error) {
        let errormsg = "No se pudo hacer el test necesario para abrir el ticket ";
        if (error.response && error.response.data && error.response.data.msg) {
          console.log("error controlado", error.response);
          errormsg += error.response.data.msg
        } else if (error.request) {
          console.log(error.request);
          errormsg += error.request;
        } else {
          console.log('Error', error.message || error);
          errormsg += error.message || error;
        }
      }
    },
    async abrirTicket(){
      if(necesitaEsperar(this.item.ID_ADMINISTRATIVO)) return;
      if(!this.datosTicket){
        this.haciendoTestParaTicket = true;
        this.datosTicket = await this.hacerTestparaTicket();
        this.haciendoTestParaTicket = false;
      }
      let email = getJWTpayload().email;
      let operador = getJWTpayload().operador;
      let mensaje = `Hola, estoy teniendo problemas con el servicio ${this.item.ID_ADMINISTRATIVO} de un cliente.\nEspero su respuesta.`
      let infoExtra = `\r
        --Info Servicio--\r
          ID_ADMINISTRATIVO: ${this.item.ID_ADMINISTRATIVO}\r
          REMOTE_ID: ${this.item.REMOTE_ID}\r
          Dirección: ${this.item.direccion}\r
          ID_DOMICILIO: ${this.item.ID_DOMICILIO}\r
          Alta: ${new fecha(this.item.fechaCreacion).fechaTotal}\r
          Perfil velocidad: ${this.item.PERFILVELOCIDAD}\r
        --Fin Info Servicio--\n
        --Test--
          ${this.datosTicket}
        --Fin Test--
      `
      enviarTicket("Problemas con cliente serviciado", email, mensaje, operador, infoExtra);
    },
    downloadCSV(){
      let nombre = 'Diagnóstico - ' + this.item.ID_ADMINISTRATIVO + ' - ' + new fecha().ddmmyyyy;
      crearYdescargar(this.csv, nombre,  'csv'); 
    },
    downloadExcel(){
      let nombre = 'Diagnóstico - ' + this.item.ID_ADMINISTRATIVO + ' - ' + new fecha().ddmmyyyy;
      crearYdescargar(this.excel, nombre, 'excel'); 
    },
  },
  created(){
    this.getServicio();
  }
}
</script>

<style scoped>

.dwnld-btn{
  width: 12rem;
}

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
    inline-size: auto;
  }
}

.tablaScrolleable{
  overflow: auto;
  max-height: 30rem;
}
</style>
