<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Reservas</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Nueva reserva-->
              <div>
                  <router-link :to="{name: 'Reservar'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block" v-if="esOperadorAdministrativo()"><i class="fas fa-plus"></i> Nueva reserva</router-link>
              </div>
            </div>
            
            
            <div class="card-body">
             
              <!-- Carga -->
              <div v-if="cargandoReservas" class="my-4">
                <b-spinner></b-spinner>
                <h4 >Cargando datos...</h4>
              </div>

              <!-- Error cargando -->
              <div v-else-if="reservas.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                <div class="d-flex">
                  <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                  <div v-if="mensajedeError" class="text-danger">
                    {{mensajedeError}}
                  </div>  
                  <div v-if="reservas.length < 1" class="text-danger align-self-center">
                    No se han encontrado reservas
                  </div>           
                </div>
              </div>

              <!-- Contenido -->
              <div v-else>
                  <div class="table-responsive shadow list-limited-height">

                    <table class="table table-striped table-bordered">
                        <thead class="text-start">
                          <tr class="noselect">
                            <th scope="col" class="ID_ADMINISTRATIVO d-table-cell line  sorting" @click="ordenarTabla($event, 'ID_ADMINISTRATIVO')">ID ADMINISTRATIVO</th>
                            <th scope="col" class="d-table-cell sorting" @click="ordenarTabla($event, 'direccion')">DIRECCIÓN</th>
                            <th scope="col" class="d-table-cell line sorting sorting_asc" @click="ordenarTabla($event, 'fechaCreacion')">FECHA CREACIÓN</th>
                            <th scope="col" class="d-none d-md-table-cell sorting" @click="ordenarTablaCaducidad($event)">CADUCIDAD</th>
                            <th scope="col" class="d-none d-lg-table-cell line sorting" @click="ordenarTabla($event, 'REMOTE_ID')">REMOTE ID</th>
                            <th scope="col" class="d-none d-xl-table-cell sorting" @click="ordenarTabla($event, 'PERFILVELOCIDAD')">PERFIL</th>
                            <th scope="col" class="d-none d-xxl-table-cell acciones text-center">ACCIÓN</th>
                          </tr>
                        </thead>

                        <tbody class="align-middle text-start">
                          <template v-for="(item, index) in reservas">

                              <tr :key="item.ID_ADMINISTRATIVO" :id="item.index" class="accordion searchable" role="listaServicios" :class="{ 'table-secondary' :item.caducidad.segundosRestantes <= 0}">
                                <!-- ID_ADMINISTRATIVO -->
                                <td scope="row">
                                  <div class="d-flex justify-content-between">
                                    <span class="align-self-center fw-bold searchable-text">{{item.ID_ADMINISTRATIVO}}</span>
                                    <b-button type="button" variant="primary" class="btn btn-primary btn-circle border-0 shadow-sm expand-btn" @click="animarCollapse('acordeon'+item.ID_ADMINISTRATIVO); toggleIcon(item.ID_ADMINISTRATIVO)"><i class="expand-icon fa-solid fa-plus fs-6" :id="'boton'+item.ID_ADMINISTRATIVO"></i></b-button>
                                  </div>
                                </td>
                                <!-- Direccion -->
                                <td class="searchable-text">{{item.direccion}}</td>
                                <!-- FECHA CREACION -->
                                <td class="d-table-cell">{{fechaComputada(item.fechaCreacion)}}</td>
                                <!-- CADUCIDAD -->
                                <td class="text-center d-none d-md-table-cell">   
                                  <span class=" fs-6 align-middle" v-if="item.caducidad.segundosRestantes> 0"> 
                                    <span class="badge my-1 align-middle" :class="badgecolorFecha(item.caducidad.days)">{{`${item.caducidad.days} días, ${item.caducidad.hours} horas y ${item.caducidad.minutes} minutos`}}</span>
                                  </span>
                                  <span class="fs-6 align-middle" v-else>
                                    <span class="badge my-1 bg-secondary align-middle" :class="{'text-opacity-50' :item.caducidad.segundosRestantes <= 0}">Reserva caducada</span>
                                  </span>            
                                </td>
                                <!-- REMOTE ID -->
                                <td class="d-none d-lg-table-cell searchable-text">{{item.REMOTE_ID}}</td>
                                <!-- TEMPLATE -->
                                <td class="d-none d-xl-table-cell searchable-text">{{item.PERFILVELOCIDAD}}</td>
                                <td class="d-none d-xxl-table-cell">
                                  <div class="d-flex justify-content-around">
                                    <i type="button" v-show="esOperadorTecnico()" :disabled="item.caducidad.segundosRestantes <= 0" class="fa-solid fa-upload fs-4 mx-1 icon-btn text-primary" :class="{ 'disabled' : item.caducidad.segundosRestantes <= 0}" title="Alta" @click="if(item.caducidad.segundosRestantes > 0) {alta(item)}"></i>
                                    <i type="button" v-show="esOperadorAdministrativo()" class="fa-solid fa-trash-alt fs-4 mx-1 icon-btn text-danger" title="Cancelar recurso" @click="borrarReserva(item.ID_ADMINISTRATIVO, item.caducidad.segundosRestantes <= 0)"></i>
                                  </div>
                                </td>


                              </tr>
                              <!-- COLLAPSABLE INFO EXTRA -->
                              <tr :key="index">
                                <td colspan="7" class="p-0 overflow-hidden">
                                  <div class="acordeon"  :id="'acordeon'+item.ID_ADMINISTRATIVO" accordion="my-accordion">
                                    <div>
                                        <TarjetaServicio :item="item" :fullwidth="true"></TarjetaServicio>
                                        <div class="d-flex justify-content-center my-3">
                                              <button v-show="esOperadorTecnico()" type="button" :disabled="item.caducidad.segundosRestantes <= 0" class="btn btn-primary mx-3 accion-btn" @click="alta(item)">
                                                <i type="button" class="fa-solid fa-upload fa-1x mx-1" title="Alta recurso"></i>
                                                Alta
                                              </button>
                                              <button v-show="esOperadorAdministrativo()"  type="button" class="btn btn-danger mx-3 accion-btn" @click="borrarReserva(item.ID_ADMINISTRATIVO, item.caducidad.segundosRestantes <= 0)">
                                                <i type="button" class="fa-solid fa-trash-alt fa-1x mx-1" title="Cancelar recurso"></i>
                                                Borrar
                                              </button>
                                        </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                      
                          </template>
                        
                        </tbody>
                    </table>
                  </div>
              </div>

            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, successAlert } from '../helpers/fastAlert';
import { getJWTpayload, verificarJWT, desconectar, isOperadorAdministrativo, isOperadorTecnico } from '../helpers/JWT';
import Swal from 'sweetalert2'
import axios from "axios";
import { fecha } from "../classes/fecha.class"
import TarjetaServicio from "@/components/TarjetaServicio"
import { empezarEsperaServicioCreado } from "../helpers/bloqueosPorTiempo"
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';
import { desexpandirTodo } from '../helpers/utils';

export default {
  /*
    Muestra una lista con las reservas hechas por el operador, le permite ver información detallada, darlas de alta, o borrarlas.
    Las reservas tienen una caducidad de 5 días, se muestra con una etiqueta el tiempo restante para que caduque, en verde si son más de 2 dias, 
    amarillo si quedan menos de dos dias, rojo si queda menos de uno y gris si ha caducado ya, impidiendole hacer el alta en este caso
  */
  components: { TarjetaServicio },
  name: 'Reservas',
  component: {
    TarjetaServicio
  },
  data() {
    return {
      cargandoReservas: false,
      mensajedeError: '',
      reservas: []
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Mis reservas', 'En esta página se encuentran todos los recursos reservados por el usuario y se pueden <strong>cancelar</strong> y dar de <strong>alta</strong>.')
    },
    ordenarTabla(event, columna){
      let elemento = event.target;
      let ascOrder = true;
      if(elemento.classList.contains('sorting_asc')){
        ascOrder = false;
      }
      if(elemento && elemento.parentElement){
        let ordenables = elemento.parentElement.querySelectorAll('.sorting');
        for (const iterator of ordenables) {
          iterator.classList.remove('sorting_desc', 'sorting_asc');
        }
        if(ascOrder){
          elemento.classList.add('sorting_asc');
          this.reservas.sort((a, b) => a[columna].localeCompare(b[columna]))
        }else{
          elemento.classList.add('sorting_desc');
          this.reservas.sort((a, b) => b[columna].localeCompare(a[columna]));
        }  
        desexpandirTodo();
      }
      
    },
    ordenarTablaCaducidad(event){
      let elemento = event.target;
      let ascOrder = true;
      if(elemento.classList.contains('sorting_asc')){
        ascOrder = false;
      }
      if(elemento && elemento.parentElement){
        let ordenables = elemento.parentElement.querySelectorAll('.sorting');
        for (const iterator of ordenables) {
          iterator.classList.remove('sorting_desc', 'sorting_asc');
        }
        if(ascOrder){
          elemento.classList.add('sorting_asc');
          this.reservas.sort((a, b) => a.caducidad.segundosRestantes - b.caducidad.segundosRestantes);
        }else{
          elemento.classList.add('sorting_desc');
          this.reservas.sort((a, b) => b.caducidad.segundosRestantes - a.caducidad.segundosRestantes);
        }  
        desexpandirTodo();
      }
      
    },
    //Anima acordeones
    animarCollapse(id){ 
      let colapsable = document.getElementById(id);
      let expandido = colapsable.classList.contains('show');
      for (const iterator of document.getElementsByClassName('acordeon')) {
        iterator.classList.remove('show');
        iterator.style.height = 0 + 'px';
      }
      if(!expandido) {
        colapsable.classList.add('show');
        colapsable.style.height = colapsable.scrollHeight + 'px';
      }
    },
    //Cambia el diseño de los botones de los acordeones
    toggleIcon(id){
      let icono = document.getElementById('boton'+id);
      let expandido = document.getElementById('acordeon'+id).classList.contains('show');
      for (const iterator of document.getElementsByClassName('expand-icon')) {
        iterator.parentElement.classList.remove('bg-danger')
        iterator.parentElement.classList.add('bg-primary')
        iterator.classList.remove('fa-minus')
        iterator.classList.add('fa-plus')
      }

      if(expandido){
        icono.classList.remove('fa-plus')
        icono.classList.add('fa-minus')
        icono.parentElement.classList.remove('bg-primary')
        icono.parentElement.classList.add('bg-danger')
      }
    },
    //Descarga una lista con las servicios de la API y se queda con los que son de tipo 'reserva'
    async cargarReservas(){
      try {        
        this.cargandoReservas = true;
        let OPID = getJWTpayload().OPID;

        let respuestaPandora = await axios.get('/servicios/reservas', {
          method: 'GET',
          params: {OPID: OPID},
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.reservas = respuestaPandora.data.data.listaServicios.filter( item => item.tipo == 'reserva');
        for (const iterator of this.reservas) {
          iterator.caducidad = this.tiempoRestante(iterator.fechaCreacion);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } finally {
        this.cargandoReservas = false;
      }
    },
    //Elimina una reserva
    async borrarReserva(iua, caducado){
          
        await Swal.fire({
          title: '¿Borrar la reserva de recursos?',
          text: "¡La reserva será eliminada para siempre!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  '<i class="fa-solid fa-trash-alt"></i> Borrar',
          cancelButtonText:   'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            //Confirmar identidad
            verificarJWT().then( verificado => {
                if (!verificado) {
                    errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
                    desconectar();
                    return;
                }
            });
            if(!caducado) {
              //Eliminar del orquestador
              return axios.delete('/cancelarRecursos', {
                data: { 'ID_ADMINISTRATIVO': iua },
                headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
              }).then(resp => {
                //Eliminación correcta     
              }).catch(err => {
                
                if (err.response) {
                  //Request made and server responded
                  Swal.showValidationMessage(`${err.response.data.msg}. ${err.response.data.error ? err.response.data.error.descResultado : ''}`);
                } else if (err.request) {
                  // The request was made but no response was received
                  Swal.showValidationMessage('No se ha recibido respuesta del servidor');
                } else {
                  // Something happened in setting up the request that triggered an err
                  Swal.showValidationMessage(err.message);
                }  
              })
            }else{ //Si esta caducado solo se borra de pandora
                  //Borrar de pandora
                  return axios.delete('/servicios', {
                    params: {ID_ADMINISTRATIVO: iua},
                    headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
                  }).then(res =>{
                  }).catch(error => {
                    if (error.response && error.response.data && error.response.data.msg) {
                      //Request made and server responded
                      Swal.showValidationMessage(error.response.data.msg);
                    } else if (error.request) {
                      // The request was made but no response was received
                      Swal.showValidationMessage('No se ha recibido respuesta del servidor');
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      Swal.showValidationMessage(error.message);
                    }              
                  })
            }
          },
          backdrop: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          this.cargarReservas();
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Eliminada',
              text: 'La reserva de recursos ha sido borrada',
              icon: 'success',
              confirmButtonText: 'Vale'
            }
            )
          }
        })
    },
    //Da de alta una reserva
    async alta(item){
        let iua = item.ID_ADMINISTRATIVO;
        //Mostrar loader
        Swal.fire({
            title: 'Procesando solicitud..',
            backdrop: true,
            allowOutsideClick: false,
        });
        Swal.showLoading();

        //Peticion de verificacion de seguridad
        let verificado =  await verificarJWT();
        if (!verificado) {
            errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
            desconectar();
            return;
        }
        
        try {
          //Alta en el orquestador
          let respuestaOrquestador = await axios({
            method: 'POST',
            url: '/alta',
            data: { ID_ADMINISTRATIVO: iua },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          successAlert('Operación completada', respuestaOrquestador.data.msg);
          empezarEsperaServicioCreado(iua);

          localStorage.setItem('focusedItem', iua);
          this.$router.push({name: 'Servicios'});
          this.cargarReservas();
      
        } catch (error) {
          setTimeout(() => {
            Swal.hideLoading();
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log(error.response);
              errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              noResponseAlert();
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              serverErrorAlert(error.message);
            }
            return;
          }, 1000);
        }finally{
          setTimeout(() => {
            Swal.hideLoading();
          }, 1000);
        }
    },
    /*
      -- tiempoRestante(fecha) --
      Calcula el tiempo restante para que caduque una fecha, caduca en cinco dias.
      params:
        fecha (dateTime) - fecha a calcular
      res:
        {
          days,               //Dias restantes
          hours,              //Horas restantes
          minutes,            //Minutos restantes
          segundosRestantes   //Total de segundos restantes
        }
    */
    tiempoRestante(fecha){
      let fechainicial = new Date();
      let fechafinal = new Date(fecha);
      
      fechafinal.setDate(fechafinal.getDate()+3) //Caduca en 3 dias
      let tiempoRestante = fechafinal/1000 - fechainicial/1000;
      let segundosRestantes = tiempoRestante;

      var days = Math.floor(tiempoRestante / 86400);
      tiempoRestante -= days * 86400;
      // calculate (and subtract) whole hours
      var hours = Math.floor(tiempoRestante / 3600) % 24;
      tiempoRestante -= hours * 3600;
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(tiempoRestante / 60) % 60;
      tiempoRestante -= minutes * 60;
      return { days, hours, minutes, segundosRestantes }
    },
    /*
      Expande un item de la lista si se ha guardado en la localStorage como 'focusedItem': IUA
      El objetivo es mostrar un elemento destacado al cargar la página si esque existe. (Por ejemplo al crear una nueva reserva)
    */
    openitemifFocus(){
      let itemIUA = localStorage.getItem('focusedReserva');
      let element = document.getElementById('acordeon'+itemIUA);
      if(element){
        this.animarCollapse('acordeon'+itemIUA);
        this.toggleIcon(itemIUA);
        element.scrollIntoView({ behavior: 'smooth', block: 'start'})
        localStorage.removeItem('focusedReserva');
      }
    },
    esOperadorAdministrativo(){      
      return isOperadorAdministrativo();
    },
    esOperadorTecnico(){
      return isOperadorTecnico();
    }
  },
  created(){
    this.cargarReservas();
  },
  updated() {
    this.openitemifFocus();
  },
  computed: {
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },
    //Calcula el color de las etiquetas de fecha restante
    badgecolorFecha () {
      return (days) => {
          if(days >= 2){
             return 'bg-success' 
          }else if(days >= 1){
             return 'bg-warning'
          } else{ 
             return 'bg-danger'
          }
      }
    }
  }
}
</script>

<style scoped>

.acciones {
  width: 7rem;
}
.accion-btn {
  width: 10rem;
}

</style>
