<template>
    <!-- Contenido -->
    <div class="m-auto">
      <b-overlay :show="cargando" class="m-2 p-2 shadow-lg">
        <table :id="'datatable' + indice" class="table table-striped table-tiny">
            <thead>
                <tr class="noselect">
                <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                <th scope="col" class="align-middle">MODELO_OLT</th>
                <th scope="col" class="align-middle">FRAME_OLT</th>
                <th scope="col" class="align-middle">TARJETA_OLT</th>
                <th scope="col" class="align-middle">PUERTO_GPON</th>
                <th scope="col" class="align-middle">CAPACIDAD_GPON</th>
                <th scope="col" class="align-middle">CODIGO_CTO</th>
                <th scope="col" class="align-middle">TIPO_CTO</th>
                <th scope="col" class="align-middle">DIRECCION_CTO</th>
                <th scope="col" class="align-middle">SPLITTER_ID</th>
                <th scope="col" class="align-middle">TIPO_SPLITTER</th>
                <th scope="col" class="align-middle">PUERTO_CTO</th>
                <th scope="col" class="align-middle">GESTOR_VERTICAL</th>
                <th scope="col" class="align-middle">MUTUALIZADO</th>
              </tr>
            </thead>
            <tbody class="align-middle">                   
            </tbody>
        </table>
      </b-overlay>                
    </div>
</template>


<script>
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';

export default {
  name: 'DatatableCambiosTrazados',
  data() {
    return {   
      listaDatos: '',
      tabla: '',
      cargando: true
    }
  },  
  props: ['item', 'indice'],
  methods:{
    initDatatable(){
      this.tabla = $('#datatable' + this.indice).DataTable({
              autoWidth: true,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                { data: 'CODIGO_OLT',
                  render: (data, type, row) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                  } 
                },
                { data: 'MODELO_OLT', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'FRAME_OLT', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TARJETA_OLT', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PUERTO_GPON', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CAPACIDAD_GPON', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CTO', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_CTO', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'DIRECCION_CTO', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'SPLITTER_ID', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_SPLITTER', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PUERTO_CTO', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'GESTOR_VERTICAL', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'MUTUALIZADO', 
                    render: (data, type, rowData) => {
                     if(!data) return '';
                     if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { 
                  data: 'uid',
                  visible: false 
                },
              ]   
        });
      //A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
      setTimeout(() => {
        $('#datatable' + this.indice).wrap('<div class="table-responsive"></div>')
      }, 500);
    },
    ponerDatos(){
      this.tabla.clear();
      this.tabla.rows.add(this.listaDatos).draw();
      this.cargando = false;

    },
    procesarDatos(){
      this.listaDatos = JSON.parse(this.item);
    }
  },
  created() {
    this.procesarDatos()
  },
  computed: {

  },
  mounted() {
    this.initDatatable()
    this.ponerDatos()    
  },

}
</script>

<style scoped>
 /* .table-responsive {
  max-width: 95vw;
  width: 100%;
 } */
</style>
