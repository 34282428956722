import Swal from "sweetalert2";
import axios from "axios";
import { successAlert } from "./fastAlert";
import { registrarEnHistorial } from "./registrarEnHistorial";

///////////
//Tickets//
///////////


function validarCampos() {

    let okay = true;

    //Validar mensaje
    let mensajeInput = document.getElementById('mensaje-input');
    let mensajeError = document.getElementById('mensaje-error');
    if (mensajeInput && mensajeError) {
        mensajeInput.classList.remove('is-invalid');
        mensajeError.classList.add('d-none');
        if (!mensajeInput.value || mensajeInput.value.length < 10 || mensajeInput.value.length > 512) {
            mensajeInput.classList.add('is-invalid');
            mensajeError.classList.remove('d-none');
            mensajeInput.focus();
            okay = false;
        }
    }

    //Validar asunto
    let asuntoInput = document.getElementById('asunto-input');
    let asuntoError = document.getElementById('asunto-error');
    if (asuntoInput && asuntoError) {
        asuntoInput.classList.remove('is-invalid');
        asuntoError.classList.add('d-none');
        if (!asuntoInput.value) {
            asuntoInput.classList.add('is-invalid');
            asuntoError.classList.remove('d-none');
            asuntoInput.focus();
            okay = false;
        }
    }

    //Validar email
    let emailInput = document.getElementById('email-input');
    let emailError = document.getElementById('email-error');
    if (emailInput && emailError) {
        emailInput.classList.remove('is-invalid');
        emailError.classList.add('d-none');
        if (!emailInput.value || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput.value)) {
            emailInput.classList.add('is-invalid');
            emailError.classList.remove('d-none');
            emailInput.focus();
            okay = false;
        }
    }
    return okay;
}

export async function enviarTicket(asunto = '', email = '', mensaje = '', operador = '', infoExtra = '') {

    await Swal.fire({
        title: 'Enviar ticket',
        html: `
            <label for="email-input" class="swal2-input-label justify-content-start mx-3 mt-3 mb-1">Correo electrónico *</label>
            <input value="${email || ''}" class="swal2-input form-control mt-1" id="email-input" placeholder="Correo electrónico" type="email" maxlength="65" style="margin-left: auto; margin-right: auto; width: 95%">
            <small id="email-error" class="text-danger d-flex justify-content-start ms-4 d-none"> Debe de introducir un email válido </small>

            <label for="asunto-input" class="swal2-input-label justify-content-start mx-3 mt-3 mb-1">Asunto *</label>
            <input value="${asunto || ''}" class="swal2-input form-control mt-1" id="asunto-input" placeholder="Asunto" type="text" maxlength="40" style="margin-left: auto; margin-right: auto; width: 95%">
            <small id="asunto-error" class="text-danger d-flex justify-content-start ms-4 d-none"> Debe de introducir un asunto </small>
           
            <label for="mensaje-input" class="swal2-input-label justify-content-start mx-3 mt-3 mb-1">Mensaje *</label>
            <textarea oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' aria-label="Escribe tu mensaje aquí" class="swal2-textarea form-control mt-1" maxlength="512"  placeholder="Escribe tu mensaje aquí..." id="mensaje-input" style="margin-left: auto; margin-right: auto; width: 95%; max-height: 22rem;">${mensaje || ''}</textarea>
            <small id="mensaje-error" class="text-danger d-flex justify-content-start ms-4 d-none"> Debe de introducir un mensaje entre 10 y 512 caracteres</small>
            `,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale',
        showLoaderOnConfirm: true,
        didOpen: () => {
            let e = document.getElementById('email-input');
            if (e) e.focus();
            let textarea = document.getElementById('mensaje-input');
            textarea.addEventListener('oninput', () => {
                this.style.height = "";
                this.style.height = this.scrollHeight + "px"
            }, false);
            textarea.style.height = "";
            textarea.style.height = textarea.scrollHeight + "px"
        },
        preConfirm: () => {
            let e = document.getElementById('email-input')
            let a = document.getElementById('asunto-input')
            let m = document.getElementById('mensaje-input')
            if (e) email = e.value;
            if (a) asunto = a.value;
            if (m) mensaje = m.value;
            if (!validarCampos()) {
                return false;
            }
            //Validación ok
            return axios({
                method: 'POST',
                url: '/ticket',
                data: {
                    email,
                    asunto,
                    operador,
                    mensaje,
                    infoExtra
                },
                headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            }).then(res => {}).catch(error => {
                if (error.response && error.response.data && error.response.data.msg) {
                    //Request made and server responded
                    console.log(error.response);
                    Swal.showValidationMessage(error.response.data.msg);
                } else if (error.request) {
                    // The request was made but no response was received
                    Swal.showValidationMessage('No se ha recibido respuesta del servidor');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Swal.showValidationMessage(error.message);
                }
            })
        },
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading()
    }).then(resultado => {
        if (resultado.isConfirmed) {
            let datos = { 'Correo': email, 'Asunto': asunto, 'Mensaje': mensaje };
            if (infoExtra) datos.infoExtra = infoExtra;
            registrarEnHistorial('Enviar ticket', 'Ninguno', datos);
            successAlert('Ticket enviado', 'La solicitud se ha registrado correctamente,');
        }
    })
}