<template>
    <!-- Contenido -->
    <div class="m-3" :class="{ 'card': !fullwidth, 'shadow': !fullwidth}">
      <div class="text-start" :class="{'card-body': !fullwidth}">
        

        <!-- OPERACIÓN -->
        <div v-if="item.OPERACION">
          <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle">Operación: </span>
            <span class="mx-2 fs-6 align-middle">{{item.OPERACION}} </span>
          </div>
          <hr class="my-1">   
        </div>
        

        <!-- FECHA -->
        <div v-if="item.FECHA">
          <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">Fecha: </span>
            <span class="mx-2 fs-6 align-middle">{{fechaComputada(item.FECHA)}} </span>                                     
          </div>
          <hr class="my-1">   
        </div>


        <!-- ID_ADMINISTRATIVO -->
        <div v-if="item.ID_ADMINISTRATIVO">
          <div class="d-flex justify-content-start flex-column flex-sm-row">
              <span class="mx-2 fs-6 fw-bold align-middle ">Id administrativo: </span>
              <span class="mx-2 fs-6 align-middle">
                {{item.ID_ADMINISTRATIVO}}
              </span>
          </div>
          <hr class="my-1">
        </div>
        <!-- OPERADOR -->
        <div v-show="visible('OPERADOR')"  >
          <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle">Operador: </span>
            <span class="mx-2 fs-6 align-middle">{{item.OPERADOR}} - {{item.ID_OPERADOR}}</span>
          </div>
          <hr class="my-1">   
        </div>
        <!-- USUARIO -->
        <div v-if="item.USUARIO">
          <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle">Usuario: </span>
            <span class="mx-2 fs-6 align-middle">{{item.USUARIO}} </span>
          </div>
          <hr class="my-1">   
        </div>

        <!-- ADMINISTRADOR -->
        <div v-if="item.ADMINISTRADOR">
          <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle">Administrador: </span>
            <span class="mx-2 fs-6 align-middle">{{item.ADMINISTRADOR}} </span>
          </div>
          <hr class="my-1">   
        </div>
        
        <!-- Resto de datos -->
        <div v-for="(key, index) in Object.keys(item.DATOS)" :key="index">
          <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle text-capitalized">{{key}}:</span>
            <span class="mx-2 fs-6 align-middle">{{item.DATOS[key]}}</span>
          </div>
          <hr class="my-1">   
        </div>
                                 
        <div v-if="item.DATOSTABLA" class="m-2">  
          <!-- Tabla key-valor -->
          <div class="table-responsive">
            <table class="table table-striped table-bordered text-start">
              <!-- Cabeceras tabla -->
              <thead>
                <tr>
                  <th scope="col">Clave</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <!-- Cuerpo tabla -->
              <tbody>
                <tr v-for="(clave, i) in Object.keys(item.DATOSTABLA)" :key="i">
                  <th scope="row">{{clave}}</th>
                  <td>
                     {{item.DATOSTABLA[clave]}} 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>



      </div>                              
    </div>
</template>


<script>
import { fecha } from "../classes/fecha.class"

export default {
  name: 'TarjetaGeneral',
  props: ['item', 'fullwidth', 'hiddenElements'],
  data() {
    return {   
      
    }
  },  
  methods:{
    visible (key) {
      if(!this.hiddenElements) return true;
      return !this.hiddenElements.includes(key);
    },
  },
  computed: {
    //Fecha con formato propio
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },
  },

}
</script>

