<template>
<div>
      <router-view></router-view>

</div>
      
</template>

<script>
export default {
  /*
    Comoponente de adminsitración
  */
  name: 'AdminView',

  data() {
    return {
    }
  }
}
</script>
