import { waitAlert } from "@/helpers/fastAlert"

///////////////////////
//Bloqueos por tiempo//
///////////////////////

//Funciones que establecen un periodo de espera necesario antes de poder realizar otra operación

const tiempoAlta = { segundos: 30, text: '30 segundos' }
const tiempoCambioONT = { segundos: 60, text: '1 minuto' }
const tiempoCambioServicio = { segundos: 60, text: '1 minuto' }
const tiempoResetONT = { segundos: 60 * 4, text: '4 minutos' }

/*
Comprueba si es necesario esperar en general
@OUTPUT true : false
*/
export function necesitaEsperar(iua) {
    if (checkRecienCreado(iua)) return true;
    if (checkCambioServicioReciente(iua)) return true;
    if (checkCambioONT(iua)) return true;
    if (checkResetONTReciente(iua)) return true;
    return false;
}


/*
Comprueba si se creó el servicio recientemente
*/
export function checkRecienCreado(iua) {
    if (localStorage.getItem('recienCreado' + iua)) {
        let lastChange = new Date(localStorage.getItem('recienCreado' + iua));
        let ahora = new Date();
        let elapsedSeconds = (ahora - lastChange) / 1000;
        if (elapsedSeconds < tiempoAlta.segundos) {
            waitAlert('alta del servicio', tiempoAlta.text, (tiempoAlta.segundos - elapsedSeconds));
            return true;
        }
        localStorage.removeItem('recienCreado' + iua);
    }
    return false;
}


/*
Comprueba si se hizo un cambio de ONT en el último minuto
*/
export function checkCambioONT(iua) {
    if (localStorage.getItem('lastONTChange' + iua)) {
        let lastChange = new Date(localStorage.getItem('lastONTChange' + iua));
        let ahora = new Date();
        let elapsedSeconds = (ahora - lastChange) / 1000;
        if (elapsedSeconds < tiempoCambioONT.segundos) {
            waitAlert('cambio de ONT', tiempoCambioONT.text, (tiempoCambioONT.segundos - elapsedSeconds));
            return true;
        }
        localStorage.removeItem('lastONTChange' + iua);
    }
    return false;
}

/*
Comprueba si se hizo un cambio de servicio en el último minuto
*/
export function checkCambioServicioReciente(iua) {
    if (localStorage.getItem('lastServiceChange' + iua)) {
        let lastChange = new Date(localStorage.getItem('lastServiceChange' + iua));
        let ahora = new Date();
        let elapsedSeconds = (ahora - lastChange) / 1000;
        if (elapsedSeconds < tiempoCambioServicio.segundos) {
            waitAlert('cambio de servicio', tiempoCambioServicio.text, (tiempoCambioServicio.segundos - elapsedSeconds));
            return true;
        }
        localStorage.removeItem('lastServiceChange' + iua);
    }
    return false;
}

/*
Comprueba si se reseteo la ONT en los últimos 4 minutos
*/
export function checkResetONTReciente(iua) {
    if (localStorage.getItem('lastONTReset' + iua)) {
        let lastChange = new Date(localStorage.getItem('lastONTReset' + iua));
        let ahora = new Date();
        let elapsedSeconds = (ahora - lastChange) / 1000;
        if (elapsedSeconds < tiempoResetONT.segundos) {
            waitAlert('reseteo de ONT', tiempoResetONT.text, (tiempoResetONT.segundos - elapsedSeconds));
            return true;
        }
        localStorage.removeItem('lastONTReset' + iua);
    }
    return false;
}

/*
Comienza el contador porque se ha realizado un reseteo de ONT
*/
export function empezarEsperaResetONT(iua) {
    localStorage.setItem('lastONTReset' + iua, new Date().toString());
}

/*
Comienza el contador porque se ha realizado un cambio de servicio
*/
export function empezarEsperaCambioServicio(iua) {
    localStorage.setItem('lastServiceChange' + iua, new Date().toString());
}

/*
Comienza el contador porque se ha realizado un cambio de ONT
*/
export function empezarEsperaCambioONT(iua) {
    localStorage.setItem('lastONTChange' + iua, new Date().toString());
}

/*
Comienza el contador porque se ha realizado un cambio de ONT
*/
export function empezarEsperaServicioCreado(iua) {
    localStorage.setItem('recienCreado' + iua, new Date().toString());
}