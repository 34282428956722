<template>
    <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">
           
            <!-- Header -->
            <div class="d-flex align-items-baseline mx-4 my-4">
              <h1 >Buscar cobertura</h1>
              <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
            </div>

            <div class="card-body">
              <!-- Cargando -->
              <div v-if="cargandoCoberturas" class="my-4">
                <b-spinner></b-spinner>
                <h4 >Cargando datos...</h4>
              </div>

              <!-- Error cargando -->
              <div v-else-if="coberturas.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                <div class="d-flex flex-column text-start">
                  <div v-if="mensajedeError" class="text-danger">
                    {{mensajedeError}}
                  </div>  
                  <div v-if="coberturas.length < 1" class="text-danger">
                    No se han encontrado coberturas disponibles
                  </div>   
                </div>
                <i class="fa-solid fs-3 fa-circle-question ms-2 clickable help-icon-error text-black" @click="infoError"></i>
              </div>

              <!-- Contenido -->
              <div v-else>
                
                  <form class="user d-flex flex-column" @submit.prevent novalidate>
                    <div class="row">
                        <div class="d-flex justify-content-center mt-2 mb-5">
                          <button type="button" class="btn fw-bold mx-2 mx-md-4 accion-btn" :class="[tipoBusqueda=='buscador' ? 'btn-primary' : 'btn-secondary']" @click="setModoBusquedaBuscador()">
                              Buscar por campos
                          </button>
                          <button type="button" class="btn fw-bold mx-2 mx-md-4 accion-btn"  :class="[tipoBusqueda=='id' ? 'btn-primary' : 'btn-secondary']" @click="setModoBusquedaID()">
                              Buscar por ID
                          </button>
                        </div>
                        <div v-if="tipoBusqueda=='id'" class="row">
                            <!-- ID_DOMICILIO -->
                            <div class="col-12 my-2">
                                  <div class="row d-flex align-items-center">
                                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                      <label for="ID_DOMICILIO" class="line mx-1 fs-5 d-flex align-items-center">Id domicilio</label>
                                    </div>
                                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                      <div class="row d-flex flex-row">
                                        <div class="col-10">  
                                          <input type="text" id="ID_DOMICILIO" class="form-control m-0" autocomplete="off" required maxlength="38" v-model="data.ID_DOMICILIO" placeholder="Id del domicilio" @input="buscarPorID()">  
                                        </div>
                                        <div class="col-2 px-0 text-start align-self-center">
                                          <i class="fa-solid fa-circle-question clickable fs-5" @click="infoIDDomicilio"></i>
                                        </div>                                                            
                                      </div>
                                    </div>
                                  </div>                              
                            </div>
                        </div>
                        <div v-if="tipoBusqueda=='buscador'" class="row">
                          <!-- Provincia -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="Provincia" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <input id="Provincia" class="form-control w-100 m-0" list="provinciasOpciones" autocomplete="off" required maxlength="65" v-model.lazy="$v.data.provincia.$model" placeholder="Provincia" :class="{ 'is-invalid': $v.data.provincia.$error}" @change="loseFocus" >
                                          <datalist id="provinciasOpciones">
                                            <option v-for="item in provinciasDistintas" :key="item" :value="item"></option> 
                                          </datalist>       
                                      </div>
                                    </div>                      
                                  </div>
                                </div>
                                <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.data.provincia.$dirty && !$v.data.provincia.required" class="text-danger">
                                      Debe seleccionar la provincia del domicilio
                                    </small>
                                    <small v-show="$v.data.provincia.$dirty && !$v.data.provincia.maxLength" class="text-danger">
                                      No puede introducir tantos caracteres
                                    </small>                                
                                  </div>
                              </div>                                  
                          </div>
                          <!-- Pueblo -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="poblacion" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <input id="poblacion" class="form-control w-100 m-0" :disabled="!$v.data.provincia.$dirty || $v.data.provincia.$error" list="poblacionesOpciones" autocomplete="off" required maxlength="65" v-model.lazy="$v.data.poblacion.$model" placeholder="Población" :class="{ 'is-invalid': $v.data.poblacion.$error}" @change="loseFocus">
                                          <datalist id="poblacionesOpciones">
                                            <option v-for="item in poblacionesDisponibles" :key="item" :value="item"></option> 
                                          </datalist>      
                                      </div>
                                    </div>                            
                                  </div>
                                </div>
                                <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.data.poblacion.$dirty && !$v.data.poblacion.required" class="text-danger">
                                      Debe seleccionar la poblacion del domicilio
                                    </small>
                                    <small v-show="$v.data.poblacion.$dirty && !$v.data.poblacion.maxLength" class="text-danger">
                                      No puede introducir tantos caracteres
                                    </small>                                
                                  </div>
                              </div>    
                          </div>    

                          <!-- TIPO VIA -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="calle" class="line mx-1 fs-5 d-flex align-items-center">Tipo vía</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10 col-xxl-10">  
                                        <select id="tipo" class="form-control  m-0" :disabled="!computedTiposVia.length > 0" maxlength="128" v-model.lazy="data.tipoVia" @change="filtrarDomicilios">                                
                                          <option value="" selected>Sin especificar</option>
                                            <option v-for="(item, index) in computedTiposVia" :key="index" :value="item.TIPO_VIA">{{item.TIPO_VIA}}</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                          </div>    
                          <!-- Calle -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="calle" class="line mx-1 fs-5 d-flex align-items-center">Nombre vía</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <div class="input-group">
                                        <span class="input-group-text" id="calle">{{data.tipoVia ? data.tipoVia : 'Vía'}}</span>
                                        <input id="calle" class="form-control  m-0" :disabled="!$v.data.poblacion.$dirty || $v.data.poblacion.$error" required maxlength="128" v-model.lazy="$v.data.calle.$model" placeholder="Ej.: San Martín" @change="buscarhogares" autocomplete="off" >                                
                                        </div>
                                      </div>
                                      <div class="col-2 px-0 text-start align-self-center">
                                        <i class="fa-solid fa-circle-question clickable fs-5" @click="infoDomicilio"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.data.calle.$dirty && !cargandoDomicilios && this.data.calle.length > 1 && domiciliosDisponibles.length < 1" class="text-danger">
                                      No se han encontrado resultados para esta búsqueda
                                    </small>                        
                                    <small v-show="$v.data.calle.$dirty && !cargandoDomicilios && this.data.calle.length < 2" class="text-danger">
                                      Necesitas poner almenos dos caracteres
                                    </small>  
                                  </div>
                              </div>    
                          </div>    
                          <!-- Numero -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="numero" class="line mx-1 fs-5 d-flex align-items-center">Número</label>
                                  </div>
                                  <div class="number-input">
                                    <select id="numero" class="form-control w-100 m-0" :disabled="computedNumerosDisponibles.length < 1" type="number" min="0"  maxlength="65" v-model.lazy="data.numero" @change="filtrarDomicilios">                                                   
                                          <option value="" selected>Sin especificar</option>
                                          <option v-for="(item, index) in computedNumerosDisponibles" :key="index" :value="item">{{item}}</option>
                                    </select>
                                  </div>
                                </div>
                          </div>   
                        </div> 
                        
                        <!-- Buscador de coberturas -->
                        <b-overlay :show="cargandoDomicilios">
                        <div class="card border-dark border-3 mx-4 mt-2 bg-light action-card flex-shrink-1">
                          <div class="container-fluid my-2">

                            <i class="fa-solid fa-circle-question clickable fs-5 action-card-help" @click="infoBuscador"></i>
                            <span class="text-black-50 fw-bold">Buscador de coberturas: </span>
                            <p class="fs-2" :class="colorMensajeActionCard">{{mensajeActionCard}}</p>
                             
                            <!-- Mensaje ticket -->
                            <div v-if="dirty" class="alert alert-info d-flex align-items-center my-2" role="alert">
                              <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center w-100">
                                
                                <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                                  <i class="fa-solid fa-circle-exclamation text-info fs-3 mx-2 mb-2 mb-sm-0"> </i>   
                                  <div class="align-self-center text-center text-sm-start">
                                    ¿No encuentras el domicilio? Pide información o solicita ampliar tu cobertura contratada.
                                  </div>           
                                </div>
                                <button  type="button" class="btn btn-primary btn-sm line mx-2 mt-3 mt-sm-0 w-a" @click="abrirTicket()">
                                  Abrir Ticket
                                </button>
                              </div>
                            </div>

                            <!-- Lista domicilios -->
                            <div class="action-card-items">
                              <template v-for="(item, index) in domiciliosDisponiblesFiltrados">
                                <div :key="index">
                                  <div v-if="index<maxDomicilios" class="alert alert-light shadow-sm flex-shrink-1 my-2 py-3 px-4 text-start action-card-item">
                                    <div class="row">
                                      <div class="col-12 d-flex flex-column align-self-center">
                                          <span> {{item.callemontada}}</span>
                                          <div class="d-flex flex-column text-black-50 m-font">
                                            <span>DIRECCION_CTO: <i>{{item.DIRECCION_CTO}}</i></span>
                                            <span>ID_DOMICILIO: <i>{{item.ID_DOMICILIO}}</i></span>
                                          </div>
                                      </div>
                                    </div>                         
                                  </div>
                                  <div v-if="index==maxDomicilios-1 && domiciliosDisponiblesFiltrados.length>=index" class="card shadow-sm flex-shrink-1 my-1 p-2 text-center pointerHover boton-mostrarmas m-auto"  @click="maxDomicilios += 5">
                                      <span class="fs-5 text-black-50">Mostrar más</span>                              
                                  </div>
                                </div>
                              </template>
                            </div>
                          
                  
                          </div>
                        </div>
                        </b-overlay>

                    </div>
                  </form>          
              </div>
            </div>

            
          </div>

      </div>
    </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { getJWTpayload } from '../helpers/JWT';
import { enviarTicket } from '../helpers/enviarTicket';
import { infoAlert } from '../helpers/fastAlert';
import axios from "axios";
import { montarNombreCalle } from '../helpers/utils';

export default {
  /*
    Busca entre las coberturas que tiene asignadas el operador.
    Descarga una lista con las coberturas que tiene contratadas el operador y extrae las provincias, poniendola en un dataList, cuando el usuario selecciona una provincia, se le asignan
    los pueblos disponibles al siguiente dataList de pueblos y se desbloquea para que elija un pueblo. Si elige un pueblo se extraen los tipos de via disponibles y se desbloquea 
    la calle. Al escribir la calle se hace la busqueda en la API  y se muestran los domicilios disponibles filtrados por el input tipo via y número.
  */
  name: "BuscarCobertura",
  data() {
    return {
      data: {
        provincia: '',                  //Provincia a buscar 
        poblacion: '',                  //Población a buscar 
        tipoVia: '',                    //Tipo de via (filtro)
        calle: '',                      //Calle a buscar, inicia la busqueda real
        numero: ''                      //Numero de calle (filtro)
      },
      ID_DOMICILIO: '',
      tipoBusqueda: 'buscador',
      coberturas: [],                   //Coberturas contratadas por el operador
      provinciasDistintas: [],          //Las provincias disponibles (input) 
      poblacionesDisponibles: [],       //Los pueblos disponibles (input) 
      tiposdeVia: [],                   //tipos de via (input) 
      numerosDisponibles: [],           //Numeros disponibles (input)  

      mensajedeError: '',
      cargandoCoberturas: false,
      cargandoDomicilios: false,
      domiciliosDisponibles: '',              //Domicilios disponibles resultado de la busqueda
      domiciliosDisponiblesFiltrados: '',     //Domicilios disponibles filtrados
      mensajeActionCard: 'Introduzca datos',
      colorMensajeActionCard: 'text-black-50',

      maxDomicilios: 15,

      cargandoSolicitud: false,
      dirty: false,                     //Indica si se ha hecho almenos una búsqueda
    }
  },
  validations: {
    data: {
      REMOTE_ID: {
        required,
        maxLength: maxLength(50)
      },
      provincia: {
        required,
        maxLength: maxLength(128)
      },
      poblacion: {
        required,
        maxLength: maxLength(128)
      },
      calle: {}
    }
  },
  methods: {
    //Descarga de la API las coberturas contratadas por el operador
    async getCoberturas(){
        try {
          this.cargandoCoberturas = true;
          let OPID = getJWTpayload().OPID;
          let respuesta = await axios.get('/coberturas?OPID=' + OPID, { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.coberturas = respuesta.data.data.coberturas;
          this.provinciasDistintas = this.coberturas.map(item => item.provincia).filter((value, index, self) => self.indexOf(value) === index);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        } finally {
            this.cargandoCoberturas = false;
        }
    }, 
    //Descarga los tipos de vía de una provincia y población
    async getTiposdeVia(){
        try {
          let headers = {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('x-token') 
          }
          let body = { 
            provincia: this.data.provincia,
            poblacion: this.data.poblacion,
            calle: this.data.calle
          }
          let respuesta = await axios.get('/hogares/vias', {
            params: { provincia: this.data.provincia, poblacion: this.data.poblacion },
            headers
          });
          this.tiposdeVia = respuesta.data.data.tiposdeVia;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    },
    //Descarga de la API los domicilios dependiendo de la provincia, poblacion y calle escrita
    async getDomicilios(){
        try {
          this.cargandoDomicilios = true;
          this.colorMensajeActionCard = 'text-black-50'

          const respuesta = await axios.get('/hogares/calle', {
              method: 'GET',
              params: { 
              provincia: this.data.provincia,
              poblacion: this.data.poblacion,
              calle: this.data.calle
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          })
          this.dirty = true;
          this.domiciliosDisponibles = respuesta.data.data.calle;
          //Construimos las direcciones completas
          for (const domicilio of this.domiciliosDisponibles) {
            montarNombreCalle(domicilio);
          }
          //Mostramos los números disponibles, quitamos los repetidos y los ordenamos
          this.numerosDisponibles = this.domiciliosDisponibles.map(item => parseInt(item.NUMERO)).filter((value, index, self) => self.indexOf(value) === index).sort((a,b)=>a-b);
          this.filtrarDomicilios();
          
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              //Request made and server responded
              this.mensajeActionCard = error.response.data.msg;
              this.colorMensajeActionCard = 'text-danger'
            } else if (error.request) {
              console.log(error.request);
              // The request was made but no response was received
              this.mensajeActionCard = 'No se ha recibido respuesta del servidor';
              this.colorMensajeActionCard = 'text-danger'
            } else {
              console.log(error.message);
              // Something happened in setting up the request that triggered an Error
              this.mensajeActionCard = error.message;
              this.colorMensajeActionCard = 'text-danger'
            }
        } finally {
            this.cargandoDomicilios = false;
        }
    },
    //Pierde el focus del dataList para que se oculten las sugerencias
    loseFocus(evt) {
      evt.target.blur()
    },
    //Busca los hogares disponibles cuando se escribe una calle con más de dos caracteres
    buscarhogares(){
      this.domiciliosDisponibles = [];
      this.domiciliosDisponiblesFiltrados = [];
      this.maxDomicilios = 15;
      this.data.numero = ''
      if(this.data.calle && this.data.poblacion && this.data.provincia && this.data.calle.length >= 2) 
      {
        this.getDomicilios();
      }      
    },
    //Filtra los domicilios disponibles por via y número
    filtrarDomicilios(){
      this.maxDomicilios = 15;
      if(!this.domiciliosDisponibles || !this.domiciliosDisponibles.length > 0) return;

      this.domiciliosDisponiblesFiltrados = this.domiciliosDisponibles;
      
      //Si hay un filtro de vía
      if(this.data.tipoVia){
        this.domiciliosDisponiblesFiltrados = this.domiciliosDisponiblesFiltrados.filter( item => item.TIPO_VIA == this.data.tipoVia )
      }
      //Si hay un filtro de número
      if(this.data.numero){
        this.domiciliosDisponiblesFiltrados = this.domiciliosDisponiblesFiltrados.filter( item => parseInt(item.NUMERO) == this.data.numero )
      }
    
    if(this.domiciliosDisponiblesFiltrados.length > 0)
    { 
      this.colorMensajeActionCard = 'text-black-50';
    } else{
      this.colorMensajeActionCard = 'text-danger'
    }     
    this.mensajeActionCard = `${this.domiciliosDisponiblesFiltrados.length} resultados `;

    },
    //Formatea una calle legible para el ser humano a partir de los datos de hogares
    montarNombreCalle(domicilio){
      return montarNombreCalle(domicilio);
    },
    //Información botones interrogación
    infoPage(){
      infoAlert('Buscar cobertura', 'Aquí puedes buscar la cobertura que tienes contratada y que puedes ofrecer a tus clientes.')
    },
    infoDomicilio(){
      infoAlert('Nombre vía', 'Introduzca el nombre de la vía sin especificar el tipo (Calle, Avenida, Plaza...) <br><br> <small class="fst-italic">Ej.: "San Martín" para "Calle San Martín"</small> <br><br>De este modo podremos buscar el domicilio del cliente.');
    },
    infoBuscador(){
      infoAlert('Buscador de coberturas', 'El buscador sirve para encontrar el domicilio. <br><br> Introduzca la <strong>provincia</strong>, <strong>población</strong> y <strong>nombre de vía</strong> para mostrar una lista de domicilios disponibles.');
    },
    infoError(){
      infoAlert('Error cargando', 'Es posible que no se pueda hacer al buscador de coberturas debido a un error cargando sus datos, o bien que no se le hayan asignado <strong>perfiles de servicio</strong> o un area de <strong>coberturas.</strong>')
    },
    infoIDDomicilio(){
      infoAlert('Id de domicilio', 'El id del domicilio es en un identificador único que hace referencia a un hogar en concreto. Si tienes un id de domicilio, podrás <strong>buscar el hogar directamente</strong>.');
    },        
    setModoBusquedaBuscador(){
      this.tipoBusqueda = 'buscador';
      this.resetearFormulario();
    },
    setModoBusquedaID(){
        this.tipoBusqueda = 'id'
        setTimeout(() => {          
          let input = document.getElementById('ID_DOMICILIO');
          if(input) input.focus();
        }, 100);
      this.resetearFormulario();
    },
    async buscarPorID(){    
      if(this.data.ID_DOMICILIO && this.data.ID_DOMICILIO.length >=26 && this.data.ID_DOMICILIO.length <= 38){
        try{
          let respuesta = await axios({
            method: 'GET',
            url: '/hogares/domicilio',
            params: { ID_DOMICILIO: this.data.ID_DOMICILIO },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.dirty = true;
          let domicilio = respuesta.data.data.domicilio[0];
          montarNombreCalle(domicilio);
          this.domiciliosDisponibles = [];
          this.domiciliosDisponiblesFiltrados = [];
          this.domiciliosDisponibles.push(domicilio);
          this.domiciliosDisponiblesFiltrados.push(domicilio);
          this.mensajeActionCard = 'Domicilio encontrado';
          this.colorMensajeActionCard = 'text-success';
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              //Request made and server responded
              this.mensajeActionCard = error.response.data.msg;
              this.colorMensajeActionCard = 'text-danger'
            } else if (error.request) {
              console.log(error.request);
              // The request was made but no response was received
              this.mensajeActionCard = 'No se ha recibido respuesta del servidor';
              this.colorMensajeActionCard = 'text-danger'
            } else {
              console.log(error.message);
              // Something happened in setting up the request that triggered an Error
              this.mensajeActionCard = error.message;
              this.colorMensajeActionCard = 'text-danger'
            }
        } 
      }
    },
    //Reinicia la pagina
    resetearFormulario(){
        document.getElementsByClassName("Panel")[0].scrollTop = 0;
        this.data.ID_DOMICILIO = '';
        this.data.provincia = '';
        this.data.poblacion = '';
        this.data.tipoVia = '';
        this.data.calle = '';
        this.data.numero = '';
        this.domiciliosDisponibles = '';
        this.domiciliosDisponiblesFiltrados = '';
        this.numerosDisponibles = [];
        this.tiposdeVia = [];
        this.defaultActionCardMSG();
        this.maxDomicilios = 15;
        this.$v.$reset();        
    },
    //Pone el mensaje por defecto en el buscador de domicilios
    defaultActionCardMSG(){
      this.mensajeActionCard = 'Introduzca datos';
      this.colorMensajeActionCard = 'text-black-50';
    },
    abrirTicket(){
      let email = getJWTpayload().email;
      let operador = getJWTpayload().operador;
      let mensaje = '';
      if(this.data.provincia){
        mensaje = "Hola, nos gustaría saber si podriamos ampliar nuestra cobertura contratada en la provincia " + this.data.provincia.toUpperCase() + "\nUn saludo.";
      }
      if(this.data.provincia && this.data.poblacion){
        mensaje = "Hola, nos gustaría saber si podriamos ampliar nuestra cobertura contratada en la población " + this.data.poblacion.toUpperCase() + " (" + this.data.provincia.toUpperCase() + ")\nUn saludo.";
      }
      if(!mensaje) mensaje = "Hola, nos gustaría obtener más información sobre las coberturas disponibles para contratar.\nUn saludo.";
      enviarTicket("Información de coberturas", email, mensaje, operador);
    }
  },
  created() {
    this.getCoberturas();
  },
  //Comprobamos los cambios de provincias y poblaciones
  watch: {
    'data.provincia'(newVal, oldVal){

      this.data.poblacion = ''
      this.data.tipoVia = ''
      this.data.calle = ''
      this.data.numero = ''
      this.domiciliosDisponibles = ''
      this.domiciliosDisponiblesFiltrados = ''
      this.defaultActionCardMSG()

      let valido = false
      for (const item of this.provinciasDistintas) {
       if(item == newVal) {
         valido = true;
       }
      }
      if (!valido) {
        this.data.provincia = ''
        return;
      }

      this.poblacionesDisponibles = []
      for (const iterator of this.coberturas) {
        if(iterator.provincia == newVal) this.poblacionesDisponibles.push(iterator.poblacion)
      }
    },
    'data.poblacion'(newVal, oldVal){
      this.data.tipoVia = ''
      this.data.calle = ''
      this.data.numero = ''
      this.domiciliosDisponibles = ''
      this.domiciliosDisponiblesFiltrados = ''
      this.defaultActionCardMSG()
      if (!this.poblacionesDisponibles.includes(newVal)) {
        this.data.poblacion = ''
        return;
      }

      this.getTiposdeVia();
    },

  },
  computed: {
    computedTiposVia(){
      if (this.tiposdeVia) {
        return this.tiposdeVia;
      }
      return [];
    },
    computedNumerosDisponibles(){
      if (this.numerosDisponibles) {
        return this.numerosDisponibles;
      }
      return [];
    }
  
  }
};
</script>


<style scoped>
  .number-input {
    width: 10rem;
  }
  .boton-mostrarmas{
    width: 10rem;
  }

</style>
