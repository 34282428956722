<template>

    <div class="card page-card border-0 shadow-lg my-5">
       
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Codigos de municipios</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
        <!--Nuevo municipio-->
        <div v-if="esEditorInfluencia()">
            <button class="btn btn-sm btn-primary shadow-sm d-none d-sm-block" @click="toggleCreator()"><i class="fas fa-plus"></i> Nuevo municipio</button>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>

      <!-- ############### -->
      <!-- Nuevo municipio -->
      <!-- ############### -->
      <b-collapse id="creator" v-model="showCreator">
        <div  class="m-3 card shadow">
            <div class="card-body"> 
            <!-- header -->
            <div class="d-flex align-items-baseline justify-content-start">
                <h4>Nuevo municipio</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoNuevoMunicipio()"></i>
            </div>
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                <div class="row">
                    <!-- codigo_provincia -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                            <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                <label for="codigo_provincia" class="line mx-1  d-flex align-items-center">Código provincia</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                <div class="row d-flex flex-row">
                                <div class="col-10">
                                    <input id="codigo_provincia" class="form-control w-100 m-0" list="listaProvincias" :disabled="cargandoPeticion" maxlength="11" v-model.lazy="$v.codigo_provincia.$model" placeholder="Código de provincia" :class="{ 'is-invalid': $v.codigo_provincia.$error}" autocomplete="off" @change="$event.target.blur(); ponerUtlimoCodigo_municipio()">
                                    <datalist id="listaProvincias">
                                    <option v-for="(item, index) in listaCodigosProvincias" :key="index" :value="item.codigo_provincia">{{item.provincia}}</option> 
                                    </datalist> 
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>            
                    
                    <!-- codigo_municipio -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="codigo_municipio" class="mx-1  d-flex align-items-center">Código municipio</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10">
                                   <input id="codigo_municipio"  class="form-control w-100 m-0" type="text" required maxlength="11" v-model="$v.codigo_municipio.$model" placeholder="Código privado del municipio" :class="{ 'is-invalid': $v.codigo_municipio.$error}" autocomplete="off" v-input-number>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>  

                    <!-- municipio -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="nombre_municipio" class="mx-1  d-flex align-items-center">Nombre municipio</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10">
                                   <input id="nombre_municipio"  class="form-control w-100 m-0" type="text" required maxlength="255" v-model="$v.nombre_municipio.$model" placeholder="Nombre del municipio" :class="{ 'is-invalid': $v.nombre_municipio.$error}" autocomplete="off">
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>    

                    <hr class="mt-4">
                    <div class="d-flex flex-column flex-md-row  justify-content-center">
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarFormulario()" title="Borra todos los datos escritos en el formulario">        
                        Limpiar
                        </button>
                        <button type="button" :disabled="cargandoPeticion || $v.$invalid" class="btn btn-primary boton-generar line my-1 mx-2" @click="crearCodigoMunicipio()" title="Añade el municipio nuevo">
                        <i  v-if="!cargandoPeticion" class="fa-solid fa-file-circle-plus mx-1"></i>
                        <div v-else class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                        Añadir
                        </button>
                    </div>

                </div>

                </form>
            
            </div>
            </div>
        </div>
      </b-collapse>

      <!-- ############ -->
      <!-- Asignar olts -->
      <!-- ############ -->
      <b-collapse id="creator" v-model="showEditor">
        <div  class="m-3 card shadow">
            <div class="card-body"> 
            <!-- header -->
            <div class="d-flex align-items-baseline justify-content-start">
                <h4>Asignar OLTs al municipio</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoActualizarMunicipio()"></i>
            </div>
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                <div class="row">  
                    <!-- codigo_provincia -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                            <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                <label for="" class="line mx-1  d-flex align-items-center">Código provincia</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                <div class="row d-flex flex-row">
                                <div class="col-10 text-start">
                                    <small>{{dataEdicion.codigo_provincia}} ({{dataEdicion.provincia}})</small>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>            
                    
                    <!-- codigo_municipio -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="" class="mx-1  d-flex align-items-center">Código municipio</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10 text-start">
                                    <small>{{dataEdicion.codigo_municipio}} ({{dataEdicion.municipio}})</small>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>  
                    <!-- Olts -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="olts_municipio" class="line mx-1  d-flex align-items-center">OLTs del municipio</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                            <div class="col-10">
                                <input id="olts_municipio" class="form-control w-100 m-0" list="listaOltsDom" :disabled="cargandoPeticion" maxlength="23" v-model.lazy="oltSeleccionada" placeholder="CODIGO_OLT" autocomplete="off" @change="$event.target.blur()">
                                <datalist id="listaOltsDom">
                                <option v-for="(item, index) in listaOlts" :key="index" :value="item.CODIGO_OLT">{{item.IP_GESTION}}</option> 
                                </datalist> 
                            </div>
                            </div>
                        </div>
                      </div>
                    </div>  
                    <div v-if="oltsElegidas && oltsElegidas.length" class="table-responsive shadow list-limited-height my-4 mx-auto p-0 tabla-olts">
                        <table class="table table-striped table-bordered table-tiny">
                            <thead class="text-start">
                            <tr class="noselect">
                                <th scope="col" class="d-table-cell action-column">Acción</th>
                                <th scope="col" class="d-table-cell">CODIGO_OLT</th>
                                <th scope="col" class="d-table-cell">IP_GESTION_GPON</th>
                            </tr>
                            </thead>
                            <tbody class="align-middle text-start">     
                                <tr v-for="(item, index) in oltsElegidas" :key="index" :id="'olt'+item.index" role="lista olt seleccionadas">
                                    <!-- Acción -->
                                    <td class="d-table-cell">
                                    <div class="d-flex justify-content-around ">
                                        <i type="button" class="fa-solid fa-xmark fs-4 mx-1 icon-btn text-danger" title="Quitar OLT" @click="quitarOLT(item.CODIGO_OLT)"></i>
                                    </div>
                                    </td>
                                    <!-- CODIGO_OLT -->
                                    <td class="d-table-cell">{{item.CODIGO_OLT}}</td>
                                    <!-- IP GESTION GPON -->
                                    <td class="d-table-cell">{{item.IP_GESTION}}</td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                    
                    <hr class="mt-4">
                    <div class="d-flex flex-column flex-md-row  justify-content-center">
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarFormulario()" title="Borra todos los datos escritos en el formulario">        
                         Limpiar
                        </button>
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-danger boton-generar line my-1 mx-2" @click="cancelarEditar()" title="Cancela la asignación de OLTs">        
                          Cancelar
                        </button>                        
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-primary boton-generar line my-1 mx-2" @click="enviarPeticionEdicion()" title="Asignar OLTs">
                        <i  v-if="!cargandoPeticion" class="fa-solid fa-floppy-disk mx-1"></i>
                        <div v-else class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                          Guardar
                        </button>
                    </div>

                </div>

                </form>
            
            </div>
            </div>
        </div>
      </b-collapse>

      <!-- ##################### -->
      <!-- Municipios existentes -->
      <!-- ##################### -->
      <div v-show="listaMunicipios && listaMunicipios.length" class="m-3 card shadow">
        <div class="card-body"> 

          <!-- Header -->
          <div class="d-flex align-items-baseline justify-content-start">
             <h4>Lista de municipios</h4>
          </div>
          <b-overlay :show="cargandoDatatable">
          <div class="mt-3">
            <div class="m-2 p-2 shadow-lg">
                <table id="datatable"  class="table table-striped table-bordered w-100 table-tiny" width="100%">
                    <thead class="text-start">
                        <tr class="noselect">
                        <th v-if="esEditorInfluencia()" scope="col" class="d-table-cell align-middle text-center" style="max-width: 3.5rem;">ACCION</th>
                        <th scope="col" class="d-table-cell">Código de provincia</th>
                        <th scope="col" class="d-table-cell">Código de municipio</th>
                        <th scope="col" class="d-table-cell">OLTs</th>
                        </tr>
                    </thead>
                    <tbody class="align-middle text-start">                              
                    </tbody>
                </table>
            </div>
          </div>
          </b-overlay>
        </div>
      </div>


    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert, warningAlert } from '../../helpers/fastAlert';
import { required, maxLength, numeric } from 'vuelidate/lib/validators';
import axios from "axios";
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from 'sweetalert2'
import { isEditorInluencia } from '../../helpers/JWT';


export default {
  name: 'Municipios',
  data() {
    return {
      //Nuevo municipio
      listaCodigosProvincias: [],
      listaOlts: [],
      mensajedeError: '',
      codigo_provincia: '',
      codigo_municipio: '',
      nombre_municipio: '',
      oltSeleccionada: '',
      oltsElegidas: [],
      cargandoPeticion: false,
      cargandoDatatable: false,
      showCreator: false,
      showEditor: false,
      dataEdicion: {},

      //ListaMunicipios
      listaMunicipios: [],
      tabla: ''
    } 
  },
  validations: {
    codigo_municipio: {
      required,
      numeric,
      maxLength: maxLength(11)
    },
    nombre_municipio: {
      required,
      maxLength: maxLength(255)
    },    
    codigo_provincia: {
      required,
      numeric,
      maxLength: maxLength(11)
    }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Códigos de municipios', 'Los códigos de los municipios son unos identificadores privados que se utilizan para generar los <strong>CODIGO_OLT</strong>, los <strong>CODIGO_CTO</strong> y el <strong>ID_DOMICILIO</strong>. Además, partir de estos municipios se generarán los nuevos registros de huella.')
    },
    infoNuevoMunicipio(){
      infoAlert('Nuevo municipio', 'Crearás un nuevo municipio con su correspondiente código de municipio, que será usado para formatear los <strong>CODIGO_OLT</strong>, los <strong>CODIGO_CTO</strong> y el <strong>ID_DOMICILIO</strong> que correspondan a el nuevo municipio.')
    },    
    infoActualizarMunicipio(){
      infoAlert('Asignar OLTs', 'Al asignar OLTs indicas con que OLTs trabaja ese municipio y por lo tanto se utilizará para crear nuevos trazados')
    },  
    toggleCreator(){        
        this.showCreator = !this.showCreator
        this.showEditor = !this.showCreator && this.showEditor
        document.getElementsByClassName("Panel")[0].scrollTop = 0;
    },
    initDatatable(){
      this.cargandoDatatable = true;
      this.tabla = $('#datatable').DataTable({
              autoWidth: false,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                {
                  data: null,
                  defaultContent:  `
                    <i type="button" class="fa-solid fa-pencil fa-2x mx-1 icon-btn text-primary editar-item" title="Asignar OLTs al municipio"></i>
                    <i type="button" class="fa-solid fa-trash-alt fa-2x mx-1 icon-btn text-danger eliminar-item" title="Eliminar codigo de municipio"></i>
                  `,
                  className: 'dt-body-center',
                  width: '3rem',
                  visible: isEditorInluencia()
                },
                { 
                 data: 'codigo_provincia',
                 render: (data, type, rowData) =>  `
                      <div class="d-flex align-items-center flex-column">
                       ${('00' + data).substr(-2)} (${rowData.provincia || 'Provincia desconocida'})
                      </div>`
                },
                { 
                 data: 'codigo_municipio',
                 render: (data, type, rowData) => `
                      <div class="d-flex align-items-center flex-column">
                        ${('000' + data).substr(-3)} (${rowData.municipio || 'Municipio desconocido'})
                      </div>`
                },
                { 
                 data: 'olts',
                 render: (data, type, rowData) => {
                    if(!data || !data.length) return ''
                    let htmlfinal = ''
                    for (const iterator of data) {
                      if(iterator.CODIGO_OLT){
                        let splited = iterator.CODIGO_OLT.split('-');
                        let cpOLT = splited[1]
                        let cmOLT = splited[2]
                        if(cpOLT == rowData.codigo_provincia && cmOLT == rowData.codigo_municipio){
                          htmlfinal += `<div class="line"><strong>[NODO]</strong> ${iterator.CODIGO_OLT || ''} - ${iterator.IP_GESTION || 'IP desconocida'}<br></div>`
                          iterator.nodo = true;
                        }else htmlfinal += `<div class="line">${iterator.CODIGO_OLT || ''} - ${iterator.IP_GESTION || 'IP desconocida'}<br></div>`

                      }
                    }
                    return ` <div class="d-flex align-items-start flex-column">
                        ${htmlfinal}
                    </div>`
                 }
                },
              ]    
        });
      if(isEditorInluencia()){
        $('#datatable tbody').on('click', 'i.eliminar-item', (event) => {   
          let rowData = this.tabla.row($(event.target).parents('tr')).data();
          this.eliminarCodigoMunicipio(rowData);
        });
        $('#datatable tbody').on('click', 'i.editar-item', (event) => {   
          let rowData = this.tabla.row($(event.target).parents('tr')).data();
          this.editarCodigoMunicipio(rowData);
        });
      }
    },
    async crearCodigoMunicipio(){
       try {
          this.cargandoPeticion = true;
          let respuesta = await axios({
            method: 'POST',
            url: '/municipios',
            data: {
              codigo_provincia: this.codigo_provincia,
              codigo_municipio: this.codigo_municipio,
              municipio: this.nombre_municipio,
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          successAlert('Proceso completado', respuesta.data.msg)
          this.cargandoPeticion = false;
          this.limpiarFormulario()

          this.getMunicipios();
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            errorAlert('Error creando codigo de municipio', error.response.data.msg)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
          }
        }
    },
    async eliminarCodigoMunicipio(rowData){
      this.cargandoPeticion = true;
      await Swal.fire({
            title: '¿Está seguro?',
            html: `Se borrará el código de municipio y la relación con las OLTs asignadas`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText:  '<i class="fa-solid fa-trash-alt"></i> Eliminar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {

                return axios({
                  method: 'DELETE',
                  url: '/municipios',
                  params: { uid: rowData.uid},
                  headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
                })
                .then(resp => {})
                .catch(err => {
                  console.log("error", err);
                  if (err.response) {
                    //Request made and server responded
                    console.log(' manejado', err.response);
                    Swal.showValidationMessage(`Error en el proceso. ${err.response.data.msg}`);
                  } else if (err.request) {
                    // The request was made but no response was received
                    Swal.showValidationMessage('No se ha recibido respuesta del servidor');
                  } else {
                    // Something happened in setting up the request that triggered an err
                    console.log('err no manejado', err.message);
                    Swal.showValidationMessage(err.message);
                  }  
                })

            },
            backdrop: true,
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                successAlert('Proceso completado', 'Se ha eliminado el código de municipio correctamente')
                this.getMunicipios();
              }
            })
            this.limpiarFormulario()
            this.showCreator = false;
            this.showEditor = false;
      this.cargandoPeticion = false;
    },
    editarCodigoMunicipio(rowData){
      this.dataEdicion = rowData;
      this.oltsElegidas = JSON.parse(JSON.stringify(this.dataEdicion.olts))
      this.showEditor = true;
      this.showCreator = false;
      document.getElementsByClassName("Panel")[0].scrollTop = 0;
    },
    cancelarEditar(){
      this.limpiarFormulario()
      this.showEditor = false;
    },
    async enviarPeticionEdicion(){
        try {
          this.cargandoPeticion = true;
          let nuevas = this.oltsElegidas.map(e => e.CODIGO_OLT)
          let respuesta = await axios({
            method: 'PUT',
            url: '/municipios',
            params: { uid: this.dataEdicion.uid},
            data: {
              olts: nuevas
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.getMunicipios();
          successAlert('¡Proceso completado!', respuesta.data.msg)
          this.cancelarEditar();
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            errorAlert('No se pudo asignar OLTs', error.response.data.msg)
            console.log(error.response);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            serverErrorAlert(error.message);
          }
        }
    },
    ponerDatosDatatable(){
      this.tabla.clear();
      this.tabla.rows.add(this.listaMunicipios).draw();
      this.cargandoDatatable = false;
    },
    limpiarFormulario(){
      this.codigo_provincia = '';
      this.codigo_municipio = '';
      this.nombre_municipio = '';
      this.oltSeleccionada = '';
      if(this.dataEdicion && this.dataEdicion.olts) this.oltsElegidas = JSON.parse(JSON.stringify(this.dataEdicion.olts))
      this.$v.$reset();
    },
    quitarOLT(CODIGO_OLT){
        this.oltsElegidas = this.oltsElegidas.filter( e => e.CODIGO_OLT != CODIGO_OLT)
    },
    async getMunicipios(){
        try {
          let respuesta = await axios.get('/municipios', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.listaMunicipios = respuesta.data.data.listaMunicipios; 
          this.ponerDatosDatatable()
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    async getCodigosProvincias(){
        try {
          let respuesta = await axios.get('/municipios/provincias', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.listaCodigosProvincias = respuesta.data.data.listaProvincias;          
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    async getOlts(){
        try {
          let respuesta = await axios.get('/olts', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.listaOlts = respuesta.data.data.listaOlts;          
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    ponerUtlimoCodigo_municipio(){
       let valorMaximo = Math.max(...this.listaMunicipios.filter( e => e.codigo_provincia == this.codigo_provincia).map(e => e.codigo_municipio));
       if(valorMaximo && Number.isInteger(valorMaximo)) this.codigo_municipio = valorMaximo + 1;
       else this.codigo_municipio = 1
    },
    esEditorInfluencia(){
      return isEditorInluencia();
    },
  },
  created() {
    this.getMunicipios();
    this.getCodigosProvincias();
    this.getOlts();
  },
  mounted(){
    this.initDatatable()
  },
  watch: {
    'oltSeleccionada'(newVal, oldVal){
      if(newVal) newVal = newVal.trim(); //Quitamos espacios en blanco que de copiar y pegar suelen quedarse
      let oltencontrada = this.listaOlts.find(e => e.CODIGO_OLT == newVal)
      if(oltencontrada && !this.oltsElegidas.find(e => e.CODIGO_OLT == newVal)){
        this.oltsElegidas.push(oltencontrada);
      }
      this.oltSeleccionada = ''
    },
    'codigo_provincia'(newVal, oldVal){
      if(!this.listaCodigosProvincias.find(e => e.codigo_provincia == newVal))
      {
        this.codigo_provincia = ''
      }
    },
  }
}
</script>

<style scoped>
.action-column{
  width: 2rem;
}
.tabla-olts{
    max-width: 30rem;
}
</style>
