<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1>Historial</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>
            <HCHistorialOperadores></HCHistorialOperadores>


          </div>

      </div>

      
  </div>
</template>

<script>
import { infoAlert } from '../helpers/fastAlert';
import HCHistorialOperadores from "@/components/HCHistorialOperadores"

export default {
  /*

  */
  name: 'Historial',
  components: {
    HCHistorialOperadores
  },
  data() {
    return {

    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Historial de operaciones', 'En esa página podrás encontrar una lista de registros con todas las operaciones que ha realizado tu operador.')
    },
  },
}
</script>

<style scoped>
.firstColumn{
  width: 17rem;
}
.acciones {
  width: 9rem;
}
.accion-btn {
  width: 13rem;
}

</style>
