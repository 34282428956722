<template>
    <div class="card-body">
      <!-- Filtros -->  
      <b-collapse id="filtros" v-model="mostrarFiltros">
        <div>
            <form class="user d-flex flex-column" @submit.prevent="realizarBusquedaFiltrada()" novalidate>
              <div class="row">
                  <!-- OPERADOR FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="OPERADOR" class="line mx-1 fs-5 d-flex align-items-center">Operador</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERADOR" class="form-control  m-0" maxlength="128" v-model="filtros.OPID">                                
                                  <option value="" selected>Sin especificar</option>
                                    <option v-for="(item, index) in operadores" :key="index" :value="item.id_operador">{{item.operador}} - {{item.id_operador}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>

                  <!-- ID_ADMINISTRATIVO FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="ID_ADMINISTRATIVO" class="mx-1 fs-5 d-flex align-items-center">ID Servicio</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="ID_ADMINISTRATIVO"  class="form-control w-100 m-0" type="text" required maxlength="14" v-model="filtros.ID_ADMINISTRATIVO" placeholder="ID administrativo" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>

                  <!-- DIRECCION FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label for="direccion" class="mx-1 fs-5 d-flex align-items-center">Dirección</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                          <div class="row d-flex flex-row">
                            <div class="col-10">
                              <input id="direccion"  class="form-control w-100 m-0" type="text" required maxlength="255" v-model="filtros.DIRECCION" placeholder="Dirección física" autocomplete="off">
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>                

                  <!-- REMOTE ID FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label for="remoteid" class="mx-1 fs-5 d-flex align-items-center">Remote ID</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                          <div class="row d-flex flex-row">
                            <div class="col-10">
                              <input id="remoteid"  class="form-control w-100 m-0" type="text" required maxlength="50" v-model="filtros.REMOTE_ID" placeholder="Número de serie" autocomplete="off">
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>      
                  <!-- Perfil velocidad FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="perfil" class="line mx-1 fs-5 d-flex align-items-center">Perfil</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="perfil" class="form-control  m-0" maxlength="255" v-model="filtros.PERFIL">                                
                                  <option value="" selected>Sin especificar perfil de velocidad</option>
                                    <option v-for="(item, index) in perfilesDistintos" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!-- Provincia FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="Provincia" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="Provincia" class="form-control  m-0" maxlength="255" v-model="filtros.PROVINCIA" @change="mostrarPueblosDisponibles()">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in provincias" :key="index" :value="item.PROVINCIA">{{item.PROVINCIA}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>            
                  
                  <!-- Pueblos FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="Pueblo" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="Pueblo" class="form-control  m-0" maxlength="255" v-model="filtros.POBLACION" :disabled="!filtros.PROVINCIA">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in poblaciones" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>                  
                  <!-- FECHA FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label for="datepicker-desde" class="line mx-1 fs-5 d-flex align-items-center">Fecha</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                          <div class="row d-flex flex-row align-items-center">
                            <div class="col-10 col-md-5 text-start">
                              <b-form-datepicker today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-desde" placeholder="Desde" locale="es" v-model="filtros.DESDE"></b-form-datepicker>
                            </div>
                            <div class="col-10 col-md-5 mt-3 mt-md-0 text-start">
                              <b-form-datepicker today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-hasta" placeholder="Hasta" locale="es" v-model="filtros.HASTA"></b-form-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  
                  <!-- Botones -->
                  <div class="d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-danger form-btn mx-2" @click="resetFiltros()">Cancelar</button>
                    <button type="submit" :disabled="cargandoServicios" class="btn btn-primary form-btn mx-2">
                      <i v-if="!cargandoServicios" class="fa-solid fa-magnifying-glass mx-1"></i>
                      <div v-else class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      Buscar
                    </button>
                  </div>		
                  <hr class="mt-4">


              </div>
            </form>          
        </div>
      </b-collapse>

      <!-- Carga -->
      <div v-if="cargandoServicios" class="my-4">
        <b-spinner></b-spinner>
        <h4 >Cargando datos...</h4>
      </div>
      


      <!-- Contenido -->
      <div>
          <!-- Paginador y boton filtro -->
          <div class="d-flex justify-content-between">
            <button v-if="numRegistros || filtrado" class="btn btn-secondary text-white fw-bold fit-content m-3 filtrar" type="button" v-b-toggle="'filtros'">
              <i class="fa-solid fa-filter"></i>
              Filtrar ({{numRegistros}})
            </button>
            <Paginador v-if="numRegistros" class="m-3" @recargar="cambiarPagina" :page="page" :numRegistros="numRegistros" :registrosPorPagina="registrosPorPagina"></Paginador>
          </div>
          <!-- Tabla -->
          <div v-if="numRegistros" class="table-responsive shadow list-limited-height">

            <table class="table table-striped table-bordered">
              
                <thead class="text-start">
                  <tr class="noselect">
                    <th scope="col" class="ID_ADMINISTRATIVO d-table-cell line sorting" @click="ordenarTabla($event, 'ID_ADMINISTRATIVO')">ID ADMINISTRATIVO</th>
                    <th scope="col" class="d-table-cell sorting" @click="ordenarTabla($event, 'id_operador')">OPERADOR</th>
                    <th scope="col" class="d-table-cell sorting"  @click="ordenarTabla($event, 'direccion')">DIRECCIÓN</th>
                    <th scope="col" class="d-table-cell line sorting sorting_desc" @click="ordenarTabla($event, 'fechaCreacion')">FECHA ALTA</th>
                    <th scope="col" class="d-none d-lg-table-cell sorting" @click="ordenarTabla($event, 'PERFILVELOCIDAD')">PERFIL</th>
                    <th scope="col" class="d-none d-xl-table-cell line sorting" @click="ordenarTabla($event, 'REMOTE_ID')">REMOTE ID</th>
                    <th scope="col" class="d-none d-xxl-table-cell acciones text-center">ACCIÓN</th>
                  </tr>
                </thead>
                <tbody class="align-middle text-start">     
                  <template v-for="(item, index) in servicios">
                      <tr :key="item.ID_ADMINISTRATIVO" :id="item.index" class="accordion searchable" role="listaServicios">
                        <!-- ID_ADMINISTRATIVO -->
                        <td scope="row">
                          <div class="d-flex justify-content-between">
                            <span class="align-self-center fw-bold searchable-text">{{item.ID_ADMINISTRATIVO}}</span>
                            <b-button type="button" variant="primary" class="btn btn-primary btn-circle border-0 shadow-sm expand-btn" @click="animarCollapse('acordeon'+item.ID_ADMINISTRATIVO); toggleIcon(item.ID_ADMINISTRATIVO)"><i class="expand-icon fa-solid fa-plus fs-6" :id="'boton'+item.ID_ADMINISTRATIVO"></i></b-button>
                          </div>
                        </td>
                        <td class="d-table-cell">{{item.operador}}</td>
                        <td class="d-table-cell searchable-text">{{item.direccion}}</td>
                        <td class="d-table-cell">
                          <div class="d-flex flex-column">
                            <div class="line">{{fechaComputada(item.fechaCreacion)}}</div>
                            <span v-show="item.no_facturable" class="mx-2 fs-6 align-middle" title="Cliente marcado como no facturable o prueba, no saldrá en reportes de facturación.">
                              <span class="badge bg-secondary align-middle">Servicio no facturable</span>
                            </span>    
                          </div>
                        </td>
                        <td class="d-none d-lg-table-cell searchable-text">{{item.PERFILVELOCIDAD}}</td>
                        <td class="d-none d-xl-table-cell searchable-text">{{item.REMOTE_ID}}</td>
                        <td class="d-none d-xxl-table-cell">
                          <div class="d-flex justify-content-around ">
                            <!-- <i type="button" class="fa-solid fa-phone fs-4 mx-1 icon-btn text-success" title="Configurar VoIP" @click="configurarVOIP(item)"></i> -->
                            <i type="button" class="fa-solid fa-gauge-high fs-4 mx-1 icon-btn text-primary" title="Cambio servicio" @click="cambiarServicio(item)"></i>
                            <i type="button" class="fa-solid fa-barcode fs-4 mx-2 icon-btn text-" title="Cambiar ONT" @click="cambiarONT(item)"></i>
                            <i type="button" class="fa-solid fa-chart-column fs-4 mx-1 icon-btn text-warning" title="Test" @click="realizarTest(item)"></i>
                            <i type="button" class="fa-solid fa-trash-alt fs-4 mx-1 icon-btn text-danger" title="Baja servicio" @click="bajaServicio(item)"></i>
                          </div>
                        </td>
                      </tr>
                      
                      <!-- COLLAPSABLE INFO EXTRA -->
                      <tr :key="index">
                        <td colspan="7" class="p-0 overflow-hidden">
                          <div class="acordeon"  :id="'acordeon'+item.ID_ADMINISTRATIVO" accordion="my-accordion">
                            <div>
                              <!-- Tarjeta de servicio -->
                              <TarjetaServicio :item="item" @actualizar_servicio="actualizarItem" :fullwidth="true"></TarjetaServicio>
                              <!-- Botones de acción -->
                              <div class="d-flex  justify-content-center my-3 ">
                                    <!-- <button type="button" class="btn btn-success mx-3 accion-btn" @click="configurarVOIP(item)">
                                      <i type="button" class="fa-solid fa-phone fa-1x mx-1" title="Configurar VoIP"></i>
                                      Configurar VoIP
                                    </button>                                     -->
                                    <button type="button" class="btn btn-primary mx-3 accion-btn" @click="cambiarServicio(item)">
                                      <i type="button" class="fa-solid fa-gauge-high fa-1x mx-1" title="Cambiar servicio"></i>
                                      Cambiar servicio
                                    </button>                                            
                                    <button type="button" class="btn btn-dark mx-3 accion-btn" @click="cambiarONT(item)">
                                      <i type="button" class="fa-solid fa-barcode fa-1x mx-1" title="Cambiar ONT"></i>
                                      Cambiar ONT
                                    </button>
                                    <button type="button" class="btn btn-warning mx-3 accion-btn text-white" @click="realizarTest(item)">
                                      <i type="button" class="fa-solid fa-chart-column fa-1x mx-1" title="Test"></i>
                                      Diagnostico
                                    </button>
                                    <button type="button" class="btn btn-danger mx-3 accion-btn" @click="bajaServicio(item)">
                                      <i type="button" class="fa-solid fa-trash-alt fa-1x mx-1" title="Baja servicio"></i>
                                      Baja servicio
                                    </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
              
                  </template>
                
                </tbody>
            </table>
          </div>
          <Paginador class="d-flex justify-content-center" v-if="numRegistros" @recargar="cambiarPagina" :page="page" :numRegistros="numRegistros" :registrosPorPagina="registrosPorPagina"></Paginador>

      </div>
      <!-- Error cargando -->
      <div v-if="!cargandoServicios && (servicios.length < 1 || mensajedeError)" class="alert alert-warning d-flex align-items-center" role="alert">
        <div class="d-flex align-items-center">
          <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
          <div class="d-flex flex-column">
            <div v-if="mensajedeError" class="text-danger">
              {{mensajedeError}}
            </div>  
            <div v-if="servicios.length < 1" class="text-danger align-self-center">
              No se han encontrado servicios
            </div>           
          </div>
        </div>
      </div>
    </div>
</template>


<script>

import { errorAlert, infoAlert } from '../helpers/fastAlert';
import { getJWTpayload, verificarJWT, desconectar, isOperadorAdministrativo } from '../helpers/JWT';
import { necesitaEsperar } from '../helpers/bloqueosPorTiempo';
import Swal from 'sweetalert2'
import axios from "axios";
import { fecha } from "../classes/fecha.class"
import TarjetaServicio from "@/components/TarjetaServicio"
import Paginador from "@/components/Paginador"
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';
import { desexpandirTodo } from '../helpers/utils';

/*
  Si el usuario es administrador, muestra todos los servicios, y permite filtrarlos incluso por operador.
  Además permite la gestión de los servicios
*/
export default {
  name: 'ServiciosAdmin',
    components: {
    TarjetaServicio,
    Paginador
  },
  data() {
    return {   
     cargandoServicios: false,
      mensajedeError: '',
      servicios: [],  //Lista de servicios
      //Filtros y paginacion
      filtros: {
        ID_ADMINISTRATIVO: '',
        DIRECCION: '',
        REMOTE_ID: '',
        PERFIL: '',
        DESDE: '',
        HASTA: '',
        PROVINCIA: '',
        POBLACION: '',
        OPID: ''
      },
      filtrosUsados: {
        ID_ADMINISTRATIVO: '',
        DIRECCION: '',
        REMOTE_ID: '',
        PERFIL: '',
        DESDE: '',
        HASTA: '',
        PROVINCIA: '',
        POBLACION: '',
        OPID: ''
      },
      ascOrder: 'DESC',
      orderElement: 'fechaCreacion',

      mostrarFiltros: false,
      filtrado: false,
      page: 1,
      numRegistros: 0,
      registrosPorPagina: 10,
      perfilesDistintos: [],
      provincias: [],
      poblaciones: [],
      operadores: [],
      servidores_acs: []
    }
  },  
  methods:{
    actualizarItem(nuevoItem) {
      this.servicios = this.servicios.map((item) => (item.ID_ADMINISTRATIVO === nuevoItem.ID_ADMINISTRATIVO ? nuevoItem : item));
    },
    async cambiarPagina(page){
      this.page = page;
      await this.cargarServicios();
    },
    ordenarTabla(event, columna){
      let elemento = event.target;
      let ascOrder = true;
      if(elemento.classList.contains('sorting_asc')){
        ascOrder = false;
      }
      if(elemento && elemento.parentElement){
        let ordenables = elemento.parentElement.querySelectorAll('.sorting');
        for (const iterator of ordenables) {
          iterator.classList.remove('sorting_desc', 'sorting_asc');
        }
        this.orderElement = columna;
        if(ascOrder){
          elemento.classList.add('sorting_asc');
          this.ascOrder = 'ASC';
        }else{
          elemento.classList.add('sorting_desc');
          this.ascOrder = 'DESC';
        }  
        this.page = 1;
        this.cargarServicios();
      }
      
    },
    async cargarFiltros(){
      try {
        let perfilesResp = await axios.get('/tarifas', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.perfilesDistintos = perfilesResp.data.data.tarifas.map(e => e.descripcion).sort();
        let provinciasResp = await axios.get('/hogares/influencia', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.provincias = provinciasResp.data.data.influencia;
      
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },
    mostrarPueblosDisponibles(){
      this.filtros.POBLACION = '';
      if(this.filtros.PROVINCIA){
        this.poblaciones = this.provincias.find(e => e.PROVINCIA == this.filtros.PROVINCIA).PUEBLOS
      }
    },
    realizarBusquedaFiltrada(){
      this.page = 1;
      this.filtrado = true;
      this.filtrosUsados.ID_ADMINISTRATIVO = this.filtros.ID_ADMINISTRATIVO;
      this.filtrosUsados.DIRECCION = this.filtros.DIRECCION;
      this.filtrosUsados.REMOTE_ID = this.filtros.REMOTE_ID;
      this.filtrosUsados.PERFIL = this.filtros.PERFIL;
      this.filtrosUsados.DESDE = this.filtros.DESDE;
      this.filtrosUsados.HASTA = this.filtros.HASTA;
      this.filtrosUsados.PROVINCIA = this.filtros.PROVINCIA;
      this.filtrosUsados.POBLACION = this.filtros.POBLACION;
      this.filtrosUsados.OPID = this.filtros.OPID;
      this.cargarServicios();
    },
    resetFiltros(){
      this.mostrarFiltros = false;
      this.page = 1;
      this.filtros.ID_ADMINISTRATIVO = '';
      this.filtros.DIRECCION = '';
      this.filtros.REMOTE_ID = '';
      this.filtros.PERFIL = '';
      this.filtros.DESDE = '';
      this.filtros.HASTA = '';
      this.filtros.PROVINCIA = '';
      this.filtros.POBLACION = '';
      this.filtros.OPID = '';

      this.filtrosUsados.ID_ADMINISTRATIVO = '';
      this.filtrosUsados.DIRECCION = '';
      this.filtrosUsados.REMOTE_ID = '';
      this.filtrosUsados.PERFIL = '';
      this.filtrosUsados.DESDE = '';
      this.filtrosUsados.HASTA = '';
      this.filtrosUsados.PROVINCIA = '';
      this.filtrosUsados.POBLACION = '';
      this.filtrosUsados.OPID = '';

      this.ascOrder = 'DESC';
      this.orderElement = 'fechaCreacion';
      this.cargarServicios();
    },
    //animar acordeones
    animarCollapse(id){ 
      let colapsable = document.getElementById(id);
      let expandido = colapsable.classList.contains('show');
      for (const iterator of document.getElementsByClassName('acordeon')) {
        iterator.classList.remove('show');
        iterator.style.height = 0 + 'px';

      }
      if(!expandido) {
        colapsable.classList.add('show');
        colapsable.style.height = colapsable.scrollHeight + 'px';
      }
    },
    //Formato de botones de acordeones
    toggleIcon(id){
      let icono = document.getElementById('boton'+id);
      let expandido = document.getElementById('acordeon'+id).classList.contains('show');
      for (const iterator of document.getElementsByClassName('expand-icon')) {
        iterator.parentElement.classList.remove('bg-danger')
        iterator.parentElement.classList.add('bg-primary')
        iterator.classList.remove('fa-minus')
        iterator.classList.add('fa-plus')
      }

      if(expandido){
        icono.classList.remove('fa-plus')
        icono.classList.add('fa-minus')
        icono.parentElement.classList.remove('bg-primary')
        icono.parentElement.classList.add('bg-danger')
      }
    },
    //Cargar de la API los servicios del operador
    async cargarServicios(){
      try {
        this.cargandoServicios = true;
        desexpandirTodo();
        //Filtros
        let filtrosParam = { ...this.filtrosUsados };
        if(filtrosParam.HASTA) filtrosParam.HASTA += ' 23:59:59' //Se añaden tiempo en el parametro hasta para que sea el dia completo y no desde el principio

        let respuestaPandora = await axios.get('/servicios', {
          method: 'GET',
          params: {
            page: this.page,
            registrosPorPagina: this.registrosPorPagina,
            ...filtrosParam,
            ascOrder: this.ascOrder,
            orderElement: this.orderElement
          },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.servicios = respuestaPandora.data.data.listaServicios.filter( item => item.tipo == 'servicio').map(e => {
          e.operador = this.operadores.find(elem => elem.id_operador == e.id_operador).operador + ' - ' + e.id_operador;
          e.servidor_acs = this.servidores_acs.find(elem => elem.id_operador == e.id_operador);
          return e;
        });
        this.numRegistros = respuestaPandora.data.data.numRows;

      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } finally {
        this.cargandoServicios = false;
      }
    },
    //Dar de baja un servicio
    async bajaServicio(item){
      let iua = item.ID_ADMINISTRATIVO;
        if(necesitaEsperar(iua)) return;
          
        await Swal.fire({
          title: '¿Dar de baja el servicio?',
          text: "¡El servicio se eliminará para siempre!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  '<i class="fa-solid fa-trash-alt"></i> Baja',
          cancelButtonText:   'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            //Confirmar identidad
            verificarJWT().then( verificado => {
                if (!verificado) {
                    errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
                    desconectar();
                    return;
                }
            });
            
              //Eliminar del orquestador
              return axios({
                method: 'POST',
                url: '/baja',
                data: { 'ID_ADMINISTRATIVO': iua },
                headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
              }).then(resp => {
                //Eliminación correcta
              }).catch(err => {
                console.log("error", err);
                if (err.response) {
                  //Request made and server responded
                  console.log(' manejado', err.response);
                  Swal.showValidationMessage(`${err.response.data.msg}. ${err.response.data.error ? err.response.data.error.descResultado : ''}`);
                } else if (err.request) {
                  // The request was made but no response was received
                  Swal.showValidationMessage('No se ha recibido respuesta del servidor');
                } else {
                  // Something happened in setting up the request that triggered an err
                  console.log('err no manejado', err.message);
                  
                  Swal.showValidationMessage(err.message);
                }  
              })

          },
          backdrop: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.cargarServicios();
            Swal.fire({
              title: 'Eliminado',
              text: 'El servicio se ha dado de baja correctamente',
              icon: 'success',
              confirmButtonText: 'Vale'
            }
            )
          }
        })
    },
    //Ir a página de configurar VoIP
    configurarVOIP(item){
      this.$router.push({
        name: 'configurarVoIP',
        params: { item }
      })
    },        
    //Ir a página de cambio de servicio
    cambiarServicio(item){
      item.editorAdmin = true;
      this.$router.push({
        name: 'cambioServicio',
        params: { item }
      })
    },
    //Ir a página de cambio de ONT
    cambiarONT(item){
      item.editorAdmin = true;
      this.$router.push({
        name: 'CambioONT',
        params: { item }
      })
    },
    //Ir a página de test
    realizarTest(item){
      item.editorAdmin = true;
      this.$router.push({
        name: 'Test',
        params: { item }
      })
    },
    async getOperadores(){
      try {
        let respuesta = await axios.get('/operadores', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
        this.operadores = respuesta.data.data.listaOperadores;          
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log('Error controlado', error.response);
            this.mensajedeError = error.response.data.msg;
          } else if (error.request) {
            // The request was made but no response was received
            this.mensajedeError = 'No se ha recibido respuesta del servidor';
            console.log('Error servidor', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error genérico', error.message);
            this.mensajedeError = error.message;
          }
      }
    },
    async cargarServidorACS(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/acs/servidor',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.servidores_acs = respuestaPandora.data.data.servidores;
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log(error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('No se ha recibido respuesta del servidor');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      } 
    },    
  },
  async created(){
    this.cargarFiltros();
    await this.cargarServidorACS();
    await this.getOperadores();
    this.cargarServicios();
  },
  computed: {
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },

  }

}
</script>


<style scoped>

  .acciones {
    width: 9rem;
  }
  .accion-btn {
    width: 13rem;
  }

</style>

