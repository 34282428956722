<template>
    <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">
           
            <!-- Header -->
            <div class="d-flex align-items-baseline mx-4 my-4">
              <h1 >Reservar</h1>
              <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
            </div>

            <div class="card-body">
              <!-- Cargando -->
              <div v-if="cargandoPerfiles || cargandoCoberturas" class="my-4">
                <b-spinner></b-spinner>
                <h4 >Cargando datos...</h4>
              </div>

              <!-- Error cargando -->
              <div v-else-if="perfiles.length < 1 || coberturas.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                <div class="d-flex flex-column text-start">
                  <div v-if="mensajedeError" class="text-danger">
                    {{mensajedeError}}
                  </div>  
                  <div v-if="perfiles.length < 1" class="text-danger">
                    No se han encontrado perfiles disponibles
                  </div>     
                  <div v-if="coberturas.length < 1" class="text-danger">
                    No se han encontrado coberturas disponibles
                  </div>   
                </div>
                <i class="fa-solid fs-3 fa-circle-question ms-2 clickable help-icon-error text-black" @click="infoError"></i>
              </div>

              <!-- Contenido -->
              <div v-else>
                  <form class="user d-flex flex-column" @submit.prevent="realizarReserva" novalidate>
                    <div class="row">
                        <!-- Remote ID -->
                        <div class="col-sm-12 col-xxl-6 my-2">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="Remote_ID" class="line mx-1 fs-5 d-flex align-items-center">Remote ID</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">
                                      <input id="Remote_ID"  class="form-control w-100 m-0" type="text" required maxlength="50" v-model.lazy="$v.data.REMOTE_ID.$model" placeholder="Número de serie" :class="{ 'is-invalid': $v.data.REMOTE_ID.$error}" autofocus autocomplete="off">
                                    </div>
                                    <div class="col-2 px-0 text-start align-self-center">
                                      <i class="fa-solid fa-circle-question clickable fs-5" @click="infoRemoteID"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <div class="row justify-content-end text-start">
                              <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                <small v-show="$v.data.REMOTE_ID.$dirty && !$v.data.REMOTE_ID.required" class="text-danger">
                                  Debe de introducir un número de serie
                                </small>
                                <small v-show="$v.data.REMOTE_ID.$dirty && !$v.data.REMOTE_ID.maxLength" class="text-danger">
                                  No puede introducir tantos caracteres
                                </small>                                
                              </div>
                            </div>

                        </div>

                        <!-- Perfil -->
                        <div class="col-sm-12 col-xxl-6 my-2">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="TEMPLATE" class="line mx-1 fs-5 d-flex align-items-center">Perfil</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">  
                                      <input id="TEMPLATE"  class="form-control w-100 m-0" list="perfilesOpciones" autocomplete="off" required maxlength="65" v-model.lazy="$v.data.TEMPLATE.$model" placeholder="Perfil de servicio" :class="{ 'is-invalid': $v.data.TEMPLATE.$error}" @change="loseFocus">
                                          <datalist id="perfilesOpciones">
                                            <option v-for="item in perfiles" :key="item.id" :value="item.template">{{item.descripcion}}</option> 
                                          </datalist> 
                                    </div>
                                    <div class="col-2 px-0 text-start align-self-center">
                                      <i class="fa-solid fa-circle-question clickable fs-5" @click="infoPerfil"></i>
                                    </div>
                                  </div>                                 
                                </div>
                              </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.data.TEMPLATE.$dirty && !$v.data.TEMPLATE.required" class="text-danger">
                                    Debe de seleccionar un perfil
                                  </small>
                                  <small v-show="$v.data.TEMPLATE.$dirty && !$v.data.TEMPLATE.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                            </div>                              
                        </div>

                        <!-- Comentario -->
                        <div class="col-sm-12 col-xxl-6 my-2">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="comentario" class="line mx-1 fs-5 d-flex align-items-center">Comentario</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">  
                                        <textarea class="form-control" :class="{ 'is-invalid': $v.data.comentario.$invalid}" id="comentario" rows="3" aria-label="Comentario del cliente" maxlength="1024" v-model="$v.data.comentario.$model" placeholder="Descripción del cliente u otros detalles... (Opcional)"></textarea>
                                    </div>
                                    <div class="col-2 px-0 text-start align-self-center">
                                      <i class="fa-solid fa-circle-question clickable fs-5" @click="infoComentario()"></i>
                                    </div>
                                  </div>                                 
                                </div>
                              </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="!$v.data.comentario.maxLength" class="text-danger">
                                     El comentario no puede superar los 1024 caracteres.
                                  </small>                             
                                </div>
                            </div>                              
                        </div>
                        <!-- Facturable -->
                        <div v-if="esRolInterno() || contieneRol(['PERMISONOFACTURABLES'])" class="col-sm-12 col-xxl-6 my-2">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="facturar" class="line mx-1 fs-5 d-flex align-items-center">Facturar</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">  
                                      <select id="facturar" class="form-select" :class="{ 'is-invalid': $v.data.no_facturable.$invalid}" aria-label="Facturar el cliente" maxlength="1" v-model="$v.data.no_facturable.$model">                                
                                        <option :value="0">SI</option>
                                        <option :value="1">NO</option>
                                      </select>
                                    </div>
                                    <div class="col-2 px-0 text-start align-self-center">
                                      <i class="fa-solid fa-circle-question clickable fs-5" @click="infoFacturacion()"></i>
                                    </div>
                                  </div>                                 
                                </div>
                              </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.data.no_facturable.$invalid" class="text-danger">
                                      Los valores de facturar son inválidos.
                                  </small>                             
                                </div>
                            </div>                              
                        </div>                  

                        <hr class="mt-4">
                        <div class="d-flex justify-content-center  mt-2 mb-3">
                          <button type="button" class="btn fw-bold mx-2 mx-md-4 accion-btn" :class="[tipoBusqueda=='buscador' ? 'btn-primary' : 'btn-secondary']" @click="setModoBusquedaBuscador()">
                              Buscar por campos
                          </button>
                          <button type="button" class="btn fw-bold mx-2 mx-md-4 accion-btn"  :class="[tipoBusqueda=='id' ? 'btn-primary' : 'btn-secondary']" @click="setModoBusquedaID()">
                              Buscar por ID
                          </button>
                        </div>
                        <div v-if="tipoBusqueda=='id'" class="row">
                            <!-- ID_DOMICILIO -->
                            <div class="col-12 my-2">
                                  <div class="row d-flex align-items-center">
                                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                      <label for="ID_DOMICILIO" class="line mx-1 fs-5 d-flex align-items-center">Id domicilio</label>
                                    </div>
                                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                      <div class="row d-flex flex-row">
                                        <div class="col-10">  
                                          <input type="text" id="ID_DOMICILIO" class="form-control w-100 m-0" autocomplete="off" required maxlength="38" v-model="data.ID_DOMICILIO" placeholder="Id del domicilio" @input="buscarPorID()">  
                                        </div>
                                        <div class="col-2 px-0 text-start align-self-center">
                                          <i class="fa-solid fa-circle-question clickable fs-5" @click="infoIDDomicilio"></i>
                                        </div>                                                            
                                      </div>
                                    </div>
                                  </div>
                                               
                            </div>
                        </div>
                        <div v-if="tipoBusqueda=='buscador'" class="row">
                          <!-- Provincia -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="Provincia" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <input id="Provincia" class="form-control w-100 m-0" list="provinciasOpciones" autocomplete="off" required maxlength="65" v-model.lazy="$v.data.provincia.$model" placeholder="Provincia" :class="{ 'is-invalid': $v.data.provincia.$error}" @change="loseFocus" >
                                          <datalist id="provinciasOpciones">
                                            <option v-for="item in provinciasDistintas" :key="item" :value="item"></option> 
                                          </datalist>       
                                      </div>
                                    </div>                      
                                  </div>
                                </div>
                                <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.data.provincia.$dirty && !$v.data.provincia.required" class="text-danger">
                                      Debe seleccionar la provincia del domicilio
                                    </small>
                                    <small v-show="$v.data.provincia.$dirty && !$v.data.provincia.maxLength" class="text-danger">
                                      No puede introducir tantos caracteres
                                    </small>                                
                                  </div>
                              </div>                                  
                          </div>
                          <!-- Pueblo -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="poblacion" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <input id="poblacion" class="form-control w-100 m-0" :disabled="!$v.data.provincia.$dirty || $v.data.provincia.$error" list="poblacionesOpciones" autocomplete="off" required maxlength="65" v-model.lazy="$v.data.poblacion.$model" placeholder="Población" :class="{ 'is-invalid': $v.data.poblacion.$error}" @change="loseFocus">
                                          <datalist id="poblacionesOpciones">
                                            <option v-for="item in poblacionesDisponibles" :key="item" :value="item"></option> 
                                          </datalist>      
                                      </div>
                                    </div>                            
                                  </div>
                                </div>
                                <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.data.poblacion.$dirty && !$v.data.poblacion.required" class="text-danger">
                                      Debe seleccionar la poblacion del domicilio
                                    </small>
                                    <small v-show="$v.data.poblacion.$dirty && !$v.data.poblacion.maxLength" class="text-danger">
                                      No puede introducir tantos caracteres
                                    </small>                                
                                  </div>
                              </div>    
                          </div>    
                          <!-- TIPO VIA -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="calle" class="line mx-1 fs-5 d-flex align-items-center">Tipo vía</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10 col-xxl-10">  
                                        <select id="tipo" class="form-control  m-0" :disabled="!computedTiposVia.length > 0" maxlength="128" v-model.lazy="data.tipoVia" @change="filtrarDomicilios">                                
                                          <option value="" selected>Sin especificar</option>
                                            <option v-for="(item, index) in computedTiposVia" :key="index" :value="item.TIPO_VIA">{{item.TIPO_VIA}}</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                          </div>    
                          <!-- Calle -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="calle" class="line mx-1 fs-5 d-flex align-items-center">Nombre vía</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <div class="input-group">
                                        <span class="input-group-text" id="calle">{{data.tipoVia ? data.tipoVia : 'Vía'}}</span>
                                        <input id="calle" class="form-control  m-0" :disabled="!$v.data.poblacion.$dirty || $v.data.poblacion.$error" required maxlength="128" v-model.lazy="$v.data.calle.$model" placeholder="Ej.: San Martín" @change="buscarhogares" autocomplete="off" >                                
                                        </div>
                                      </div>
                                      <div class="col-2 px-0 text-start align-self-center">
                                        <i class="fa-solid fa-circle-question clickable fs-5" @click="infoDomicilio"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.data.calle.$dirty && !cargandoDomicilios && this.data.calle.length > 1 && domiciliosDisponibles.length < 1" class="text-danger">
                                      No se han encontrado resultados para esta búsqueda
                                    </small>                        
                                    <small v-show="$v.data.calle.$dirty && !cargandoDomicilios && this.data.calle.length < 2" class="text-danger">
                                      Necesitas poner almenos dos caracteres
                                    </small>  
                                  </div>
                              </div>    
                          </div>    
                          <!-- Numero -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="numero" class="line mx-1 fs-5 d-flex align-items-center">Número</label>
                                  </div>
                                  <div class="number-input">
                                    <select id="numero" class="form-control w-100 m-0" :disabled="computedNumerosDisponibles.length < 1" type="number" min="0"  maxlength="65" v-model.lazy="data.numero" @change="filtrarDomicilios">                                                   
                                          <option value="" selected>Sin especificar</option>
                                          <option v-for="(item, index) in computedNumerosDisponibles" :key="index" :value="item">{{item}}</option>
                                    </select>
                                  </div>
                                </div>
                          </div>  
                        </div>

                        <!-- Buscador de domicilios -->
                        <b-overlay :show="cargandoDomicilios">
                        <div class="card border-dark border-3 mx-4 mt-2 bg-light action-card flex-shrink-1">
                          <div class="container-fluid my-2">


                            <i class="fa-solid fa-circle-question clickable fs-5 action-card-help" @click="infoBuscador"></i>
                            <span class="text-black-50 fw-bold">Buscador de domicilios: </span>
                            <p class="fs-2" :class="colorMensajeActionCard">{{mensajeActionCard}}</p>

                            <!-- Mensaje ticket -->
                            <div v-if="haFallado" class="alert alert-info d-flex align-items-center my-2" role="alert">
                              <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center w-100">
                                
                                <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                                  <i class="fa-solid fa-circle-exclamation text-info fs-3 mx-2 mb-2 mb-sm-0"> </i>   
                                  <div class="align-self-center text-center text-sm-start">
                                    ¿Problemas realizando la reserva? Abre un ticket y lo solucionaremos.
                                  </div>           
                                </div>
                                <button  type="button" class="btn btn-primary btn-sm line mx-2 mt-3 mt-sm-0 w-a" @click="abrirTicket()">
                                  Abrir Ticket
                                </button>
                              </div>
                            </div>
                            
                            <!-- Lista domicilios -->
                            <div v-if="!domicilioSeleccionado" class="action-card-items">
                              <template v-for="(item, index) in domiciliosDisponiblesFiltrados">
                                <div :key="index">
                                  <div v-if="index<maxDomicilios" class="alert alert-light shadow-sm flex-shrink-1 my-2 py-3 px-4 text-start action-card-item pointerHover"  @click="seleccionarDomicilio(item)">
                                    <div class="row">
                                      <div class="col-12 d-flex flex-column align-self-center">
                                          <span> {{item.callemontada}}</span>
                                          <div class="d-flex flex-column text-black-50 m-font">
                                            <span>DIRECCION_CTO: <i>{{item.DIRECCION_CTO}}</i></span>
                                            <span>ID_DOMICILIO: <i>{{item.ID_DOMICILIO}}</i></span>
                                          </div>
                                      </div>
                                    </div>                         
                                  </div>
                                  <div v-if="index==maxDomicilios-1 && domiciliosDisponiblesFiltrados.length>=index" class="card shadow-sm flex-shrink-1 my-2 p-2 text-center pointerHover boton-mostrarmas m-auto"  @click="maxDomicilios += 5">
                                         <span class="fs-5 text-black-50">Mostrar más</span>                              
                                  </div>
                                </div>
                              </template>
                            </div>
                            <!-- Domicilio seleccionado -->
                            <div v-else class="overflow-auto action-card-items">
                              <div class="alert alert-success  border-2 flex-shrink-1 my-2 py-3 px-4 text-start bg-success text-dark bg-opacity-10">
                                <div class="row">
                                  <div class="col-12 col-sm-11 align-self-center">
                                      <span> {{domicilioSeleccionado.callemontada}}</span>
                                      <div class="d-flex flex-column text-black-50 line m-font">
                                        <span>DIRECCION_CTO: {{domicilioSeleccionado.DIRECCION_CTO}}</span>
                                        <span>ID_DOMICILIO: {{domicilioSeleccionado.ID_DOMICILIO}}</span>
                                      </div>
                                  </div>
                                  <div class="col-sm-1 text-center align-self-center">
                                    <button type="button" class="btn-close" aria-label="Close"  @click="quitarDomicilio"></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                  
                          </div>
                        </div>
                        </b-overlay>

                        <!-- Botones -->
                        <hr class="mt-4">
                        <div class="d-flex justify-content-center">
                          <button type="button" class="btn btn-danger form-btn mx-2" @click="resetearFormulario">Cancelar</button>
                          <button :disabled="$v.data.REMOTE_ID.$invalid || $v.data.TEMPLATE.$invalid || !domicilioSeleccionado || cargandoSolicitud" type="submit" class="btn btn-primary form-btn mx-2">
                            <i v-if="!cargandoSolicitud" class="fa-solid fa-download mx-2"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                            Reservar
                          </button>
                        </div>		



                    </div>
                  </form>          
              </div>
            </div>

            
          </div>

      </div>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { contieneAlgunRolPermitido, getJWTpayload, isInternoAsteo } from '../helpers/JWT';
import { montarNombreCalle } from '../helpers/utils';
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, successAlert } from '../helpers/fastAlert';
import axios from "axios";
import Swal from "sweetalert2";
import { enviarTicket } from '../helpers/enviarTicket';

export default {
  /*
    Reserva un nuevo recurso para hacer una alta posteriormente. Para ello hay que elegir
    el remote ID, perfil de velocidad(template) y un hogar. Para localizar el hogar se usa un buscador de domicilios.
     Busca entre las coberturas que tiene asignadas el operador.
    El bscador de domicilios descarga una lista con las coberturas que tiene contratadas el operador y extrae las provincias, poniendola en un dataList, cuando el usuario selecciona una provincia, se le asignan
    los pueblos disponibles al siguiente dataList de pueblos y se desbloquea para que elija un pueblo. Si elige un pueblo se extraen los tipos de via disponibles y se desbloquea 
    la calle. Al escribir la calle se hace la busqueda en la API  y se muestran los domicilios disponibles filtrados por el input tipo via y número.
  */
  name: "Reservar",
  data() {
    return {
      data: {
        REMOTE_ID: '',      //Remote ID, número de serie (S/N)
        TEMPLATE: '',       //Perfil de velocidad del recurso
        TEMPLATEDESC: '',   //Perfil de velocidad del recurso humanamente legible
        provincia: '',      //Provincia para buscar domicilios
        poblacion: '',      //Poblacion para buscar domicilios
        tipoVia: '',        //Tipo de via elegido (filtro)
        calle: '',          //Calle a buscar, que realmente hace la busqueda de domicilios
        numero: '',          //Número elegido (filtro)
        ID_DOMICILIO: '',
        comentario: '',
        no_facturable: 0
      },
      tipoBusqueda: 'buscador',

      perfiles: [],                       //Perfiles de velocidad disponibles
      coberturas: [],                     //Todas las coberturas contratadas por el operador
      provinciasDistintas: [],            //Las distintas provincias encontradas entre las contratadas
      poblacionesDisponibles: [],         //Los publos de la provincia elegida
      tiposdeVia: [],                     //Tipos de via extraidas de la poblacion y provincia (filtra)
      numerosDisponibles: [],             //Numeros disponibles extraidos de las calles encontradas (filtra)

      mensajedeError: '',
      cargandoPerfiles: false,
      cargandoCoberturas: false,
      cargandoDomicilios: false,
      domiciliosDisponibles: '',                  //Lista de domicilios disponibles
      domiciliosDisponiblesFiltrados: '',         //lista de domicilios disponibles filtrados
      mensajeActionCard: 'Introduzca datos',
      colorMensajeActionCard: 'text-black-50',
      domicilioSeleccionado: '',                  //El domicilio seleccionado para crear el recurso

      maxDomicilios: 15,

      cargandoSolicitud: false,

      //tickets
      ultimoDomicilio: '',
      ultimoError: '',
      haFallado: false,
    }
  },
  //Validaciones
  validations: {
    data: {
      REMOTE_ID: {
        required,
        maxLength: maxLength(50)
      },
      TEMPLATE: {
        required,
        maxLength: maxLength(128)
      },
      provincia: {
        required,
        maxLength: maxLength(128)
      },
      poblacion: {
        required,
        maxLength: maxLength(128)
      },
      calle: {},
      comentario: {
        maxLength: maxLength(1024)
      },
      no_facturable: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1)
      }
    }
  },
  methods: {
    infoComentario(){
      infoAlert('Comentario', 'Puede agregar una descripción del cliente para proporcionar información adicional, como su dirección o si se trata de una prueba, entre otros detalles.')
    }, 
    infoFacturacion(){
      infoAlert('Facturar', 'Se puede marcar un cliente como <strong>no facturable</strong> si se trata de un <strong>cliente de prueba</strong>. Esto hará que no se incluya el cliente en el sistema de reportes.')
    },         
    esRolInterno(){
      return isInternoAsteo()
    },    
    contieneRol(allowedRols){
      return contieneAlgunRolPermitido(allowedRols);
    }, 
    //Realiza la reserva de recursos
    async realizarReserva(){
      this.cargandoSolicitud = true;
      try {
          let respuesta = await axios({
            method: 'POST',
            url: '/reservaRecursos',
            data: { 
              REMOTE_ID: this.data.REMOTE_ID,
              TEMPLATE: this.data.TEMPLATE,
              PERFILVELOCIDAD: this.data.TEMPLATEDESC,
              ID_DOMICILIO: this.domicilioSeleccionado.ID_DOMICILIO,
              direccion: this.domicilioSeleccionado.callemontada,
              PROVINCIA: this.domicilioSeleccionado.PROVINCIA.toUpperCase(),
              POBLACION: this.domicilioSeleccionado.POBLACION.toUpperCase(),
              comentario: this.data.comentario,
              no_facturable: this.data.no_facturable,
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });

          Swal.fire({
            title: 'Operación completada',
            text: respuesta.data.msg,
            icon: 'success',
            confirmButtonText: 'Vale'
          }).then( result => {
            if(respuesta.data.data.ID_ADMINISTRATIVO){
              localStorage.setItem('focusedReserva', respuesta.data.data.ID_ADMINISTRATIVO);
              this.$router.push({name: 'Reservas'});
            }
          })
          this.cargandoSolicitud = false;

      } catch (error) {
          this.cargandoSolicitud = false;
          this.haFallado = true;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log(error.response);
            this.ultimoError = error.response.data.msg + error.response.data.error ? error.response.data.error.descResultado : '';
            errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            this.ultimoError = error.request;
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            this.ultimoError = error.message || error;
            serverErrorAlert(error.message || error);
          }
          return;
      }

      this.resetearFormulario();
    },
    //Carga los perfiles de velocidad de la API
    async getPerfiles(){
        try {
          this.cargandoPerfiles = true;
          let OPID = getJWTpayload().OPID;
          let respuesta = await axios.get('/tarifas?OPID=' + OPID, { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.perfiles = respuesta.data.data.tarifas;          
        } catch (error) {

            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        } finally {
            this.cargandoPerfiles = false;
        }
    },
    //Carga las coberturas contratadas de la API
    async getCoberturas(){
        try {
          this.cargandoCoberturas = true;
          let OPID = getJWTpayload().OPID;
          let respuesta = await axios.get('/coberturas?OPID=' + OPID, { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.coberturas = respuesta.data.data.coberturas;
          this.provinciasDistintas = this.coberturas.map(item => item.provincia).filter((value, index, self) => self.indexOf(value) === index);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        } finally {
            this.cargandoCoberturas = false;
        }
    }, 
    //Carga los tipos de vía de la API
    async getTiposdeVia(){
        try {
          let headers = {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('x-token') 
          }
          let body = { 
            provincia: this.data.provincia,
            poblacion: this.data.poblacion,
            calle: this.data.calle
          }
          let respuesta = await axios.get('/hogares/vias', {
            params: { provincia: this.data.provincia, poblacion: this.data.poblacion },
            headers
          });
          this.tiposdeVia = respuesta.data.data.tiposdeVia;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    },
    //Carga los domicilios disponibles en base a la provincia, poblacion y calle elegida
    async getDomicilios(){
        try {
          this.cargandoDomicilios = true; 
          this.colorMensajeActionCard = 'text-black-50'
          const respuesta = await axios.get('/hogares/calle', {
              method: 'GET',
              params: { 
              provincia: this.data.provincia,
              poblacion: this.data.poblacion,
              calle: this.data.calle
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          })
          this.domiciliosDisponibles = respuesta.data.data.calle;

          //Construimos las direcciones completas
          for (const domicilio of this.domiciliosDisponibles) {
            montarNombreCalle(domicilio);
          }
          //Mostramos los números disponibles, quitamos los repetidos y los ordenamos
          this.numerosDisponibles = this.domiciliosDisponibles.map(item => parseInt(item.NUMERO)).filter((value, index, self) => self.indexOf(value) === index).sort((a,b)=>a-b);
          this.filtrarDomicilios();
          
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              //Request made and server responded
              this.mensajeActionCard = error.response.data.msg;
              this.colorMensajeActionCard = 'text-danger'
            } else if (error.request) {
              console.log(error.request);
              // The request was made but no response was received
              this.mensajeActionCard = 'No se ha recibido respuesta del servidor';
              this.colorMensajeActionCard = 'text-danger'
            } else {
              console.log(error.message);
              // Something happened in setting up the request that triggered an Error
              this.mensajeActionCard = error.message;
              this.colorMensajeActionCard = 'text-danger'
            }
        } finally {
            this.cargandoDomicilios = false;
        }
    },
    //Pierde el focus de un dataList para que deje de mostrar sugerencias
    loseFocus(evt) {
      evt.target.blur()
    },
    //Busca los hogares disponibles cuando se escribe una calle con más de dos caracteres
    buscarhogares(){
      this.domiciliosDisponibles = [];
      this.domiciliosDisponiblesFiltrados = [];
      this.maxDomicilios = 15;
      this.data.numero = ''
      this.quitarDomicilio()
      if(this.data.calle && this.data.poblacion && this.data.provincia && this.data.calle.length >= 2) 
      {
        this.getDomicilios();
      }      
    },
    //Filtra los domicilios disponibles por via y número
    filtrarDomicilios(){
      this.quitarDomicilio();
      this.maxDomicilios = 15;
      if(!this.domiciliosDisponibles || !this.domiciliosDisponibles.length > 0) return;

      this.domiciliosDisponiblesFiltrados = this.domiciliosDisponibles;
      
      //Si hay un filtro de vía
      if(this.data.tipoVia){
        this.domiciliosDisponiblesFiltrados = this.domiciliosDisponiblesFiltrados.filter( item => item.TIPO_VIA == this.data.tipoVia )
      }
      //Si hay un filtro de número
      if(this.data.numero){
        this.domiciliosDisponiblesFiltrados = this.domiciliosDisponiblesFiltrados.filter( item => parseInt(item.NUMERO) == this.data.numero )
      }
    
    if(this.domiciliosDisponiblesFiltrados.length > 0)
    { 
      this.colorMensajeActionCard = 'text-black-50';
    } else{
      this.colorMensajeActionCard = 'text-danger'
    }     
    this.mensajeActionCard = `${this.domiciliosDisponiblesFiltrados.length} resultados `;

    },
    //Selecciona el domicilio en el que se creará el recurso
    seleccionarDomicilio(domicilio){
      this.domicilioSeleccionado = domicilio;
      this.ultimoDomicilio = domicilio;
      this.colorMensajeActionCard = 'text-success'
      this.mensajeActionCard = 'Domicilio seleccionado'
    },
    //Elimina el domicilio en el que se creará el recurso
    quitarDomicilio(){
      this.domicilioSeleccionado = '';
      this.domiciliosDisponiblesFiltrados.length < 1 ? this.colorMensajeActionCard = 'text-danger' : this.colorMensajeActionCard = 'text-black-50';
      this.mensajeActionCard = `${this.domiciliosDisponiblesFiltrados.length} resultados `;
      this.$v.$reset;
    },
    setModoBusquedaBuscador(){
      this.tipoBusqueda = 'buscador';
      this.resetearBusqueda();
    },
    setModoBusquedaID(){
      this.tipoBusqueda = 'id'
      setTimeout(() => {          
        let input = document.getElementById('ID_DOMICILIO');
        if(input) input.focus();
      }, 100);
      this.resetearBusqueda();
    },
    //Botones informativos
    infoPage(){
      infoAlert('Reservar', 'En esta página puedes asignar los recursos necesarios para asegurar una provisión de un alta posterior. <br><br> Para ello introduzca <strong>Remote ID</strong>, <strong>Perfil</strong> y un <strong>domicilio</strong> con el buscador de domicilios.')
    },
    infoError(){
      infoAlert('Error cargando', 'Es posible que no se pueda hacer la reserva debido a un error cargando sus datos, o bien que no se le hayan asignado <strong>perfiles de servicio</strong> o un area de <strong>coberturas.</strong>')
    },
    infoRemoteID(){
      infoAlert('Remote ID', 'El "Remote ID" es el número de serie correspondiente a la ONT.', require("@/assets/ontsn.png"), 'Remote ID');
    },
    infoPerfil(){
      infoAlert('Perfil', 'El perfil es el servicio que se le da al cliente final, es decir, la <strong>velocidad de conexión</strong>.');
    },
    infoDomicilio(){
      infoAlert('Nombre vía', 'Introduzca el nombre de la vía sin especificar el tipo (Calle, Avenida, Plaza...) <br><br> <small class="fst-italic">Ej.: "San Martín" para "Calle San Martín"</small> <br><br>De este modo podremos buscar el domicilio del cliente.');
    },
    infoBuscador(){
      infoAlert('Buscador de domicilios', 'El buscador sirve para encontrar el domicilio al cual se le quiere proveer el servicio. <br><br> Introduzca la <strong>provincia</strong>, <strong>población</strong>, <strong>nombre de vía</strong> y <strong>número</strong> para mostrar una lista de domicilios disponibles. Haga click en uno para seleccionarlo.');
    },
    infoIDDomicilio(){
      infoAlert('Id de domicilio', 'El id del domicilio es en un identificador único que hace referencia a un hogar en concreto. Si tienes un id de domicilio, podrás <strong>buscar el hogar directamente</strong>.');
    },    
    async buscarPorID(){    
      if(this.data.ID_DOMICILIO && this.data.ID_DOMICILIO.length >=26 && this.data.ID_DOMICILIO.length <= 38){
        try{
          let respuesta = await axios({
            method: 'GET',
            url: '/hogares/domicilio',
            params: { ID_DOMICILIO: this.data.ID_DOMICILIO },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          let domicilio = respuesta.data.data.domicilio[0];
          montarNombreCalle(domicilio);
          this.seleccionarDomicilio(domicilio);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              //Request made and server responded
              this.mensajeActionCard = error.response.data.msg;
              this.colorMensajeActionCard = 'text-danger'
            } else if (error.request) {
              console.log(error.request);
              // The request was made but no response was received
              this.mensajeActionCard = 'No se ha recibido respuesta del servidor';
              this.colorMensajeActionCard = 'text-danger'
            } else {
              console.log(error.message);
              // Something happened in setting up the request that triggered an Error
              this.mensajeActionCard = error.message;
              this.colorMensajeActionCard = 'text-danger'
            }
        } 

      }
      
    },
    //Reinicia la página
    resetearFormulario(){
        document.getElementsByClassName("Panel")[0].scrollTop = 0;
        this.data.REMOTE_ID = '';
        this.data.TEMPLATE = '';
        this.data.TEMPLATEDESC = '';
        this.resetearBusqueda();
    },
    resetearBusqueda(){
        this.data.ID_DOMICILIO = '';
        this.data.provincia = '';
        this.data.poblacion = '';
        this.data.tipoVia = '';
        this.data.calle = '';
        this.data.numero = '';
        this.domiciliosDisponibles = '';
        this.domiciliosDisponiblesFiltrados = '';
        this.domicilioSeleccionado = '';
        this.numerosDisponibles = [];
        this.tiposdeVia = [];
        this.defaultActionCardMSG();
        this.maxDomicilios = 15;
        this.$v.$reset();        
    },
    //Pone el mensaje por defecto en el buscador de domicilios
    defaultActionCardMSG(){
      this.mensajeActionCard = 'Introduzca datos';
      this.colorMensajeActionCard = 'text-black-50';
    },
    abrirTicket(){
      let email = getJWTpayload().email;
      let operador = getJWTpayload().operador;
      //Mensaje automático
      let infoCalle = '';
      let infoError = '';
      if(this.ultimoDomicilio && this.ultimoDomicilio.ID_DOMICILIO && this.ultimoDomicilio.callemontada){
        infoCalle = ` sobre la dirección:\n${this.ultimoDomicilio.callemontada}\n${this.ultimoDomicilio.ID_DOMICILIO}`
      }
      if(this.ultimoError){
        infoError = `\nEl error que estoy recibiendo es:\n"${this.ultimoError}"`
      }
      let mensaje = `Hola, estoy experimentando problemas al intentar hacer una reserva${infoCalle}${infoError}\nEspero su respuesta.`;

      enviarTicket("Problemas al reservar", email, mensaje, operador);
    },

  },
  created() {
    this.getPerfiles();
    this.getCoberturas();
  },
  //Comprueba que los cambios de los dataList son correctos y realiza acciones relevantes
  watch: {
    'data.TEMPLATE'(newVal, oldVal){
      let valido = false
      for (const item of this.perfiles) {
       if(item.template == newVal) {
         valido = true;
         this.data.TEMPLATEDESC = item.descripcion;
         }
      }
      if (!valido) {
        this.data.TEMPLATE = ''
      }
    },
    'data.provincia'(newVal, oldVal){

      this.data.poblacion = ''
      this.data.tipoVia = ''
      this.data.calle = ''
      this.data.numero = ''
      this.domiciliosDisponibles = ''
      this.domiciliosDisponiblesFiltrados = ''
      this.quitarDomicilio();
      this.defaultActionCardMSG()
    

      let valido = false
      for (const item of this.provinciasDistintas) {
       if(item == newVal) {
         valido = true;
       }
      }
      if (!valido) {
        this.data.provincia = ''
        return;
      }

      this.poblacionesDisponibles = []
      for (const iterator of this.coberturas) {
        if(iterator.provincia == newVal) this.poblacionesDisponibles.push(iterator.poblacion)
      }
    },
    'data.poblacion'(newVal, oldVal){
      this.data.tipoVia = ''
      this.data.calle = ''
      this.data.numero = ''
      this.domiciliosDisponibles = ''
      this.domiciliosDisponiblesFiltrados = ''
      this.quitarDomicilio()
      this.defaultActionCardMSG()
      if (!this.poblacionesDisponibles.includes(newVal)) {
        this.data.poblacion = ''
        return;
      }

      this.getTiposdeVia();
    },

  },
  computed: {
    computedTiposVia(){
      if (this.tiposdeVia) {
        return this.tiposdeVia;
      }
      return [];
    },
    computedNumerosDisponibles(){
      if (this.numerosDisponibles) {
        return this.numerosDisponibles;
      }
      return [];
    }
  
  }
};
</script>


<style scoped>
  .number-input {
    width: 10rem;
  }
  .boton-mostrarmas{
    width: 10rem;
  }
  .action-card-item:hover{
    opacity: .8;
  }
    
  .action-card-item:active{
    opacity: .5;
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }
</style>
