<template>
    <div>
      <div class="d-flex justify-content-center mt-4">
        <button v-if="esCreadorInfluencia()" type="button" class="btn fw-bold mx-2 accion-btn" :class="[estado=='AI_IMPORTER' ? 'btn-primary' : 'btn-secondary']" @click="setModo('AI_IMPORTER')">
            Importador de AI
        </button>
        <button v-if="esCreadorInfluencia()" type="button" class="btn fw-bold mx-2 accion-btn" :class="[estado=='C_HUELLA' ? 'btn-primary' : 'btn-secondary']" @click="setModo('C_HUELLA')">
            Creador de AI unitario
        </button>
        <button v-if="esEditorInfluencia()" type="button" class="btn fw-bold mx-2 accion-btn" :class="[estado=='EDITOR' ? 'btn-primary' : 'btn-secondary']" @click="setModo('EDITOR')">
            Editor de influencia
        </button>    
        <button v-if="esEditorInfluencia()" type="button" class="btn fw-bold mx-2 accion-btn position-relative" :class="[estado=='REVISIONHUELLA' ? 'btn-primary' : 'btn-secondary']" @click="setModo('REVISIONHUELLA')">
            Revisión nueva huella
            <span v-show="numero_revisiones" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{numero_revisiones >= 100 ? '99+' : numero_revisiones}}
              <span class="visually-hidden">unread messages</span>
            </span>
        </button>                
        <button v-if="esEditorInfluencia()" type="button" class="btn fw-bold mx-2 accion-btn"  :class="[estado=='HISTORIAL' ? 'btn-primary' : 'btn-secondary']" @click="setModo('HISTORIAL')">
            Historial de cambios
        </button>
      </div>
      <AIImporter v-if="estado=='AI_IMPORTER' && esCreadorInfluencia()" ref="AIImporter"></AIImporter>
      <CreadorHuella v-if="estado=='C_HUELLA' && esCreadorInfluencia()" ref="CreadorHuella" @refresh="obtenerNumeroRevisiones()"></CreadorHuella>
      <EditorInfluencia v-if="estado=='EDITOR' && esEditorInfluencia()" ref="Buscador"></EditorInfluencia>
      <RevisionHuella v-if="estado=='REVISIONHUELLA' && esEditorInfluencia()" ref="RevisionHuella" @refresh="obtenerNumeroRevisiones()"></RevisionHuella>
      <HistorialEditor v-if="estado=='HISTORIAL' && esEditorInfluencia()" ref="HistorialEditor"></HistorialEditor>
    </div>
</template>

<script>

import AIImporter from "../../components/EditorInfluencia/AIImporter.vue"
import CreadorHuella from "../../components/EditorInfluencia/CreadorHuella.vue"
import EditorInfluencia from "../../components/EditorInfluencia/EditorInfluencia.vue"
import RevisionHuella from "../../components/EditorInfluencia/RevisionHuella.vue"
import HistorialEditor from "../../components/EditorInfluencia/HistorialEditor.vue"
import { isCreadorInfluencia, isEditorInluencia } from '../../helpers/JWT'
import axios from "axios";

export default {
  name: "GestionAI",
  components: {
    AIImporter,
    CreadorHuella,
    EditorInfluencia,
    RevisionHuella,
    HistorialEditor,
  },
  data() {
    return {
      estado: 'HISTORIAL',
      numero_revisiones: 0,
    }
  },

  methods: {
    setModo(estado){
      this.estado = estado
    },
    async obtenerNumeroRevisiones(){      
        try {

          if(!isEditorInluencia()) return;          
          const respuesta = await axios({
              method: 'GET',
              url: '/hogares/numero_solicitudes', 
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.numero_revisiones = respuesta.data.data.numero_solicitudes;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log(error.message);
            }
        }
    },
    esCreadorInfluencia(){
      return isCreadorInfluencia();
    },
    esEditorInfluencia(){
      return isEditorInluencia();
    },
    setComponenteDependiendodeRol(){
      if(isEditorInluencia()){
        this.estado = 'EDITOR'
      }else if (isCreadorInfluencia()){
        this.estado = 'C_HUELLA'
      }
    }
  },
  created() {
    this.obtenerNumeroRevisiones();
    this.setComponenteDependiendodeRol()
  },
  mounted(){
  }
};
</script>

<style scoped>
.accion-btn {
  width: fit-content;
}
</style>