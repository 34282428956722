<template> 
    <div class="card-body">
      
      <!-- Filtros -->  
      <b-collapse id="filtros" v-model="mostrarFiltros">
        <div>
            <form class="user d-flex flex-column" @submit.prevent="realizarBusquedaFiltrada()" novalidate>
              <div class="row">
                  <!-- OPERACION FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="OPERACION" class="line mx-1 fs-5 d-flex align-items-center">Operación</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERACION" class="form-control  m-0" maxlength="128" v-model="filtros.OPERACION">                                
                                  <option value="" selected>Sin especificar</option>
                                    <option v-for="(item, index) in OPERACIONESDISTINTAS" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!-- FECHA FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="datepicker-desde" class="line mx-1 fs-5 d-flex align-items-center">Fecha</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row align-items-center">
                              <div class="col-10 col-md-5 text-start">
                                <b-form-datepicker today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-desde" placeholder="Desde" locale="es" v-model="filtros.DESDE"></b-form-datepicker>
                              </div>
                              <div class="col-10 col-md-5 mt-3 mt-md-0 text-start">
                                <b-form-datepicker today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-hasta" placeholder="Hasta" locale="es" v-model="filtros.HASTA"></b-form-datepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!-- ID_ADMINISTRATIVO FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="ID_ADMINISTRATIVO" class="mx-1 fs-5 d-flex align-items-center">ID servicio</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="ID_ADMINISTRATIVO"  class="form-control w-100 m-0" type="text" required maxlength="50" v-model="filtros.ID_ADMINISTRATIVO" placeholder="ID administrativo" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!-- ID_DOMICILIO FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="ID_DOMICILIO" class="mx-1 fs-5 d-flex align-items-center">ID_DOMICILIO</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="ID_DOMICILIO"  class="form-control w-100 m-0" type="text" required maxlength="38" v-model="filtros.ID_DOMICILIO" placeholder="ID del domicilio" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>           
                  <!-- PROVINCIA FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="PROVINCIA" class="mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="PROVINCIA"  class="form-control w-100 m-0" type="text" required maxlength="128" v-model="filtros.PROVINCIA" placeholder="PROVINCIA" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>    

                  <!-- POBLACION FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="POBLACION" class="mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="POBLACION"  class="form-control w-100 m-0" type="text" required maxlength="128" v-model="filtros.POBLACION" placeholder="POBLACION" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>   

                  <!-- REMOTE_ID FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="REMOTE_ID" class="mx-1 fs-5 d-flex align-items-center">REMOTE_ID</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="REMOTE_ID"  class="form-control w-100 m-0" type="text" required maxlength="50" v-model="filtros.REMOTE_ID" placeholder="NÚMERO DE SERIE" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>                        
                  <!-- OPERADOR FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="OPERADOR" class="line mx-1 fs-5 d-flex align-items-center">Operador</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERADOR" class="form-control  m-0" maxlength="128" v-model="filtros.OPERADOR">                                
                                  <option value="" selected>Sin especificar</option>
                                    <option v-for="(item, index) in OPERADORESDISTINTOS" :key="index" :value="item.ID_OPERADOR">{{item.OPERADOR}} - {{item.ID_OPERADOR}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!-- USUARIO FILTRO -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="USUARIO" class="line mx-1 fs-5 d-flex align-items-center">Usuario</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="USUARIO" class="form-control  m-0" maxlength="128" v-model="filtros.USUARIO">                                
                                  <option value="" selected>Sin especificar</option>
                                    <option v-for="(item, index) in USUARIOSDISTINTOS" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  
                  <!-- Botones -->
                  <div class="d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-danger form-btn mx-2" @click="resetFiltros">Cancelar</button>
                    <button type="submit" :disabled="cargandoHistorial" class="btn btn-primary form-btn mx-2">
                      <i v-if="!cargandoHistorial" class="fa-solid fa-magnifying-glass mx-1"></i>
                      <div v-else class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      Buscar
                    </button>
                  </div>		
                  <hr class="mt-4">


              </div>
            </form>          
        </div>
      </b-collapse>

      <!-- Carga -->
      <div v-if="cargandoHistorial" class="my-4">
        <b-spinner></b-spinner>
        <h4 >Cargando datos...</h4>
      </div>
      


      <!-- Contenido -->
      <div>
          <div class="d-flex justify-content-between">
            <button v-if="numRegistros || filtrado" class="btn btn-secondary text-white fw-bold fit-content m-3 filtrar" type="button" v-b-toggle="'filtros'">
              <i class="fa-solid fa-filter"></i>
              Filtrar ({{numRegistros}})
            </button>
            <Paginador v-if="numRegistros" class="m-3" @recargar="cambiarPagina" :page="page" :numRegistros="numRegistros" :registrosPorPagina="registrosPorPagina"></Paginador>
          </div>
          <!-- Historial -->
          <div v-if="numRegistros" class="table-responsive shadow">
            <table class="table table-striped table-bordered">
                <thead class="text-start">
                  <tr class="noselect">
                    <th scope="col" class="firstColumn d-table-cell line sorting" @click="ordenarTabla($event, 'OPERACION')">OPERACION</th>
                    <th scope="col" class="d-table-cell sorting" @click="ordenarTabla($event, 'FECHA')">FECHA</th>
                    <th scope="col" class="d-none d-sm-table-cell line sorting" @click="ordenarTabla($event, 'ID_ADMINISTRATIVO')">ID ADMINISTRATIVO</th>
                    <th scope="col" class="d-none d-md-table-cell line sorting" @click="ordenarTabla($event, 'ID_OPERADOR')">OPERADOR</th>
                    <th scope="col" class="d-none d-lg-table-cell line sorting" @click="ordenarTabla($event, 'USUARIO')">USUARIO</th>
                  </tr>
                </thead>
                <tbody class="align-middle text-start">
                  <template v-for="(item, index) in registros">
                      <tr :key="index" :id="item.index" class="accordion" role="listaRegistros">
                        <!-- OPERACION -->
                        <td scope="row">
                          <div class="d-flex justify-content-between">
                            <span class="align-self-center fw-bold">{{item.OPERACION}}</span>
                            <b-button type="button" variant="primary" class="btn btn-primary btn-circle border-0 shadow-sm expand-btn mx-3" @click="animarCollapse('acordeon'+index); toggleIcon(index)"><i class="expand-icon fa-solid fa-plus fs-6" :id="'boton'+index"></i></b-button>
                          </div>
                        </td>
                        <!-- FECHA -->
                        <td class="d-table-cell">{{fechaComputada(item.FECHA)}}</td>
                        <!-- ID_ADMINISTRATIVO -->
                        <td class="d-none d-sm-table-cell">
                            <label class="link-primary pointerHover" @click="navigateToService(item.ID_ADMINISTRATIVO)">{{item.ID_ADMINISTRATIVO}}</label>
                        </td>
                        <!-- OPERADOR -->
                        <td class="d-none d-md-table-cell">{{item.OPERADOR}} - {{item.ID_OPERADOR}}</td>                        
                        <!-- USUARIO -->
                        <td class="d-none d-lg-table-cell">{{item.USUARIO}}</td>
                      </tr>
                      <!-- COLLAPSABLE INFO EXTRA -->
                      <tr :key="'c'+index">
                        <td colspan="7" class="p-0 overflow-hidden">
                          <div class="acordeon"  :id="'acordeon'+index" accordion="my-accordion">
                              <!-- Tarjeta de servicio -->
                              <TarjetaGeneral :item="item" :fullwidth="true"></TarjetaGeneral>
                          </div>
                        </td>
                      </tr>
              
                  </template>
                </tbody>
            </table>
          </div>
          <Paginador class="d-flex justify-content-center" v-if="numRegistros" @recargar="cambiarPagina" :page="page" :numRegistros="numRegistros" :registrosPorPagina="registrosPorPagina"></Paginador>

      </div>
      <!-- Error cargando -->
      <div v-if="registros.length < 1 && !cargandoHistorial" class="alert alert-warning d-flex align-items-center" role="alert">
        <div class="d-flex align-items-center">
          <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>  
          <div class="d-flex flex-column text-start mx-2">
            <div v-if="mensajedeError" class="text-danger">
              {{mensajedeError}}
            </div>  
            <div v-if="registros.length < 1" class="text-danger align-self-center">
              No se han encontrado registros
            </div>           
          </div> 
        </div>
      </div>
    </div>
</template>

<script>
import { getJWTpayload } from '../helpers/JWT';
import { animarCollapse, desexpandirTodo, toggleIcon } from '../helpers/utils';
import axios from "axios";
import { fecha } from "../classes/fecha.class"
import TarjetaGeneral from "@/components/TarjetaGeneral"
import Paginador from "@/components/Paginador"

export default {
  /*

  */
  name: 'HistorialTodosOperadores',
  components: {
    TarjetaGeneral,
    Paginador
  },
  data() {
    return {
      //Filtros
      filtros: {
        OPERACION: '',
        ID_ADMINISTRATIVO: '',
        DESDE: '',
        HASTA: '',
        OPERADOR: '',
        USUARIO: '',
        ID_DOMICILIO: '',
        PROVINCIA: '',
        POBLACION: '',
        REMOTE_ID: ''        
      },
      filtrosEstablecidos: {
        OPERACION: '',
        ID_ADMINISTRATIVO: '',
        DESDE: '',
        HASTA: '',
        OPERADOR: '',
        USUARIO: '',
        ID_DOMICILIO: '',
        PROVINCIA: '',
        POBLACION: '',
        REMOTE_ID: ''        
      },
      OPERACIONESDISTINTAS: [],
      USUARIOSDISTINTOS: [],
      OPERADORESDISTINTOS: [],
      mostrarFiltros: false,
      filtrado: false,


      //Historial
      cargandoHistorial: false,
      page: 1,
      numRegistros: 0,
      registrosPorPagina: 20,
      mensajedeError: '',
      registros: [],  //Lista de operaciones 

      orden: 'DESC',
      ordenColumna: 'FECHA',
    } 
  },
  methods: {
    ordenarTabla(event, columna){
      let elemento = event.target;
      let ascOrder = true;
      if(elemento.classList.contains('sorting_asc')){
        ascOrder = false;
      }
      if(elemento && elemento.parentElement){
        let ordenables = elemento.parentElement.querySelectorAll('.sorting');
        for (const iterator of ordenables) {
          iterator.classList.remove('sorting_desc', 'sorting_asc');
        }
        if(ascOrder){
          elemento.classList.add('sorting_asc');
          this.orden = 'ASC';
        }else{
          elemento.classList.add('sorting_desc');
          this.orden = 'DESC';
        }  
        this.ordenColumna = columna;
        this.page = 1;
        this.cargarRegistros();
      }
      
    },
    //Cargar de la API las operaciones realizadas
    async cargarRegistros(){
      try {
        this.cargandoHistorial = true;
        desexpandirTodo();
        
        //Filtros
        let filtrosParam = { ...this.filtrosEstablecidos };
        if(filtrosParam.HASTA) filtrosParam.HASTA += ' 23:59:59' //Se añaden tiempo en el parametro hasta para que sea el dia completo y no desde el principio
        
        //Petición
        let respuestaPandora = await axios.get('/historial', {
          method: 'GET',
          params: {OPID: this.filtros.OPERADOR, page: this.page, registrosPorPagina: this.registrosPorPagina, ...filtrosParam, orden: this.orden, ordenColumna: this.ordenColumna},
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.registros = respuestaPandora.data.data.registrosList;
        this.numRegistros = respuestaPandora.data.data.numRows;
        this.procesarRegistros();
        this.cargandoHistorial = false;
      } catch (error) {
         this.cargandoHistorial = false;
         console.log(error);
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },
    async cargarFiltros(){
      try {
        let OPID = getJWTpayload().OPID;

        let respuestaPandora = await axios.get('/historial/filtros', {
          method: 'GET',
          params: { OPID: OPID },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.OPERACIONESDISTINTAS = respuestaPandora.data.data.OPERACIONES.map( e => e.OPERACION).sort();
        this.USUARIOSDISTINTOS = respuestaPandora.data.data.USUARIOS.map( e => e.USUARIO).sort();
        this.OPERADORESDISTINTOS = respuestaPandora.data.data.OPERADORES.sort( (a, b) => a.ID_OPERADOR < b.ID_OPERADOR);
        

      } catch (error) {
          console.log(error);
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.warn(error.response.data.msg)
        } else if (error.request) {
          // The request was made but no response was received
          console.warn('No se ha recibido respuesta del servidor')
        } else {
          // Something happened in setting up the request that triggered an Error
          console.warn(error.message)
        }
      }
    },
    animarCollapse(id){
      animarCollapse(id);
    },
    toggleIcon(id){
      toggleIcon(id);
    },
    cambiarPagina(page){
      this.page = page;
      this.cargarRegistros();
    },
    navigateToService(id){
      this.mostrarFiltros = false;
      this.page = 1;
      this.filtros.OPERACION = '';
      this.filtros.ID_ADMINISTRATIVO = '';
      this.filtros.DESDE = '';
      this.filtros.HASTA = '';
      this.filtros.USUARIO = ''; 
      this.filtros.ID_DOMICILIO = '';
      this.filtros.PROVINCIA = '';
      this.filtros.POBLACION = '';      
      this.filtros.REMOTE_ID = '';

      this.filtrosEstablecidos.OPERACION = '';
      this.filtrosEstablecidos.ID_ADMINISTRATIVO = '';
      this.filtrosEstablecidos.DESDE = '';
      this.filtrosEstablecidos.HASTA = '';
      this.filtrosEstablecidos.USUARIO = ''; 
      this.filtrosEstablecidos.ID_DOMICILIO = '';
      this.filtrosEstablecidos.PROVINCIA = '';
      this.filtrosEstablecidos.POBLACION = '';            
      this.filtrosEstablecidos.REMOTE_ID = '';

      this.orden = 'DESC';
      this.ordenColumna = 'FECHA'
      this.filtros.ID_ADMINISTRATIVO = id;
      this.realizarBusquedaFiltrada();
      this.mostrarFiltros = true;
    },
    realizarBusquedaFiltrada(){
      this.page = 1;
      this.filtrado = true;
      this.filtrosEstablecidos.OPERACION = this.filtros.OPERACION;
      this.filtrosEstablecidos.ID_ADMINISTRATIVO = this.filtros.ID_ADMINISTRATIVO;
      this.filtrosEstablecidos.DESDE = this.filtros.DESDE;
      this.filtrosEstablecidos.HASTA = this.filtros.HASTA;
      this.filtrosEstablecidos.OPERADOR = this.filtros.OPERADOR;
      this.filtrosEstablecidos.USUARIO = this.filtros.USUARIO; 
      this.filtrosEstablecidos.ID_DOMICILIO = this.filtros.ID_DOMICILIO;
      this.filtrosEstablecidos.PROVINCIA = this.filtros.PROVINCIA;
      this.filtrosEstablecidos.POBLACION = this.filtros.POBLACION;            
      this.filtrosEstablecidos.REMOTE_ID = this.filtros.REMOTE_ID;

      this.cargarRegistros();
    },
    resetFiltros(){
      this.mostrarFiltros = false;
      this.page = 1;
      this.filtros.OPERACION = '';
      this.filtros.ID_ADMINISTRATIVO = '';
      this.filtros.DESDE = '';
      this.filtros.HASTA = '';
      this.filtros.OPERADOR = '';
      this.filtros.USUARIO = ''; 
      this.filtros.ID_DOMICILIO = '';
      this.filtros.REMOTE_ID = '';
      this.filtros.PROVINCIA = '';
      this.filtros.POBLACION = '';      
    
      this.filtrosEstablecidos.OPERACION = '';
      this.filtrosEstablecidos.ID_ADMINISTRATIVO = '';
      this.filtrosEstablecidos.DESDE = '';
      this.filtrosEstablecidos.HASTA = '';
      this.filtrosEstablecidos.USUARIO = ''; 
      this.filtrosEstablecidos.ID_DOMICILIO = '';
      this.filtrosEstablecidos.REMOTE_ID = '';
      this.filtrosEstablecidos.PROVINCIA = '';
      this.filtrosEstablecidos.POBLACION = '';     
      this.orden = 'DESC';
      this.ordenColumna = 'FECHA';
      this.cargarRegistros();
    },
    procesarRegistros(){
      for (const iterator of this.registros) {
        if(iterator.DATOS){
          iterator.DATOS = JSON.parse(iterator.DATOS)
        }
        if(iterator.DATOSTABLA){
          iterator.DATOSTABLA = JSON.parse(iterator.DATOSTABLA);
        }
      }
    }
  },
  created(){
    this.cargarRegistros();
    this.cargarFiltros();
  },
  computed: {
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },
  }
}
</script>

<style scoped>
.firstColumn{
  width: 17rem;
}
.acciones {
  width: 9rem;
}
.accion-btn {
  width: 13rem;
}

</style>
