<template>
  <div class="Panel bg-light vh-100 fix-body">
    <Sidebar></Sidebar>
    <Navbar></Navbar>
    <Carga :show="mostrarCarga"></Carga>
    <div class="container-fluid my-3">
      <router-view></router-view>
      <small>Copyright © {{actual_year}} Asteo Red Neutra S.L. Todos los derechos reservados.</small>
    </div>

  </div>
</template>

<script>

import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import Carga from '@/components/Carga.vue'
import { recalcularAltura } from '../helpers/utils';

export default {
  /*
    Vista de panel, contendrá todo el contenido de la aplicación
  */
  name: 'Panel',
  components: {
    Sidebar,
    Navbar,
    Carga
  },
  data() {
    return {
      mostrarCarga: false,
      actual_year: new Date().getFullYear(),
    }
  },
  methods: {
    mostrarAnimacionCarga(){
      if(this.$route.params.mostrarCarga){
        this.mostrarCarga = true;
        setInterval(() => {
          this.mostrarCarga = false;
        }, 3000);
      }
    }
  },
  created(){    
    //this.mostrarAnimacionCarga();
  },
  mounted(){
   window.addEventListener("resize", recalcularAltura);   
  },
  updated(){

  }
}
</script>
