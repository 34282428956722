import router from '../router'
import axios from 'axios'

//////////////////////////////////
//Seguridad JSON WEB TOKEN (JWT)//
//////////////////////////////////

/*
Funciones para trabajar con el JWT de seguridad
*/


/*
Almacena localmente el token de seguridad
@token(String) - Token de seguridad generado al loguearse
*/
export function setJWT(token) {
    localStorage.setItem('x-token', token);
}

/*
Comprueba si hay un token de seguridad almacenado
@OUPUT(Bolean) - true : false
*/
export function isAuthenticated() {
    return localStorage.getItem('x-token') ? true : false;
}

/*
Decodifica el JWT obteniendo el contenido del payload
@token(String) - El token de seguridad generado al loguearse (opcional, se recoge del almacenado)
*/
export function getJWTpayload(token = localStorage.getItem('x-token')) {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        let datosDecodificados = JSON.parse(atob(base64));
        return datosDecodificados;
    } catch (error) {
        desconectar();
    }
}

/*
Elimina el token de seguridad almacenado desconectando al usuario y lo dirige al login
*/
export function desconectar() {
    console.log("Desconectando...");
    localStorage.removeItem('x-token');
    router.push({ name: 'Login' });
}

/*
Verifica que el usuario actual es válido. Para ello hace una llamada API en la que se verifica el token y se renueva.
Se utiliza para comprobar que la identificación no ha sido borrada y el usuario no haya sido adulterado.
@OUPUT(Bolean) - true : false
*/
export async function verificarJWT() {
    let token = localStorage.getItem('x-token') || '';
    let verified = false;
    try {
        let respuesta = await axios.get('/auth', {
            headers: {
                'x-token': token
            }
        });
        setJWT(respuesta.data.data.token);
        verified = true;
    } catch (error) {

    } finally {
        return verified;
    }

}



/*
Comprueba si el usuario puede usar pandora o solo es un usuario de Hydra.
@OUPUT(Bolean) - true : false
*/
export function checkAccesoPandora(token) {
    try {
        return !getJWTpayload(token).soloHydra
    } catch (err) {
        if (err) desconectar();
    }
}


/*
Comprueba si el rol del token es de tipo administrador, para mostrar el contenido de administrador o no
@OUPUT(Bolean) - true : false
*/
export function isSuperAdmin() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        return roles.includes('SUPERADMINISTRADOR');
    } catch (err) {
        console.log(err)
        return false;
    }
}

/*
Comprueba si el rol del token es de tipo interno de asteo que tiene acceso a la parte administrativa de Asteo y a la parte de operador
@OUPUT(Bolean) - true : false
*/
export function isInternoAsteo() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        if (roles.includes('INTERNOASTEO') || roles.includes('SUPERADMINISTRADOR')) {
            return true;
        }
        return false;
    } catch (err) {
        console.log(err)
        return false;
    }
}

/*
Comprueba si el rol del token es de tipo creador de influencia
@OUPUT(Bolean) - true : false
*/
export function isCreadorInfluencia() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        return (roles.includes('CREADORINFLUENCIA') || roles.includes('SUPERADMINISTRADOR'));
    } catch (err) {
        console.log(err)
        return false;
    }
}

/*
Comprueba si el rol del token es de tipo editor de area de influenica de asteo que tiene acceso a la gestión de huella y trazados
@OUPUT(Bolean) - true : false
*/
export function isEditorInluencia() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        if (roles.includes('EDITORINFLUENCIA') || roles.includes('SUPERADMINISTRADOR')) {
            return true;
        }
        return false;
    } catch (err) {
        console.log(err)
        return false;
    }
}

/*
Comprueba si el rol del token es de tipo operador administrador, que tiene acceso a todas las funcionalidades del operador, incluida la gestión de usuarios
@OUPUT(Bolean) - true : false
*/
export function isOperadorAdmin() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        if (roles.includes('OPERADORADMIN') || roles.includes('SUPERADMINISTRADOR') || roles.includes('INTERNOASTEO')) {
            return true;
        }
        return false;
    } catch (err) {
        console.log(err)
        return false;
    }
}

/*
Comprueba si el rol del token es de tipo operador técnico que puede realizar altas y test
@OUPUT(Bolean) - true : false
*/
export function isOperadorTecnico() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        if (roles.includes('OPERADORTECNICO') || roles.includes('OPERADORADMIN') || roles.includes('SUPERADMINISTRADOR') || roles.includes('INTERNOASTEO')) {
            return true;
        }
        return false;
    } catch (err) {
        console.log(err)
        return false;
    }
}
/*
Comprueba si el rol del token es de tipo operador administrativo que puede usar el buscador, gestionar los clientes serviciados y hacer reservas de recursos
@OUPUT(Bolean) - true : false
*/
export function isOperadorAdministrativo() {
    try {
        let roles = getJWTpayload().roles;
        if (!roles) {
            throw 'No tienes suficientes permisos';
        }
        if (roles.includes('OPERADORADMINISTRATIVO') || roles.includes('OPERADORADMIN') || roles.includes('SUPERADMINISTRADOR') || roles.includes('INTERNOASTEO')) {
            return true;
        }
        return false;
    } catch (err) {
        console.log(err)
        return false;
    }
}
/*
Comprueba si el usuario contiene algun rol de los pasados por la lista
@OUPUT(Bolean) - true : false
*/
export function contieneAlgunRolPermitido(allowedRols) {
    let roles = getJWTpayload().roles;
    if (roles.includes('SUPERADMINISTRADOR')) {
        return true;
    }
    if (roles.some(r => allowedRols.indexOf(r) >= 0)) return true
    return false
}