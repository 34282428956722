<template>
    <div class="card-body">
      <!-- Tarjeta informativa -->
      <div v-if="cargandoServicio" class="my-4">
        <b-spinner></b-spinner>
        <h4 >Cargando datos...</h4>
      </div>
      <div v-if="!item && ID_ADMINISTRATIVO && !cargandoServicio && servicioBuscado" class="alert alert-warning d-flex align-items-center" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>   
        </div>
      </div>
      <TarjetaServicio v-if="item.ID_ADMINISTRATIVO" :item="item" @actualizar_servicio="actualizarItem"></TarjetaServicio>
      
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline">
            <h4>Buscador de servicios</h4>
            <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoBuscadorServicios()"></i>
          </div>
          <!-- Select TEST -->
          <form @submit.prevent="getServicio()">
            <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                <!-- ID_ADMINISTRATIVO -->
                <div class="col-sm-8 col-md-9 col-lg-10 my-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label for="ID_ADMINISTRATIVO" class="mx-1 fs-5 d-flex align-items-center">Id servicio</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                          <div class="row d-flex flex-row">
                            <div class="col-12">
                              <input id="ID_ADMINISTRATIVO"  class="form-control w-100 m-0" :disabled="cargandoTest || cargandoServicio" type="text" required maxlength="13" v-model="$v.ID_ADMINISTRATIVO.$model" placeholder="ID administrativo" autocomplete="off" autofocus @input="resetData(); getServicio();">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                          <small v-show="$v.ID_ADMINISTRATIVO.$dirty && !$v.ID_ADMINISTRATIVO.required" class="text-danger mt-2">
                            Debe de introducir un ID ADMINISTRATIVO
                          </small>
                          <small v-show="$v.ID_ADMINISTRATIVO.$dirty && !$v.ID_ADMINISTRATIVO.maxLength" class="text-danger mt-2">
                            El ID ADMINISTRATIVO solo puede tener 13 caracteres
                          </small>        
                          <small v-show="$v.ID_ADMINISTRATIVO.$dirty && !$v.ID_ADMINISTRATIVO.minLength" class="text-danger mt-2">
                            El ID ADMINISTRATIVO debe de contener como mínimo 12 caracteres
                          </small>                           
                        </div>
                      </div>    
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <button type="submit" :disabled="$v.ID_ADMINISTRATIVO.$invalid || cargandoServicio || cargandoTest" class="btn btn-primary text-white w-100 launch-btn">
                      <i v-if="!cargandoServicio" type="button" class="fa-solid fa-magnifying-glass fa-1x mx-1" title="Buscar servicio"></i>
                      <div v-else class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Buscando servicio Test...</span>
                      </div>
                      Buscar
                    </button>
                </div>
            </div>
          </form>

        </div>
      </div>
      <!-- Gestor de test -->
      <div  class="m-3 card shadow" v-if="ID_ADMINISTRATIVO && ID_ADMINISTRATIVO.length >= 12 && !cargandoServicio && servicioBuscado">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline">
            <h4>Gestor de Tests</h4>
            <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoGestorTest()"></i>
          </div>
          <!-- Select TEST -->
          <form  @submit.prevent="realizarTest()">
            <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                
                <!-- Tipo TEST -->
                <div class="col-sm-8 col-md-9 col-lg-10 my-2">
                    <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label for="ID_ADMINISTRATIVO" class="mx-1 fs-5 d-flex align-items-center">Test</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                          <div class="row d-flex flex-row">
                            <div class="col-12">
                              <select id="testid" :disabled="cargandoTest || cargandoServicio || !servicioBuscado" class="form-select my-1" aria-label="Selector de test" maxlength="128" v-model="$v.testElegido.$model" @change="resetTest()">                                
                                <option value="" disabled selected>Seleccione Test</option>
                                <option v-for="(testItem, index) in testPermitidos" :key="index" :value="testItem.codigo">{{testItem.descripcion}}</option>
                              </select>                                    
                            </div>
                          </div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <button type="submit" :disabled="$v.$invalid || cargandoTest || cargandoServicio || !servicioBuscado" class="btn btn-warning text-white w-100 launch-btn">
                      <i v-if="!cargandoTest" type="button" class="fa-solid fa-rocket fa-1x mx-1" title="Test"></i>
                      <div v-else class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Cargando Test...</span>
                      </div>
                      Lanzar
                    </button>
                </div>

            </div>
          </form>
          <!-- Action card -->
          <div v-if="resultadoTest">
            <b-overlay :show="cargandoTest">
            <div class="card border-dark border-3 mx-1 mt-2 bg-light action-card flex-shrink-1 justify-content-center">
                <div class="mt-2 mb-1">
                    <span class="text-black-50 fw-bold ">{{testElegidoEntero.descripcion}} - {{testElegido}}</span>
                </div>
                <div class="m-2">                            
                  <!-- Tabla resultado test -->
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered text-start">
                      <!-- Cabeceras tabla -->
                      <thead>
                        <tr>
                          <th scope="col" class="w-50">Clave</th>
                          <th scope="col" class="w-50">Valor</th>
                        </tr>
                      </thead>
                      <!-- Cuerpo tabla -->
                      <tbody>
                        <tr v-for="(item, index) in resultadoTest" :key="index">
                          <th scope="row">{{item.key}}</th>
                          <td :class="[item.color ? `${item.color} fw-bold` : '']">{{item.value ? item.value : ''}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            </b-overlay>
                <!-- Descargar resultados -->
                <div v-if="resultadoTest && (csv  || excel)" class="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-3">
                  <button type="button" v-if="csv" class="btn btn-secondary text-white m-2 dwnld-btn line"  title="Descargar csv" @click="downloadCSV()">
                    <i type="button" class="fa-solid fa-file-csv fa-1x mx-1"></i>
                    Descargar CSV
                  </button>
                  <button type="button" v-if="csv" class="btn btn-secondary text-white m-2 dwnld-btn line"  title="Descargar Excel" @click="downloadExcel()">
                    <i type="button" class="fa-solid fa-file-excel fa-1x mx-1"></i>
                    Descargar EXCEL
                  </button>
                </div>
          </div>
        </div>
      </div>
      <div id="bottomReference"></div>
    </div>
</template>


<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, waitAlert } from '../helpers/fastAlert';
import { necesitaEsperar, empezarEsperaResetONT } from '../helpers/bloqueosPorTiempo';
import Swal from 'sweetalert2'
import axios from "axios";
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import TarjetaServicio from '@/components/TarjetaServicio';
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';
import { getJWTpayload } from '../helpers/JWT';
import { fecha } from '../classes/fecha.class';
import converter from 'json-2-csv';
import { convertCsvToExcelBuffer, crearYdescargar } from '../helpers/utils';
import $ from 'jquery';
/*

Componente que permite introducir un ID_ADMINISTRATIVO y realizar un test sobre el. Si el servicio existe en la base de datos
se muestra una tarjeta informativa, aunque no exita se puede realizar el test. (Por ejemplo, en caso de que sea un servicio creado
con plataformas externas y no está en nuestra base de datos)

*/
export default {
  name: 'TestDirecto',
    components: {
    TarjetaServicio
  },
  data() {
    return {   
      ID_ADMINISTRATIVO: '',
      servicioBuscado: false,
      testElegido: '',          //Template del test
      testElegidoEntero: '',    //Template del test y la descripción
      resultadoTest: '',        //datos resultado
      cargandoTest: false,  
      cargandoServicio: false,
      item: '',
      //Lista de test permitidos
      testPermitidos: [
        { codigo: 'TST_FTTH_STATE_ONT', descripcion: 'Estado ONT', datosDescartables: ['ont_vendor']},
        { codigo: 'TST_FTTH_DATOS', descripcion: 'Datos ONT'},
        { codigo: 'TST_FTTH_RESET_ONT', descripcion: 'Reseteo ONT' },
        { codigo: 'TST_FTTH_FACTORY_RESET_ONT', descripcion: 'Reseteo de fabrica ONT' },
        { codigo: 'TST_FTTH_CONF_EMP', descripcion: 'Test de configuración' },
        { codigo: 'TST_FTTH_OSTATE', descripcion: 'Estado óptico' },
        { codigo: 'TST_FTTH_ONT_OLT', descripcion: 'Verificación de configuración ONT en OLT' },
        { codigo: 'TST_ESTADO_PON', descripcion: 'Estado PON' },
        { codigo: 'TST_FTTH_PON', descripcion: 'Diagnóstico PON' }
      ],
      //Test en los que hay que solicitar una confirmación antes de hacerlos
      testCriticos: ['TST_FTTH_RESET_ONT', 'TST_FTTH_FACTORY_RESET_ONT'],

      //Descarga resultados
      excel: '',
      csv: '',
    }
  },  
  //Validaciones
  validations: {
    testElegido: {
      required,
      maxLength: maxLength(128)
    },
    ID_ADMINISTRATIVO: {
      required,
      maxLength: maxLength(14),
      minLength: minLength(12),
    }
  },
  methods:{
    infoGestorTest(){
      infoAlert('Gestor de Tests', 'En este apartado puede ejecutar los diagnósticos que necesite, seleccione el test en el desplegable y pulse <strong>Lanzar</strong>. <br><br> Se mostrará una tabla con el resultado del diagnóstico.')
    },
    infoBuscadorServicios(){
      infoAlert('Buscador de servicios', 'Introduce el <strong>ID ADMINISTRATIVO</strong> del servicio para encontrarlo y desplegar información detallada. Aunque no se encuentre en nuestra base de datos, podrás lanzar un test sobre el servicio.')
    },
    actualizarItem(nuevoItem) {
      this.item = nuevoItem;
    },      
    //Iniciar test
    async realizarTest(){
      try {
        this.cargandoTest = true;

        if(necesitaEsperar(this.item.ID_ADMINISTRATIVO)) return;
        
        //Pedir confirmación para test críticos
        if(this.testCriticos.includes(this.testElegido)){
          let result = await Swal.fire({
            title: '¿Está seguro?',
            html: `Tenga en cuenta que lanzar el test <strong>${this.testElegidoEntero.descripcion}</strong> realizará cambios importantes.`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
          })
          if(!result.isConfirmed) return;
        }
        
        let respuesta = await axios({
          method: 'POST',
          url: '/test',
          data: { 'IUA': this.ID_ADMINISTRATIVO, 'Test': this.testElegido },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        let datos = ''
        console.log('Respuesta:', respuesta);
        try {
          datos = respuesta.data.data.Resultado.Datos.OutputParam;
        } catch (error) {
          throw { response: {data: {msg: 'Resultado no encontrado'}}}
        }
        this.resultadoTest = this.tratarResultadoTest(datos);
        if((this.testElegido == 'TST_FTTH_RESET_ONT' || this.testElegido == 'TST_FTTH_FACTORY_RESET_ONT')){
          let testResult = this.resultadoTest.find(dato => dato.key == 'Verificacion');
          if(testResult.value == "OK"){
            empezarEsperaResetONT(this.item.ID_ADMINISTRATIVO)
          }
        }
        //Se procesan los datos para registrarlo en el historial
        let datosRegistro = {};
        for (const iterator of datos) {
          datosRegistro[iterator.key] = iterator.value
        }
        //CSV
        converter.json2csv(datosRegistro, (err, csv) => {          
          if(err){
            console.error(err);
            return;
          }
          this.excel = convertCsvToExcelBuffer(csv);
          this.csv = csv;
        })

      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
      } finally {
        this.cargandoTest = false;
      }
    },
    //reiniciar pagina
    resetTest(){
      this.resultadoTest = '';
      this.testElegidoEntero = this.testPermitidos.find(iterator => iterator.codigo == this.testElegido);
    },
    resetData(){
      this.item = '';
      this.testElegido = '';
      this.resultadoTest = '';
      this.servicioBuscado = false;
      setTimeout(() => {
        document.getElementById('ID_ADMINISTRATIVO').focus();
      }, 50);
    },
    //Recuperar servicio activo
    async getServicio(){
      try {
        if(this.ID_ADMINISTRATIVO.length < 12){
          return;
        }

        this.servicioBuscado = true;
        this.cargandoServicio = true;
        this.item = '';
        this.resultadoTest = '';
        let respuesta = await axios({
          method: 'GET',
          url: '/servicios/servicio',
          params: { ID_ADMINISTRATIVO: this.ID_ADMINISTRATIVO},
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        })
        this.item = respuesta.data.data.servicio;
        let element = document.getElementById('bottomReference');
        if(element){
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'start'})
          }, 80);
        }


        this.cargandoServicio = false;
      } catch (error) {
        this.cargandoServicio = false;
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          this.mensajedeError = error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          this.mensajedeError = error.message || error;
        }
      }

    },
    //Formatea el resultado del test poniendo color a datos importantes
    tratarResultadoTest(datos){
      let datosNuevos = datos;
      if(this.testElegidoEntero.datosDescartables){
        datosNuevos = datos.filter( dato => !this.testElegidoEntero.datosDescartables.includes(dato.key));
      }
      for (const iterator of datosNuevos) {
        if(iterator.key == 'ont_estado_operacional' && iterator.value == 'down'){
          iterator.color = 'text-danger';
        }
        if(iterator.key == 'ont_potencia_rx' || iterator.key == 'olt_potencia_rx'){
          let valor = parseFloat(iterator.value);
          if(valor>-14.00 || valor<-30.00){
            iterator.color = 'text-danger';
          }else if(valor<-18){
            iterator.color = 'text-warning';
          }else{
            iterator.color = 'text-success';
          }
        }
      }
      return datosNuevos;
    },
    downloadCSV(){
      let nombre = 'Diagnóstico - ' + this.item.ID_ADMINISTRATIVO + ' - ' + new fecha().ddmmyyyy;
      crearYdescargar(this.csv, nombre,  'csv'); 
    },
    downloadExcel(){
      let nombre = 'Diagnóstico - ' + this.item.ID_ADMINISTRATIVO + ' - ' + new fecha().ddmmyyyy;
      crearYdescargar(this.excel, nombre, 'excel'); 
    },
  },
  computed: {

  },

}
</script>

