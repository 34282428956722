<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">
            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Usuarios</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Nueva reserva-->
              <div>
                  <router-link :to="{name: 'NuevoUsuario'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fas fa-user-plus"></i> Nuevo usuario</router-link>
              </div>
            </div>
            <div class="card-body">
              <!-- Carga -->
              <div v-if="cargandoOperadores" class="my-4">
                <b-spinner></b-spinner>
                <h4 >Cargando datos...</h4>
              </div>
              
              <!-- Error cargando -->
              <div v-else-if="operadores.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>  
                  <div class="d-flex flex-column">
                    <div v-if="mensajedeError" class="text-danger">
                      {{mensajedeError}}
                    </div>  
                    <div v-if="operadores.length < 1" class="text-danger align-self-center">
                      No se han encontrado operadores.
                    </div>           
                  </div>
                </div>
              </div>

              <!-- Contenido -->
              <div v-else>
           
                    <div class="table-responsive list-limited-height">
                    <table class="table table-striped table-bordered">
                      
                        <thead class="text-start align-middle">
                          <tr class="noselect">
                            <th scope="col" class="firstColumn d-table-cell line" @click="ordenarTabla($event, 'operador')">OPERADOR</th>
                            <th scope="col" class="d-table-cell" @click="ordenarTabla($event, 'id_operador')">ID OPERADOR</th>
                            <th scope="col" class="d-table-cell">
                                <div class="d-flex justify-content-between align-items-center">
                                  PERFILES ASIGNADOS
                                  <i class="fa-solid fs-4 fa-circle-question clickable" @click="infoPerfiles()"></i>
                                </div>
                            </th>
                            <th scope="col" class="d-table-cell">
                                <div class="d-flex justify-content-between align-items-center">
                                  COBERTURAS ASIGNADAS
                                  <i class="fa-solid fs-4 fa-circle-question clickable" @click="infoCoberturas()"></i>
                                </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="align-middle text-start">
                          <template v-for="(item, index) in operadores" >
                              <tr :key="index" :id="item.id_operador" class="accordion searchable">
                                <!-- OPERADOR -->
                                <td scope="row">
                                  <div class="d-flex justify-content-between">
                                    <span class="align-self-center fw-bold searchable-text">{{item.operador}}</span>
                                  </div>
                                </td>
                                <td class="d-table-cell searchable-text">{{item.id_operador}}</td>
                                <td class="text-center" :class="{ 'text-danger' : !item.numPerfiles }">{{perfilComputed(item.numPerfiles)}}</td>
                                <td class="d-table-cell text-center" :class="{ 'text-danger' : !item.numPueblos }">{{coberturaComputed(item.numPueblos)}}</td>
                              </tr>
                              
                              <!-- COLLAPSABLE INFO EXTRA -->
                              <tr :key="'c'+index">
                                <td colspan="7" class="p-0 overflow-hidden">
                                  <div :id="'acordeon'+item.id_operador" accordion="my-accordion">
                                      <div>
                                          <!-- No hay perfiles o coberturas -->
                                          <div class="m-4">
                                            <div v-if="item.perfiles.length < 1 || item.pueblos.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                                              <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                                              <div class="d-flex flex-column text-start">
                                                <div v-if="mensajedeError" class="text-danger">
                                                  {{mensajedeError}}
                                                </div>  
                                                <div v-if="item.perfiles.length < 1" class="text-danger">
                                                  ¡No tienes perfiles asignados y no podrás trabajar!
                                                </div>     
                                                <div v-if="item.pueblos.length < 1" class="text-danger">
                                                  ¡No tienes cobertura asignada y no podrás trabajar!
                                                </div>   
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Tarjeta de operador -->
                                          <TarjetaOperador :item="item" :fullwidth="true" @recalcular="recalcularHeight" @finalizado="finalizarRecalcularHeight" @reiniciar="reiniciarPagina"></TarjetaOperador>     
                                      </div>
                                  </div>
                                </td>
                              </tr>
                      
                          </template>
                        </tbody>
                    </table>
                    </div>
              </div>

            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { infoAlert } from '../helpers/fastAlert';
import { desexpandirTodo, procesarCoberturas, recalcularAltura } from '../helpers/utils';
import axios from "axios";
import TarjetaOperador from "@/components/TarjetaOperador"

export default {
  /*
    Sección de usuarios dedicada a los usuarios con rol OPERADORADMIN
    Muestra una tabla con información del operador del usuario.
    Además muestra los usuarios pertenecientes al operador y le permite realizar operaciones sobre ellos (Cambio de contraseñas, cambio de rol y eliminarlos)
    También permite crear nuevos usuarios.

  */
  name: 'UsuariosOperador',
  components: {
    TarjetaOperador
  },
  data() {
    return {
      cargandoOperadores: false,
      cargandoPerfiles: false,
      cargandoCoberturas: false,
      mensajedeError: '',
      operadores: [],               //Lista de operadores
      usuarios: [],                 //Lista de usuarios  
      perfiles: [],                 //Perfiles de velocidad
      coberturas: [],                //Coberturas asignadas
      rolesDisponibles: [],
      rolesAsignados: [],
      servidores_acs: [],
      servidores_voip: [],
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Operadores', 'Encuentra una lista con los operadores que trabajan con Asteo, aquí les puedes asignar <strong>perfiles de velocidad</strong> y <strong>coberturas contratadas.</strong>')
    },   
    infoPerfiles(){
      infoAlert('Perfiles asignados', 'Son los perfiles de velocidad asignados a tu operador. <br><br> ¡Si no te han asignado ninguno, el no podrás trabajar!')
    },
    infoCoberturas(){
      infoAlert('Coberturas asignadas', 'Son los pueblos que se le han asignado a tu operador, los pueblos contratados con los que trabajar. <br><br> ¡Si te han asignado ninguno, el operador no podrá trabajar!')
    },
    //Descarga los operadores existentes de la api
    async cargarRolesAsignados(){
      try {
        this.cargandoRolesAsignados = true;

        let respuestaPandora = await axios({
          method: 'GET',
          url: '/roles/asignados',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.rolesAsignados = respuestaPandora.data.data.roles;
        this.cargandoRolesAsignados = false;
      } catch (error) {
        this.cargandoRolesAsignados = false;
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }

    },
    async cargarOperadores(){
      try {
        this.cargandoOperadores = true;
        await this.cargarPerfiles();
        await this.cargarCoberturas();
        await this.cargarRolesAsignados();
        await this.cargarServidorACS();
        await this.cargarServidoresVoIP();

        let respuestaPandora = await axios({
          method: 'GET',
          url: '/operadores/usuarios',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.usuarios = respuestaPandora.data.data.listaOperadores;
        this.procesarOperadores();
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } finally {
        this.cargandoOperadores = false;
      }
    },
    //Carga de la API todos los perfiles de velocidad
    async cargarPerfiles(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/perfiles',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.perfiles = respuestaPandora.data.data.perfiles;
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },
    //Carga de la API todas las coberturas asignadas
    async cargarCoberturas(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/huella/coberturas',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        
        this.coberturas = respuestaPandora.data.data.coberturas;

      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },
    //Reinicia la página
    reiniciarPagina(){
      this.mensajedeError='';
      this.operadores=[];
      this.usuarios=[];
      this.perfiles=[];
      this.coberturas=[];
      this.cargarOperadores();
    },   
    //Procesa los operadores dejandolos con el formato:
    //this.operadores = [ id_operador, operador, numPerfiles, perfiles: [perfil1, perfil2 ...], numPueblos, pueblos: [pueblo1, pueblo2 ...], usuarios: [usuario1, usuario2 ...]]
    //De este modo cada operador tiene toda su información, y es ordenador por id_operador
    procesarOperadores(){
      try {
        for (const iterator of this.usuarios) {
          iterator.roles = this.rolesAsignados.filter(e => e.user == iterator.user)
          let encontrado = this.operadores.find(dato => dato.id_operador == iterator.id_operador && dato.operador == iterator.operador);

          let { id_operador, operador } = iterator;
          if(!encontrado){
            let tarifas = this.perfiles;
            let numPerfiles = tarifas.length;

            let pueblos = this.coberturas;
            let numPueblos = pueblos.length;
            pueblos = procesarCoberturas(pueblos);

            let servidor_acs = this.servidores_acs.find(element => element.id_operador == id_operador);
            let servidores_voip = this.servidores_voip.filter(element => element.id_operador == id_operador);

            this.operadores.push({id_operador, operador, numPerfiles, perfiles: tarifas, numPueblos, pueblos, servidor_acs: servidor_acs ? servidor_acs.ip : '', servidores_voip, usuarios: [iterator]})
          }else{
            encontrado.usuarios.push(iterator);
          }
        }
        this.operadores.sort(function(a, b) {
          return a.id_operador - b.id_operador;
        });

      } catch (error) {
        console.log(error);
      }
    },
    //Recalcula la altura del acordeon cuando cambia el tamaño del desplegable añadiendole el parametro height
    recalcularHeight(height){      
      for (const iterator of document.querySelectorAll('.acordeon.show')) {
        iterator.style.height = (iterator.offsetHeight + height) + 'px';
      }
    },
    //Recalcula el tamaño del acordeon cuand termina de desplegarse para asegurarnos que el tamaño final es el correcto ya que si se lana recalcularHeight de manera descuidada puede estar mal calculado
    finalizarRecalcularHeight(){  
        for (const iterator of document.querySelectorAll('.acordeon.show')) {          
          let contenedor = iterator.querySelector('.acordeon-content')
          if(contenedor){
            iterator.style.height = (contenedor.offsetHeight+ 39) + 'px';
          }
        }
    },
    //Carga de la API todos los servidores acs
    async cargarServidorACS(){      
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/acs/servidor',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        console.log("res", respuestaPandora);

        this.servidores_acs = respuestaPandora.data.data.servidores;
      } catch (error) {
          console.log("res", error);
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },    
    //Carga de la API todos los servidores voip
    async cargarServidoresVoIP(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/acs/servidor_voip',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.servidores_voip = respuestaPandora.data.data.servidores;
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },            
  },
  created(){
    this.cargarOperadores();
  },

  computed:{
    perfilComputed(){
      return (numPerfiles) => {
        if(numPerfiles > 1){
          return numPerfiles + ' perfiles'
        }else if(numPerfiles == 1){
          return numPerfiles + ' perfil'
        }
        return '¡Ningún perfil asignado!'
      }
    },
    coberturaComputed(){
      return (numPueblos) => {
        if(numPueblos > 1){
          return numPueblos + ' pueblos'
        }else if(numPueblos == 1){
          return numPueblos + ' pueblo'
        }
        return '¡Ningún pueblo asignado!'
      }
    }
  }
}
</script>

<style scoped>
.firstColumn {
  width: 14rem;
}
.acciones {
  width: 9rem;
}
.accion-btn {
  width: 12rem;
}

</style>
