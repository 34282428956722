<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">
            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Configurar VoIP</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Volver a mis Servicios-->
              <div>
                  <router-link :to="{name: item.editorAdmin ? 'ServiciosTest' : 'Servicios'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fa-solid fa-coins mx-1"></i> Servicios</router-link>
              </div>
            </div>
            
            <div class="card-body">
              <!-- Tarjeta informativa -->
              <TarjetaServicio :item="item"></TarjetaServicio>

              <!-- Nueva tarifa -->
              <b-overlay :show="cargandoPreset">
                
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <!-- Action card header -->
                    <div class="d-flex align-items-baseline">
                      <h4>VoIP_{{item.REMOTE_ID}}</h4>
                      <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoNuevoPerfil()"></i>
                    </div> 

                   <div v-if="preset_voip">
                      <!-- _ID -->
                      <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle ">PRESET: </span>
                        <span class="mx-2 fs-6 align-middle">{{preset_voip._id}}</span>
                      </div>
                      <hr class="my-1">

                      <!-- Servidor VoIP -->
                      <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle ">SERVIDOR VOIP: </span>
                        <span class="mx-2 fs-6 align-middle">{{preset_voip.configurations[0].args[0]}}</span>
                      </div>
                      <hr class="my-1">

                      <!-- USUARIO -->
                      <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle ">USUARIO: </span>
                        <span class="mx-2 fs-6 align-middle">{{preset_voip.configurations[0].args[1]}}</span>
                      </div>
                      <hr class="my-1">

                      <!-- PASSWORD -->
                      <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle ">PASSWORD: </span>
                        <span class="mx-2 fs-6 align-middle">{{preset_voip.configurations[0].args[2]}}</span>
                      </div>
                      <hr class="my-1">
                                                           
                   </div>
                    <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center" role="alert">
                        <i class="fa-solid fs-5 fa-triangle-exclamation text-warning mx-2"> </i>                
                      <div>
                        {{mensajedeError}}
                      </div>
                    </div>

                    <!-- Botones -->
                    <div v-if="preset_voip" class="d-flex justify-content-center">
                      <button type="button" class="btn btn-danger form-btn mx-2 mt-2" :disabled="cargandoSolicitudBorrarPreset"  @click="eliminarConfiguracionVOIP">
                        <div v-show="cargandoSolicitudBorrarPreset" class="spinner-border spinner-border-sm" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        Eliminar
                      </button>
                    </div>		
                  </div>
                </div>
              </b-overlay>

              <!-- Configurar VoIP -->
              <div class="m-3 card shadow">
                <div class="card-body"> 
                  <!-- Action card header -->
                  <div class="d-flex align-items-baseline">
                    <h4>Configurar VoIP</h4>
                  </div> 

                  <div>
                    <form class="user d-flex flex-column" @submit.prevent="configurar_voip" novalidate>
                      <div class="row">

                          <!-- Servidor VoIP -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="servidor_voip" class="line mx-1 fs-5 d-flex align-items-center">Servidor VoIP</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">  
                                        <input id="servidor_voip"  class="form-control w-100 m-0" list="servidoresOpciones" autocomplete="off" required maxlength="65" v-model.lazy="$v.servidor_elegido.$model" placeholder="IP VoIP" :class="{ 'is-invalid': $v.servidor_elegido.$error}" @change="loseFocus">
                                            <datalist id="servidoresOpciones">
                                              <option v-for="(item, index) in servidores_voip" :key="index" :value="item.ip">{{item.operador || "Operador desconocido"}}</option> 
                                            </datalist> 
                                      </div>
                                      <div class="col-2 px-0 text-start align-self-center">
                                        <i class="fa-solid fa-circle-question clickable fs-5" @click="infoServidor"></i>
                                      </div>
                                    </div>                                 
                                  </div>
                                </div>
                                <div class="row justify-content-end text-start">
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                    <small v-show="$v.servidor_elegido.$dirty && !$v.servidor_elegido.required" class="text-danger">
                                      Debe de seleccionar un perfil
                                    </small>
                                    <small v-show="$v.servidor_elegido.$dirty && !$v.servidor_elegido.maxLength" class="text-danger">
                                      No puede introducir tantos caracteres
                                    </small>                                
                                  </div>
                              </div>                              
                          </div>

                          <!-- usuario_elegido -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="usuario_elegido" class="line mx-1 fs-5 d-flex align-items-center">Usuario VoIP</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">
                                        <input id="usuario_elegido"  class="form-control w-100 m-0" type="text" required maxlength="65" v-model="$v.usuario_elegido.$model" placeholder="Usuario" :class="{ 'is-invalid': $v.usuario_elegido.$error}" autofocus autocomplete="off">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.usuario_elegido.$dirty && !$v.usuario_elegido.required" class="text-danger">
                                    Debe de introducir un usuario VoIP
                                  </small>
                                  <small v-show="$v.usuario_elegido.$dirty && !$v.usuario_elegido.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                              </div>
                          </div>

                          <!-- password_elegida -->
                          <div class="col-sm-12 col-xxl-6 my-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                    <label for="password_elegida" class="line mx-1 fs-5 d-flex align-items-center">Contraseña VoIP</label>
                                  </div>
                                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                    <div class="row d-flex flex-row">
                                      <div class="col-10">
                                        <input id="password_elegida"  class="form-control w-100 m-0" type="text" required maxlength="65" v-model="$v.password_elegida.$model" placeholder="Contraseña VoIP" :class="{ 'is-invalid': $v.password_elegida.$error}" autofocus autocomplete="off">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.password_elegida.$dirty && !$v.password_elegida.required" class="text-danger">
                                    Debe de introducir un password
                                  </small>
                                  <small v-show="$v.password_elegida.$dirty && !$v.password_elegida.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                              </div>
                          </div>

                          <!-- Botones -->
                          <hr class="mt-4">
                          <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-secondary form-btn mx-2" @click="resetearFormulario">Limpiar</button>
                            <button :disabled="$v.servidor_elegido.$invalid || $v.usuario_elegido.$invalid || $v.password_elegida.$invalid || cargandoSolicitud" type="submit" class="btn btn-primary form-btn mx-2">
                              <i v-if="!cargandoSolicitud" class="fa-solid fa-save mx-2"></i>
                              <div v-else class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              Guardar
                            </button>
                          </div>		



                      </div>
                    </form>          
                  </div>
                  
                </div>
              </div>




            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, successAlert, confirmAlert } from '../helpers/fastAlert';
import { empezarEsperaCambioServicio, necesitaEsperar } from '../helpers/bloqueosPorTiempo';

import Swal from 'sweetalert2'
import axios from "axios";
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TarjetaServicio from '@/components/TarjetaServicio';
import { getJWTpayload, verificarJWT, desconectar, isInternoAsteo } from '../helpers/JWT';

export default {
  /*
    En esta página se le puede asignar un nuevo perfil de velocidad a un servicio.
    Se descarga de la API los perfiles de velocidad disponibles y se ponen en el selector.
  */
  name: 'ConfigurarVoIP',
  components: {
    TarjetaServicio
  },
  data() {
    return {
      cargandoPreset: false,
      cargandoSolicitud: false,
      cargandoSolicitudBorrarPreset: false,
      preset_voip: '',
      servidores_voip: [],
      servidor_elegido: '',
      usuario_elegido: '',
      password_elegida: '',
      mensajedeError: '',
    } 
  },
  validations: {
    servidor_elegido: {
      required,
      maxLength: maxLength(65)
    },
    usuario_elegido: {
      required,
      maxLength: maxLength(65)
    },
    password_elegida: {
      required,
      maxLength: maxLength(65)
    },    
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Configurar VoIP', 'En esta página puedes configurar el usuario y contraseña VoIP para la ONT del cliente.')
    },   
    infoNuevoPerfil(){
      infoAlert('Perfil de VoIP', 'El perfil de VoIP define la configuración de VoIP de un cliente, está compuesta por un <strong>usuario</strong> y <strong>contraseña</strong>.')
    },  
    infoServidor(){
      infoAlert('Servidor VoIP', 'Es el servidor VoIP que gestiona las operaciones VoIP, se compone de una <strong>IP</strong> o <strong>DNS</strong>.')
    },      
    //Recuperar el servicio actual de la localStorage
    getServicio(){
      if(this.$route.params.item){
        localStorage.setItem('servicioActivo', JSON.stringify(this.$route.params.item));
      }
      if(localStorage.getItem('servicioActivo')){
        this.item = JSON.parse(localStorage.getItem('servicioActivo'));
      }      
      if(!this.item){
        this.$router.push({ name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});
      }
      this.comentarioNuevo = this.item.comentario;
      this.facturacion = this.item.no_facturable;
      localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
    },
    //Descargar los perfiles de velocidad de la api
    async getPreset(){
        try {
          this.cargandoPreset = true;
          
          let respuestaPandora = await axios({
            method: 'GET',
            url: '/acs/servidor_voip/preset',
            params: { remote_id: this.item.REMOTE_ID },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          console.log(respuestaPandora);
          this.preset_voip = respuestaPandora.data.preset_voip;     
          this.cargandoPreset = false;
        } catch (error) {
          this.cargandoPreset = false;
          this.preset_voip = ''
            console.log(error);
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    },
    async getServidoresVoIP(){
        try {
          this.cargandoServidoresVoIP = true;
          
          let respuestaPandora = await axios({
            method: 'GET',
            url: '/acs/servidor_voip',
            params: { id_operador: this.item.id_operador },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          console.log(respuestaPandora);
          this.servidores_voip = respuestaPandora.data.data.servidores;       
          this.resetearFormulario()   
          this.cargandoServidoresVoIP = false;
        } catch (error) {
          this.cargandoServidoresVoIP = false;
            console.log(error);
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    },    
    resetearFormulario(){
      this.servidor_elegido = this.servidores_voip && this.servidores_voip.length ? this.servidores_voip[0].ip : '';
      this.usuario_elegido = '';
      this.password_elegida = '';
      this.$v.$reset();        
    },
    async configurar_voip(){
        try {
          this.cargandoSolicitud = true;
          let respuestaPandora = await axios({
            method: 'POST',
            url: '/acs/servidor_voip/preset',
            data: { ip: this.servidor_elegido, usuario: this.usuario_elegido, password: this.password_elegida, remote_id: this.item.REMOTE_ID },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });

          console.log(respuestaPandora);     
          successAlert("¡Proceso completado!", respuestaPandora.data.msg)
          this.resetearFormulario()   
          await this.getPreset()
          this.mensajedeError = ''
          this.cargandoSolicitud = false;
        } catch (error) {
          this.cargandoSolicitud = false;
            console.log(error);
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            errorAlert("Error en la operación", error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            noResponseAlert()
          } else {
            // Something happened in setting up the request that triggered an Error
            serverErrorAlert(error.message || error)
          }
        }
    },
    async eliminarConfiguracionVOIP(){
        try {
          this.cargandoSolicitudBorrarPreset = true;
          let respuestaPandora = await axios({
            method: 'DELETE',
            url: '/acs/servidor_voip/preset',
            data: { remote_id: this.item.REMOTE_ID },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          successAlert("¡Proceso completado!", respuestaPandora.data.msg)
          this.mensajedeError = ''
          await this.getPreset()
          this.cargandoSolicitudBorrarPreset = false;
        } catch (error) {
          this.cargandoSolicitudBorrarPreset = false;
            console.log(error);
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            errorAlert("Error en la operación", error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            noResponseAlert()
          } else {
            // Something happened in setting up the request that triggered an Error
            serverErrorAlert(error.message || error)
          }
        }
    },    
    esRolInterno(){
      return isInternoAsteo()
    },       
    //Pierde el focus de un dataList para que deje de mostrar sugerencias
    loseFocus(evt) {
      evt.target.blur()
    },
  },
  created(){
    this.getServicio();
    this.getPreset();
    this.getServidoresVoIP()
  }
}
</script>

<style scoped>

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
  }
}

.tablaScrolleable{
  overflow: auto;
  max-height: 30rem;
}
</style>
