import 'dotenv/config'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles.css'
import './assets/datatables.css'


//Validador de formularios
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


//BoostrapVue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

//Sweet Alert 2 para popups
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

//Import axios para consumir api
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

Vue.config.productionTip = false
Vue.prototype.$enviarAorquestador = true;

//Directiva para campos en el que solo queremos mayusculas
Vue.directive('input-upper', {
    update: (el) => {
        const start = el.selectionStart
        el.value = el.value.toUpperCase()
        el.setSelectionRange(start, start)
        el.dispatchEvent(new Event('input'));
    },

})

//Directiva para campos en el que solo queremos números
Vue.directive('input-number', {
    bind(el) {
        el.addEventListener('keypress', (evt) => {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            }
        })
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')