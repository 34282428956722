<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Panel de administración</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>
            <div class="row m-2">
              <!-- Sincronización y mantenimiento -->
              <div class="col-12 col-lg-7 m-auto my-3">
                <div class="card lista-accesos-card shadow-lg">
                  <div class="card-header p-3  text-start">
                    <div class="d-flex justify-content-between align-items-center ignore-pmargin">
                        <h4 class="fw-bold mx-2 ">Sincronización y mantenimiento</h4>
                    </div>
                  </div>                  
                  <div v-if="cargandoImportacion || cargandoSincronizacionHuella || cargandoSincronizacionTrazados || cargandoActualizacionGescal" class="alert alert-warning d-flex align-items-center mx-auto mt-4 mb-0" role="alert">
                    <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                    <div class="d-flex flex-column text-start">
                      <div class="text-danger">
                        El proceso podría tardar varios minutos...
                      </div>  
                    </div>
                  </div>
                  <div class="bard-body row noselect justify-content-center align-items-center h-100 m-3 overflow-auto">

                      <!-- Importar operaciones externas -->
                        <div class="acceso-item my-2 col-12 col-sm-6 col-md-4" @click="forzarImportacion()">
                          <b-overlay :show="cargandoImportacion" class="w-100 h-100 d-flex align-items-center justify-content-center">

                    
                            <div class="card shadow bg-light" title="Importar opreciones externas">
                                <div class="card-body acceso-content">
                                  <div class="d-flex flex-column justify-content-between align-items-center h-100">
                                    <!-- Icono -->
                                    <div class="d-flex justify-content-center align-items-center w-100 h-50">
                                      <i class="fa-solid fa-4x fa-cloud-upload-alt text-dark"></i>  
                                    </div>
                                    <!-- Titulo -->
                                    <span class="text-secondary fs-5">Importar operaciones externas</span>
                                  </div>
                                </div>
                            </div>
                          </b-overlay>
                        </div>
                    
                        <!-- Sincronizar huella -->
                        <div class="acceso-item my-2 col-12 col-sm-6 col-md-4" @click="sincronizar_huella()">
                          <b-overlay :show="cargandoSincronizacionHuella" class="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div class="card shadow bg-light" title="Sincroniza la huella para que esté alineada con la del orquestador">
                                <div class="card-body acceso-content">
                                  <div class="d-flex flex-column justify-content-between align-items-center h-100">
                                    <!-- Icono -->
                                    <div class="d-flex justify-content-center align-items-center w-100 h-50">
                                      <i class="fa-solid fa-4x fa-house-signal text-dark"></i>  
                                    </div>
                                    <!-- Titulo -->
                                    <span class="text-secondary fs-5">Sincronizar huella</span>
                                  </div>
                                </div>
                            </div>
                          </b-overlay>                          
                        </div>
                        <!-- Sincronizar trazados -->
                        <div class="acceso-item my-2 col-12 col-sm-6 col-md-4" @click="sincronizar_trazados()">
                          <b-overlay :show="cargandoSincronizacionTrazados" class="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div class="card shadow bg-light" title="Sincronizar trazados para que estén alineados con el orquestador">
                                <div class="card-body acceso-content">
                                  <div class="d-flex flex-column justify-content-between align-items-center h-100">
                                    <!-- Icono -->
                                    <div class="d-flex justify-content-center align-items-center w-100 h-50">
                                      <i class="fa-solid fa-4x fa-sitemap text-dark"></i>  
                                    </div>
                                    <!-- Titulo -->
                                    <span class="text-secondary fs-5">Sincronizar trazados</span>
                                  </div>
                                </div>
                            </div>
                          </b-overlay>                              
                        </div>                                                
                        <!-- Actualizar GESCAL_37 -->
                        <div class="acceso-item my-2 col-12 col-sm-6 col-md-4" @click="actualizar_gescal_37()">
                          <b-overlay :show="cargandoActualizacionGescal" class="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div class="card shadow bg-light" title="Actualiza la GESCAL_37 de todos los domicilios que contienen ID_TECNICO">
                                <div class="card-body acceso-content">
                                  <div class="d-flex flex-column justify-content-between align-items-center h-100">
                                    <!-- Icono -->
                                    <div class="d-flex justify-content-center align-items-center w-100 h-50">
                                      <i class="fa-solid fa-4x fa-barcode text-dark"></i>  
                                    </div>
                                    <!-- Titulo -->
                                    <span class="text-secondary fs-5">Actualizar GESCAL_37</span>
                                  </div>
                                </div>
                            </div>
                          </b-overlay>                              
                        </div>      
                        <!-- Migración Masiva -->
                        <router-link class="acceso-item my-2 col-12 col-sm-6 col-md-4 text-decoration-none text-light li-link animated" :to="{ 'name': 'MigracionMasiva' }">
                            <div class="card shadow bg-light" title="Migración masiva de clientes">
                                <div class="card-body acceso-content">
                                  <div class="d-flex flex-column justify-content-between align-items-center h-100">
                                    <!-- Icono -->
                                    <div class="d-flex justify-content-center align-items-center w-100 h-50">
                                      <i class="fa-solid fa-4x fa-file-import text-dark"></i>  
                                    </div>
                                    <!-- Titulo -->
                                    <span class="text-secondary fs-5">Migración masiva</span>
                                  </div>
                                </div>
                            </div>
                        </router-link>                                                    
                  </div>
                </div>
              </div>
                              
              <!-- Servicios no facturables -->
              <ServiciosNoFacturables></ServiciosNoFacturables>
       
              <div v-if="es_super_admin()">
                  <!-- Servicios descartables -->
                  <ServiciosDescartables></ServiciosDescartables>       
              </div>

            </div>
            


          </div>

      </div>

      
  </div>
</template>

<script>

import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert';
import Swal from "sweetalert2";
import axios from "axios";
import ServiciosNoFacturables from "@/components/ServiciosNoFacturables"
import ServiciosDescartables from "@/components/ServiciosDescartables"
import { isSuperAdmin } from '../helpers/JWT';

export default {
  name: 'PanelAdmin',
  components: {
    ServiciosNoFacturables,
    ServiciosDescartables
  },
  data() {
    return {
      cargandoImportacion: false,
      cargandoSincronizacionHuella: false,
      cargandoSincronizacionTrazados: false,
      cargandoActualizacionGescal: false,
    } 
  },

  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Panel de administración', 'En esta página se podrá realizar tareas de administración, como <strong>forzar la importación de servicios externos</strong>, <strong>forzar la sincronización de huella</strong> o <strong>forzar la sincronización de trazados</strong>.')
    },   
    async forzarImportacion(){
      try {
        if(this.cargandoSincronizacionHuella || this.cargandoSincronizacionTrazados || this.cargandoImportacion) {
          errorAlert("No puedes hacer eso", "Debes de esperar a que terminen los otros procesos...")
          return;
        };
        let resultado = await Swal.fire({
            title: 'Importar operaciones externas',
            html: `Diariamente se importan los registros provenientes de sistemas externos como <strong>Golix</strong> o cualquier plataforma ajena al flujo de trabajo de Pandora.<br> 
                  Esto se refleja en el <strong>historial/reportes</strong> con altas, bajas y cambios perfil de sistemas externos además de sus <strong>servicios</strong>. <br> 
                  Puede forzar la importación para sincronizar los clientes y operaciones de sistemas externos.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText:  'Continuar',
            cancelButtonText:   'Cancelar',
        });
        if(!resultado.isConfirmed) return;
        this.cargandoImportacion = true;
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/importador',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') },
          timeout: 600000,
        });
        successAlert('Operación completada', respuestaPandora.data.msg);
        this.cargandoImportacion = false;
      } catch (error) {
        this.cargandoImportacion = false;
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          errorAlert("Error en la operación", error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          noResponseAlert()
        } else {
          // Something happened in setting up the request that triggered an Error
          serverErrorAlert(error.message)
        }
      }
    },     
   async sincronizar_huella(){
      try {
        if(this.cargandoSincronizacionHuella || this.cargandoSincronizacionTrazados || this.cargandoImportacion) {
          errorAlert("No puedes hacer eso", "Debes de esperar a que terminen los otros procesos...")
          return;
        };
        let resultado = await Swal.fire({
            title: 'Sincronizar huella',
            html: `Diariamente se sincroniza la huella que hay en el orquestador con la de nuestra base de datos para asegurarnos de tener siempre los mismos datos. <br><br>Si se ha realizado algún cambio se puede iniciar una sincronización manual para actualizar la huella.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText:  'Continuar',
            cancelButtonText:   'Cancelar',
        });
        if(!resultado.isConfirmed) return;
        this.cargandoSincronizacionHuella = true;
        let respuestaPandora = await axios({
          method: 'PUT',
          url: '/hogares/sincronizar',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') },
          timeout: 600000,
        });
        console.log(respuestaPandora)
        successAlert('Operación completada', respuestaPandora.data.msg, respuestaPandora.data.data);
        this.cargandoSincronizacionHuella = false;
      } catch (error) {
        this.cargandoSincronizacionHuella = false;
        console.log(error);
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          errorAlert("Error en la operación", error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          noResponseAlert()
        } else {
          // Something happened in setting up the request that triggered an Error
          serverErrorAlert(error.message)
        }
      }
    }, 
   async sincronizar_trazados(){
      try {
        if(this.cargandoSincronizacionHuella || this.cargandoSincronizacionTrazados || this.cargandoImportacion) {
          errorAlert("No puedes hacer eso", "Debes de esperar a que terminen los otros procesos...")
          return;
        };
        let resultado = await Swal.fire({
            title: 'Sincronizar trazados',
            html: `Diariamente se sincroniza los trazados que hay en el orquestador con los de nuestra base de datos para asegurarnos de tener siempre los mismos datos. <br><br>Si se ha realizado algún cambio se puede iniciar una sincronización manual para actualizar los trazados.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText:  'Continuar',
            cancelButtonText:   'Cancelar',
        });
        if(!resultado.isConfirmed) return;
        this.cargandoSincronizacionTrazados = true;
        let respuestaPandora = await axios({
          method: 'PUT',
          url: '/trazados/sincronizar',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') },
          timeout: 600000,
        });
        console.log(respuestaPandora)
        successAlert('Operación completada', respuestaPandora.data.msg, respuestaPandora.data.data);
        this.cargandoSincronizacionTrazados = false;
      } catch (error) {
        this.cargandoSincronizacionTrazados = false;
        console.log(error);
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          errorAlert("Error en la operación", error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          noResponseAlert()
        } else {
          // Something happened in setting up the request that triggered an Error
          serverErrorAlert(error.message)
        }
      }
    },   
   async actualizar_gescal_37(){
      try {
        if(this.cargandoActualizacionGescal) return;
        this.cargandoActualizacionGescal = true;
        let respuestaPandora = await axios({
          method: 'PUT',
          url: '/hogares/gescal_37',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') },
          timeout: 600000,
        });
        console.log(respuestaPandora)
        successAlert('Operación completada', respuestaPandora.data.msg, respuestaPandora.data.data);
        this.cargandoActualizacionGescal = false;
      } catch (error) {
        this.cargandoActualizacionGescal = false;
        console.log(error);
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          errorAlert("Error en la operación", error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          noResponseAlert()
        } else {
          // Something happened in setting up the request that triggered an Error
          serverErrorAlert(error.message)
        }
      }
    },       
    es_super_admin(){
      return isSuperAdmin()
    }           
  },
  created(){
  }
}
</script>

<style scoped>
.alert{
  width: 95%;
}
.acceso-item{
  cursor: pointer;
  height: 11rem;
  max-width: 30rem;
  min-width: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acceso-item .card{
  width: 100%;
  height: 100%;
  margin-top: .25rem;
}
.acceso-item .card:hover{
  margin-top: 0;
}
.acceso-item .card:active{
  width: 97%;
  height: 97%;
}
.lista-accesos-card{
  max-width: 120rem;
}

.ul-group .li-link{
  background: var(--dark);
  transition: all .15s ease-in;
  border-radius: 5px !important;
  opacity: 1;
}


.ul-group .li-link:hover {
  background: hsla(0,0%,100%,.13);
}

.ul-group .li-link.active{
  background: hsla(0,0%,100%,.23);
}

</style>
