<template>

    <div class="card page-card border-0 shadow-lg my-5">

      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Migración masiva</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
        </div>
      </div>
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline">
            <h4>Cola de migración </h4>
          </div>
          <div class="d-flex justify-content-center my-4">
            <input id="csvfile" name="csvfile" type="file" accept=".csv" ref="csvInput" @input="cargarCSV()" hidden>
            <div class="d-flex justify-content-between">
              <button for="csvfile" type="button" class="btn fw-bold mx-2 btn-primary accion-btn" :disabled="cargandoFichero || algoritmoEnEjecucion" @click="clickInput()" >
                <i v-if="!cargandoFichero" type="button" class="fa-solid fa-file-import fa-1x mx-1" title="Importar csv"></i>
                <div v-else class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Importar cola
              </button>
            </div>

            <button type="button" class="btn fw-bold mx-2 btn-danger accion-btn" :disabled="cargandoFichero || algoritmoEnEjecucion || colaMigracion.length < 1" @click="borrarCola()">
                <i type="button" class="fa-solid fa-trash-alt fa-1x mx-1" title="Borrar cola"></i>
                Borrar cola
            </button>
          </div>
          
          <div class="m-2">
              <div v-if="colaMigracion.length">
                    <!-- Cola de migración -->
                    <div class="m-2">                            
                      <div class="table-responsive tabla-cola">
                        <table class="table table-bordered text-start small-font">
                          <thead>
                            <tr>
                              <th scope="col">ID_ADMINISTRATIVO</th>
                              <th scope="col">ID_DOMICILIO</th>
                              <th scope="col">TEMPLATE</th>
                              <th scope="col">FECHA_CREACION</th>
                              <th scope="col">REMOTE_ID</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(registro, index) in colaMigracion" :key="index" :class="[ rowColor(registro.ESTADO) ]">
                                <td>
                                  {{registro.ID_ADMINISTRATIVO}}
                                </td>
                                <td>
                                  {{registro.ID_DOMICILIO}}
                                </td>
                                <td>
                                  {{registro.TEMPLATE}}
                                </td>
                                <td>
                                  {{registro.FECHA_CREACION}}
                                </td>                                
                                <td>
                                  {{registro.REMOTE_ID}}
                                </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- Resumen cola -->
                    <div class="m-2">                            
                      <div class="table-responsive m-auto">
                        <table class="table table-bordered text-start small-font">
                          <thead>
                            <tr>
                              <th scope="col">Total</th>
                              <th scope="col">Migrados</th>
                              <th scope="col">Por migrar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                                <td>
                                  {{colaMigracion.length}}
                                </td>
                                <td>
                                  {{numCompletados}}
                                </td>
                                <td>
                                  {{numFallidos + numPendientes}}
                                </td>                          
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-if="colaMigracion && colaMigracion.length">
                          <button :disabled="algoritmoEnEjecucion || cargandoFichero" @click="descargarColaMigracionCSV" class="btn btn-primary">Descargar CSV</button>
                    </div>
              </div>
              <!-- Error en cola -->
              <div v-else-if="colaMigracion.length < 1 && !cargandoFichero" class="alert alert-warning d-flex align-items-center" role="alert">
                <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                <div class="d-flex flex-column text-start">
                  <div v-if="errorCola" class="text-danger">
                    {{errorCola}}
                  </div>  
                  <div v-if="colaMigracion.length < 1" class="text-danger">
                      La cola de migración está vacía
                  </div>     
                </div>
              </div>
          </div>


        </div>
      </div>


      <div v-if="colaMigracion.length" class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Comenzar migración</h4>
            <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoEjecutar"></i>
          </div>

            <!-- Algoritmo -->
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                  <div class="row gap-2">

                  <!-- OPERADOR -->
                  <div class="col-12">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end">
                            <label for="OPERADOR" class="line mx-1 fs-5 d-flex align-items-center">
                              Operador
                            </label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERADOR" class="form-control  m-0" maxlength="128" v-model="id_operador" :disabled="algoritmoEnEjecucion">                                
                                  <option value="" selected>Sin especificar</option>
                                    <option v-for="(item, index) in operadores" :key="index" :value="item.id_operador">{{item.operador}} - {{item.id_operador}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>

                  <!-- FECHA MÍNIMA -->
                  <div class="col-12">
                    <div class="row d-flex align-items-center">
                      <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-end flex-column">
                        <label for="FECHA_MINIMA" class="line mx-1 fs-5 d-flex align-items-center">Fecha mínima</label>
                        <small class="text-muted">(Opcional)</small>
                      </div>
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                        <div class="row d-flex flex-row">
                          <div class="col-10">
                            <input type="date" id="FECHA_MINIMA" class="form-control m-0" v-model="fecha_minima" :disabled="algoritmoEnEjecucion">
                          </div>
                          <div class="col-2 px-0 text-start align-self-center">
                            <i class="fa-solid fa-circle-question clickable fs-5" @click="infoFecha()"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <hr class="mt-4">
                  <!-- Carga -->
                  <div v-if="algoritmoEnEjecucion">
                    <Carga :show="algoritmoEnEjecucion" :fullscreen="false" class="logocarga"></Carga>
                    
                    <!-- <span class="badge bg-secondary font-italic fit-content m-auto fs-6 my-3">
                      {{registroActual}} de {{colaMigracion.length}} ➜ {{EstadoCarga}}               
                      <div class="spinner-border spinner-border-sm" role="status"></div>
                    </span>
                    <div v-if="algoritmoEnEjecucion" class="progress barra-progreso">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="porcentajeCompletado" aria-valuemin="0" aria-valuemax="100" :style="{ width: porcentajeCompletado + '%' }">{{porcentajeCompletado + '%'}}</div>
                    </div> -->
                  </div>

                   <div class="d-flex justify-content-center">
                      <button v-if="!algoritmoEnEjecucion" type="button" :disabled="$v.$invalid || cargandoFichero || colaMigracion.length < 1 || algoritmoEnEjecucion || (!numPendientes && !numFallidos)" class="btn btn-primary form-btn mx-2" @click="ejecutarAlgoritmo()">
                        <i class="fa-solid fa-rocket mx-1"></i>
                        Migrar
                      </button>
                    </div>	

                  </div>
                </form>
                 
            </div>
        </div>
      </div>
      <div v-if="colaMigracion.length && colaMigracion.length!=numPendientes" class="m-3 card shadow">
        <div class="card-body"> 
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Resultados</h4>
          </div>
              <!-- Resumen -->
              <div class="m-2">                            
                <div class="table-responsive fit-content m-auto">
                  <table class="table table-bordered text-start">
                    <thead>
                      <tr>
                        <th scope="col">Pendientes</th>
                        <th scope="col">Completados</th>
                        <th scope="col">Fallidos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td class="table-light">
                            {{numPendientes}}
                          </td>
                          <td class="table-success">
                            {{numCompletados}}
                          </td>
                          <td class="table-danger">
                            {{numFallidos}}
                          </td>                          
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
             
          
          <!-- Fallidos -->
          <div v-if="numFallidos">
            <div class="d-flex align-items-baseline justify-content-start">
              <h4>Migraciones fallidas</h4>
            </div>
            <div class="m-2">
              <div v-if="colaMigracion.length">
                    <div class="m-2">                            
                      <!-- Tabla resultado test -->
                      <div class="table-responsive tabla-cola">
                        <table class="table table-bordered text-start small-font">
                          <!-- Cabeceras tabla -->
                          <thead>
                            <tr>
                              <th scope="col">ID_ADMINISTRATIVO</th>
                              <th scope="col">ID_DOMICILIO</th>
                              <th scope="col">REMOTE_ID</th>
                              <th scope="col">Causa</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(registro, index) in colaFallida" :key="index" class="table-danger">
                                <td>
                                  {{registro.ID_ADMINISTRATIVO}}
                                </td>
                                <td>
                                  {{registro.ID_DOMICILIO}}
                                </td>
                                <td>
                                  {{registro.REMOTE_ID}}
                                </td>                                                                
                                <td>
                                  {{registro.ERROR}}
                                </td>                        
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert';
import { sleep, readUploadedFileAsText, montarNombreCalle } from '../helpers/utils';
import { desconectar, verificarJWT } from '../helpers/JWT';
import axios from "axios";
import Swal from "sweetalert2";
import { required, maxLength } from 'vuelidate/lib/validators';
import Carga from '../components/Carga';
import { registrarEnHistorial, registrarOperacionAdmin } from '../helpers/registrarEnHistorial';

export default {
  name: 'MigracionMasiva',
  components: {
    Carga
  },
  data() {
    return {
      cargandoFichero: false,
      errorCola: '',
      colaMigracion: [],
      id_operador: '',
      fecha_minima: '',
      algoritmoEnEjecucion: false,
      operadores: [],
    } 
  },
  //Validaciones
  validations: {
      id_operador: {
        required,
        maxLength: maxLength(5)
      }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Migración masiva', 'La migración masiva consiste en automatizar la migración de clientes de un operador nuevo. <br><br> La herramienta guardará una lista de servicios en una cola de migración y comenzará un proceso en el que los guardará en nuestros registros uno a uno.')
    },

    infoID(){
      infoAlert('ID de operador', 'El id de operador que tendrán los nuevos servicios. Es un número identificativo del operador, por ejemplo <strong>301</strong>.');
    },  
    infoEjecutar(){
      infoAlert('Comenzar migración', 'Una vez comience el proceso de migración, empezará a darse de alta cada uno de los servicios en la cola de migración uno a uno.');
    },
    infoFecha(){
      infoAlert('Fecha mínima', `Si establece una fecha mínima para los clientes, estos se migrarán y su fecha de creación no podrá ser inferior a la fecha mínima seleccionada. 
      <br>Esto es util en situaciones en las que se migra una cantidad determinada de clientes a partir de una fecha y la facturación tiene que ser a partir de esta.`);
    },    

    infoResultado(){
      let fallidosText = this.numFallidos ? '<br>Mira la sección de <strong>Altas fallidas</strong> para más información.' : '';
      Swal.fire({
        title: "Ejecución completada",
        html: `
            <table class="table table-bordered text-start">
              <thead>
                <tr>
                  <th scope="col">Pendientes</th>
                  <th scope="col">Completados</th>
                  <th scope="col">Fallidos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td class="table-light">
                      ${this.numPendientes}
                    </td>
                    <td class="table-success">
                      ${this.numCompletados}
                    </td>
                    <td class="table-danger">
                      ${this.numFallidos}
                    </td>                          
                </tr>
              </tbody>
            </table>
            ${fallidosText}`,
        icon: 'info',
        confirmButtonText: 'Vale'
     })
    },
    infoFormatoGeneral(title, subtitle, icon = 'error'){
        Swal.fire({
            title: title,
            html: 
            `
              <span>${subtitle}</span>
              <br> <br>
              <span>El formato del fichero debe de seguir la siguiente estructura: </span>
              <div class="table-responsive">
              <table class="table table-striped text-start small-font">
                <thead>    
                  <tr>
                    <th class="w-auto" scope="col">ID_ADMINISTRATIVO</th>
                    <th class="w-auto" scope="col">ID_DOMICILIO</th>
                    <th class="w-auto" scope="col">TEMPLATE</th>
                    <th class="w-auto" scope="col">FECHA_CREACION</th>
                    <th class="w-auto" scope="col">REMOTE_ID</th>
                  </tr>            
                </thead>
                <tbody>
                    <tr>
                      <td> ID_ADM1 </td>
                      <td> ID_DOM1 </td>
                      <td> TEMPLT1 </td>
                      <td> FECHA1 </td>
                      <td> RMT_ID1 </td>
                    </tr>                  
                    <tr>
                      <td> ID_ADM2 </td>
                      <td> ID_DOM2 </td>
                      <td> TEMPLT2 </td>
                      <td> FECHA2 </td>
                      <td> RMT_ID2 </td>
                    </tr>
                    <tr>
                      <td> ID_ADM3 </td>
                      <td> ID_DOM3 </td>
                      <td> TEMPLT3 </td>
                      <td> FECHA3 </td>
                      <td> RMT_ID3 </td>
                    </tr>                                                                    
                </tbody>
              </table>
            </div>
            `,
          icon: icon,
          confirmButtonText: 'Vale'
        });
    },
    async clickInput(){
        Swal.fire({
                title: 'Crear nueva cola de migración',
                html: 
                `
                  <div class="text-start">
                  <span>Necesitarás cargar un archivo CSV con los servicios que se quieren migrar. El CSV normalmente se obtiene al exportar un Excel u hoja de cálculo.</span>
                  <br><br>
                    <span>Deberá contener las siguientes columnas: </span>
                    <ul>
                      <li><strong>ID_ADMINISTRATIVO: </strong> El identificador del cliente</li>
                      <li><strong>ID_DOMICILIO: </strong> El identificador del hogar</li>
                      <li><strong>TEMPLATE: </strong> El perfil de velocidad</li>
                      <li><strong>FECHA_CREACION: </strong> La fecha cuando se creó el cliente</li>
                      <li><strong>REMOTE_ID: </strong> El número de serie de la ONT</li>
                    </ul>
                  <br>
                  <span>Estructura fichero: </span>
                  </div>
                  <div class="table-responsive">
                  <table class="table table-striped text-start small-font">
                    <thead>    
                      <tr>
                        <th class="w-auto" scope="col">ID_ADMINISTRATIVO</th>
                        <th class="w-auto" scope="col">ID_DOMICILIO</th>
                        <th class="w-auto" scope="col">TEMPLATE</th>
                        <th class="w-auto" scope="col">FECHA_CREACION</th>
                        <th class="w-auto" scope="col">REMOTE_ID</th>
                      </tr>            
                    </thead>
                    <tbody>
                      <tr>
                        <td> ID_ADM1 </td>
                        <td> ID_DOM1 </td>
                        <td> TEMPLT1 </td>
                        <td> FECHA1 </td>
                        <td> RMT_ID1 </td>
                      </tr>                  
                      <tr>
                        <td> ID_ADM2 </td>
                        <td> ID_DOM2 </td>
                        <td> TEMPLT2 </td>
                        <td> FECHA2 </td>
                        <td> RMT_ID2 </td>
                      </tr>
                      <tr>
                        <td> ID_ADM3 </td>
                        <td> ID_DOM3 </td>
                        <td> TEMPLT3 </td>
                        <td> FECHA3 </td>
                        <td> RMT_ID3 </td>
                      </tr>                                                                                  
                    </tbody>
                  </table>
                </div>

                <small class="text-danger align-self-center">¡Cargar un nuevo fichero eliminará la cola anterior!</small>
                `,
              icon: 'info',
              confirmButtonText: 'Cargar archivo',
              showCancelButton: true,
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar'
        }).then( result => {
          if(result.isConfirmed && this.$refs.csvInput){
              this.$refs.csvInput.click();
          }
        });
      
    },
    async cargarCSV(){
      try{
        this.cargandoFichero = true;
        let file = this.$refs.csvInput.files[0];
        if(file){
          let chunk = await readUploadedFileAsText(file);
          let lines = chunk.split(/\r?\n/);
          if(lines.length < 1){
            this.cargandoFichero = false;
            this.infoFormatoGeneral("¡No hay suficientes datos!", "No se ha encontrado ningún registro en tu fichero CSV.");
            return;
          }
          for (let index = 0; index < lines.length; index++) {
            lines[index] = lines[index].split(',');
            if(lines[index].length != 5)
            {
              this.cargandoFichero = false;
              this.infoFormatoGeneral("¡Formato CSV incorrecto!", "El número de columnas del archivo CSV no es correcto.");
              return;
            }
            if(lines[index].includes('') || lines[index].includes(undefined))
            {
              this.cargandoFichero = false;
              this.infoFormatoGeneral("¡Formato CSV incorrecto!", "Alguna columna tiene valores vacíos.");
              return;
            }
          }
          let response = await axios({
            method: 'POST',
            url: '/migrar/cola',
            data: {lines: lines},
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.cargarCola();
        };
        

      } catch (error) {
        console.log('err', error);
        
          this.cargandoFichero = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            errorAlert(error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            errorAlert('Ocurrió un error', error.message || error);
          }
      } finally {
        this.$refs.csvInput.value = '';
      }
    },
    descargarColaMigracionCSV() {
      // Crear el contenido CSV
      const contenidoCSV = this.convertirACSV(this.colaMigracion);

      // Crear un enlace para descargar el archivo CSV
      const enlaceDescarga = document.createElement('a');
      enlaceDescarga.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(contenidoCSV));
      enlaceDescarga.setAttribute('download', 'migracion.csv');
      document.body.appendChild(enlaceDescarga);

      // Hacer clic en el enlace para iniciar la descarga
      enlaceDescarga.click();

      // Eliminar el enlace después de la descarga
      document.body.removeChild(enlaceDescarga);
    },
    convertirACSV(data) {
      // Encabezados de las columnas del CSV
      let csv = 'ID_ADMINISTRATIVO,ID_DOMICILIO,TEMPLATE,REMOTE_ID,FECHA_CREACION,ESTADO,ERROR\n';

      // Convertir cada objeto en una fila del CSV
      data.forEach(item => {
        // Formatear la fecha si está presente
        let fechaCreacion = item.FECHA_CREACION ? `"${item.FECHA_CREACION}"` : '';
        // Escapar las comillas dobles dentro de los valores
        let error = item.ERROR.replace(/"/g, '""');
        // Construir la fila del CSV
        csv += `${item.ID_ADMINISTRATIVO};${item.ID_DOMICILIO};${item.TEMPLATE};${item.REMOTE_ID};${fechaCreacion};${item.ESTADO};${error}\n`;
      });

      return csv;
    },
    async cargarCola(){
        try{
          this.colaMigracion = []
          this.cargandoFichero = true;
            let response = await axios({
                method: 'GET',
                url: '/migrar',
                headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
              });
              this.colaMigracion = response.data.data.listaRegistros;
              this.cargandoFichero = false;
        } catch (error) {
            this.cargandoFichero = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log("error controlado", error.response);
              this.errorCola = error.response.data.msg;
              return;
            } else if (error.request) {
              // The request was made but no response was received
              this.errorCola = error.request;
              return;
            } else {
              // Something happened in setting up the request that triggered an Error
               this.errorCola = error.message || error;
              return;
            }
        }
    },
    async borrarCola(){
      try{
        let result = await Swal.fire({
          title: '¿Está seguro?',
            html: `Si borra la cola de migración se perderán todos los datos incluso si el proceso está a medias de completar.`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
          })

          if(!result.isConfirmed) return;
          this.cargandoFichero = true;
          
          let response = await axios({
              method: 'DELETE',
              url: '/migrar/cola',
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
            successAlert('Proceso completado', response.msg || 'Se ha borrado la cola de migración');
            this.cargarCola();
      } catch (error) {
          this.cargandoFichero = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            errorAlert(error.response.data.msg);
            return;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
            return;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
            return;
          }
      }
    },
    async getOperadores(){
        try {
          let respuesta = await axios.get('/operadores', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.operadores = respuesta.data.data.listaOperadores;          
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    async ejecutarAlgoritmo(){ 
      try{
          //Confirmación
          let resultado = await Swal.fire({
              title: '¿Está seguro?',
              html: `¡Se migrará <strong>${(this.numPendientes + this.numFallidos) > 1 ? (this.numPendientes + this.numFallidos) + ' clientes' : '1 cliente'}</strong>!`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText:  'Continuar',
              cancelButtonText:   'Cancelar',
          });
          if(!resultado.isConfirmed) return;

          //Si no hay pendientes o fallidos no hay nada que hacer
          if(!this.numPendientes && !this.numFallidos) return;
          this.algoritmoEnEjecucion = true;

            let response = await axios({
              method: 'POST',
              url: '/migrar/migrar',
              params: {
                id_operador: this.id_operador,
                fecha_minima: this.fecha_minima
              },
              data: {
                
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });

          await this.cargarCola()
          this.infoResultado();
          this.algoritmoEnEjecucion = false;
        } catch (error) {
            this.cargandoFichero = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log("error controlado", error.response);
              errorAlert(error.response.data.msg);
            } else if (error.request) {
              // The request was made but no response was received
              noResponseAlert(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              serverErrorAlert(error.message || error)
            }
        }
    },
  },
  created() {
    this.cargarCola();
    this.getOperadores();
  },
  computed: {
    rowColor(){
      return (estado) => {
        switch (estado) {
          case 'completado':
              return 'table-success'
          case 'fallido':
              return 'table-danger'
          default:
              return 'table-light'
        }
      }
    },
    numPendientes(){
      return this.colaMigracion.filter(item => item.ESTADO === 'pendiente').length
    },
    numCompletados(){
      return this.colaMigracion.filter(item => item.ESTADO === 'completado').length
    },
    numFallidos(){
      return this.colaMigracion.filter(item => item.ESTADO === 'fallido').length
    },
    colaFallida(){
      return this.colaMigracion.filter(item => item.ESTADO === 'fallido')
    }
  }
}
</script>

<style scoped>
.logocarga{
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.barra-progreso{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding: 0;
  width: 80%;
}
.tabla-cola{
  max-height: 20rem;
}
</style>
