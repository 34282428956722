<template>
            <form @submit.prevent="cambiarRoles()" novalidate>
              <div class="d-flex align-items-center justify-items-center my-3  px-0 flex-column flex-sm-row container-fluid">

                  <ul class="list-group list-group-horizontal-lg m-auto pe-0">
                    <li v-for="(rol, index) in rolesDisponibles" :key="index" data-bs-toggle="tooltip" data-bs-placement="top" :title="rol.descripcion" class="list-group-item" data-bs-trigger='hover'>
                      <div class="d-flex justify-content-center align-items-center">
                        <label :for="'rol'+rol.rol" class="form-check-label noselect m-1" :class="{ 'text-muted' : rol.disabled }">{{rol.nombre}}</label>
                        <input :id="'rol'+rol.rol" :disabled="rol.disabled" :checked="rol.activo || rol.disabled" type="checkbox" class="form-check-input m-1" @change="rolesCambiados(rol)">
                      </div>
                    </li>
                  </ul>
              </div>
                <hr v-if="!HiddenRequest" class="mt-4">
                <div v-if="!HiddenRequest" class="d-flex justify-content-center">
                  <button :disabled="cargandoPeticion" type="button" class="btn btn-danger form-btn mx-2" @click="volver()">Cancelar</button>
                  <button :disabled="cargandoPeticion || !rolesElegidos.length" type="submit" class="btn btn-primary form-btn mx-2">
                    <i v-if="!cargandoPeticion" class="fa-solid fa-save mx-2"></i>
                    <div v-else class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Guardar
                  </button>
                </div>		
            </form>
            
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, successAlert } from '../helpers/fastAlert';

import axios from "axios";
import { verificarJWT, desconectar, isSuperAdmin } from '../helpers/JWT';
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';
import { Tooltip } from "bootstrap";
/*

  Componente que permite asignar roles a un usuario, hay que pasarle como propiedad el usuario objetivo
  y tiene una propiedad para ocultar el botón para confirmar los cambios si se quiere gestionar automaticamente
  desde el componente padre

*/
export default {
  name: 'Roles',
  props: ['HiddenRequest', 'usuario'],
  data() {
    return {
      rolesDisponibles: [],
      cargandoRoles: false,
      cargandoPeticion: false,
      rolesElegidos: []
    } 
  },
  methods: {
    //Botones informativo
    volver(){
      localStorage.setItem('focusedOperator', this.usuario.id_operador);
      isSuperAdmin() ? this.$router.push({name: 'Operadores'}) : this.$router.push({name: 'UsuariosOperador'})
    },
    rolesCambiados(rolCambiado){
      rolCambiado.activo = !rolCambiado.activo;
      this.manageRoles();
      this.rolesElegidos = this.rolesDisponibles.filter(e => e.activo);

    },
    manageRoles(){
      this.checkBoxReset();
      for (const iterator of this.rolesDisponibles) {
        switch (iterator.rol) {
          case 'SUPERADMINISTRADOR':
            if(iterator.activo){
              this.adminActivo();
            }
            break;
          case 'INTERNOASTEO':
            if(iterator.activo){
              this.internoActivo();
            }
            break;
          case 'OPERADORADMIN':
            if(iterator.activo){
              this.operadorAdminActivo();
            }
            break;
          default:
            break;
        }
      }
      this.$forceUpdate();
    },
    checkBoxReset(){
      for (var iterator of this.rolesDisponibles) {
        if(iterator.disabled){
          iterator.activo = false;
          iterator.disabled = false;
        }
      }
    },
    adminActivo(){
      for (const iterator of this.rolesDisponibles) {
        if(iterator.rol != 'SUPERADMINISTRADOR'){
          iterator.activo = false;
          iterator.disabled = true;
        }
      }
    },
    internoActivo(){
      for (const iterator of this.rolesDisponibles) {
        if(!(iterator.rol == 'SUPERADMINISTRADOR' || iterator.rol == 'INTERNOASTEO' || iterator.rol == 'CREADORINFLUENCIA' || iterator.rol == 'EDITORINFLUENCIA')){
          iterator.activo = false;
          iterator.disabled = true;
        }
      }
    },
    operadorAdminActivo(){   
      for (const iterator of this.rolesDisponibles) {
        if(!(iterator.rol == 'SUPERADMINISTRADOR' || iterator.rol == 'INTERNOASTEO' || iterator.rol == 'OPERADORADMIN' ||  iterator.rol == 'CREADORINFLUENCIA' || iterator.rol == 'EDITORINFLUENCIA')){
          iterator.activo = false;
          iterator.disabled = true;
        }
      }
    },
    async getRolesDisponibles(){
      try {
        this.cargandoRoles = true;
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/roles',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.rolesDisponibles = respuestaPandora.data.data.roles;
        for (const iterator of this.rolesDisponibles) {
          iterator.activo = this.usuario.roles.some(e => e.rol == iterator.rol)
          iterator.disabled = false;
        }
        this.manageRoles();
        setTimeout(() => {          
          var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        }, 500);
        this.cargandoRoles = false;
      } catch (error) {
        this.cargandoRoles = false;
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }

    },
    async cambiarRoles(usuarioNuevo = undefined){
      try {
        this.cargandoPeticion = true;
        let rolesfiltrados = this.rolesElegidos.map(e => e.uid)
        if((!this.usuario && !usuarioNuevo) || !rolesfiltrados || !rolesfiltrados.length) throw 'Los datos no son válidos'
        let respuestaPandora = await axios({
          method: 'POST',
          url: '/roles',
          params: {
            usuario: usuarioNuevo || this.usuario.user
          },
          data:{
            roles: this.rolesDisponibles.filter(e => e.activo).map(e => e.uid)
          },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        if(!this.HiddenRequest){
          successAlert('Proceso completado', respuestaPandora.data.msg);

          this.volver();
        }
         this.cargandoPeticion = false;
      } catch (error) {
         this.cargandoPeticion = false;
         if (error.response && error.response.data && error.response.data.msg) {
          errorAlert('No se pudieron asignar los roles', error.response.data.msg)
        } else if (error.request) {
          errorAlert('Error interno', error.request)
        } else {
          errorAlert('Error asignando roles', error.message || error)
        }
      }
    }
  },
  created(){
    this.getRolesDisponibles();
  },
  mounted() {

  },
}
</script>

<style scoped>


 


</style>
