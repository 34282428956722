import Swal from "sweetalert2";

/////////////
//fastAlert//
/////////////

/*
 Métodos reutilizables que utilizan las librerías de Sweet Alert 2
 para mostrar alertas genéricas por pantalla.
*/


/*
Muestra alerta de ERROR
@title(String) - Titulo de la alerta ('Ops...' por defecto) 
@msg(String)   - Texto a mostrar en la alerta
*/
export function errorAlert(title = 'Ops...', msg) {
    Swal.fire({
        icon: 'error',
        title,
        html: msg || 'Error en la operación',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale'
    });
}

/*
Muestra alerta que indica que el servidor no respondió
*/
export function noResponseAlert() {
    let icon = 'error'
    Swal.fire({
        icon: icon,
        title: 'No se obtuvo ninguna respuesta del servidor',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale'
    });
}

/*
Muestra alerta de ERROR DE SERVIDOR
@msg(String) - Texto a mostrar en la alerta
*/
export function serverErrorAlert(msg) {
    let icon = 'error'
    Swal.fire({
        icon: icon,
        title: 'Ocurrió un error en el servidor',
        text: msg,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale'
    });
}

/*
Muestra alerta de información para ayudar a los usuarios, puede contener una imagen
@title(String)       - Titulo de la alerta ('Ops...' por defecto) 
@msg(String)         - Texto a mostrar en la alerta
@img(Require('url')) - Imagen a mostrar en la alerta
@alt(String)         - Alt de la imagen
*/
export function infoAlert(title, msg, img = '', alt = '') {
    if (img)
        img = `<img src="${img}" alt="${alt}" class="fill-available"></img>`
    Swal.fire({
        icon: 'info',
        title,
        html: `<p> ${msg} </p> ${img}`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale'
    })
}

/*
Muestra alerta de peligro, puede contener una imagen
@title(String)       - Titulo de la alerta ('Ops...' por defecto) 
@msg(String)         - Texto a mostrar en la alerta
@img(Require('url')) - Imagen a mostrar en la alerta
@alt(String)         - Alt de la imagen
*/
export function warningAlert(title, msg, img = '', alt = '') {
    if (img)
        img = `<img src="${img}" alt="${alt}" class="fill-available"></img>`
    Swal.fire({
        icon: 'warning',
        title,
        html: `<p> ${msg} </p> ${img}`,
        confirmButtonText: 'Vale'
    })
}

/*
Muestra alerta que indica al usuario que tiene que esperar el tiempo indicado
@causa(String)           - La operación que ha iniciado la espera
@tiempoTotal(String)     - Texto que indica el tiempo total a esperar al hacer la operación
@segundosRestantes(int)  - Segundos restantes que debe de esperar el usuario
*/
export function waitAlert(causa, tiempoTotal, segundosRestantes) {
    segundosRestantes = Math.round(segundosRestantes);
    let timerInterval
    Swal.fire({
        title: '¡Espera un poco!',
        html: `Debes esperar por lo menos <strong>${tiempoTotal}</strong> antes de hacer esta operación porque se ha hecho un <strong>${causa}</strong> recientemente. <br><br>Espera <b></b> segundos más...`,
        icon: 'warning',
        timer: segundosRestantes * 1000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
                if (!isNaN(Swal.getTimerLeft())) {
                    b.textContent = Math.round(Swal.getTimerLeft() / 1000)
                }
            }, 100)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
    });
}

/*
Muestra alerta de operación exitosa
@Titulo(String)  - El titulo de la alerta
@msg(String)     - Texto informativo
*/
export async function successAlert(title, msg, datos = null) {
    let htmlContent = `<p>${msg}</p>`;
    
    if (datos && typeof datos === 'object') {
        htmlContent += '<table class="table table-bordered text-start">';
        htmlContent += '<tbody>';
        for (const key in datos) {
            if (datos.hasOwnProperty(key)) {
                htmlContent += `
                    <tr>
                        <th class="table-dark">${key}</th>
                        <td class="table-light">${datos[key]}</td>
                    </tr>
                `;
            }
        }
        htmlContent += '</tbody>';
        htmlContent += '</table>';
    }

    return Swal.fire({
        icon: 'success',
        title,
        html: htmlContent,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale'
    });
}

/*
Muestra alerta que indica que el servidor no respondió
*/
export function sinPermisosAlert() {
    Swal.fire({
        icon: 'error',
        title: 'No tienes permisos para realizar esta acción',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Vale'
    });
}

/*
Alerta que pide confirmación del usuario
*/
export async function confirmAlert(msg = '') {
    let confirm = await Swal.fire({
        title: '¿Está seguro?',
        html: `${msg}`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
    })
    return confirm.isConfirmed || false
}