<template>
  <div class="container my-4">
    <br><br>
    <img alt="Logo Asteo" src="../assets/LOGO-ASTEO.svg" class="pe-2 my-3">
  <router-view></router-view>
      <small>Copyright © {{actual_year}} Asteo Red Neutra S.L. Todos los derechos reservados.</small>
  </div>
</template>

<script>
/*
  Vista de autentificación, contendrá el inicio de sesión y cambio de contraseña
*/
export default {
  name: 'AuthView',
  data() {
    return {
      actual_year: new Date().getFullYear(),
    }
  }
}
</script>

<style scoped>
img {
  width: 75%;
  max-width: 25rem;
}
</style>
