<template>

    <div class="card page-card border-0 shadow-lg my-5">

       
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Reportes de recursos</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>

      <!-- Comprobar que los movimientos han sido importados correctamente -->
      <div v-if="erroresImportador && erroresImportador.length > 0" class="alert alert-warning d-flex align-items-center justify-content-between mx-4" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start notification-items">
        
          <div v-for="(errorItem, index) in erroresImportador" :key="index" class="text-danger">
            {{errorItem}}
          </div>     
            <button  type="button" :disabled="cargandoImportacion" class="btn btn-danger btn-sm mx-2 mt-2 fit-content align-self-center" @click="forzarImportacion()">
              Forzar importación de registros
              <div v-if="cargandoImportacion" class="spinner-border spinner-border-sm" role="status">
              </div>
            </button>
        </div>
        <i class="fa-solid fs-3 fa-circle-question ms-2 clickable text-black" @click="infoError"></i>
      </div>
      <!-- ###################### -->
      <!-- Reporte de penetración -->
      <!-- ###################### -->
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- header -->
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Reporte de penetración</h4>
            <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoPenetracion()"></i>
          </div>
          <div class="mt-3">
            <form class="user d-flex flex-column" novalidate>
              <div class="row">

                  <!-- OPERADOR -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="OPERADORPEN" class="line mx-1 fs-5 d-flex align-items-center">Operador</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERADORPEN" class="form-control  m-0" maxlength="128" v-model="penetracion.id_operador" :disabled="penetracion.generandoReporte">                                
                                    <option value="" selected>Todos</option>
                                    <option v-for="(item, index) in operadores" :key="index" :value="item.id_operador">{{item.operador}} - {{item.id_operador}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <!-- Provincia -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="ProvinciaPEN" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="ProvinciaPEN" class="form-control  m-0" maxlength="255" v-model="penetracion.PROVINCIA" :disabled="penetracion.generandoReporte" @change="mostrarPueblosDisponiblesPenetracion()">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in areaInfluencia" :key="index" :value="item.PROVINCIA">{{item.PROVINCIA}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>            
                  
                  <!-- Pueblos -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="PuebloPEN" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="PuebloPEN" class="form-control  m-0" maxlength="255" v-model="penetracion.POBLACION" :disabled="penetracion.generandoReporte || !penetracion.PROVINCIA">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in penetracion.pueblosDisponibles" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>    
                  
                  <!-- FECHA -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="datepicker-desdePEN" class="line mx-1 fs-5 d-flex align-items-center">Fecha</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row align-items-center">
                              <div class="col-10 col-md-5 text-start">
                                <b-form-datepicker :disabled="penetracion.generandoReporte" today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-desdePEN" placeholder="Desde" locale="es" v-model="penetracion.DESDE"></b-form-datepicker>
                              </div>
                              <div class="col-10 col-md-5 mt-3 mt-md-0 text-start">
                                <b-form-datepicker :disabled="penetracion.generandoReporte" today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-hastaPEN" placeholder="Hasta" locale="es" v-model="penetracion.HASTA"></b-form-datepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>

                  <!-- Incluir no facturables -->
                  <div class="col-sm-12 col-xxl-4 my-2">
                    <div class="row d-flex align-items-center" title="Incluye los datos de los servicios marcados como 'No facturables' en el reporte">
                      <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                        <label class="switchlabel mx-1 fs-5 d-flex align-items-center" for="incluir_no_facturables_penetracion">Incluir clientes no facturables</label>
                      </div>
                      <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                          <div class="row d-flex flex-row">
                            <div class="col-10">
                              <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                <input class="form-check-input m-0 p-0" type="checkbox" id="incluir_no_facturables_penetracion" :disabled="penetracion.generandoReporte" v-model="penetracion.incluir_no_facturables">
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>                    
                <hr class="mt-4">
                <div class="d-flex flex-column flex-md-row  justify-content-center">
                    <button type="button" :disabled="penetracion.generandoReporte" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarPenetracion()" title="Limpiar filtros penetración">        
                      Limpiar
                    </button>
                    <button type="button" :disabled="penetracion.generandoReporte" class="btn btn-primary boton-generar line my-1 mx-2" @click="generarInformePenetracion()" title="Generar reporte penetración">
                      <i  v-if="!penetracion.generandoReporte" class="fa-solid fa-file-circle-plus mx-1"></i>
                      <div v-else class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Cargando login...</span>
                      </div>
                      Generar reporte
                    </button>
                    <button type="button" :disabled="penetracion.generandoReporte" class="btn btn-success boton-generar line my-1 mx-2" @click="generarInformePenetracion(true)" title="Generar reporte penetración y descargar">
                      <i class="fa-solid fa-file-excel mx-1"></i>
                      Generar y descargar
                    </button>
                </div>

              </div>

            </form>
            <!-- Tabla -->
            <div v-show="penetracion.table && penetracion.informePenetracion" class="mt-4 p-4 shadow">

                <table id="tablaPenetracion" ref="tablaPenetracion" class="table table-striped w-100" width="100%">
                    <thead>
                      <tr class="noselect">
                        <th scope="col" class="align-middle">PROVINCIA</th>
                        <th scope="col" class="align-middle">POBLACIÓN</th>
                        <th scope="col" class="align-middle">UUIs</th>
                        <th scope="col" class="align-middle">FECHA ACTIVACIÓN</th>
                        <th scope="col" class="align-middle">OPERADOR</th>
                        <th scope="col" class="align-middle">PENETRACIÓN</th>
                        <th scope="col" class="align-middle">TEMPLATE</th>
                        <th scope="col" class="align-middle">RESERVAS</th>
                        <th scope="col" class="align-middle">ALTAS</th>
                        <th scope="col" class="align-middle">BAJAS</th>
                      </tr>
                    </thead>
                    <tbody class="align-middle text-start">                             
                    </tbody>
                </table>
                <button type="button" :disabled="penetracion.generandoReporte" class="btn btn-success boton-generar line mt-2 mx-2" @click="descargarInformePenetracion()" title="Descargar excel del informe de penetración">
                      <i  class="fa-solid fa-file-excel mx-1"></i>
                      Descargar
                </button>  
            </div>
          </div>
        </div>
      </div>

      <!-- ######################### -->
      <!-- Reporte de fecha de altas -->
      <!-- ######################### -->
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Reporte de fecha de altas</h4>
            <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoAltas()"></i>
          </div>

            <!-- Algoritmo -->
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                  <div class="row">

                  <!-- OPERADOR -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end">
                            <label for="OPERADORaltas" class="line mx-1 fs-5 d-flex align-items-center">Operador</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERADORaltas" class="form-control  m-0" maxlength="128" v-model="altas.id_operador" :disabled="altas.generandoReporte">                                
                                    <option value="" selected>Todos</option>
                                    <option v-for="(item, index) in operadores" :key="index" :value="item.id_operador">{{item.operador}} - {{item.id_operador}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                   
                  <!-- Provincia -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="Provinciaaltas" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="Provinciaaltas" class="form-control  m-0" maxlength="255" v-model="altas.PROVINCIA" :disabled="altas.generandoReporte" @change="mostrarPueblosDisponiblesAltas()">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in areaInfluencia" :key="index" :value="item.PROVINCIA">{{item.PROVINCIA}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>            
                  
                  <!-- Pueblos -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="Puebloaltas" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="Puebloaltas" class="form-control  m-0" maxlength="255" v-model="altas.POBLACION" :disabled="altas.generandoReporte || !altas.PROVINCIA">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in altas.pueblosDisponibles" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>                  
                    <!-- Incluir no facturables -->
                    <div class="col-sm-12 col-xxl-4 my-2">
                      <div class="row d-flex align-items-center" title="Incluye los datos de los servicios marcados como 'No facturables' en el reporte">
                        <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label class="switchlabel mx-1 fs-5 d-flex align-items-center" for="incluir_no_facturables_altas">Incluir clientes no facturables</label>
                        </div>
                        <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                  <input class="form-check-input m-0 p-0" type="checkbox" id="incluir_no_facturables_altas" :disabled="altas.generandoReporte" v-model="altas.incluir_no_facturables">
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>                 
                  <hr class="mt-4">

                   <div class="d-flex flex-column flex-md-row  justify-content-center">
                      <button type="button" :disabled="altas.generandoReporte" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarAltas()" title="Limpiar filtros altas">        
                        Limpiar
                      </button>
                      <button type="button" :disabled="altas.generandoReporte" class="btn btn-primary boton-generar line my-1 mx-2" @click="generarinformeFechaAltas()" title="Generar informe de fechas de alta">
                            <i  v-if="!altas.generandoReporte" class="fa-solid fa-file-circle-plus mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando login...</span>
                            </div>
                            Generar reporte
                      </button>
                      <button type="button" :disabled="altas.generandoReporte" class="btn btn-success boton-generar line my-1 mx-2" @click="generarinformeFechaAltas(true)" title="Generar informe de fechas de alta y descargar excel">
                            <i class="fa-solid fa-file-excel mx-1"></i>
                            Generar y descargar
                      </button>
                   </div>	

                  </div>

                </form>
              <!-- Tabla -->
            <div v-show="altas.table && altas.informeAltas" class="mt-4 p-4 shadow">

                <table id="tablaAltas" class="table table-striped w-100" width="100%">
                    <thead>
                      <tr class="noselect">
                        <th scope="col" class="align-middle">PROVINCIA</th>
                        <th scope="col" class="align-middle">POBLACIÓN</th>
                        <th scope="col" class="align-middle">OPERADOR</th>
                        <th scope="col" class="align-middle">FECHA ALTA</th>
                        <th scope="col" class="align-middle">TEMPLATE</th>
                        <th scope="col" class="align-middle">REMOTE_ID</th>
                        <th scope="col" class="align-middle">ID_DOMICILIO</th>
                        <th scope="col" class="align-middle">ID ADMINISTRATIVO</th>
                        <th scope="col" class="align-middle">DIRECCIÓN DEL DOMICILIO</th>
                        <th scope="col" class="align-middle">PRIMERA ALTA</th>
                      </tr>
                    </thead>
                    <tbody class="align-middle text-start">                             
                    </tbody>
                </table>
                <button type="button" :disabled="altas.generandoReporte" class="btn btn-success boton-generar line mt-2 mx-2" @click="descargarInformeFechaAltas()" title="Descargar excel del informe de decha de altas">
                      <i  class="fa-solid fa-file-excel mx-1"></i>
                      Descargar
                </button>  
            </div>
            </div>
        </div>
      </div>

      <!-- ###################### -->
      <!-- Reporte de operaciones -->
      <!-- ###################### -->
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Reporte de operaciones</h4>
            <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoOperaciones()"></i>
          </div>

            <!-- Algoritmo -->
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                  <div class="row">

                  <!-- OPERADOR -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end">
                            <label for="OPERADOROPERACIONES" class="line mx-1 fs-5 d-flex align-items-center">Operador</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="OPERADOROPERACIONES" class="form-control  m-0" maxlength="128" v-model="operaciones.id_operador" :disabled="operaciones.generandoReporte">                                
                                    <option value="" selected>Todos</option>
                                    <option v-for="(item, index) in operadores" :key="index" :value="item.id_operador">{{item.operador}} - {{item.id_operador}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                   
                  <!-- Provincia -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="ProvinciaOperaciones" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="ProvinciaOperaciones" class="form-control  m-0" maxlength="255" v-model="operaciones.PROVINCIA" :disabled="operaciones.generandoReporte" @change="mostrarPueblosDisponiblesOperaciones()">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in areaInfluencia" :key="index" :value="item.PROVINCIA">{{item.PROVINCIA}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>            
                  
                  <!-- Pueblos -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="PuebloOperaciones" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="PuebloOperaciones" class="form-control  m-0" maxlength="255" v-model="operaciones.POBLACION" :disabled="operaciones.generandoReporte || !operaciones.PROVINCIA">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in operaciones.pueblosDisponibles" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>                  
                  <!-- FECHA -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="datepicker-desde-operaciones" class="line mx-1 fs-5 d-flex align-items-center">Fecha</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row align-items-center">
                              <div class="col-10 col-md-5 text-start">
                                <b-form-datepicker :disabled="operaciones.generandoReporte" today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-desde-operaciones" placeholder="Desde" locale="es" v-model="operaciones.DESDE"></b-form-datepicker>
                              </div>
                              <div class="col-10 col-md-5 mt-3 mt-md-0 text-start">
                                <b-form-datepicker :disabled="operaciones.generandoReporte" today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-hasta-operaciones" placeholder="Hasta" locale="es" v-model="operaciones.HASTA"></b-form-datepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                    <!-- Incluir no facturables -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center" title="Incluye los datos de los servicios marcados como 'No facturables' en el reporte">
                        <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label class="switchlabel mx-1 fs-5 d-flex align-items-center" for="incluir_no_facturables_operaciones">Incluir clientes no facturables</label>
                        </div>
                        <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                  <input class="form-check-input m-0 p-0" type="checkbox" id="incluir_no_facturables_operaciones" :disabled="operaciones.generandoReporte" v-model="operaciones.incluir_no_facturables">
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>                  
                    <!-- Reporte plan unico -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center" title="Reporte exclusivo de operaciones realizadas en el plan único">
                        <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label class="switchlabel mx-1 fs-5 d-flex align-items-center" for="reporte_plan_unico">Reporte plan Único</label>
                        </div>
                        <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                  <input class="form-check-input m-0 p-0" type="checkbox" id="reporte_plan_unico" :disabled="operaciones.generandoReporte" v-model="operaciones.reporte_plan_unico">
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>                             
                  <hr class="mt-4">

                   <div class="d-flex flex-column flex-md-row  justify-content-center">
                      <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarOperaciones()" title="Limpiar filtros operaciones">        
                        Limpiar
                      </button>
                      <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-primary boton-generar line my-1 mx-2" @click="generarInformeOperaciones()" title="Generar informe de operaciones">
                            <i  v-if="!operaciones.generandoReporte" class="fa-solid fa-file-circle-plus mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                            </div>
                            Generar reporte
                      </button>
                      <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-success boton-generar line my-1 mx-2" @click="generarInformeOperaciones(true)" title="Generar informe de operaciones y descargar excel">
                            <i  class="fa-solid fa-file-excel mx-1"></i>
                            Generar y descargar
                      </button>
                   </div>	

                  </div>

                </form>
              <!-- Tabla -->
            <div v-show="operaciones.table && operaciones.informeOperaciones" class="mt-4 p-4 shadow">

                <table id="tablaOperaciones" class="table table-striped w-100" width="100%">
                    <thead>
                      <tr class="noselect">
                        <th scope="col" class="align-middle">PROVINCIA</th>
                        <th scope="col" class="align-middle">POBLACIÓN</th>
                        <th scope="col" class="align-middle">OPERADOR</th>
                        <th scope="col" class="align-middle">FECHA OPERACION</th>
                        <th scope="col" class="align-middle">ID ADMINISTRATIVO</th>
                        <th scope="col" class="align-middle">OPERACION</th>
                        <th scope="col" class="align-middle">TEMPLATE</th>
                        <th scope="col" class="align-middle" style="width: 15rem;">DIRECCIÓN</th>
                        <th scope="col" class="align-middle">ID_DOMICILIO</th>
                        <th scope="col" class="align-middle">GESCAL_37</th>
                        <th scope="col" class="align-middle">DETALLE</th>
                      </tr>
                    </thead>
                    <tbody class="align-middle text-start">                             
                    </tbody>
                </table>
                <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-success boton-generar line mt-2 mx-2" @click="descargarInformeOperaciones()" title="Descargar excel del informe de operaciones">
                      <i  class="fa-solid fa-file-excel mx-1"></i>
                      Descargar
                </button>  
            </div>
            </div>
        </div>
      </div>

      <!-- ###################### -->
      <!-- Reporte de altas netas -->
      <!-- ###################### -->
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Reporte de altas netas</h4>
            <i class="fa-solid fa-circle-question helpIcon ms-2 fs-5" @click="infoAltasNetas()"></i>
          </div>
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                  <div class="row justify-content-center">

                    <!-- FECHA -->
                    <div class="col-sm-12 my-2">
                          <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-2 d-flex align-items-center justify-content-end justify-content-md-end">
                              <label for="datepicker-desde-operaciones" class="line mx-1 fs-5 d-flex align-items-center">Fecha</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-10 ">
                              <div class="row d-flex flex-row align-items-center">
                                <div class="col-10 col-md-5 text-center">
                                  <date-picker class="w-100" :lang="lang" :disabled="altasNetas.generandoReporte" v-model="altasNetas.DESDE" type="month" :show-time-header="true" placeholder="Desde" valueType="date"></date-picker>
                                </div>
                                <div class="col-10 col-md-5 text-center">
                                  <date-picker class="w-100" :lang="lang" :disabled="altasNetas.generandoReporte" v-model="altasNetas.HASTA" type="month" :show-time-header="true" placeholder="Hasta" valueType="date"></date-picker>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                    <!-- Acumulado -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label class="switchlabel line mx-1 fs-5 d-flex align-items-center" for="acumuladoinput">Acumulado</label>
                        </div>
                        <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                  <input class="form-check-input m-0 p-0" type="checkbox" id="acumuladoinput" :disabled="altasNetas.generandoReporte" v-model="altasNetas.ACUMULADO">
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <!-- Incluir no facturables -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                      <div class="row d-flex align-items-center" title="Incluye los datos de los servicios marcados como 'No facturables' en el reporte">
                        <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label class="switchlabel mx-1 fs-5 d-flex align-items-center" for="incluir_no_facturables_altasNetas">Incluir clientes no facturables</label>
                        </div>
                        <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                  <input class="form-check-input m-0 p-0" type="checkbox" id="incluir_no_facturables_altasNetas" :disabled="altasNetas.generandoReporte" v-model="altasNetas.incluir_no_facturables">
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>                    
                    <hr class="mt-4">

                    <div class="d-flex flex-column flex-md-row  justify-content-center">
                      <button type="button" :disabled="altasNetas.generandoReporte" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarAltasNetas()" title="Limpiar filtros altasNetas">        
                        Limpiar
                      </button>
                      <button type="button" :disabled="altasNetas.generandoReporte" class="btn btn-primary boton-generar line my-1 mx-2" @click="generarinformeAltasNetas()" title="Generar informe de altas netas">
                            <i  v-if="!altasNetas.generandoReporte" class="fa-solid fa-file-circle-plus mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                            </div>
                            Generar reporte
                      </button>
                      <button type="button" :disabled="altasNetas.generandoReporte" class="btn btn-success boton-generar line my-1 mx-2" @click="generarinformeAltasNetas(true)" title="Generar informe de altas netas y descargar excel">
                            <i class="fa-solid fa-file-excel mx-1"></i>
                            Generar y descargar
                      </button>                      
                    </div>	

                  </div>

                </form>
              <!-- Tabla -->
            <div v-show="altasNetas.table && altasNetas.informeAltasNetas" class="mt-4 p-4 shadow">

                <table id="tablaAltasNetas" class="table table-striped w-100" width="100%">
                    <thead>
                      <tr class="noselect">
                        <th v-for="(item, index) in altasNetas.cabeceras" :key="index" scope="col" class="align-middle" style="min-width: 5rem;">{{item}}</th>
                      </tr>
                    </thead>
                    <tbody class="align-middle text-start">                             
                    </tbody>
                </table>

                <button type="button" :disabled="altasNetas.generandoReporte" class="btn btn-success boton-generar line mt-2 mx-2" @click="descargarInformeAltasNetas()" title="Descargar excel del informe de altas netas">
                      <i  class="fa-solid fa-file-excel mx-1"></i>
                      Descargar
                </button>  
            </div>
            </div>
        </div>
      </div>


      <!-- ###################### -->
      <!-- Reporte de altas netas -->
      <!-- ###################### -->
      <div class="m-3 card shadow">
          <div class="card-body">
              <div class="d-flex align-items-baseline justify-content-start">
                  <h4>Reportes MasMovil</h4>
                  <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoMasMovil()"></i>
              </div>
              <div class="mt-3">
                  <button type="button" class="btn btn-primary mb-3" @click="getReportesMM">Cargar Reportes</button>
                  <table v-show="reportes_mm.lista_reportes && reportes_mm.lista_reportes.length" id="tablaReportesMM" class="table table-striped w-100" width="100%">
                      <thead>
                          <tr class="noselect">
                              <th scope="col" class="align-middle">File Name</th>
                              <th scope="col" class="align-middle">Operador</th>
                              <th scope="col" class="align-middle">Tipo</th>
                              <th scope="col" class="align-middle">Periodo Facturación</th>
                              <th scope="col" class="align-middle">Acción</th>
                          </tr>
                      </thead>
                      <tbody class="align-middle">
                          <tr v-for="(reporte, index) in reportes_mm.lista_reportes" :key="index">
                              <td>{{ reporte.file_name }}</td>
                              <td>{{ reporte.operador }}</td>
                              <td>{{ reporte.tipo }}</td>
                              <td>{{ reporte.periodo_facturacion }}</td>
                              <td>
                                  <button class="btn btn-success" @click="descargarReporteMM(reporte.file_name)">Descargar</button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>



    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert, warningAlert } from '../helpers/fastAlert';
import { crearYdescargar, jsonToExcelBuffer } from '../helpers/utils';
import axios from "axios";
import { fecha } from '../classes/fecha.class';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from "sweetalert2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

function base64ToBlob(base64, mime) {
  const binary = atob(base64);
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([buffer], { type: mime });
}

export default {
  name: 'Reporte',
  components: { DatePicker },
  data() {
    return {
      penetracion: {
        generandoReporte: false,
        id_operador: '',
        PROVINCIA: '',
        POBLACION: '',
        DESDE: '',
        HASTA: '',  
        incluir_no_facturables: false,
        pueblosDisponibles: [],
        informePenetracion: '',
        table: '',
      },
      altas: {
        generandoReporte: false,
        id_operador: '',
        PROVINCIA: '',
        POBLACION: '',
        incluir_no_facturables: false,
        pueblosDisponibles: [],
        informeAltas: '',
        table: '',
      },
      operaciones: {
        generandoReporte: false,
        id_operador: '',
        PROVINCIA: '',
        POBLACION: '',
        DESDE: '',
        HASTA: '',
        incluir_no_facturables: false,
        reporte_plan_unico: false,
        pueblosDisponibles: [],
        informeOperaciones: '',
        table: '',
      },
      altasNetas: {
        generandoReporte: false,
        DESDE: '',
        HASTA: '',
        ACUMULADO: false,
        incluir_no_facturables: false,
        cabeceras: [],
        informeAltasNetas: '',
        table: '',
      },
      reportes_mm: {
        lista_reportes: []
      },
      areaInfluencia: [],
      mensajedeError: '',
      operadores: [],
      erroresImportador: [],
      cargandoErroresImportador: false,
      cargandoImportacion: false,
      lang: 'es'
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Reportes de recursos', 'En esta página se podrán generar reportes de facturación de los distintos operadores existentes.')
    },
    infoPenetracion(){
      infoAlert('Reporte de penetración', 'El informe de penetración es un resumen con todas las reservas, altas y bajas por cada perfil de velocidad y población. En el se indica cual es la penetración de abonados activos en cada localidad.')
    },
    infoAltas(){
      infoAlert('Reporte de fecha de altas', 'El informe de fecha de altas contiene todos los hogares activos actualmente con la correspondiente fecha en la que se hizo el alta.')
    },
    infoOperaciones(){
      infoAlert('Reporte de operaciones', 'El informe de operaciones contiene <strong>todos los movimientos</strong> que se han realizado para la gestión de servicios (<strong>Altas, bajas y cambios de servicio</strong>).')
    },
    infoAltasNetas(){
      infoAlert('Reporte de altas netas', 'El informe de altas netas contiene un sumario de todas las altas netas que se han realizado cada mes, puede ser acumulado.')
    },
    infoMasMovil(){
      infoAlert('Reportes de MasMovil', 'MasMovil tiene unos reportes distintos, está el de <strong>facturación mensual</strong> (Billing) que contiene un resumen de la facturación de todos los clienes mensualmente y el acumulado, que contiene <strong>información acumulada</strong> (Provision) de todas los clientes que se han tenido.')
    },    
    infoError(){
      infoAlert('Errores en los movimientos', 'Automaticamente nos encargamos de comprobar que los registros importados de sistemas externos tengan un domicilio válido, si no está en nuestra area de influencia no se podrá importar a nuestros registros y no se reflejará ni en los informes ni en los servicios activos.')
    },
    async getOperadores(){
        try {
          let respuesta = await axios.get('/operadores', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.operadores = respuesta.data.data.listaOperadores;          
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    limpiarPenetracion(){
      this.penetracion.id_operador = '';
      this.penetracion.PROVINCIA = '';
      this.penetracion.POBLACION = '';
      this.penetracion.DESDE = '';
      this.penetracion.HASTA = '';
      this.penetracion.incluir_no_facturables = false;
      this.penetracion.informePenetracion = '';
      if(this.penetracion.table) {
        this.penetracion.table.clear().draw();
      }
    },
    descargarInformePenetracion(){
        //Crear excel y exportarlo
        let excel = jsonToExcelBuffer(this.penetracion.informePenetracion);
        let inicioFecha = new fecha(this.penetracion.DESDE || '2020-01-01').yyyymmdd
        let finFecha = new fecha(this.penetracion.HASTA).yyyymmdd
        let nombre = 'Reporte de penetración - ' + inicioFecha + '_' + finFecha;
        crearYdescargar(excel, nombre, 'excel')
    },
    async generarInformePenetracion(descargar = false){
        try {
          //Obtener los datos del reporte a través del servidor
          this.penetracion.generandoReporte = true;
          let HASTA = this.penetracion.HASTA;
          if(HASTA) HASTA += ' 23:59:59' //Se añaden tiempo en el parametro hasta para que sea el dia completo y no desde el principio
          let respuesta = await axios({
            method: 'GET',
            url: '/reportes/penetracion',
            params: {
              ID_OPERADOR: this.penetracion.id_operador,
              PROVINCIA: this.penetracion.PROVINCIA,
              POBLACION: this.penetracion.POBLACION,
              DESDE: this.penetracion.DESDE,
              HASTA,
              incluir_no_facturables: this.penetracion.incluir_no_facturables
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
          let reportData = respuesta.data.data.reporte;
          //Si no hay datos notificamos con una alerta
          if(!reportData || !reportData.length){
            throw 'No se encontraron datos.';
          }

          //Si hay algún error mostramos información
          let datosErroneos = []
          for (const iterator of reportData) {
            if(iterator.UUIIs == 'Desconocido' && !datosErroneos.includes(`${iterator.POBLACION} (${iterator.PROVINCIA})`)){
              datosErroneos.push(`${iterator.POBLACION} (${iterator.PROVINCIA})`)
            }
          }
          if(datosErroneos.length){
            warningAlert('Se han encontrado errores los datos', 
              `
                Normalmente estos errores ocurren al realizar operaciones en domicilios
                que contienen <strong>erratas en la huella</strong>.<br>
                <div class="text-start">
                  Poblaciones afectadas: <br>
                  <strong>${datosErroneos.join("</strong>, <br><strong>")}</strong>
                </div>
                <br>
                <div class="text-danger">
                  El informe podría estar incompleto o ser inválido
                </div>
              `
            )
          }

          this.penetracion.informePenetracion = reportData;
          if(this.penetracion.table) this.penetracion.table.destroy();
          this.penetracion.table = $('#tablaPenetracion').DataTable({
            data: this.penetracion.informePenetracion,
            responsive: true,
            language: { 
              
              "oPaginate": {
                "sPrevious": "&#10094;&#10094;",
                "sNext":     "&#10095;&#10095;",
              },
            },
            columns: [
              { data: 'PROVINCIA' },
              { data: 'POBLACION' },
              { data: 'UUIIs', 
                render: (data, type, row) => {
                  return data == 'Desconocido' ? `<div class="text-danger">${data}</div>` : data
                }  
              },
              { data: 'FECHA ACTIVACION', 
                render: (data, type, row) => {
                  return data == 'Desconocido' ? `<div class="text-danger">${data}</div>` : data
                }
              },
              { data: 'OPERADOR' },
              { data: 'PENETRACION', 
                render: (data, type, row) => {
                  return data == 'Desconocido' ? `<div class="text-danger">${data}</div>`: data
                }
              },
              { data: 'TEMPLATE' },
              { data: 'RESERVAS' },
              { data: 'ALTAS' },
              { data: 'BAJAS' },
            ]
          });
          this.penetracion.table.draw();
        
          if(descargar){
            this.descargarInformePenetracion();
          }


          this.penetracion.generandoReporte = false;
        } catch (error) {
          this.penetracion.generandoReporte = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log('Error controlado', error.response);
            errorAlert('¡No se pudo generar el reporte!', error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            errorAlert('¡No se pudo generar el reporte!', error.message || error);
          }
        }
    },
    limpiarAltas(){
      this.altas.id_operador = '';
      this.altas.PROVINCIA = '';
      this.altas.POBLACION = '';
      this.altas.informeAltas = '';
      this.altas.incluir_no_facturables = false;
      if(this.altas.table) {
        this.altas.table.clear().draw();
      }
    },
    descargarInformeFechaAltas(){
        //Crear excel y exportarlo
        let excel = jsonToExcelBuffer(this.altas.informeAltas);
        let nombre = 'Reporte de fecha de altas';
        crearYdescargar(excel, nombre, 'excel')
    },
    async generarinformeFechaAltas(descargar = false){
        try {
          
          //Obtener los datos del reporte a través del servidor
          this.altas.generandoReporte = true;
          let respuesta = await axios({
            method: 'GET',
            url: '/reportes/fechaAltas',
            params: {
              ID_OPERADOR: this.altas.id_operador,
              PROVINCIA: this.altas.PROVINCIA,
              POBLACION: this.altas.POBLACION,
              incluir_no_facturables: this.altas.incluir_no_facturables
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          let reportData = respuesta.data.data.reporte;
          //Si no hay datos notificamos con una alerta
          if(!reportData || !reportData.length){
            throw 'No se encontraron datos.';
          }

          this.altas.informeAltas = reportData;
          if(this.altas.table) this.altas.table.destroy();
          this.altas.table = $('#tablaAltas').DataTable({
            data: this.altas.informeAltas,
            responsive: true,
            language: { 
              
              "oPaginate": {
                "sPrevious": "&#10094;&#10094;",
                "sNext":     "&#10095;&#10095;",
              },
            },
            columns: [
              { data: 'PROVINCIA' },
              { data: 'POBLACION' },
              { data: 'OPERADOR' },
              { data: 'FECHA ALTA' },
              { data: 'TEMPLATE' },
              { data: 'REMOTE_ID' },
              { data: 'ID_DOMICILIO' },
              { data: 'ID_ADMINISTRATIVO' },
              { data: 'DIRECCION' },
              { data: 'PRIMERA ALTA' },
            ]
          });
          this.altas.table.draw();

          if(descargar){
            this.descargarInformeFechaAltas();
          }


          this.altas.generandoReporte = false;
        } catch (error) {
          this.altas.generandoReporte = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log('Error controlado', error.response);
            errorAlert('¡No se pudo generar el reporte!', error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            errorAlert('¡No se pudo generar el reporte!', error.message || error);
          }
        }
    },
    limpiarAltasNetas(){
        this.altasNetas.generandoReporte = false;
        this.altasNetas.RANGO = '';
        this.altasNetas.ACUMULADO = false;
        this.altasNetas.informeAltasNetas = '';
        this.altasNetas.incluir_no_facturables = false;
        if(this.altasNetas.table) 
        {
          this.altasNetas.table.clear().draw();
        }
    },
    descargarInformeAltasNetas(){
        //Crear excel y exportarlo
        let excel = jsonToExcelBuffer(this.altasNetas.informeAltasNetas);
        let inicioFecha = new fecha(this.altasNetas.DESDE || '2020-01-01').yyyymmdd
        let finFecha = new fecha(this.altasNetas.HASTA).yyyymmdd
        let nombre = 'Reporte de altas netas - ' + inicioFecha + '_' + finFecha;
        crearYdescargar(excel, nombre, 'excel')
    },
    async generarinformeAltasNetas(descargar = false){
        try {
          
          //Obtener los datos del reporte a través del servidor
          this.altasNetas.generandoReporte = true;
          if(this.altasNetas.table) 
          {
            this.altasNetas.informeAltasNetas = ''
            this.altasNetas.cabeceras = []
            this.altasNetas.table.clear()
            this.altasNetas.table.destroy()
            this.altasNetas.table = ''
          }
          
        
          let DESDE = null;
          let HASTA = null;
          if(this.altasNetas.DESDE){
            DESDE = this.altasNetas.DESDE
          } 
          if(this.altasNetas.HASTA){
            HASTA = new Date(this.altasNetas.HASTA.setMinutes(this.altasNetas.HASTA.getMinutes()-5))
          }
          let respuesta = await axios({
            method: 'GET',
            url: '/reportes/altasNetas',
            params: {
              DESDE, HASTA, ACUMULADO: this.altasNetas.ACUMULADO, incluir_no_facturables: this.altasNetas.incluir_no_facturables
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          let reportData = respuesta.data.data.reporte;
          //Si no hay datos notificamos con una alerta
          if(!reportData || !reportData.length){
            throw 'No se encontraron datos.';
          }


          this.altasNetas.informeAltasNetas = reportData;
          this.altasNetas.cabeceras = Object.keys(reportData[0])
          console.log(this.altasNetas.cabeceras)
          this.$forceUpdate()
          let reportWithoutTotal = JSON.parse(JSON.stringify(reportData))
          reportWithoutTotal.pop();

          this.altasNetas.table = $('#tablaAltasNetas').DataTable({
            data: reportWithoutTotal,
            autoWidth: true,
            language: { 
              
              "oPaginate": {
                "sPrevious": "&#10094;&#10094;",
                "sNext":     "&#10095;&#10095;",
              },
            },
            "order": [],
            columns: this.altasNetas.cabeceras.map( e => {
              return {
                data: e
              }
            })
          });
          
          this.altasNetas.table.draw();
          // A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
          setTimeout(() => {
            $('#tablaAltasNetas').wrap('<div class="table-responsive"></div>')
          }, 500);
          if(descargar){
            this.descargarInformeAltasNetas();
          }


          this.altasNetas.generandoReporte = false;
        } catch (error) {
          this.altasNetas.generandoReporte = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log('Error controlado', error.response);
            errorAlert('¡No se pudo generar el reporte!', error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            errorAlert('¡No se pudo generar el reporte!', error.message || error);
          }
        }
    },    
    limpiarOperaciones(){
      this.operaciones.id_operador = '';
      this.operaciones.PROVINCIA = '';
      this.operaciones.POBLACION = '';
      this.operaciones.DESDE = '';
      this.operaciones.HASTA = '';
      this.operaciones.informeOperaciones = '';
      this.operaciones.incluir_no_facturables = false;
      this.operaciones.reporte_plan_unico = false;

      if(this.operaciones.table) {
        this.operaciones.table.clear().draw();
      }
    },
    descargarInformeOperaciones(){
        //Crear excel y exportarlo
        let excel = jsonToExcelBuffer(this.operaciones.informeOperaciones);
        let inicioFecha = new fecha(this.operaciones.DESDE || '2020-01-01').yyyymmdd
        let finFecha = new fecha(this.operaciones.HASTA).yyyymmdd
        let nombre = 'Reporte de operaciones - ' + inicioFecha + '_' + finFecha;
        crearYdescargar(excel, nombre, 'excel')
    },
    async generarInformeOperaciones(descargar = false){
        try {
          //Obtener los datos del reporte a través del servidor
          this.operaciones.generandoReporte = true;
          let HASTA = this.operaciones.HASTA;
          if(HASTA) HASTA += ' 23:59:59' //Se añaden tiempo en el parametro hasta para que sea el dia completo y no desde el principio
          let respuesta = await axios({
            method: 'GET',
            url: '/reportes/operaciones',
            params: {
              ID_OPERADOR: this.operaciones.id_operador,
              PROVINCIA: this.operaciones.PROVINCIA,
              POBLACION: this.operaciones.POBLACION,
              DESDE: this.operaciones.DESDE,
              HASTA,
              incluir_no_facturables: this.operaciones.incluir_no_facturables,
              reporte_plan_unico: this.operaciones.reporte_plan_unico
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          let reportData = respuesta.data.data.reporte;
          //Si no hay datos notificamos con una alerta
          if(!reportData || !reportData.length){
            throw 'No se encontraron datos.';
          }
          this.operaciones.informeOperaciones = reportData;
          if(this.operaciones.table) this.operaciones.table.destroy();
          this.operaciones.table = $('#tablaOperaciones').DataTable({
            data: this.operaciones.informeOperaciones,
            responsive: true,
            language: { 
              
              "oPaginate": {
                "sPrevious": "&#10094;&#10094;",
                "sNext":     "&#10095;&#10095;",
              },
            },
            columns: [
              { data: 'PROVINCIA' },
              { data: 'POBLACION' },
              { data: 'OPERADOR' },
              { data: 'FECHA OPERACION' },
              { data: 'ID_ADMINISTRATIVO' },
              { data: 'OPERACION' },
              { data: 'TEMPLATE' },
              { data: 'DIRECCION' },
              { data: 'ID_DOMICILIO' },
              { data: 'GESCAL_37' },
              { data: 'DETALLE' },
            ]
          });
          
          this.operaciones.table.draw();

          if(descargar){
            this.descargarInformeOperaciones();
          }


          this.operaciones.generandoReporte = false;
        } catch (error) {
          this.operaciones.generandoReporte = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log('Error controlado', error.response);
            errorAlert('¡No se pudo generar el reporte!', error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            errorAlert('¡No se pudo generar el reporte!', error.message || error);
          }
        }
    },    
    async cargarAreadeInfluencia(){
      try {
        let respuestaPandora = await axios.get('/hogares/influencia', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.areaInfluencia = respuestaPandora.data.data.influencia;
        
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },
    async comprobarEstadoImportador(){
      try {
        this.cargandoErroresImportador = true;
        let respuestaPandora = await axios.get('/importador/estado', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.erroresImportador = respuestaPandora.data.data.errores;
        this.cargandoErroresImportador = false;
      } catch (error) {
        this.cargandoErroresImportador = false;
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },   
    async forzarImportacion(){
      try {
        let resultado = await Swal.fire({
            title: '¿Está seguro?',
            html: `Diariamente se importan los registros provenientes de sistemas externos, pero si ha solucionado un error de datos puede <b>forzar la importación</b> para actualizar los datos.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText:  'Continuar',
            cancelButtonText:   'Cancelar',
        });
      if(!resultado.isConfirmed) return;
        this.cargandoImportacion = true;
        let respuestaPandora = await axios.get('/importador', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        await this.comprobarEstadoImportador();
        successAlert('Operación completada', respuestaPandora.data.msg);
        this.cargandoImportacion = false;
      } catch (error) {
        this.cargandoImportacion = false;
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },     
    mostrarPueblosDisponiblesAltas(){
      this.altas.POBLACION = '';
      let provincia = this.areaInfluencia.find(e => e.PROVINCIA == this.altas.PROVINCIA);
      if(provincia && provincia.PUEBLOS){
        this.altas.pueblosDisponibles = provincia.PUEBLOS;
      }else{
        this.altas.pueblosDisponibles = [];
      }
    },
    mostrarPueblosDisponiblesPenetracion(){
      this.penetracion.POBLACION = '';
      let provincia = this.areaInfluencia.find(e => e.PROVINCIA == this.penetracion.PROVINCIA);
      if(provincia && provincia.PUEBLOS){
        this.penetracion.pueblosDisponibles = provincia.PUEBLOS;
      }else{
        this.penetracion.pueblosDisponibles = [];
      }
    },
    mostrarPueblosDisponiblesOperaciones(){
      this.operaciones.POBLACION = '';
      let provincia = this.areaInfluencia.find(e => e.PROVINCIA == this.operaciones.PROVINCIA);
      if(provincia && provincia.PUEBLOS){
        this.operaciones.pueblosDisponibles = provincia.PUEBLOS;
      }else{
        this.operaciones.pueblosDisponibles = [];
      }
    },
    async getReportesMM() {
      try {
        
        let respuesta = await axios({
            method: 'GET',
            url: '/reportes/masmovil',
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
        
        this.reportes_mm.lista_reportes = respuesta.data.data.reportes.map(file => {
          let parts = file.split('-');
          return {
            file_name: file,
            tipo: parts[0],
            operador: parts[1],
            periodo_facturacion: parts[parts.length - 1].replace('.xlsx', '')
          };
        });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log('Error controlado', error.response);
          errorAlert('¡No se pudieron cargar los reportes!', error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          errorAlert('¡No se pudieron cargar los reportes!', error.message || error);
        }
      }
    },
    // Método para descargar un reporte específico
    async descargarReporteMM(file_name) {
      try {
        let respuesta = await axios({
          method: 'GET',
          url: '/reportes/masmovil',
          params: {
            file_name
          },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') },
        });
        console.log(respuesta);
        if (!respuesta.data.data.reporte) {
          errorAlert("Archivo no válido", "No se encontró el archivo en la respuesta del servidor");
          return;
        }
        const base64Content = respuesta.data.data.reporte;
        const blob = base64ToBlob(base64Content, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remover el enlace del DOM después de la descarga

      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log('Error controlado', error.response);
          errorAlert('¡Error descargando el reporte!', error.response.data.msg);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          errorAlert('¡No se pudo descargar el reporte!', error.message || error);
        }
      }
    }
  },
  created() {
    this.getOperadores();
    this.cargarAreadeInfluencia();
    this.comprobarEstadoImportador();
  },
  mounted(){
  }
}
</script>

<style scoped>
.boton-generar{
  min-width: 10rem;
}

.alert .notification-items{
  max-height: 10rem;
  overflow: auto;
}
@media (min-width: 1200px) {
  .boton-generar {
    max-width: 12rem;
    inline-size: auto;
  }
}
</style>
