import Vue from 'vue'
import Vuex from 'vuex'
import { getJWTpayload } from '../helpers/JWT'

Vue.use(Vuex)

//////////////
//VUEX STORE//
//////////////

//Store que amacena datos localmente
export default new Vuex.Store({
    state: {
        usuario: '',
        colaHogares: '',
        colaTrazados: ''
    },
    mutations: {
        setStateUsuario(state, payload) {
            state.usuario = payload.toLowerCase();
        },
        setStateColaHogares(state, payload) {
            state.colaHogares = payload;
        },
        setStateColaTrazados(state, payload) {
            state.colaTrazados = payload;
        }
    },
    actions: {
        setUsuario({ commit }) {
            let email = getJWTpayload().email;
            commit('setStateUsuario', email);
        },
        setColaHogares({ commit }, payload) {
            commit('setStateColaHogares', payload)
        },
        setColaTrazados({ commit }, payload) {
            commit('setStateColaTrazados', payload)
        }
    },
    modules: {}
})