<template>
    <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">
           
            <!-- Header -->
            <div class="d-flex align-items-baseline mx-4 my-4">
              <h1 >Nuevo usuario</h1>
              <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
            </div>

            <div class="card-body">

              <!-- Contenido -->
              <div>
                  <form class="user d-flex flex-column" @submit.prevent="crearUsuario()" novalidate>
                    <div class="row">
                        <!-- Email -->
                        <div class="col-sm-12 col-xxl-4 my-2">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="Email" class="line mx-1 fs-5 d-flex align-items-center">Email</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">
                                      <input id="Email"  class="form-control w-100 m-0" type="email" required maxlength="65" v-model.lazy="$v.form.email.$model" placeholder="Correo del usuario" :class="{ 'is-invalid': $v.form.email.$error}" autofocus autocomplete="off">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- errores -->
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.form.email.$dirty && !$v.form.email.required" class="text-danger">
                                    Debe de introducir el correo del usuario
                                  </small>
                                  <small v-show="$v.form.email.$dirty && !$v.form.email.email" class="text-danger">
                                    Formato de correo no válido
                                  </small>
                                  <small v-show="$v.form.email.$dirty && !$v.form.email.minLength" class="text-danger">
                                    Necesias introducir por lo menos 6 caracteres
                                  </small>                                      
                                  <small v-show="$v.form.email.$dirty && !$v.form.email.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                              </div>
                        </div>

                        <!-- Contraseña -->
                        <div class="col-sm-12 col-xxl-4 my-2">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="password" class="line mx-1 fs-5 d-flex align-items-center">Contraseña</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">
                                      <input id="password" class="form-control w-100 m-0" type="text" required maxlength="65" v-model.lazy="$v.form.password.$model" placeholder="Contraseña usuario" :class="{ 'is-invalid': $v.form.password.$error}" autocomplete="off">
                                    </div>
                                    <div class="col-2 px-0 text-start align-self-center">
                                      <i class="fa-solid fa-rotate clickable fs-5" @click="generarPassword()"></i>
                                    </div>
                                  </div>               
                                </div>
                              </div>
                              <!-- errores -->
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.form.password.$dirty && !$v.form.password.required" class="text-danger">
                                    Debe de introducir una contraseña
                                  </small>
                                  <small v-show="$v.form.password.$dirty && !$v.form.password.minLength" class="text-danger">
                                    Necesias introducir por lo menos 6 caracteres
                                  </small>                                  
                                  <small v-show="$v.form.password.$dirty && !$v.form.password.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                              </div>
                        </div>
                        
                        <!-- Puede usar pandora -->
                        <div v-if="esSuperAdmin()" class="col-sm-12 col-xxl-4 my-2">
                          <div class="row d-flex align-items-center">
                            <div class="col-8 col-sm-7  col-md-3 col-lg-2 col-xxl-5 d-flex align-items-center justify-content-end justify-content-md-end">
                              <label class="switchlabel line mx-1 fs-5 d-flex align-items-center" for="switchUsarPandora">Acceso a pandora</label>
                            </div>
                            <div class="col-4 col-sm-5 col-md-9 col-lg-10 col-xxl-7 ">
                                <div class="row d-flex flex-row">
                                  <div class="col-10">
                                    <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 m-0">
                                      <input class="form-check-input m-0 p-0" type="checkbox" id="switchUsarPandora" v-model="form.puedeUsarPandora">
                                    </div>
                                 </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <Roles :HiddenRequest="true" ref="RolesComponent"></Roles>


                        <div v-if="esSuperAdmin()">
                          <hr class="mt-4">                 
                          <!-- OPERADORES ACTIONCARD -->
                          <b-overlay :show="cargandoOperadores">
                          <div class="card border-dark border-3 mx-4 mt-2 bg-light action-card flex-shrink-1">
                            <i class="fa-solid fa-circle-question clickable fs-5 action-card-help" @click="infoOperadores"></i>
                            <div class="container mb-2 mt-4">
                                    <!-- TABS -->
                                    <ul class="nav nav-tabs mx-4">
                                        <li class="nav-item" :class="{ 'disabled' : !operadores.length}">
                                          <span id="tabExistente" class="nav-link tab active" @click="esOperadorExistente(true)">Operador existente</span>
                                        </li>
                                        <li class="nav-item">
                                          <span id="tabNuevo" class="nav-link tab" @click="esOperadorExistente(false)">Nuevo operador</span>
                                        </li>
                                    </ul>


                                    <!-- OPERADOR EXISTENTE -->
                                    <div v-show="!crearNuevoOperador" class="my-2">
                                        <span class="text-black-50 fw-bold">Operadores existentes: </span>
                                        <!-- Error -->
                                        <div v-if="!cargandoOperadores && (mensajedeError || operadores.length < 1)" class="alert alert-warning d-flex align-items-center my-2" role="alert">
                                          <div class="d-flex align-items-center">
                                            <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                                            <div class="d-flex flex-column">
                                              <div v-if="mensajedeError" class="text-danger">
                                                {{mensajedeError}}
                                              </div>  
                                              <div v-if="operadores.length < 1" class="text-danger align-self-center">
                                                No se han encontrado operadores
                                              </div>           
                                            </div>
                                          </div>
                                        </div>
                                        <!-- Operadores -->
                                        <div class="d-flex justify-content-evenly align-content-center flex-wrap m-3">
                                          <button :id="'boton'+item.operador+item.id_operador" type="button" class="btn btn-secondary operador-btn m-2" v-for="(item, index) in operadores" :key="index" @click="elegirOperador(item.operador, item.id_operador, 'boton'+item.operador+item.id_operador)">
                                            <span class="line">{{item.operador}}</span>
                                            {{item.id_operador}}
                                          </button>
                                        </div>
                                    </div>

                                    <!-- NUEVO OPERADOR -->
                                    <div v-show="crearNuevoOperador" class="row py-2">
                                            <!-- Operador -->
                                            <div class="col-sm-12 col-xxl-6 my-2">
                                                  <div class="row d-flex align-items-center">
                                                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                                      <label for="operador" class="line mx-1 fs-6 d-flex align-items-center">Operador</label>
                                                    </div>
                                                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                                      <div class="row d-flex flex-row">
                                                        <div class="col-10 col-sm-10  col-xxl-10">
                                                          <input id="operador" class="form-control w-100 m-0" type="text" required maxlength="65" v-model="$v.form.operador.$model" placeholder="Nombre operador" :class="{ 'is-invalid': $v.form.operador.$error}" autocomplete="off">
                                                        </div>
                                                      </div>               
                                                    </div>
                                                  </div>
                                                  <!-- errores -->
                                                  <div class="row justify-content-end text-start">
                                                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                                      <small v-show="$v.form.operador.$dirty && !$v.form.operador.required" class="text-danger">
                                                        Debe de introducir un nombre de operador
                                                      </small>                                
                                                      <small v-show="$v.form.operador.$dirty && !$v.form.operador.maxLength" class="text-danger">
                                                        No puede introducir tantos caracteres
                                                      </small>      
                                                      <small v-show="$v.form.operador.$dirty && !$v.form.operador.whiteSpaces" class="text-danger">
                                                        El nombre de operador no puede contener espacios en blanco
                                                      </small>                                                           
                                                                                
                                                    </div>
                                                  </div>
                                            </div>
                                        
                                            <!-- ID_OPERADOR -->
                                            <div class="col-sm-12 col-xxl-6 my-2">
                                                  <div class="row d-flex align-items-center">
                                                    <div class="col-5 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                                      <label for="opid" class="line mx-1 fs-6 d-flex align-items-center">ID Operador</label>
                                                    </div>
                                                    <div class="col-7 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                                      <div class="row d-flex flex-row">
                                                        <div class="col-10">
                                                          <input id="opid" class="form-control w-100 m-0" type="text" required maxlength="5" v-model="$v.form.id_operador.$model" placeholder="ID operador" :class="{ 'is-invalid': $v.form.id_operador.$error}" autocomplete="off" @keypress="isNumber($event)">
                                                        </div>
                                                        <div class="col-2 px-0 text-start align-self-center">
                                                          <i class="fa-solid fa-circle-question clickable fs-5" @click="infoID"></i>
                                                        </div>
                                                      </div>               
                                                    </div>
                                                  </div>
                                                  <!-- errores -->
                                                  <div class="row justify-content-end text-start">
                                                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                                      <small v-show="$v.form.id_operador.$dirty && !$v.form.id_operador.required" class="text-danger">
                                                        Debe de introducir el id del operador
                                                      </small>                               
                                                      <small v-show="$v.form.id_operador.$dirty && !$v.form.id_operador.maxLength" class="text-danger">
                                                        No puede introducir tantos caracteres
                                                      </small>                                
                                                    </div>
                                                  </div>
                                            </div>
                                    </div>
                        
                    
                            </div>
                          </div>
                          </b-overlay>
                        </div>
                        <!-- Botones -->
                        <hr class="mt-4">
                        <div class="d-flex justify-content-center">
                          <button type="button" class="btn btn-danger form-btn mx-2" @click="volver()">Cancelar</button>
                          <button type="submit" :disabled="$v.$invalid || cargandoSolicitud || !$refs.RolesComponent.rolesElegidos.length" class="btn btn-primary form-btn mx-2">
                            <i v-if="!cargandoSolicitud" class="fa-solid fa-user-plus mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                            Crear
                          </button>
                        </div>		

                    </div>
                  </form>          
              </div>
            </div>

            
          </div>

      </div>
    </div>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { verificarJWT, desconectar, isSuperAdmin, getJWTpayload, isOperadorAdmin } from '../helpers/JWT';
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert } from '../helpers/fastAlert';
import axios from "axios";
import Swal from "sweetalert2";
import crypto from 'crypto'
import { registrarOperacionAdmin } from '../helpers/registrarEnHistorial';
import Roles from "@/components/Roles"
import { regex } from 'vuelidate/lib/validators/common';

export default {
  /*
    Crea un nuevo usuario y le envía un correo con el usuario y contraseña
    Si el operador del usuario es uno existente solo hace falta hacer clic en el operador, en cambio si es uno nuevo, se puede hacer clic en la pestaña y escribir
    el nuevo operador
  */
  name: "NuevoUsuario",
  components: {
    Roles
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        operador: '',
        id_operador: '',
        puedeUsarPandora: true
      },
      operadores: '',
      mensajedeError: '',
      cargandoOperadores: false,    
      cargandoSolicitud: false,
      crearNuevoOperador: false
    }
  },
  //Validaciones
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(65)
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(65)
      },
      operador: {
        required,
        maxLength: maxLength(65),
        whiteSpaces: (value) => !value ? true : /^\S+$/.test(value) //Se comprueba que no tenga espacios en blanco
      },
      id_operador: {
        required,
        maxLength: maxLength(5)
      }
    }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Nuevo usuario', 'Aquí puedes crear <strong>nuevos usuarios</strong> para que tengan acceso a la plataforma.')
    },
    infoOperadores(){
      infoAlert('Selecciona el operador', 'Elige el operador del usuario, puedes escoger entre seleccionar un <strong>operador existente</strong> o <strong>crear uno nuevo</strong>. <br><br>Recuerda que no puedes crear un nuevo operador usando una ID ya existente.')
    },
    infoID(){
      infoAlert('ID de operador', 'El id de operador, es un número identificativo del operador, por ejemplo <strong>301</strong>.');
    },
    volver(){
      isSuperAdmin() ? this.$router.push({name: 'Operadores'}) : this.$router.push({name: 'UsuariosOperador'})
    },
    //Se crea el nuevo usuario y se envia un correo
    async crearUsuario(){
      this.cargandoSolicitud = true;
      //Peticion de verificacion de seguridad
      let verificado =  await verificarJWT();
      if (!verificado) {
          errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
          desconectar();
          return;
      }

      try {
    
          let respuesta = await axios({
            method: 'POST',
            url: '/operadores/newUser',
            data: {
              email: this.form.email,
              password: this.form.password,
              operador: this.form.operador,
              id_operador: this.form.id_operador,
              soloHydra: !this.form.puedeUsarPandora
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
            await registrarOperacionAdmin("Nuevo usuario", this.form.operador.toUpperCase(), this.form.id_operador, { 'Usuario creado': this.form.email });
            await this.$refs.RolesComponent.cambiarRoles(this.form.email);

          await Swal.fire({
            title: 'Usuario creado',
            html: `
              <span>El usuario y contraseña han sido enviados al correspondiente correo electrónico.</span>
              <br> <br>
              <table class="table table-striped text-start">
                <!-- Cuerpo tabla -->
                <tbody>
                  <tr>
                    <th scope="row">Email</th>
                    <td> ${this.form.email} </td>
                  </tr>
                  <tr>
                    <th scope="row">Contraseña</th>
                    <td> ${this.form.password} </td>
                  </tr>
                  <tr>
                    <th scope="row">Operador</th>
                    <td> ${this.form.operador} - ${this.form.id_operador} </td>
                  </tr>
                  ${
                    isSuperAdmin() ? 
                    `
                    <tr>
                      <th scope="row">Acceso pandora</th>
                      <td> ${ this.form.puedeUsarPandora ? 'Permitido' : 'Solo Hydra'} </td>
                    </tr>` : ''
                  }                                                                                         
                </tbody>
              </table>
            `,
            text: 'Usuario y contraseña enviados al Email',
            icon: 'success',
            confirmButtonText: 'Vale'
          }).then( result => {
            localStorage.setItem('focusedOperator', this.form.id_operador);
            this.volver();
          })
        
      } catch (error) {
          
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log(error.response);
            errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            serverErrorAlert(error.message);
          }
          return;
      }finally{
        this.cargandoSolicitud = false;
      }

      this.resetearFormulario();
    },
    //Recupera una lista con los operadores disponibles para que se elija uno
    async getOperadores(){
        try {
          this.cargandoOperadores = true;
          let respuesta = await axios.get('/operadores', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.operadores = respuesta.data.data.listaOperadores;     
          if(!this.operadores.length)    {
            this.crearNuevoOperador = false;
          }
        } catch (error) {
          
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        } finally {
            this.cargandoOperadores = false;
        }
    }, 
    //Genera una contraseña de 20 caracteres aleatoria
    generarPassword(){
        let length = 20;
        let wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
        let pwd = Array.from(crypto.randomFillSync(new Uint8Array(length))).map((x) => wishlist[x % wishlist.length]).join('');
        this.form.password = pwd;
    },
    //Al elegir un operador se pone en verde
    elegirOperador(nombre, id){
      nombre = nombre.toUpperCase();
      let botones = document.querySelectorAll('.operador-btn');
      let activo = document.getElementById('boton'+nombre+id);
      for (const iterator of botones) {
        iterator.classList.remove('btn-success');
        iterator.classList.add('btn-secondary');
      }
      if(activo){
        this.form.operador = nombre;
        this.form.id_operador = id;
        activo.classList.remove('btn-secondary');
        activo.classList.add('btn-success');
      }
    },
    //Deselecciona cualquier operador elegido
    eliminarOperadoresElegidos(){
      let botones = document.querySelectorAll('.operador-btn');
      for (const iterator of botones) {
        iterator.classList.remove('btn-success');
        iterator.classList.add('btn-secondary');
      }
      this.form.operador = '';
      this.form.id_operador = '';
      let valores = this.operadores.map(dato => parseInt(dato.id_operador));
    },
    //Reinicia la página
    resetearFormulario(){      
        document.getElementsByClassName("Panel")[0].scrollTop = 0;
            this.form.email = '';
            this.generarPassword();
            this.mensajedeError = '';
            if(isSuperAdmin()){
              this.form.operador = '';
              this.form.id_operador = '';
              this.eliminarOperadoresElegidos();
            }
            this.$v.$reset();        
    },
    //Previene que se escriban letras en el input de tipo número, que es el de operador
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    //Si el operador elegido ya existe lo pone en verde
    esOperadorExistente(esExistente){
      this.eliminarOperadoresElegidos();
      this.crearNuevoOperador = !esExistente;
      for (const iterator of document.querySelectorAll('.nav-link.tab')) {
        iterator.classList.remove('active');
      }
      esExistente ? document.getElementById('tabExistente').classList.add('active') : document.getElementById('tabNuevo').classList.add('active');
    },
    esSuperAdmin(){
      return isSuperAdmin();
    },
    //Como el id de operador es obligatorio, pero que un operador admin lo elija no tiene sentido,porque va a ser el suyo si o si, lo ponemos por defecto
    seleccionarOperadorsiNoAdmin(){
      if(!isSuperAdmin()){
        this.form.id_operador = getJWTpayload().OPID;
        this.form.operador = getJWTpayload().operador;
      }
    }
  },
  created() {
    if(isSuperAdmin()){
      this.getOperadores();
    }
    this.generarPassword();
    this.seleccionarOperadorsiNoAdmin();
  },
  computed: {
    
  }
};
</script>


<style scoped>
  .operador-btn {
    min-width: 12rem;
  }
  .action-card-items{
    max-height: 30rem;
  }
  .action-card-item:hover{
    opacity: .8;
  }
    
  .action-card-item:active{
    opacity: .5;
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }

.switchlabel{
  margin-bottom: 0.25rem !important;
}

</style>
