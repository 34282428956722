<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Asignar coberturas</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Volver a mis Operadores-->
              <div>
                  <router-link :to="{name: 'Operadores'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fa-solid fa-users-gear mx-1"></i> Operadores</router-link>
              </div>
            </div>
            
            <div class="card-body">
              <!-- Tarjeta informativa del operador -->
              <TarjetaOperador :item="datosOperador" :justInfo="true"></TarjetaOperador>
              <!-- coberturas -->
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <!-- Header -->
                    <div class="d-flex align-items-baseline">
                      <h4>Gestor de coberturas</h4>
                      <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoGestor()"></i>
                    </div>
                      <!-- Filtros de provincia y población -->
                      <form @submit.prevent novalidate>
                        <div class="row d-flex align-items-center justify-content-center my-3 mx-0 flex-column flex-sm-row container-fluid">
                            
                            <div class="col-12">
                                <div class="row">
                                  <!-- Provincia -->
                                  <div class="row d-flex flex-column flex-sm-row align-items-center my-2 col-12 col-xxl-6">
                                    <div class="col-12 col-sm-5 col-md-3 col-xl-2 col-xxl-3 d-flex align-items-center justify-content-start justify-content-sm-end p-0">
                                      <label for="provincia" class="line mx-1 fs-5 d-flex align-items-center">Buscar provincia</label>
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-9 col-xl-10 col-xxl-9">
                                      <select v-if="provincias" :disabled="cargandoDatos || cargandoPueblos" id="provincia" class="form-select  m-0" maxlength="128" v-model="provinciaFiltrar" @change="filtrarPueblos()">                                
                                        <option class="text-capitalized" value="" selected>Sin especificar</option>
                                        <option class="text-capitalized" v-for="(item, index) in provincias" :key="index" :value="item">{{item}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <!-- Población -->
                                  <div class="row d-flex flex-column flex-sm-row align-items-center my-2 col-12 col-xxl-6">
                                    <div class="col-12 col-sm-5 col-md-3 col-xl-2 col-xxl-3 col-xxl-3 d-flex align-items-center justify-content-start justify-content-sm-end p-0">
                                      <label for="poblacion" class="line mx-1 fs-5 d-flex align-items-center">Buscar población</label>
                                    </div>
                                    <div class="col-12 col-sm-7 col-md-9 col-xl-10 col-xxl-9 ">
                                          <input id="poblacion" class="form-control w-100 m-0" type="text" required maxlength="65" v-model="poblacionFiltrar" placeholder="Ej.: Torrefresneda" autocomplete="off" @keyup="filtrarPueblos()">             
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </form>
                      <!-- ACTIONCARD -->
                      <b-overlay :show="cargandoDatos || cargandoPeticion" >
                      <div class="card border-dark border-3 mt-2 bg-light action-card flex-shrink-1 shadow mx-auto">
                         <div class="mb-2 mt-4">
                            <!--Error ocurrido -->
                            <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-4" role="alert">
                                <i class="fa-solid fs-5 fa-triangle-exclamation text-warning mx-2"> </i>  
                              <div  class="text-danger">
                                {{mensajedeError}}
                              </div>            
                            </div>

                            <div class="d-flex align-content-center justify-content-around w-100 flex-column flex-md-row">
                                <!-- Disponibles -->
                                <div class="d-flex flex-column bloque-coberturas text-black-50 mx-md-4">
                                    <span class="fs-5 fw-bold">Poblaciones disponibles: </span>
                                    <div class="square-box shadow my-2">
                                      <div class="card border-dark border-2 bg-white square-content p-3">
                                        <div v-if="pueblosDisponiblesFiltrados && pueblosDisponiblesFiltrados.length > 0" class="p-1 overflow-auto">
                                          <template v-for="(item, index) in pueblosDisponiblesFiltrados">
                                            <div :key="index">
                                              <hr v-show="index">
                                              <div class="shadow-sm py-1 px-2 my-1  action-card-item action-card-item-provincia d-flex justify-content-between" @click="asignarProvinciaEntera(item.provincia)">
                                                  <small class="fw-bold">{{item.provincia}} <span class="fw-normal">(Entero)</span></small>
                                                  <i class="fa-solid fs-5 fa-plus icon-btn mx-2"></i>
                                              </div>
                                              <div class="shadow-sm py-1 px-2 my-1 action-card-item action-card-item-pueblo d-flex justify-content-between" v-for="(pueblo, i) in item.poblaciones" :key="i" @click="asignarPueblo(item.provincia, pueblo)">
                                                  <small>{{pueblo.toLowerCase()}}</small>
                                                  <i class="fa-solid fs-5 fa-plus icon-btn mx-2"></i>
                                              </div>
                                            </div>
                                          </template>
                                        </div>
                                        <div v-if="pueblosDisponiblesFiltrados && pueblosDisponiblesFiltrados.length < 1 && !cargandoPueblos">
                                            <span class="text-danger fs-5">Ningún pueblo encontrado</span>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <!-- Flechas -->
                                <div class="d-flex flex-column align-self-center">
                                   <i class="fa-solid fa-3x fa-right-long align-self-center icon-btn" @click="asignarTodo()"></i>   
                                   <i class="fa-solid fa-3x fa-left-long align-self-center icon-btn" @click="desasignarTodo()"></i>   
                                </div>
                                <!-- Asignados -->
                                <div class="d-flex flex-column bloque-coberturas text-black-50 mx-md-4">
                                    <span class="fs-5 fw-bold">Poblaciones asignadas: </span>
                                    <div class="square-box shadow my-2">
                                      <div class="card border-dark border-2 bg-white square-content p-3">
                                        <div v-if="coberturasAsignadas && coberturasAsignadas.length > 0" class="p-1 overflow-auto">
                                          <template v-for="(item, index) in coberturasAsignadas">
                                            <div :key="index">
                                              <hr v-show="index">
                                              <div class="shadow-sm py-1 px-2 my-1  action-card-item action-card-item-provincia d-flex justify-content-between" @click="desasignarProvinciaEntera(item.provincia)">
                                                  <small class="fw-bold">{{item.provincia}}</small>
                                                  <i class="fa-solid fs-5 fa-minus icon-btn mx-2"></i>
                                              </div>
                                              <div class="shadow-sm py-1 px-2 my-1 action-card-item action-card-item-pueblo d-flex justify-content-between" v-for="(pueblo, i) in item.poblaciones" :key="i" @click="desasignarPueblo(item.provincia, pueblo)">
                                                  <small>{{pueblo.toLowerCase()}}</small>
                                                  <i class="fa-solid fs-5 fa-minus icon-btn mx-2"></i>
                                              </div>
                                            </div>
                                          </template>
        
                                        </div>
                                        <div v-else>
                                            <span v-if="!cargandoDatos || !cargandoPueblos" class="text-danger fs-5">Ningún pueblo encontrado</span>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                         </div>
                      </div>    
                      </b-overlay>                
                  </div>
                </div>
                <!-- Botones -->
                <hr class="mt-4">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-danger form-btn mx-2" @click="$router.push({name: 'Operadores'})">Cancelar</button>
                  <button :disabled="cargandoPeticion" type="button" class="btn btn-primary form-btn mx-2" @click="guardarCoberturas()">
                    <i v-if="!cargandoPeticion" class="fa-solid fa-save mx-2"></i>
                    <div v-else class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Guardar
                  </button>
                </div>		

            </div>

          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert } from '../helpers/fastAlert';

import Swal from 'sweetalert2'
import axios from "axios";
import TarjetaOperador from '@/components/TarjetaOperador';
import { procesarCoberturas, procesarCoberturasInvertido } from '../helpers/utils';
import { registrarOperacionAdmin } from '../helpers/registrarEnHistorial';

export default {
  /*
      La página asignar coberturas tiene como objetivo el poder asignar al operador las coberturas que ha contratado directamente de los hogares.
      Inicialmente se cargan de API las provincias y despues los pueblos de cada provincia.
      Luego se recupera el operador de la LocalStorage y se cargan de API sus coberturas asignadas.
      Se imprime el operador en una tarjeta y se procesan los pueblos asignados y los pueblos disponibles, ordenandolos alfabeticamente por provincia y 
      poniendolos en dos bloques separados por flechas. Si se hace clic en una provincia se asigna/desasigna entera, si se hace en un pueblo solo ese pueblo.
      Las flechas asignan/desasignan todo.

  */
  name: 'AsignarPerfiles',
  components: {
    TarjetaOperador
  },
  data() {
    return {
      poblacionFiltrar: '',               //Pueblo filtrar
      provinciaFiltrar: '',               //Filtro provincia


      datosOperador: '',                  //Operador para tarjetaOperador
      
      provincias: [],                     //Lista de provincias disponibles
        
      pueblosTotales: [],                 //Pueblos totales del hogares
      pueblosDisponibles: [],             //Pueblos disponibles para asignar al usuario
      pueblosDisponiblesFiltrados: [],    //Pueblos disponibles para asignar que se han filtrado por provincia y/o pueblo
      coberturasAsignadas: [],            //Las coberturas asignadas al usuario

      mensajedeError: '',                 //Mensaje de error de API
      cargandoPeticion: false,            //Se está cargando la petición de guardar
      cargandoDatos: false,               //Se están cargando las coberturas
      cargandoPueblos: false,               //Se están cargando pueblos
    } 
  },
  methods: {
    //Iconos de interrogación informativos
    infoPage(){
      infoAlert('Asignar coberturas', 'La página asignar coberturas tiene como objetivo el poder asignar al operador las coberturas que ha contratado.')
    },
    infoGestor(){
      infoAlert('Gestor de coberturas', 'En esta herramienta puede asignar coberturas al operador. <br><br> En el panel <strong>izquierdo</strong>, puede ver las <strong>poblaciones disponibles</strong>, y en el <strong>derecho</strong> las <strong>poblaciones asignadas</strong>. <br><br> Haga click, en una provincia para asignar o desasignar todos sus pueblos, o en los pueblos para hacerlo individualmente.')
    },
    //Descargar las coberturas asignadas del operador
    async getCoberturas(){
        try {
          this.cargandoDatos = true;
          let respuesta = await axios.get('/coberturas?OPID=' + this.datosOperador.id_operador, { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.coberturasAsignadas = procesarCoberturas(respuesta.data.data.coberturas); //Procesa los pueblos para que cada uno esté dentro de un objeto que será la provincia
          this.cargandoDatos = false;

        } catch (error) {
            this.cargandoDatos = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    }, 
    //Descargar un listado con las provincias disponibles
    async getProvincias(){
        try {
          this.cargandoPueblos = true;
          let respuesta = await axios.get('/hogares/provincias', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.provincias = respuesta.data.data.provincias;
          this.getPueblos();
        } catch (error) {
            this.cargandoPueblos = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    }, 
    /*
      Descargar todos los pueblos de cada provincia y ordenarlos alfabeticamente
      Se guardan todos en pueblosTotales, y se guardan solo los que quedan en pueblosDisponibles, que inicialmente es una copia de pueblosTotales
    */
    async getPueblos(){
        try {
          this.cargandoPueblos = true;
          this.pueblosTotales = [];
          for (let index = 0; index < this.provincias.length; index++) {
            const element = this.provincias[index];
            let respuesta = await axios({
              method: 'GET',
              url: '/hogares/pueblos',
              params: { 'provincia': element},
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });          
            this.pueblosTotales.push({provincia: element, poblaciones: respuesta.data.data.pueblosList.map(dato => dato.POBLACION).sort()});
          }
          this.pueblosDisponibles = JSON.parse(JSON.stringify(this.pueblosTotales));;
          this.ajustarDisponiblesAsignadas() //Elimina los pueblos disponibles que ya están en la lista de asignados
          this.cargandoPueblos = false;
        } catch (error) {
            this.cargandoPueblos = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    }, 
    //Envia una petición que guarda los cambios
    async guardarCoberturas(){
      try {
        this.cargandoPeticion = true;
        let coberturasReprocesadas = procesarCoberturasInvertido(this.coberturasAsignadas); //Procesa los pueblos para dejarlos cada uno en un registro en vez de dentro de un objeto provincia
        let respuestaPandora = await axios({
          method: 'POST',
          url: '/coberturas',
          data: { 'coberturas': coberturasReprocesadas, 'id_operador': this.datosOperador.id_operador },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        await Swal.fire({
          title: 'Proceso ejecutado correctamente',
          text:  respuestaPandora.data.msg,
          icon: 'success',
          confirmButtonText: 'Vale'
        })
        //Se procesan los datos para registrarlo en el historial
        let datosRegistro = {};
        if(coberturasReprocesadas.length > 0){
          for (const iterator of coberturasReprocesadas) {
            if(!datosRegistro[iterator.provincia]) {
              datosRegistro[iterator.provincia] = iterator.poblacion;
            }else { 
              datosRegistro[iterator.provincia] = datosRegistro[iterator.provincia] + ', ' + iterator.poblacion;
            }
          }
        }else{
          datosRegistro = { 'Poblaciones asignadas': 'Ninguna'};
        }
        await registrarOperacionAdmin("Asignar coberturas", this.datosOperador.operador, this.datosOperador.id_operador, '', datosRegistro)

        this.$router.push({name: 'Operadores'})
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined);
          return;
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
          return;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
          return;
        }
      } finally {
        this.cargandoPeticion = false;
      }
    },
    //Recupera el operador de la localstorage y si no existe redirige
    getOperador(){
      if(this.$route.params.datosOperador){
        localStorage.setItem('operadorActivo', JSON.stringify(this.$route.params.datosOperador));
      }
      if(localStorage.getItem('operadorActivo')){
        this.datosOperador = JSON.parse(localStorage.getItem('operadorActivo'));
      }      
      if(!this.datosOperador){
        this.$router.push({ name: 'Operadores'});
      }
      localStorage.setItem('focusedOperator', this.datosOperador.id_operador);
    },
    //Filtra los pueblos disponibles dependiendo de la provincia y el pueblo
    filtrarPueblos(){
      this.pueblosDisponiblesFiltrados = JSON.parse(JSON.stringify(this.pueblosDisponibles));
      
      if(this.provinciaFiltrar) {
        this.pueblosDisponiblesFiltrados = this.pueblosDisponiblesFiltrados.filter(prov => prov.provincia.toLowerCase() == this.provinciaFiltrar.toLowerCase());
      }
      if(this.poblacionFiltrar) {
        for (const iterator of this.pueblosDisponiblesFiltrados) {
          iterator.poblaciones = iterator.poblaciones.filter(dato => dato.toLowerCase().includes(this.poblacionFiltrar.toLowerCase()));
        }
      }
      this.pueblosDisponiblesFiltrados = this.pueblosDisponiblesFiltrados.filter(dato => dato.poblaciones.length>0)
    },
    //Reinicia la pagina
    resetForm(){
        this.getProvincias();
        this.getCoberturas();
    },
    //Asigna una provincia entera con todos sus pueblos
    asignarProvinciaEntera(nombreProvincia){
      this.coberturasAsignadas = this.coberturasAsignadas.filter(dato => dato.provincia != nombreProvincia);
      let provinciaDisponible = this.pueblosTotales.find(dato => dato.provincia == nombreProvincia);
      if(!provinciaDisponible) return;
      this.coberturasAsignadas.unshift({provincia: provinciaDisponible.provincia, poblaciones: provinciaDisponible.poblaciones}); //Lo mete al inicio de las provincias asignadas para que se vea bien el cambio
      
      this.ajustarDisponiblesAsignadas() //Elimina los pueblos disponibles que ya estan en los asignados
    },
    //Asigna un pueblo individualmente
    asignarPueblo(nombreProvincia, nombrePueblo){
      let provinciaEditada = this.coberturasAsignadas.find(prov => prov.provincia == nombreProvincia);
      if(!provinciaEditada){
        this.coberturasAsignadas.unshift({provincia: nombreProvincia, poblaciones: [nombrePueblo]});
      }else{
        if(!provinciaEditada.poblaciones.includes(nombrePueblo)) provinciaEditada.poblaciones.unshift(nombrePueblo);
      }
      this.ajustarDisponiblesAsignadas()//Elimina los pueblos disponibles que ya estan en los asignados
    },
    //Desasigna una provincia entera
    desasignarProvinciaEntera(nombre){
      this.coberturasAsignadas = this.coberturasAsignadas.filter(dato => dato.provincia != nombre);
      this.ajustarDisponiblesAsignadas()//Elimina los pueblos disponibles que ya estan en los asignados
    },
    //Desasigna un pueblo individualmente
    desasignarPueblo(nombreProvincia, nombrePueblo){
      let provinciaEditada = this.coberturasAsignadas.find(prov => prov.provincia == nombreProvincia);
      if(!provinciaEditada) return;
      provinciaEditada.poblaciones = provinciaEditada.poblaciones.filter(pueblo => pueblo != nombrePueblo);
      this.ajustarDisponiblesAsignadas();//Elimina los pueblos disponibles que ya estan en los asignados
    },
    //Asigna todos los pueblos disponibles
    asignarTodo(){
      this.coberturasAsignadas = JSON.parse(JSON.stringify(this.pueblosTotales));
      this.pueblosDisponibles = [];
      this.filtrarPueblos();
    },
    //Desasigna todo los pueblos asignados
    desasignarTodo(){
      this.pueblosDisponibles = JSON.parse(JSON.stringify(this.pueblosTotales));
      this.coberturasAsignadas = [];
      this.filtrarPueblos();
    },
    //Ajusta los pueblos asignados y disponibles
    ajustarDisponiblesAsignadas(){

      this.pueblosDisponibles = JSON.parse(JSON.stringify(this.pueblosTotales));
      for (const iterator of this.coberturasAsignadas) {
        let provinciaAsignada = this.pueblosDisponibles.find(dato => dato.provincia == iterator.provincia)
        if(provinciaAsignada){
          provinciaAsignada.poblaciones = provinciaAsignada.poblaciones.filter(pueblo => !iterator.poblaciones.includes(pueblo));
        }
      }
      //Si queda el objeto solo con la provincia y ningun pueblo lo quitamos ya que no quedan recursos que asignar/desasignar
      this.pueblosDisponibles = this.pueblosDisponibles.filter(dato => dato.poblaciones.length>0)
      this.coberturasAsignadas = this.coberturasAsignadas.filter(dato => dato.poblaciones.length>0)
      this.filtrarPueblos();
    }
  },
  created(){
    this.getProvincias();
    this.getOperador();
    this.getCoberturas();
  }
}
</script>

<style scoped>

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
  }
}

.action-card{
  max-width: 65rem;
}

.bloque-coberturas{
  width: 95%;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
}

.square-box{
    position: relative;
    width: 100%;
    height: 20rem;
    border-radius: 5px;
}
.square-content{
  height: 100%;
  width: 100%;
}


.action-card-item-provincia{
  width: 100%;
}

.action-card-item-pueblo{
  width: 95%;
  margin-left: auto;
}

.action-card-item{
  cursor: pointer;
  text-align: start;
  border: 1px solid;
  border-radius: 5px;
  text-transform: capitalize;
}
.action-card-item:hover{
  opacity: .8;
}
  


</style>
