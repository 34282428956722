import Vue from 'vue'
import VueRouter from 'vue-router'
import Panel from '../views/Panel.vue'
import AuthView from '../views/AuthView.vue'
import Welcome from '../pages/Welcome.vue'
import AutoProvisionar from '../pages/AutoProvisionar.vue'
import Reservar from '../pages/Reservar.vue'
import Reservas from '../pages/Reservas.vue'
import Servicios from '../pages/Servicios.vue'
import Test from '../pages/Test.vue'
import ConfigurarVoIP from '../pages/ConfigurarVoIP.vue'
import CambioServicio from '../pages/CambioServicio.vue'
import CambioONT from '../pages/CambioONT.vue'
import CambioPerfilMasivo from '../pages/CambioPerfilMasivo.vue'
import BuscarCobertura from '../pages/BuscarCobertura.vue'
import ReportesOperador from '../pages/ReportesOperador.vue'
import UsuariosOperador from '../pages/UsuariosOperador.vue'
import Historial from '../pages/Historial.vue'
import LoginForm from '../components/LoginForm.vue'
import NewPassForm from '../components/NewPassForm.vue'
import PassReset from '../components/PassReset.vue'

import AdminView from '../views/AdminView.vue'
import NuevoUsuario from '../pages/NuevoUsuario.vue'
import Operadores from '../pages/Operadores.vue'
import AsignarPerfiles from '../pages/AsignarPerfiles.vue'
import AsignarCoberturas from '../pages/AsignarCoberturas.vue'
import CambioRol from '../pages/CambioRol.vue'
import Notificar from '../pages/Notificar.vue'
import MigracionMasiva from '../pages/MigracionMasiva.vue'
import ServiciosTest from '../pages/ServiciosTest.vue'
import Reportes from '../pages/Reportes.vue'
import GestionAI from '../pages/editor-influencia/GestionAI.vue'
import MunicipiosYOlts from '../pages/MunicipiosYOlts.vue'
import HistorialAdmin from '../pages/HistorialAdmin.vue'
import PanelAdmin from '../pages/PanelAdmin.vue'
import { verificarJWT, desconectar, isAuthenticated, getJWTpayload, isSuperAdmin } from '../helpers/JWT';
import { sinPermisosAlert } from '../helpers/fastAlert'



/////////////
//Enrutador//
/////////////

//Gestiona las rutas de la aplicación, además impide el acceso si el usuario no ha iniciado sesión, o si debe de cambiar de contraseña porque es una cuenta nueva

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        component: AuthView,
        children: [{
                path: '',
                redirect: 'login'
            },
            {
                path: 'PassReset',
                name: 'PassReset',
                component: PassReset
            }, {
                path: 'login',
                name: 'Login',
                component: LoginForm
            }, {
                path: 'password',
                name: 'Password',
                component: NewPassForm,
                meta: { requireAuth: true }

            }
        ]
    },
    {
        path: '/panel',
        component: Panel,
        meta: { requireAuth: true, allowedRols: ['SUPERADMINISTRADOR'] },
        children: [

            //////////////
            //OPERADORES//
            //////////////

            {
                path: '',
                redirect: '/Welcome',
                meta: { allowedRols: ['ALL'] },
            }, {
                path: 'Welcome',
                name: 'Welcome',
                component: Welcome,
                meta: {
                    allowedRols: ['ALL']
                },
            }, {
                path: 'AutoProvisionar',
                name: 'AutoProvisionar',
                component: AutoProvisionar,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORTECNICO' ] },
            }, {
                path: 'reservar',
                name: 'Reservar',
                component: Reservar,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'reservas',
                name: 'Reservas',
                component: Reservas,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORTECNICO', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'servicios',
                name: 'Servicios',
                component: Servicios,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORTECNICO', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'test',
                name: 'Test',
                component: Test,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORTECNICO', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'configurarVoIP',
                name: 'configurarVoIP',
                component: ConfigurarVoIP,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'cambioServicio',
                name: 'cambioServicio',
                component: CambioServicio,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'CambioONT',
                name: 'CambioONT',
                component: CambioONT,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'CambioPerfilMasivo',
                name: 'CambioPerfilMasivo',
                component: CambioPerfilMasivo,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN'] },
            }, {
                path: 'BuscarCobertura',
                name: 'BuscarCobertura',
                component: BuscarCobertura,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'ReportesOperador',
                name: 'ReportesOperador',
                component: ReportesOperador,
                meta: { allowedRols: ['OPERADORADMIN'] },
            }, {
                path: 'Historial',
                name: 'Historial',
                component: Historial,
                meta: { allowedRols: ['INTERNOASTEO', 'OPERADORADMIN', 'OPERADORTECNICO', 'OPERADORADMINISTRATIVO'] },
            }, {
                path: 'Usuarios',
                name: 'UsuariosOperador',
                component: UsuariosOperador,
                meta: { allowedRols: ['OPERADORADMIN'] },
            }, {
                path: 'CambioRol',
                name: 'CambioRol',
                component: CambioRol,
                meta: { allowedRols: ['OPERADORADMIN'] },
            },

            //////////////////
            //ADMINISTRACIÓN//
            //////////////////
            {
                path: 'admin',
                component: AdminView,
                meta: { requireAuth: true },
                children: [{
                    path: 'nuevo',
                    name: 'NuevoUsuario',
                    component: NuevoUsuario,
                    meta: { allowedRols: ['OPERADORADMIN'] },
                }, {
                    path: 'operadores',
                    name: 'Operadores',
                    component: Operadores
                }, {
                    path: 'AsignarPerfiles',
                    name: 'AsignarPerfiles',
                    component: AsignarPerfiles
                }, {
                    path: 'AsignarCoberturas',
                    name: 'AsignarCoberturas',
                    component: AsignarCoberturas
                }, {
                    path: 'Notificar',
                    name: 'Notificar',
                    component: Notificar,
                    meta: { allowedRols: ['INTERNOASTEO'] },
                }, {
                    path: 'MigracionMasiva',
                    name: 'MigracionMasiva',
                    component: MigracionMasiva
                }, {
                    path: 'ServiciosTest',
                    name: 'ServiciosTest',
                    component: ServiciosTest,
                    meta: { allowedRols: ['INTERNOASTEO'] },
                }, {
                    path: 'Reportes',
                    name: 'Reportes',
                    component: Reportes,
                    meta: { allowedRols: ['INTERNOASTEO'] },
                }, {
                    path: 'GestionAI',
                    name: 'GestionAI',
                    component: GestionAI,
                    meta: { allowedRols: ['EDITORINFLUENCIA', 'CREADORINFLUENCIA'] },
                }, {
                    path: 'MunicipiosYOlts',
                    name: 'MunicipiosYOlts',
                    component: MunicipiosYOlts,
                    meta: { allowedRols: ['INTERNOASTEO', 'EDITORINFLUENCIA', 'CREADORINFLUENCIA'] },
                }, {
                    path: 'HistorialAdmin',
                    name: 'HistorialAdmin',
                    component: HistorialAdmin,
                    meta: { allowedRols: ['INTERNOASTEO'] },
                }, {
                    path: 'PanelAdmin',
                    name: 'PanelAdmin',
                    component: PanelAdmin,
                    meta: { allowedRols: ['INTERNOASTEO'] },
                }]
            }
        ]
    },


    //Paths por defecto
    {
        path: '/',
        redirect: '/panel/Welcome'
    },
    {
        path: '*',
        redirect: '/panel/Welcome'
    }


]

function removeUnusedFocusedItems(to) {
    if (localStorage.getItem('focusedReserva') && to.name != 'Reservas') {
        localStorage.removeItem('focusedReserva');
    }
    if (localStorage.getItem('focusedItem') && to.name != 'Servicios') {
        localStorage.removeItem('focusedItem');
    }
    if (localStorage.getItem('focusedOperator') && to.name != 'Operadores') {
        localStorage.removeItem('focusedOperator');
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//GUARD que impide acceder si no esta autentificado o debe de cambiar la contraseña
router.beforeEach((to, from, next) => {
    if (to.name == 'PassReset') localStorage.removeItem('x-token');
    removeUnusedFocusedItems(to);




    const rutaProtegida = to.matched.some(record => record.meta.requireAuth)
    if (rutaProtegida) {
        verificarJWT().then(verificado => {
            if (!verificado) {
                desconectar();
                next({ name: 'Login' });
                return;
            }
            //Si tiene que introducir una nueva contraseña
            if (to.name != "Password" && getJWTpayload().reqNewPass) {
                next({ name: 'Password', params: { firstTime: true } });
                return;
            }

            //Comprobamos que tiene permiso de rol
            //Obtenemos el sumatorio de roles permitidos en la pagina actual
            let allowedRols = []
            for (const ruta of to.matched) {
                if (ruta.meta.allowedRols) {
                    allowedRols = [...new Set([...allowedRols, ...ruta.meta.allowedRols])]
                }
            }
            //Comprobamos si el usuario tiene alguno de los roles permitidos
            let payload = getJWTpayload();
            if (allowedRols.length && !allowedRols.includes('ALL') && payload.roles.length) {
                const accesoPermitido = allowedRols.some(r => payload.roles.indexOf(r) >= 0);
                if (!accesoPermitido) {
                    sinPermisosAlert();
                    next({ name: 'Welcome' });
                    return;
                }
            }

            next();
        });
    } else {
        isAuthenticated() ? next({ name: 'Welcome' }) : next();
    }
})

export default router