
<template>
  <div class="card shadow-lg text-start notification-card">
    <div class="card-header p-3 fw-bold">
      <i class="fa-solid fa-lg fa-bell mx-1"></i>  
      Notificaciones
    </div>
      <div id="notificacioneslist" class="card-body notificacion-contenido">
        <b-overlay :show="cargando" :class="{ 'my-4' : cargando }">
            <div v-if="previewContent">
              <div class="d-flex justify-content-start align-items-center p-1">
                <small class="text-muted line me-2 notificacion-fecha">{{fechaActual()}}</small>
                <div class="d-flex justify-content-start align-items-center w-100">
                  <span v-html="previewContent" class="mx-2 ignore-pmargin break-word"></span>
                </div>
              </div>
              <hr class="mt-2">
            </div>
            <div v-if="previewContent">
              <div class="d-flex justify-content-start align-items-center p-1">
                <small class="text-muted line me-2 notificacion-fecha">{{fechaVieja()}}</small>
                <div class="d-flex justify-content-start align-items-center w-100">
                  <span class="mx-2 ignore-pmargin break-word"><strong>Ejemplo</strong> de notificación antigua.</span>
                </div>
              </div>
              <hr class="mt-2">
            </div>                                    
            <div v-if="(!notificaciones || notificaciones.length < 1) && !previewContent && !cargando" class="alert alert-warning d-flex align-items-center" role="alert">
            <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
            <div class="d-flex flex-column text-start">
              <div v-if="mensajeError" class="text-danger">
                {{mensajeError}}
              </div>  
              <div v-if="!notificaciones || notificaciones.length < 1" class="text-danger">
                No se han encontrado notificaciones
              </div>       
            </div>
          </div>
          <template v-if="notificaciones">
            <div  v-for="(item, index) in notificaciones" :key="index" :class="tipoBG(item.tipo)" class="alert shadow">
              <div class="d-flex justify-content-start align-items-center p-1">
                <small class="text-muted line me-2 notificacion-fecha">{{fechaCreacion(item.fecha)}}</small>
                <div class="d-flex justify-content-start align-items-center w-100" >
                  <span v-html="item.contenido" class="mx-2 ignore-pmargin break-word"></span>
                </div>
              </div>
              <!-- <hr class="mt-2"> -->
            </div>   
          </template>
        </b-overlay>
      </div>
    <div class="card-footer text-muted text-center pointerHover noselect" @click="$emit('mostrarMas')">
      Mostrar más &#10095;
    </div>
  </div>
</template>


<script>
import { fecha } from '../classes/fecha.class'

export default {
  
  name: 'ListaNotificaciones',
  props: ['previewContent','mensajeError', 'notificaciones', 'cargando'],
  data() {
    return {
     
    }
  },
  methods: {
    fechaActual(){
      return new fecha().ddmmyyhhmm;
    },
    fechaVieja(){
      let anteayer = new Date();
      anteayer.setDate(anteayer.getDate() - 2);
      anteayer.setHours(0,0);
      return new fecha(anteayer).ddmmyyhhmm;
    }
  },
  computed: {
    fechaCreacion(){
      return (fcreacion) => new fecha(fcreacion).ddmmyyhhmm
    },
    tipoBG(){
      return (tipo) => {
        switch (tipo) {
            case 'success':
              return 'alert-success'
            case 'danger':
              return 'alert-danger'   
            case 'warning':
              return 'alert-warning'   
            case 'question':
              return 'alert-secondary'   
            default:
              return 'alert-info'     
        }
      }
    }
  },
  created(){
  }
}
</script>

<style scoped>
.notification-card{
  height: 30rem !important;
}
.notificacion-fecha{
  min-height: inherit !important;
}
.notificacion-contenido{
  max-height: 25rem;
  overflow: auto;
}
</style>

