<template>
<div>
  <div class="card page-card border-0 shadow-lg my-4 mx-2">
    <div class="card-body">
        <div class="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center px-sm-4">
          <div class="d-flex flex-column text-start py-2">
            <h2>¡Bienvenido de vuelta!</h2>
            <label class="mx-1 text-secondary">{{usuario}}</label>
          </div>
          <img alt="Logo Asteo" src="../assets/LOGO-ASTEO.svg" class="logo">
        </div>
      </div>
  </div>
  <!-- Notificaciones emergentes -->
  <div id="notificacionesemergentes" class="my-2 mx-4">
    <div :id="'alert' + item.uid" class="alert fade show d-flex justify-content-between align-items-center" :class="TipoAlerta(item.tipo)" role="alert" v-for="(item, index) in notificacionesPendientes" :key="index">
      <div class="d-flex align-items-center justify-content-start">
          <div v-html="TipoIcono(item.tipo)"></div>
          <div v-html="item.contenido" class="notificacion-emergente text-start m-2 ignore-pmargin break-word"></div>
      </div>
      <button type="button" class="btn-close" aria-label="Close" @click="notificacionVista(item.uid)"></button>
    </div>
  </div>
  <!-- Contenido -->
  <div class="row m-2">
    <!-- Accesos directos -->
    <div class="col-12 col-lg-7">
      <div class="card lista-accesos-card shadow-lg">
        <div class="row noselect justify-content-center align-items-center h-100 m-3 overflow-auto">
            <div v-for="(item, index) in filteredLinks" :key="index" class="acceso-item my-2 col-12 col-sm-6 col-md-4" @click="$router.push({name: item.routerName})">
              <div class="card shadow bg-light" :title="item.title">
                  <div class="card-body acceso-content">
                    <div class="d-flex flex-column justify-content-between align-items-center h-100">
                      <div class="d-flex justify-content-center align-items-center w-100 h-50 mt-2">
                        <i class="fa-solid fa-4x text-dark" :class="item.icon"></i>  
                      </div>
                      <span class="text-secondary">{{item.name}}</span>
                    </div>
                  </div>
              </div>
            </div>
           
        </div>
      </div>
    </div>
    <!-- Notificaciones -->
    <div class="col-12 col-lg-5 my-4 my-lg-0 align-self-center">
        <ListaNotificaciones :mensajeError="mensajeErrorNotificaciones" :notificaciones="notificaciones" :cargando="cargandoNotificaciones" @mostrarMas="aumentarLimite()"></ListaNotificaciones>
    </div>
  </div>
  <div class="row m-2" v-if="esOperadorAdministrativo()">
    <div class="col-12 col-lg-8 my-2">
      <ServiciosChart></ServiciosChart>
    </div>
    <div class="col-12 col-lg-4 my-2">
      <PerfilesChart></PerfilesChart>
    </div>
  </div>

</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ListaNotificaciones from '../components/ListaNotificaciones.vue';
import axios from "axios";
import { Alert } from 'bootstrap';
import ServiciosChart from '../components/charts/ServiciosChart.vue';
import PerfilesChart from '../components/charts/PerfilesChart.vue';
import { getJWTpayload, isOperadorAdmin, isOperadorAdministrativo } from '../helpers/JWT';

export default {

  name: "Welcome",
  components: {
    ListaNotificaciones,
    ServiciosChart,
    PerfilesChart,
  },
  data() {
    return {
        notificaciones: [],
        notificacionesPendientes: [],
        cargandoNotificaciones: false,
        mensajeErrorNotificaciones: '',
        limit: 10,
        links: [ 
          { name: "Auto Provisión", title: "Realiza la provisión de abonados de manera automática", routerName: "AutoProvisionar", icon: "fa-solid fa-house-laptop", rolesPermitidos: ['SUPERADMINISTRADOR', 'OPERADORADMIN', 'OPERADORTECNICO']}, 
          { name: "Reservar", title: "Reserva recursos para hacer un alta de servicio posteriormente", routerName: "Reservar", icon: "fa-solid fa-download", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO']}, 
          { name: "Mis reservas", title: "Ver mis reservas de recursos", routerName: "Reservas", icon: "fa-solid fa-box-archive", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO', 'OPERADORTECNICO']}, 
          { name: "Clientes serviciados", title: "Ver mis clientes serviciados", routerName: "Servicios", icon: "fa-solid fa-coins", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO', 'OPERADORTECNICO']},
          { name: "Cambio de perfil masivo", title: "Hacer un cambio masivo de perfiles de velocidad", routerName: "CambioPerfilMasivo", icon: "fa-solid fa-gauge-high", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN']},
          { name: "Reportes", routerName: "ReportesOperador", icon: "fa-solid fa-file-lines", rolesPermitidos: ['OPERADORADMIN']},
          { name: "Buscar cobertura", title: "Buscar coberturas de hogares disponibles", routerName: "BuscarCobertura", icon: "fa-solid fa-map", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO']},
          { name: "Usuarios", title: "Lista de usuarios del operador", routerName: "UsuariosOperador", icon: "fa-solid fa-users-gear", rolesPermitidos: ['OPERADORADMIN']},
          { name: "Historial", title: "Historial de operaciones realizadas", routerName: "Historial", icon: "fa-solid fa-clock-rotate-left", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'OPERADORADMIN', 'OPERADORADMINISTRATIVO', 'OPERADORTECNICO']},
          { name: "Nuevo usuario", title: "Crear un nuevo usuario", routerName: "NuevoUsuario", icon: "fa-solid fa-user-plus", rolesPermitidos: ['SUPERADMINISTRADOR']}, 
          { name: "Operadores", title: "Lista de usuarios de cada operador", routerName: "Operadores", icon: "fa-solid fa-users-gear", rolesPermitidos: ['SUPERADMINISTRADOR']},
          { name: "Notificar", title: "Enviar una notificación broadcast", routerName: "Notificar", icon: "fa-solid fa-bullhorn", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
          { name: "Servicios y test", title: "Buscar servicios existentes y realizar test", routerName: "ServiciosTest", icon: "fa-solid fa-rocket", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
          { name: "Reportes", title: "Generar informes", routerName: "Reportes", icon: "fa-solid fa-file-lines", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
          { name: "Gestión AI", routerName: "GestionAI", icon: "fa-solid fa-map-location-dot", rolesPermitidos: ['SUPERADMINISTRADOR', 'CREADORINFLUENCIA', 'EDITORINFLUENCIA']},
          { name: "Municipios y OLTs", routerName: "MunicipiosYOlts", icon: "fa-solid fa-mountain-city", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO', 'CREADORINFLUENCIA', 'EDITORINFLUENCIA']},
          { name: "Panel de administración", title: "Panel para realizar operaciones de administración", routerName: "PanelAdmin", icon: "fa-solid fa-cogs", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
          { name: "Historial completo", title: "Historial de operaciones de todos los operadores y administradores", routerName: "HistorialAdmin", icon: "fa-solid fa-clock-rotate-left", rolesPermitidos: ['SUPERADMINISTRADOR', 'INTERNOASTEO']},
        ]
        
    }
  },
  methods: {
    ...mapActions(['setUsuario']),
    async cargarNotificaciones(){
        try {
          this.cargandoNotificaciones = true;
          let respuesta = await axios({
            method: 'GET',
            url: '/notificacion',
            params: { limit: this.limit },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.notificaciones = respuesta.data.data.notificaciones;  
          let notis = document.getElementById('notificacioneslist');
          notis.scroll({ top: notis.scrollHeight, behavior: 'smooth'});      
          this.cargandoNotificaciones = false;  
        } catch (error) {
          this.cargandoNotificaciones = false;  
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajeErrorNotificaciones = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajeErrorNotificaciones = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajeErrorNotificaciones = error.message || error;
            }
        }
    },
    async cargarNotificacionesNoVistas(){
        try {          
          let respuesta = await axios({
            method: 'GET',
            url: '/notificacion/pendiente',
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.notificacionesPendientes = respuesta.data.data.notificaciones;        
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.error('Error controlado', error.response);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error genérico', error.message);
            }
        }
    },
    async notificacionVista(uid){
      var myAlert = document.getElementById('alert'+uid)
      var bsAlert = new Alert(myAlert)
      bsAlert.close();
        try {          
          let respuesta = await axios({
            method: 'PUT',
            url: '/notificacion',
            params: { uid },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.error('Error controlado', error.response);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error genérico', error.message);
            }
        }
    },
    aumentarLimite(){
      this.limit += 5;
      this.cargarNotificaciones();
    },
    esOperadorAdministrativo() {
      return isOperadorAdministrativo();
    }
  },
  created() {
    this.setUsuario();
    this.cargarNotificaciones();
    this.cargarNotificacionesNoVistas();
  },
  computed: {
    ...mapState(['usuario']),
   TipoIcono() {
      return (tipo) => {
        switch (tipo) {
          case 'success':
            return '<i class="fa-solid fa-circle-check text-success fs-3 mx-2"></i>'
          case 'danger':
            return '<i class="fa-solid fa-circle-xmark text-danger fs-3 mx-2"></i>'   
          case 'warning':
            return '<i class="fa-solid fa-triangle-exclamation text-warning fs-3 mx-2"></i>'   
          case 'question':
            return '<i class="fa-solid fa-circle-question text-secondary fs-3 mx-2"></i>'   
          default:
            return '<i class="fa-solid fa-circle-exclamation text-info fs-3 mx-2"></i>'                                        
        }
      }
    },
    TipoAlerta() {
      return (tipo) => {
        switch (tipo) {
          case 'success':
            return 'alert-success'
          case 'danger':
            return 'alert-danger'   
          case 'warning':
            return 'alert-warning'   
          case 'question':
            return 'alert-secondary'   
          default:
            return 'alert-info'                                        
        }
      }
    },
    filteredLinks(){
        let payload = getJWTpayload();
        let links = this.links;
        if (payload.roles) {
          links = links.filter(e => e.rolesPermitidos.some(r => payload.roles.indexOf(r) >= 0))
        }
        return links;
    },
  }
};
</script>


<style scoped>
.logo{
  max-width: 300px;
}
.acceso-item{
  cursor: pointer;
  height: 11rem;
  max-width: 34rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acceso-item>.card{
  width: 100%;
  height: 100%;
  margin-top: .25rem;
}
.acceso-item>.card:hover{
  margin-top: 0;
}
.acceso-item>.card:active{
  width: 97%;
  height: 97%;
}

@media (min-width: 992px){
  .lista-accesos-card{
    height: 30rem !important;
  }
}
</style>
