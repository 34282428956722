<template>
  <div class="row">
      <div class="col-12">
          <!-- Cargando -->
          <div v-if="cargandoOperadores" class="my-4">
            <b-spinner></b-spinner>
            <h4 >Cargando datos...</h4>
          </div>

          <!-- Error cargando -->
          <div v-else-if="operadores.length < 1 || mensajedeError" class="alert alert-warning d-flex align-items-center mt-4" role="alert">
            <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
            <div class="d-flex flex-column text-start">
              <div v-if="mensajedeError" class="text-danger">
                {{mensajedeError}}
              </div>  
              <div v-if="operadores.length < 1" class="text-danger">
                No se han encontrado operadores
              </div>     
            </div>
          </div>
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Enviar notificación</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>            
            <div class="card-body">
              <b-overlay>
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <!-- Inputs -->
                    <div class="d-flex align-items-baseline">
                      <h4>Operador</h4>
                      <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoOperador()"></i>
                    </div>
                        <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                            <div class="col-12 col-sm-8 col-md-9 col-lg-10">
                              <select :disabled="cargandoPeticion" v-model="operador" class="form-select my-1" aria-label="Seleccionar operador" maxlength="128">                                
                                <option value="" selected>Todos los operadores</option>
                                <option v-for="(item, index) in operadores" :key="index" :value="item.id_operador">{{item.operador}} - {{item.id_operador}}</option>
                              </select>
                            </div>
                        </div>
                    <!-- Editor -->
                    <div class="d-flex align-items-baseline">
                      <h4>Mensaje</h4>
                      <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoMensaje()"></i>
                    </div>
                        <div class="m-2">
                          <vue-editor v-model="content"
                            placeholder="Introduzca su mensaje"
                            :editorToolbar="customToolbar"
                            ref="editor"
                           >
                          </vue-editor>
                        </div>
                    <!-- Previsualización -->
                    <div v-if="content">
                    <div class="d-flex align-items-baseline mt-4">
                      <h4>Previsualización</h4>
                    </div>
                        <div id="notificacionesemergentes" class="my-2 mx-4">
                          <div class="alert fade show d-flex justify-content-between align-items-center" role="alert" :class="TipoAlerta">
                            <div class="d-flex align-items-center justify-content-start">
                              <div v-html="TipoIcono"></div>
                              <div v-html="content" class="notificacion-emergente text-start m-2 ignore-pmargin break-word">
                                  
                              </div> 
                            </div>
                            <button type="button" class="btn-close" aria-label="Close"></button>
                          </div>
                        </div>
                        <div class="row justify-content-center">
                          <div class="col-lg-5 align-self-center">
                            <ListaNotificaciones :previewContent="content"></ListaNotificaciones>
                          </div>
                        </div>
                      <hr class="mt-2">
                    </div>
                    <!-- Boton -->
                    <div class="m-auto">
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-secondary text-white boton-enviarnotificacion mx-2" @click="resetPage()">
                          Limpiar
                        </button>
                        <button type="button" :disabled="cargandoPeticion || !tipo || !content || cargandoOperadores" class="btn btn-primary text-white boton-enviarnotificacion mx-2" @click="enviarNotificacion()">
                          <i v-if="!cargandoPeticion" type="button" class="fa-solid fa-bullhorn fa-1x mx-1" title="Enviar"></i>
                          <div v-else class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Cargando petición...</span>
                          </div>
                          Enviar
                        </button>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>

          </div>

      </div>

      
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from "axios";
import { errorAlert, infoAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert';
import { VueEditor } from "vue2-editor";
import ListaNotificaciones from '../components/ListaNotificaciones.vue';
import { registrarOperacionAdmin } from '../helpers/registrarEnHistorial';

export default {

  name: 'Notificar',
  components: {
    VueEditor,
    ListaNotificaciones
  },
  data() {
    return {
      operadores: [],
      cargandoOperadores: false,
      cargandoPeticion: false,
      operador: '',
      content: '',
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
        ["link"],
        ["clean"]
      ],
      tipo: 'info',
      mensajedeError: ''
    }  
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Enviar notificación', 'En esta página puedes enviar notificaciones a todos los operadores de Asteo o individualmente.')
    },
    infoOperador(){
      infoAlert('Elige operador', 'Elige a quien enviarle la notificación, a un operador individual o a todos a la vez.')
    },
    infoMensaje(){
      infoAlert('Mensaje', 'Escriba el mensaje que desea comunicar al operador u operadores, podrá ver una previsualización de como quedará la notificación emergente y también en la ventana de notificaciones')
    },
    cambiarTipo(tipo){      
        this.tipo = tipo;
        let tipos = document.querySelectorAll(".ql-formats.custom button");
        for (const iterator of tipos) {
          iterator.classList.remove('active');
        }
        let activo = document.getElementById('bt'+tipo);
        if(activo){
          activo.classList.add('active');
        }
    },
    addTypes(){
      let toolbar = document.getElementsByClassName('ql-toolbar');
      if(toolbar[0]){
        var span = document.createElement('span');
        span.classList.add('ql-formats');
        span.classList.add('custom');
        let b1 = document.createElement('button');
        b1.setAttribute('title', 'Tipo de notificación - exito');
        b1.setAttribute('id', 'btsuccess');
        b1.onclick = () => this.cambiarTipo('success');
        b1.innerHTML = '<i class="fa-solid fa-circle-check"></i>';
        let b2 = document.createElement('button');
        b2.setAttribute('title', 'Tipo de notificación - Error')
        b2.setAttribute('id', 'btdanger');
        b2.onclick = () => this.cambiarTipo('danger');
        b2.innerHTML = '<i class="fa-solid fa-circle-xmark"></i>';
        let b3 = document.createElement('button');
        b3.setAttribute('title', 'Tipo de notificación - Info')
        b3.setAttribute('id', 'btinfo');
        b3.onclick = () => this.cambiarTipo('info');
        b3.innerHTML = '<i class="fa-solid fa-circle-exclamation"></i>';
        let b4 = document.createElement('button');
        b4.setAttribute('title', 'Tipo de notificación - Alerta')
        b4.setAttribute('id', 'btwarning');
        b4.onclick = () => this.cambiarTipo('warning');;
        b4.innerHTML = '<i class="fa-solid fa-triangle-exclamation"></i>';     
        let b5 = document.createElement('button');
        b5.setAttribute('title', 'Tipo de notificación - Pregunta')
        b5.setAttribute('id', 'btquestion');
        b5.onclick = () => this.cambiarTipo('question');;
        b5.innerHTML = '<i class="fa-solid fa-circle-question"></i>';  
        span.appendChild(b1);
        span.appendChild(b2);
        span.appendChild(b3);
        span.appendChild(b4);
        span.appendChild(b5);
        toolbar[0].prepend(span);        
      }
    },
    resetPage(){
      this.content = '';
      this.operador = '',
      this.tipo = 'info'
    },
    async getOperadores(){
        try {
          this.cargandoOperadores = true;
          let respuesta = await axios.get('/operadores', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.operadores = respuesta.data.data.listaOperadores;        
          this.cargandoOperadores = false;  
        } catch (error) {
          this.cargandoOperadores = false;  
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    async enviarNotificacion(){
        try {
          this.cargandoPeticion = true;
          let respuesta = await axios({
            method: 'POST',
            url: '/notificacion',
            params: { OPID: this.operador },
            data: {
              contenido: this.content,
              tipo: this.tipo
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          if(this.operador){
            let op = this.operadores.find(e => e.id_operador == this.operador);
            registrarOperacionAdmin("Enviar notificación", op.operador, op.id_operador, '' , {"Tipo": this.tipo, 'Mensaje': this.content})
          }else{
            registrarOperacionAdmin("Enviar notificación", "Sin definir", "0", '' , { 'Operadores': this.operadores.map(e => e.operador + ' - ' + e.id_operador).join(", "), "Tipo": this.tipo,  'Mensaje': this.content});
          }
          successAlert('Proceso completado', respuesta.data.msg);
          this.resetPage();
          this.cargandoPeticion = false;  
        } catch (error) {
          this.cargandoPeticion = false;  
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              errorAlert('No se pudo enviar la notificación', error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              serverErrorAlert(error.request);
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              errorAlert('Error genérico', error.message || error)
            }
        }
    },
  },
  created(){
    if(this.$route.params.OPID){
      this.operador = this.$route.params.OPID;
    }
    this.getOperadores();
  },
  mounted(){
    this.addTypes();
  },
  computed: {
    TipoIcono() {
      switch (this.tipo) {
        case 'success':
          return '<i class="fa-solid fa-circle-check text-success fs-3 mx-2"></i>'
        case 'danger':
          return '<i class="fa-solid fa-circle-xmark text-danger fs-3 mx-2"></i>'   
        case 'warning':
          return '<i class="fa-solid fa-triangle-exclamation text-warning fs-3 mx-2"></i>'   
        case 'question':
          return '<i class="fa-solid fa-circle-question text-secondary fs-3 mx-2"></i>'   
        default:
          return '<i class="fa-solid fa-circle-exclamation text-info fs-3 mx-2"></i>'                                        
      }
    },
    TipoAlerta() {
      switch (this.tipo) {
        case 'success':
          return 'alert-success'
        case 'danger':
          return 'alert-danger'   
        case 'warning':
          return 'alert-warning'   
        case 'question':
          return 'alert-secondary'   
        default:
          return 'alert-info'                                        
      }
    }
  },
}
</script>

<style>
.boton-enviarnotificacion{
  width: 10rem;
}

.ql-formats.custom button.active{
      color: #06c !important;
}

</style>
