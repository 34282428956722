
<template>
 <div class="h-100">
      <div class="card shadow-lg h-100">
        <div class="card-header p-3  text-start">
          <div class="d-flex justify-content-between align-items-center ignore-pmargin">
              <h4 class="fw-bold mx-2 ">Servicios por perfil</h4>
          </div>
        </div>
        <div class="card-body">
          <b-overlay :show="cargandoPerfiles" class="d-flex align-items-center justify-content-center h-100 w-100">
            <!-- Error -->
            <div v-if="errorPerfiles" class="alert alert-warning d-flex align-items-center" role="alert">
              <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
              <div class="d-flex flex-column text-start">
                <div v-if="errorPerfiles" class="text-danger">
                  {{errorPerfiles}}
                </div>  
       
              </div>
            </div>
            <!-- Tabla -->
            <div v-else  class="overflow-auto d-flex align-items-center justify-content-center  h-100 w-100">
              <canvas id="NuevosServicios" ref="chartDOM"></canvas>
            </div>
          </b-overlay>
        </div>
        <div class="card-footer">
          <div class="stats">
            <i class="fa fa-chart-simple"></i> Número de servicios asignados a cada perfil
          </div>
        </div> 
      </div> 
 </div>
</template>


<script>
import axios from "axios";
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { generateRandomIntegerInRange } from "../../helpers/utils";

Chart.register(ChartDataLabels);



var chartData = {
  type: "doughnut",
  data: {
    labels: [],
    datasets: [{
        label: "Servicios por perfil de velocidad",
        data: [],
        backgroundColor: [],
        borderColor: [],
   
      }]
  },
  options: {
    responsive: true,
    plugins: {
      legend:{
        position: 'top',
      },
      datalabels: {
            anchor: 'center',
            align: 'center',
            formatter: (val) => val > 0 ? val : null,
            font: {
                weight: 'bold',
                size: 18
            },
            color: '#000'
        }
    },

  }
};

export default {
  
  name: 'NuevosServicios',
  data() {
    return {
      errorPerfiles: '',
      cargandoPerfiles: false,
      perfiles: [],
      tabla: '',
      

    }
  },
  methods: {
    async getPerfilesStat(){
      try{        
         this.cargandoPerfiles = true;
        let respuesta = await axios({
          method: 'GET',
          url: '/estadisticas/perfiles',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.errorPerfiles = '';
        this.perfiles = respuesta.data.data.perfiles;
        chartData.data.labels = this.perfiles.map((e) => e.PERFILVELOCIDAD);
        chartData.data.datasets[0].data = this.perfiles.map((e) => e.cantidad);
        chartData.data.datasets[0].backgroundColor = [];
        chartData.data.datasets[0].borderColor = [];
        for (let index = 0; index < this.perfiles.length; index++) {
          let r = generateRandomIntegerInRange(0,255);
          let g = generateRandomIntegerInRange(0,255);
          let b = generateRandomIntegerInRange(0,255);
          let rgb = `rgb(${r},${g},${b})`
          let rgba = `rgba(${r},${g},${b},.5)`
          chartData.data.datasets[0].backgroundColor.push(rgba);        
          chartData.data.datasets[0].borderColor.push(rgb);        
        }
       // chartData.data.datasets[0].borderColor = this.perfiles.map(() => this.randomRGB());
        this.cargandoPerfiles = false;
      } catch (error) {
        this.cargandoPerfiles = false;
          if (error.response && error.response.data && error.response.data.msg) {
            console.log(error.response);
            //Request made and server responded
            this.errorPerfiles = error.response.data.msg;
          } else if (error.request) {
            console.log(error.request);
            // The request was made but no response was received
            this.errorPerfiles = 'No se ha recibido respuesta del servidor';
          } else {
            console.log(error.message);
            // Something happened in setting up the request that triggered an Error
            this.errorPerfiles = error.message || error;
          }
      } 
    },
    async cargarTabla(){
      
      if(!this.tabla){
        this.tabla = new Chart(this.$refs.chartDOM, chartData);
      }
      await this.getPerfilesStat();
      this.tabla.update();
    },
    randomRGB(){
      return `rgb(${generateRandomIntegerInRange(0,255)}, ${generateRandomIntegerInRange(0,255)}, ${generateRandomIntegerInRange(0,255)})`
    },
    randomRGBA(a = .5){
      return `rgb(${generateRandomIntegerInRange(0,255)}, ${generateRandomIntegerInRange(0,255)}, ${generateRandomIntegerInRange(0,255)}, ${a})`
    }
  
  },
  mounted() {
    this.cargarTabla();
  }
}
</script>
<style scoped>
canvas {
  max-height: 20rem;
  min-height: 10rem;
}
</style>

