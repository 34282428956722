
<template>
 <div class="h-100">
      <div class="card shadow-lg h-100">
        <div class="card-header p-3  text-start">
          <div class="d-flex justify-content-between align-items-center ignore-pmargin">
              <h4 class="fw-bold mx-2 ">Servicios {{year}}</h4>
              <div class="d-flex mx-2">
                <div @click="cambiarYear(-1)" class="pointerHover boton-icono">
                    <i class="fas fa-caret-left text-primary fa-2xl mx-2"></i>
                </div>
                <div @click="cambiarYear(+1)" class="pointerHover boton-icono">
                    <i class="fas fa-caret-right text-primary fa-2xl mx-2"></i>
                </div>
              </div>
          </div>
        </div>
        <div class="card-body">
          <b-overlay :show="cargandoAltas || cargandoReservas || cargandoBajas" class="h-100 w-100">
            <!-- Error -->
            <div v-if="errorAltas || errorReservas || errorBajas" class="alert alert-warning d-flex align-items-center" role="alert">
              <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
              <div class="d-flex flex-column text-start">
                <div v-if="errorAltas" class="text-danger">
                  {{errorAltas}}
                </div>  
                <div v-if="errorReservas" class="text-danger">
                  {{errorReservas}}
                </div>  
                <div v-if="errorBajas" class="text-danger">
                  {{errorBajas}}
                </div>                 
              </div>
            </div>
            <!-- Tabla -->
            <div v-else class="overflow-auto d-flex align-items-center justify-content-center  h-100 w-100">
              <canvas id="NuevosServicios" ref="chartDOM"></canvas>
            </div>
          </b-overlay>
        </div>
        <div class="card-footer">
          <div class="stats">
            <i class="fa fa-chart-simple"></i> Comparativa entre reservas, altas y bajas
          </div>
        </div> 
      </div> 
 </div>
</template>


<script>
import axios from "axios";
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

var dataReservas = {
      label: "Reservas de recursos",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(54,73,93,.5)",
      borderColor: "#36495d",
      borderWidth: 3
};

var dataAltas = {
      label: "Altas de servicio",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(71, 183,132,.5)",
      borderColor: "#47b784",
      borderWidth: 3
};

var dataBajas = {
      label: "Bajas de servicio",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(255, 36, 1,.5)",
      borderColor: "rgb(255, 36, 1)",
      borderWidth: 3
}; 

var chartData = {
  type: "bar",
  data: {
    labels: ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
    datasets: []
  },
  options: {
    responsive: true,
    onResize: (ctx) => {
      if(ctx.height < 230){
        ctx.config.options.plugins.legend.display = false;
        ctx.config.options.plugins.datalabels.display = false;
      }else{
        ctx.config.options.plugins.legend.display = true;
        ctx.config.options.plugins.datalabels.display = true;
      }
    },
    scales: {
      y: {
        offset: true,
        min: 0
      }
    },
    plugins: {
      legend:{
        labels:{

          display: true,
        },
        position: 'top',
        borderRadius: 100
      },
      datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -7,
            formatter: (val) => val > 0 ? val : null,
            font: {
                weight: 'bold',
                size: 18
            },
            color: '#000'
        }
    },

  }
};

export default {
  
  name: 'ServiciosChart',
  data() {
    return {
      year: new Date().getFullYear(),
      errorReservas: '',
      errorAltas: '',
      errorBajas: '',
      cargandoReservas: false,
      cargandoAltas: false,
      cargandoBajas: false,
      tabla: '',
    }
  },
  methods: {
    async getNumReservas(){
      try{        
         this.cargandoReservas = true;
        let respuesta = await axios({
          method: 'GET',
          url: '/estadisticas/reservas',
          params: { year: this.year },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.errorReservas = '';
        let lista = respuesta.data.data.reservas;
        dataReservas.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (const iterator of lista) {
          dataReservas.data[iterator.mes - 1] = iterator.cantidad;
        }
        chartData.data.datasets.push(dataReservas);
        this.cargandoReservas = false;
      } catch (error) {
        this.cargandoReservas = false;
          if (error.response && error.response.data && error.response.data.msg) {
            console.log(error.response);
            //Request made and server responded
            this.errorReservas = error.response.data.msg;
          } else if (error.request) {
            console.log(error.request);
            // The request was made but no response was received
            this.errorReservas = 'No se ha recibido respuesta del servidor';
          } else {
            console.log(error.message);
            // Something happened in setting up the request that triggered an Error
            this.errorReservas = error.message || error;
          }
      } 
    },
    async getNumAltas(){
      try{        
        this.cargandoAltas = true;
        let respuesta = await axios({
          method: 'GET',
          url: '/estadisticas/altas',
          params: { year: this.year },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.errorAltas = '';
        let lista = respuesta.data.data.altas;
        dataAltas.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (const iterator of lista) {
          dataAltas.data[iterator.mes - 1] = iterator.cantidad;
        }
        chartData.data.datasets.push(dataAltas);
        this.cargandoAltas = false;
      } catch (error) {
        this.cargandoAltas = false;
          if (error.response && error.response.data && error.response.data.msg) {
            console.log(error.response);
            //Request made and server responded
            this.errorAltas = error.response.data.msg;
          } else if (error.request) {
            console.log(error.request);
            // The request was made but no response was received
            this.errorAltas = 'No se ha recibido respuesta del servidor';
          } else {
            console.log(error.message);
            // Something happened in setting up the request that triggered an Error
            this.errorAltas = error.message || error;
          }
      } 
    },
    async getNumBajas(){
      try{        
         this.cargandoBajas = true;
        let respuesta = await axios({
          method: 'GET',
          url: '/estadisticas/bajas',
          params: { year: this.year },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.errorBajas = '';
        let lista = respuesta.data.data.bajas;
        dataBajas.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (const iterator of lista) {
          dataBajas.data[iterator.mes - 1] = iterator.cantidad;
        }
        chartData.data.datasets.push(dataBajas);
        this.cargandoBajas = false;
      } catch (error) {
        this.cargandoBajas = false;
          if (error.response && error.response.data && error.response.data.msg) {
            console.log(error.response);
            //Request made and server responded
            this.errorBajas = error.response.data.msg;
          } else if (error.request) {
            console.log(error.request);
            // The request was made but no response was received
            this.errorBajas = 'No se ha recibido respuesta del servidor';
          } else {
            console.log(error.message);
            // Something happened in setting up the request that triggered an Error
            this.errorBajas = error.message || error;
          }
      } 
    },
    async cargarTabla(){
      
      if(!this.tabla){
        this.tabla = new Chart(this.$refs.chartDOM, chartData);
      }
      chartData.data.datasets = [];
      await this.getNumReservas();
      await this.getNumAltas();
      await this.getNumBajas();

      this.tabla.update();
    },
    cambiarYear(num){
      this.year += num;
      this.cargarTabla();
    }
  },
  mounted() {
    this.cargarTabla();
  }
}
</script>
<style scoped>
canvas {
  max-height: 20rem;
  min-height: 10rem;
  min-width: 10rem;
  width: auto;
}
</style>

