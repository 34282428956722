
<template>
  <Transition name="modal" >
    <div v-if="show" class="modal-mask" >
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h4 name="header">Resultado sincronización: </h4>
            <i type="button" class="close xxl-font" title="Cerrar" data-dismiss="modal" aria-label="Close" @click="cerrarModal()" >
              <span aria-hidden="true">&times;</span>
            </i>
          </div>
          <div class="modal-body">
              <!-- ID_ADMINISTRATIVO -->
              <div class="d-flex justify-content-between align-items-center flex-column flex-sm-row">
                  <div class="d-flex align-items-center">
                      <span class="mx-2 fs-6 fw-bold align-middle">ID_ADMINISTRATIVO: </span>
                      <span class="mx-2 fs-6 align-middle">{{item.ID_ADMINISTRATIVO}} </span>
                  </div>
              </div>

              <hr class="my-1">
            
              <!-- ID_DOMICILIO -->
              <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                <span class="mx-2 fs-6 fw-bold align-middle ">ID_DOMICILIO: </span>
                <span v-if="item.ID_DOMICILIO == itemNuevo.coverage.street_address.id_domicilio" class="mx-2 fs-6 align-middle">{{item.ID_DOMICILIO}} </span>
                <div v-else class="d-flex flex-column">
                  <span class="mx-1 fs-6 align-middle text-danger text-decoration-line-through">{{item.ID_DOMICILIO}}</span>
                  <span class="mx-1 fs-6 align-middle text-success">{{itemNuevo.coverage.street_address.id_domicilio}}</span>
                </div>
              </div>
              <hr class="my-1">   

              <!-- REMOTE_ID -->
              <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                <span class="mx-2 fs-6 fw-bold align-middle ">REMOTE_ID: </span>
                <span v-if="item.REMOTE_ID == itemNuevo.remote_id" class="mx-2 fs-6 align-middle">{{item.REMOTE_ID}} </span>
                <div v-else class="d-flex flex-column">
                  <span class="mx-1 fs-6 align-middle text-danger text-decoration-line-through">{{item.REMOTE_ID}}</span>
                  <span class="mx-1 fs-6 align-middle text-success">{{itemNuevo.remote_id}}</span>
                </div>
              </div>
              <hr class="my-1">            

              <!-- TEMPLATE -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle">TEMPLATE: </span>
                <span v-if="item.TEMPLATE == itemNuevo.template" class="mx-2 fs-6 align-middle">{{item.TEMPLATE}} </span>
                <div v-else class="d-flex flex-column">
                  <span class="mx-1 fs-6 align-middle text-danger text-decoration-line-through">{{item.TEMPLATE}}</span>
                  <span class="mx-1 fs-6 align-middle text-success">{{itemNuevo.template}}</span>
                </div>       
              </div>
                <hr class="my-1">     
              </div>
 

              <!-- PROVINCIA -->
              <div v-if="item.PROVINCIA" >
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">PROVINCIA: </span>
                  <span class="mx-2 fs-6 align-middle text-capitalized">{{item.PROVINCIA}} </span>
                </div>
                <hr class="my-1">  
              </div>
              <!-- POBLACION -->
              <div v-if="item.POBLACION">
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">POBLACIÓN: </span>
                  <span class="mx-2 fs-6 align-middle  text-capitalized">{{item.POBLACION}} </span>
                </div>
                <hr class="my-1">  
              </div>

              <!-- CODIGO_OLT -->
              <div v-if="item.CODIGO_OLT">
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">CODIGO_OLT: </span>
                <span v-if="item.CODIGO_OLT == itemNuevo.coverage.street_address.codigo_olt" class="mx-2 fs-6 align-middle">{{item.CODIGO_OLT}} </span>
                <div v-else class="d-flex flex-column">
                  <span class="mx-1 fs-6 align-middle text-danger text-decoration-line-through">{{item.CODIGO_OLT}}</span>
                  <span class="mx-1 fs-6 align-middle text-success">{{itemNuevo.coverage.street_address.codigo_olt}}</span>
                </div>       
              </div>
                <hr class="my-1">  
              </div>

              <!-- CODIGO_CTO -->
              <div v-if="item.CODIGO_CTO">
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">CODIGO_CTO: </span>
                  <span v-if="item.CODIGO_CTO == itemNuevo.coverage.street_address.codigo_cto" class="mx-2 fs-6 align-middle">{{item.CODIGO_CTO}} </span>
                  <div v-else class="d-flex flex-column">
                    <span class="mx-1 fs-6 align-middle text-danger text-decoration-line-through">{{item.CODIGO_CTO}}</span>
                    <span class="mx-1 fs-6 align-middle text-success">{{itemNuevo.coverage.street_address.codigo_cto}}</span>
                  </div>
                </div>
                <hr class="my-1">  
              </div>

              <!-- FailMessage -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">provision_fail_message: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.provision_fail_message}} </span>
                </div>
                <hr class="my-1">  
              </div>
              <!-- provision_type -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">provision_type: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.provision_type}} </span>
                </div>
                <hr class="my-1">  
              </div>              

              <!-- state -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">state: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.state}} </span>
                </div>
                <hr class="my-1">  
              </div>   

              <!-- vlan -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">vlan: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.vlan}} </span>
                </div>
                <hr class="my-1">  
              </div>   

              <!-- frame_olt -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">Frame (frame_olt): </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.coverage.network_trace.frame_olt}} </span>
                </div>
                <hr class="my-1">  
              </div>   

              <!-- tarjeta_olt - slot -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">Slot (tarjeta_olt): </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.coverage.network_trace.tarjeta_olt}} </span>
                </div>
                <hr class="my-1">  
              </div>                 

              <!-- puerto_gpon - port -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">Port (puerto_gpon): </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.coverage.network_trace.puerto_gpon}} </span>
                </div>
                <hr class="my-1">  
              </div>      

              <!-- puerto_cto -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">puerto_cto: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.coverage.network_trace.puerto_cto}} </span>
                </div>
                <hr class="my-1">  
              </div>  

              <!-- splitter_id -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">splitter_id: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.coverage.network_trace.splitter_id}} </span>
                </div>
                <hr class="my-1">  
              </div>  

              <!-- tipo_splitter -->
              <div>
                <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
                  <span class="mx-2 fs-6 fw-bold align-middle ">tipo_splitter: </span>
                  <span class="mx-2 fs-6 align-middle">{{itemNuevo.coverage.network_trace.tipo_splitter}} </span>
                </div>
                <hr class="my-1">  
              </div>  

              <div class="d-flex justify-content-center mt-4">
                  <button type="button" class="btn btn-primary boton-generar mx-2" @click="cerrarModal()">
                    Vale
                  </button>
              </div>	
          </div>

        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { fecha } from '../classes/fecha.class';


export default {
  name: "ModalSincronizacion",
  props: ['item'],
  data() {
    return {
      show: false,
      itemNuevo: {}
    }
  },
  methods: {
    cerrarModal(){
      this.show = false;
    },
    abrirModal(item_sincronizado){
      this.show = true;
      this.itemNuevo = item_sincronizado;
    },
   

  },
  created() {
   
  },
  computed: {
    //Fecha con formato propio
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },
  }


}
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  max-height: 90vh;
  margin: 2rem auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>