import router from '../router'
import Swal from 'sweetalert2'
import axios from "axios";
import { getJWTpayload } from './JWT';
import { montarNombreCalle } from '../helpers/utils';
import { errorAlert, noResponseAlert, serverErrorAlert, successAlert } from './fastAlert';


export function ponerTrazadosEnCola(cambios, listaTrazados) {
    //Cogemos la cola de trazados actual
    let listaCambios = JSON.parse(store.state.colaTrazados || null)
    if (!listaCambios) listaCambios = []

    for (const trazado of listaTrazados) {

        listaCambios = listaCambios.filter(e => e.uid != trazado.uid)
        let linea = {}
        for (const key of Object.keys(cambios)) {
            linea[key] = {
                'OLD': trazado[key],
                'NEW': cambios[key].editado ? cambios[key].valor : trazado[key]
            }
        }
        linea.uid = trazado.uid
        listaCambios.push(linea)
    }
    //Llamamos a la acción del vuex storage "setColaHogares"
    store.dispatch('setColaTrazados', JSON.stringify(listaCambios))


};


export async function borrarHogarEditor(listaHogares) {
    if (!listaHogares || !listaHogares.length) return false;

    let mensaje = listaHogares.length > 1 ? `Se añadirán <strong>${listaHogares.length}</strong> hogares a la cola para eliminarlos` : `El domicilio ${listaHogares[0].ID_DOMICILIO} - <strong>${listaHogares[0]['DIRECCION COMPLETA']}</strong> se añadirá a la cola de eliminación.`
    let result = await Swal.fire({
        title: '¿Está seguro?',
        html: mensaje,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
    })
    if (!result.isConfirmed) return false;

    try {
        const respuesta = await axios({
            method: 'DELETE',
            url: '/hogares/poner_UUIIs_borradas_en_cola_edicion_huella',
            data: {
                DOMICILIOS: listaHogares.map(e => e.ID_DOMICILIO)
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        successAlert('¡Operación completada!', respuesta.data.msg)
    } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
            console.log(error.response);
            errorAlert('¡Error eliminando uuii de cola de edición de hogares!', error.response.data.msg)
            this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
            console.log(error.request);
            this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
            console.log(error.message);
            this.mensajedeError = error.message || error;
        }
    }
    return true
};

export async function borrarTrazadoEditor(listaTrazados) {
    let mensaje = listaTrazados.length > 1 ? `Se añadirán <strong>${listaTrazados.length}</strong> trazados a la cola de eliminación` : `El siguiente trazado: <br><br> <strong>Codigo CTO</strong>: ${listaTrazados[0].CODIGO_CTO}<br> <strong>S/F/P</strong>: ${listaTrazados[0].TARJETA_OLT}/${listaTrazados[0].FRAME_OLT}/${listaTrazados[0].PUERTO_GPON} <br><br> Se añadirá a la cola de eliminación.`

    let result = await Swal.fire({
        title: '¿Está seguro?',
        html: mensaje,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
    })
    if (!result.isConfirmed) return false;
    let listaCambios = JSON.parse(store.state.colaTrazados || null);
    if (!listaCambios) listaCambios = []

    for (const trazado of listaTrazados) {
        let linea = {
            ELIMINADO: true
        }
        for (const key of Object.keys(trazado)) {
            linea[key] = {
                'OLD': trazado[key],
                'NEW': ''
            }
        }
        linea.uid = trazado.uid
        listaCambios = listaCambios.filter(e => e.uid != trazado.uid)
        listaCambios.push(linea)
    }
    //Llamamos a la acción del vuex storage "setColaTrazados"
    store.dispatch('setColaTrazados', JSON.stringify(listaCambios))

    return true
};