<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Cambio de ONT</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Volver a mis Servicios-->
              <div>
                  <router-link :to="{name: item.editorAdmin ? 'ServiciosTest' : 'Servicios'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fa-solid fa-coins mx-1"></i> Mis servicios</router-link>
              </div>
            </div>
            
            
            <div class="card-body">
              <!-- Tarjeta informativa -->
              <TarjetaServicio :item="item"></TarjetaServicio>

              <!-- Nuevo ONT -->
              <b-overlay :show="cargandoServicio">
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <!-- Action card header -->
                    <div class="d-flex align-items-baseline">
                      <h4>Nuevo ONT</h4>
                    </div>
                    
                    <form v-if="item && item.ID_ADMINISTRATIVO" @submit.prevent="cambiarONT()" novalidate>
                      <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                          <!-- REMOTE_ID -->
                          <div class="col-12 col-sm-3 col-md-2 col-lg-2 my-1">
                              <label for="Remote_ID" class="line mx-1 fs-5 d-flex align-items-center">Remote ID</label>
                          </div>
                          <div class="col-12 col-sm-5 col-md-7 col-lg-8 my-1">
                              <input id="Remote_ID"  class="form-control w-100 m-0" type="text" required maxlength="50" v-model="$v.REMOTE_ID.$model" placeholder="Número de serie" :class="{ 'is-invalid': $v.REMOTE_ID.$error}" autofocus autocomplete="off">
                          </div>
                          <!-- BOTON -->
                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-1">
                              <button type="submit" :disabled="$v.$invalid || cargandoPeticion" class="btn btn-primary text-white w-100 launch-btn">
                                <i v-if="!cargandoPeticion" type="button" class="fa-solid fa-save fa-1x mx-1" title="Cambiar ONT"></i>
                                <div v-else class="spinner-border spinner-border-sm" role="status">
                                  <span class="visually-hidden">Cargando petición...</span>
                                </div>
                                Guardar
                              </button>
                          </div>
                          <small v-show="$v.REMOTE_ID.$dirty && !$v.REMOTE_ID.required" class="text-danger">
                            Debe de introducir un número de serie
                          </small>
                      </div>
                    </form>
                    <div v-else class="alert alert-warning d-flex align-items-center" role="alert">
                        <i class="fa-solid fs-5 fa-triangle-exclamation text-warning mx-2"> </i>                
                      <div>
                        Los datos del servicio son erroneos
                      </div>
                    </div>
                    
                  </div>
                </div>
              </b-overlay>
            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert } from '../helpers/fastAlert';
import { empezarEsperaCambioONT, necesitaEsperar } from '../helpers/bloqueosPorTiempo';

import Swal from 'sweetalert2'
import axios from "axios";
import { required, maxLength } from 'vuelidate/lib/validators';
import TarjetaServicio from '@/components/TarjetaServicio';
import { verificarJWT, desconectar, getJWTpayload, isInternoAsteo } from '../helpers/JWT';
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';

export default {
  /*
    En esta página se permite cambiar la ONT de un servicio, iniciando un tiempo de 1 minuto
    en el que no se puede volver a hacer una operación para el mismo servicio
  */
  name: 'CambioONT',
  components: {
    TarjetaServicio
  },
  data() {
    return {
      cargandoPeticion: false,
      cargandoServicio: false,
      REMOTE_ID: ''                 //Nuevo número de serie  
    } 
  },
  //Validaciones
  validations: {
    REMOTE_ID: {
      required,
      maxLength: maxLength(50)
    }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Cambio de ONT', 'En esta página se puede cambiar la ONT del servicio, es decir el <strong>REMOTE_ID</strong> o <strong>S/N</strong>.', require("@/assets/ontsn.png"), 'Remote ID')
    },
    //Peticion de cambio de ont
    async cambiarONT(){
      try {
        if(necesitaEsperar(this.item.ID_ADMINISTRATIVO)) return;
        this.cargandoPeticion = true;
        
        //Peticion de verificacion de seguridad
        let verificado =  await verificarJWT();
        if (!verificado) {
            errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
            desconectar();
            return;
        }

        let respuestaOrquestador = await axios({
          method: 'POST',
          url: '/cambioONT',
          data: { 'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO, 'REMOTE_ID': this.REMOTE_ID },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        empezarEsperaCambioONT(this.item.ID_ADMINISTRATIVO);

        Swal.fire({
          title: 'Proceso ejecutado correctamente',
          text:  respuestaOrquestador.data.msg,
          icon: 'success',
          confirmButtonText: 'Vale'
        }).then(() => {
          localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
          this.$router.push({name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});
        })
        
        this.cargandoPeticion = false;
      } catch (error) {
        this.cargandoPeticion = false;
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
        return;
      }
    },
    //Recuperar el servicio actual de la localStorage
    getServicio(){
      if(this.$route.params.item){
        localStorage.setItem('servicioActivo', JSON.stringify(this.$route.params.item));
      }
      if(localStorage.getItem('servicioActivo')){
        this.item = JSON.parse(localStorage.getItem('servicioActivo'));
      }      
      if(!this.item){
        this.$router.push({ name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});
      }
      localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
    },
  },
  created(){
    this.getServicio();
  }
}
</script>

<style scoped>

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
  }
}

</style>
