<template>

    <div class="card page-card border-0 shadow-lg my-5">
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Revisión nueva huella</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>

      <div v-if="!cargandoPeticion && (!listaRevisiones || !listaRevisiones.length)" class="alert alert-info d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-circle-exclamation text-info mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          ¡No hay ninguna solicitud por revisar!  
        </div>
      </div>

      <!-- ################################ -->
      <!-- Tablas con los nuevos domicilios -->
      <!-- ################################ -->
      <div v-for="(item, index) in listaRevisiones" :key="index" class="m-3 card shadow">
          <div class="card-body"> 
            <b-overlay :show="cargandoPeticion">
              <!-- header -->
              <div class="d-flex align-items-baseline justify-content-between">
                <div class="d-flex align-items-baseline">
                  <h4>Petición de nueva huella</h4>
                  <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoPeticionHuella(item.usuario)"></i>
                </div>
                <span class="badge bg-warning text-dark">{{item.usuario}}</span>
              </div>
              <div class="mt-3">
                  <div class="d-flex align-items-md-baseline justify-content-start mb-2">
                    <div class="mx-1 text-start m-font text-secondary text-capitalized">
                        <span>{{item.descripcion}}</span>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table :id="'tablaRevisionHuella' + index" class="table table-striped w-100 table-tiny" width="100%">
                        <thead>
                          <tr class="noselect">
                            <th scope="col" class="align-middle" style="min-width: 10rem;">DIRECCION COMPLETA</th>
                            <th scope="col" class="align-middle">ID_DOMICILIO</th>
                            <th scope="col" class="align-middle">HOGAR_POTENCIAL</th>
                            <th scope="col" class="align-middle">CODIGO_POSTAL</th>
                            <th scope="col" class="align-middle">PROVINCIA</th>
                            <th scope="col" class="align-middle">POBLACION</th>
                            <th scope="col" class="align-middle">TIPO_VIA</th>
                            <th scope="col" class="align-middle">NOMBRE_VIA</th>
                            <th scope="col" class="align-middle">ID_TECNICO</th>
                            <th scope="col" class="align-middle">NUMERO</th>
                            <th scope="col" class="align-middle">BIS</th>
                            <th scope="col" class="align-middle">BLOQUE_FINCA</th>
                            <th scope="col" class="align-middle">IDENTIFICADOR_FINCA</th>
                            <th scope="col" class="align-middle">LETRA_FINCA</th>
                            <th scope="col" class="align-middle">ESCALERA</th>
                            <th scope="col" class="align-middle">PLANTA</th>
                            <th scope="col" class="align-middle">MANO_1</th>
                            <th scope="col" class="align-middle">MANO_2</th>
                            <th scope="col" class="align-middle">OBSERVACIONES</th>
                            <th scope="col" class="align-middle">FLAG_DUMMY</th>
                            <th scope="col" class="align-middle">CODIGO_INE</th>
                            <th scope="col" class="align-middle">CODIGO_CENSAL</th>
                            <th scope="col" class="align-middle">CODIGO_PAI</th>
                            <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                            <th scope="col" class="align-middle">CODIGO_CTO</th>
                            <th scope="col" class="align-middle">TIPO_CTO</th>
                            <th scope="col" class="align-middle">DIRECCION_CTO</th>
                            <th scope="col" class="align-middle">TIPO_PERMISO</th>
                            <th scope="col" class="align-middle">TIPO_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle">NUMERO_UI</th>
                            <th scope="col" class="align-middle">NUMERO_VIVIENDAS</th>
                            <th scope="col" class="align-middle">FECHA_ALTA</th>
                            <th scope="col" class="align-middle">CODIGO_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle">UBICACION_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle" style="min-width: 13rem;">GESCAL_37</th>
                          </tr>
                        </thead>
                        <tbody class="align-middle text-start">                   
                        </tbody>
                    </table>
                  </div>

                  <hr class="mt-4">
                  <!-- Formulario para enviar peticion -->
                  <div  class="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-2">
                      <button type="button" class="btn btn-primary fit-content line align-self-center mt-3 m-lg-0" :disabled="cargandoPeticion" @click="aceptarRevision(item)">
                            <i  v-if="!cargandoPeticion" class="fa-solid fa-check mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando...</span>
                            </div>
                            Aceptar petición
                      </button>
                      <button type="button" class="btn btn-danger fit-content line align-self-center mt-3 m-lg-0" :disabled="cargandoPeticion" @click="denegarRevision(item)">
                            <i  v-if="!cargandoPeticion" class="fa-solid fa-xmark mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando...</span>
                            </div>
                            Denegar petición
                      </button>
                  </div>	
              </div>
            </b-overlay>
          </div>
      </div>

    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert, warningAlert } from '../../helpers/fastAlert';
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import axios from "axios";
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from 'sweetalert2'
import { montarNombreCalle, scrollToTop } from '../../helpers/utils';
import { fecha } from '../../classes/fecha.class';

export default {
  name: 'RevisionHuella',
  data() {
    return {
      //Nuevo municipio
      listaRevisiones: [],
      mensajedeError: '',
      cargandoPeticion: false,
    } 
  },
  validations () {
    return {

    }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Revisión de nueva huella', 'Desde esta página puedes puedes validar y aceptar peticiones de creación de nuevos domicilios en la huella de Asteo.')
    },
    infoPeticionHuella(usuario){
      infoAlert('Petición de nueva huella', 'El usuario <strong>' + usuario + '</strong> ha hecho una petición de <strong>creación de huella</strong> para que sea validada.')
    },
    initDatatable(){
      //Creamos un datatable dinamico por cada revisión
      for (let index = 0; index < this.listaRevisiones.length; index++) {    
        this.listaRevisiones[index].tabla = $('#tablaRevisionHuella' + index).DataTable(
         {
                language: { 
                  
                  "oPaginate": {
                    "sPrevious": "&#10094;&#10094;",
                    "sNext":     "&#10095;&#10095;",
                  },
                },
                columns: [
                  { data: 'DIRECCION COMPLETA',
                    render: (data, type, row) => {
                      return montarNombreCalle(row)
                    } 
                  },
                  { data: 'ID_DOMICILIO' },
                  { data: 'HOGAR_POTENCIAL' },
                  { data: 'CODIGO_POSTAL' },
                  { data: 'PROVINCIA' },
                  { data: 'POBLACION' },
                  { data: 'TIPO_VIA' },
                  { data: 'NOMBRE_VIA' },
                  { data: 'ID_TECNICO' },
                  { data: 'NUMERO' },
                  { data: 'BIS' },
                  { data: 'BLOQUE_FINCA' },
                  { data: 'IDENTIFICADOR_FINCA' },
                  { data: 'LETRA_FINCA' },
                  { data: 'ESCALERA' },
                  { data: 'PLANTA' },
                  { data: 'MANO_1' },
                  { data: 'MANO_2' },
                  { data: 'OBSERVACIONES' },
                  { data: 'FLAG_DUMMY' },
                  { data: 'CODIGO_INE' },
                  { data: 'CODIGO_CENSAL' },
                  { data: 'CODIGO_PAI' },
                  { data: 'CODIGO_OLT' },
                  { data: 'CODIGO_CTO' },
                  { data: 'TIPO_CTO' },
                  { data: 'DIRECCION_CTO' },
                  { data: 'TIPO_PERMISO' },
                  { data: 'TIPO_CAJA_DERIVACION' },
                  { data: 'NUMERO_UI' },
                  { data: 'NUMERO_VIVIENDAS' },
                  { data: 'FECHA_ALTA' },
                  { data: 'CODIGO_CAJA_DERIVACION' },
                  { data: 'UBICACION_CAJA_DERIVACION' },
                  { data: 'GESCAL_37' },
                ],
         });
        //Nos aseguramos de que la tabla sea responsive
        setTimeout(() => {
          $('#tablaRevisionHuella' + index).wrap('<div class="table-responsive"></div>')
        }, 500);
        //Añadimos los domicilios
        this.listaRevisiones[index].tabla.clear()
        this.listaRevisiones[index].tabla.rows.add(this.listaRevisiones[index].domicilios).draw()
      }



    },
    async aceptarRevision(item){
       try {
          let result = await Swal.fire({
            title: '¿Está seguro?',
            html: `¡Los la hogares se añadirán permanentemente tanto en <strong>Pandora</strong> como en el <strong>orquestador</strong>! Se enviará un correo electrónico a <strong>${item.usuario}</strong> confirmando los cambios.`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar',
          })
          if(!result.isConfirmed) return;
          
          this.cargandoPeticion = true;
          let respuesta = await axios({
            method: 'POST',
            url: '/hogares/aceptar_solicitud',
            params: { uid: item.uid },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.obtenerRevisiones();
          console.log(respuesta);
          this.mostrarResultadoCambios(true, 'Se ha actualizado la huella correctamente', respuesta.data.data)
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            this.mostrarResultadoCambios(false, error.response.data.msg, error.response.data.data || '')
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
          }
        }
    },
    async denegarRevision(item){
       try {
          let result = await Swal.fire({
            title: '¿Está seguro?',
            html: `Se enviará un correo a <strong>${item.usuario}</strong> indicandole que se han denegado los cambios.`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar',

            input: 'textarea',
            inputPlaceholder: 'Indica porque no has aceptado la solicitud...',
            inputValidator: (value) => {
              if (!value) {
                return 'Tienes que indicar la razón'
              }
            }
       
          })
          if(!result.isConfirmed) return;

          this.cargandoPeticion = true;
          let respuesta = await axios({
            method: 'POST',
            url: '/hogares/denegar_solicitud',
            params: { uid: item.uid },
            data: { descripcion: result.value},
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.obtenerRevisiones();
          successAlert('Se ha denegado la solicitud', respuesta.data.msg)
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            errorAlert('No se pudo denegar la solicitud', error.response.data.msg)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
          }
        }
    },    
    async obtenerRevisiones(){
        try {
          this.cargandoPeticion = true;
          this.listaRevisiones = [];
          let respuesta = await axios.get('/hogares/solicitudes', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.listaRevisiones = respuesta.data.data.revisiones; 
          this.$emit('refresh');
            this.$nextTick(() => {
               this.initDatatable()
               this.cargandoPeticion = false;
            });
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },    
    mostrarResultadoCambios(success = true, msg = '', datos = '', listaErrores = []){
      if(datos){
        //Mostramos el resumen de la operación
        datos = `
          <br>
          <table class="table table-bordered text-start">
            <tbody>
              <tr>
                  <th style="min-width: 9rem;" class="table-dark">Total</th>
                  <td class="table-dark">
                      ${datos.total}
                  </td>
              </tr>
              <tr>
                  <th style="min-width: 9rem;">Creados</th>
                  <td class="table-light">
                      ${datos.created}
                  </td>
              </tr>
              <tr>
                  <th style="min-width: 9rem;">Actualizados</th>
                  <td class="table-light">
                      ${datos.updated}
                  </td>
              </tr>                      
              <tr>
                  <th style="min-width: 9rem;">Fallidos</th>
                  <td class="table-light">
                      ${datos.failed}
                  </td>
              </tr>                                       
            </tbody>
          </table>
          `
      }
      let icon = 'success'
      let title = "Operación completada"
      if(!success){
        icon = 'error';
        title = "¡Error en la operación"
      }
      Swal.fire({
        title,
        html: `
         <p>${msg}</p>
         ${listaErrores.join('<br>')}
         ${datos}
        `,
        icon,
        confirmButtonText: 'Vale'
     })
    },
  },
  created() {
    this.obtenerRevisiones();
  },
  mounted(){
  },
}
</script>

<style scoped>
.action-column{
  width: 2rem;
}
.tabla-olts{
    max-width: 30rem;
}
</style>
