
<template>
      <!--Paginador-->
      <div>
        <div v-if="numRegistros && ultimaPagina > 1" class="noselect">
            <nav aria-label="Paginador registros" class="d-flex align-items-center">
              <ul class="pagination my-0">
                <!-- << -->
                <li class="page-item pointerHover" :class="{ disabled: (page <= 1)}" @click="setPage(page - 1)"><a class="page-link">&#10094;&#10094;</a></li>
              
                <!-- 1 -->
                <li class="page-item pointerHover" v-show="page > 3" @click="setPage(1)"><a class="page-link">1</a></li>
                <!-- ... -->
                <li class="page-item pointerHover disabled" v-show="page > 4"><a class="page-link" >...</a></li>
                
                <!-- page - 2 -->
                <li class="page-item pointerHover" v-show="page - 2 > 0"  @click="setPage(page - 2)"><a class="page-link">{{page - 2}}</a></li>
                <!-- page - 1 -->
                <li class="page-item pointerHover" v-show="page - 1 > 0"  @click="setPage(page - 1)"><a class="page-link">{{page - 1}}</a></li>
                <!-- page -->
                <li class="page-item pointerHover active"><a class="page-link">{{page}}</a></li>
                <!-- page + 1 -->
                <li class="page-item pointerHover" v-show="page + 1 <= ultimaPagina" @click="setPage(page + 1)"><a class="page-link">{{page + 1}}</a></li>
                <!-- page + 2 -->
                <li class="page-item pointerHover" v-show="page + 2 <= ultimaPagina" @click="setPage(page + 2)"><a class="page-link">{{page + 2}}</a></li>                      
                
                <!-- ... -->
                <li class="page-item pointerHover disabled" v-show="page < ultimaPagina - 4"><a class="page-link">...</a></li>
                <!-- LAST -->
                <li class="page-item pointerHover" v-show="page < ultimaPagina - 2" @click="setPage(ultimaPagina)"><a class="page-link">{{ultimaPagina}}</a></li>

                <!-- >> -->
                <li class="page-item pointerHover" :class="{ disabled: (page == ultimaPagina) }" @click="setPage(page + 1)"><a class="page-link">&#10095;&#10095;</a></li>
              </ul>
            </nav>
        </div>    
      </div>
</template>


<script>

import { desexpandirTodo } from '../helpers/utils';
export default {
  
  name: 'Paginador',
  props: ['page', 'numRegistros', 'registrosPorPagina'],
  data() {
    return {
      ultimaPagina: 0,
    }
  },
  methods: {
    setPage(num){
      if(num < 1 || num > this.ultimaPagina) return; 
      desexpandirTodo();
      this.$emit('recargar', num)
    },
    irUltimaPagina(){
      if(this.page != this.ultimaPagina){
        this.setPage(this.ultimaPagina);
      }
    }
  },
  created(){
    this.ultimaPagina = Math.ceil(this.numRegistros/this.registrosPorPagina);
  },
  updated() {    
    this.ultimaPagina = Math.ceil(this.numRegistros/this.registrosPorPagina);
  }
}
</script>

