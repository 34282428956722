/*
Coge una lista de pueblos con el formato 
    {provincia1, pueblo1, ...}
    {provincia1, pueblo2, ...}
    {provincia2, pueblo3, ...}
y lo formatea a una lista de provincias con pueblos:
    {provincia1, pueblos [pueblo1,pueblo2]}
    {provincia2, pueblos [pueblo3], ...}
*/
export function procesarCoberturas(listaCoberturas) {
    let nuevaLista = []
        //Organizar por provincia
    for (const iterator of listaCoberturas) {
        let encontrado = nuevaLista.find(dato => dato.provincia == iterator.provincia);
        let { provincia, id_operador, uid, poblacion } = iterator;
        if (!encontrado) {
            nuevaLista.push({ provincia, id_operador, uid, poblaciones: [poblacion] })
        } else {
            encontrado.poblaciones.push(poblacion);
        }
    }
    //Ordenar por provincias alfabeticamente
    nuevaLista.sort(function(a, b) {
        return a.provincia.localeCompare(b.provincia);
    });

    //Ordenar los pueblos alfabeticamente
    for (const iterator of nuevaLista) {
        if (iterator.poblaciones.length > 1) {
            iterator.poblaciones = iterator.poblaciones.sort();
        }
    }
    return nuevaLista;
}


/*
Coge una lista de pueblos con el formato 
    {provincia1, pueblos [pueblo1,pueblo2]}
    {provincia2, pueblos [pueblo3], ...}
y lo formatea a una lista de provincias con pueblos:
    {provincia1, pueblo1, ...}
    {provincia1, pueblo2, ...}
    {provincia2, pueblo3, ...}
*/
export function procesarCoberturasInvertido(listaCoberturas) {
    let nuevaLista = []

    //Organizar por provincia
    for (const prov of listaCoberturas) {
        for (const pueblo of prov.poblaciones) {
            nuevaLista.push({ provincia: prov.provincia, poblacion: pueblo })
        }
    }

    return nuevaLista;
}

/*
Busca todos los elementos con la clase 'show' (los cuales son colapsables expandidos) y la elimina desexpandiendo todo.
Despues cambia todos los botones rojos con un - y los pone verdes con un + indicando que no estan expandidos.
*/
export function recalcularAltura() {

    for (const iterator of document.querySelectorAll('.acordeon.show')) {
        iterator.style.height = iterator.firstChild.offsetHeight + 39 + 'px';
    }

}

export function desexpandirTodo() {
    let items = document.querySelectorAll('.acordeon.show');
    if (!items) return;
    //Desexpandir
    for (const iterator of items) {
        iterator.classList.remove('show');
        iterator.style.height = 0 + 'px';
    }
    //Desactivar botones
    let botonesActivos = document.querySelectorAll('.expand-btn>.fa-minus')
    for (const iterator of botonesActivos) {
        iterator.parentElement.classList.remove('bg-danger')
        iterator.parentElement.classList.add('bg-primary')
        iterator.classList.remove('fa-minus')
        iterator.classList.add('fa-plus')
    }
}

//animar acordeones
export function animarCollapse(id) {

    let colapsable = document.getElementById(id);
    let expandido = colapsable.classList.contains('show');
    for (const iterator of document.getElementsByClassName('acordeon')) {
        iterator.classList.remove('show');
        iterator.style.height = 0 + 'px';
    }
    if (!expandido) {
        colapsable.classList.add('show');
        colapsable.style.height = colapsable.scrollHeight + 'px';
    }
}

//Formato de botones de acordeones
export function toggleIcon(id) {
    let icono = document.getElementById('boton' + id);
    let expandido = document.getElementById('acordeon' + id).classList.contains('show');
    for (const iterator of document.getElementsByClassName('expand-icon')) {
        iterator.parentElement.classList.remove('bg-danger')
        iterator.parentElement.classList.add('bg-primary')
        iterator.classList.remove('fa-minus')
        iterator.classList.add('fa-plus')
    }
    if (expandido) {
        icono.classList.remove('fa-plus')
        icono.classList.add('fa-minus')
        icono.parentElement.classList.remove('bg-primary')
        icono.parentElement.classList.add('bg-danger')
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function readUploadedFileAsText(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("No se ha podido leer el archivo"));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsText(inputFile);
    });
};

//Formatea una calle legible para el ser humano a partir de los datos de hogares
export function montarNombreCalle(domicilio) {
    //Iniciamos poniendo el tipo de vía y el nombre de la vía.
    let calle_total = `${domicilio.TIPO_VIA} ${domicilio.NOMBRE_VIA}`

    //NUMERO
    let numero = parseInt(domicilio.NUMERO);
    if (numero == 99999) numero = 'S/N'

    calle_total += isNaN(numero) ? ' ' + numero : ' Nº' + numero;

    //BIS/DUPLICADO
    let bis = '';
    switch (domicilio.BIS) {
        case "B":
            bis += " BIS"
            break;
        case "D":
            bis += " DUPLICADO"
            break;
        case "T":
            bis += " TRIPLICADO"
            break;
        case "C":
            bis += " CUADRUPLICADO"
            break;
        case "Q":
            bis += " QUINTUPLICADO"
            break;
        case "K":
            bis += " KILOMETRO"
            break;
        case "Y":
            bis += " KILOMETRO MARGEN IZQUIERDO"
            break;
        case "X":
            bis += " KILOMETRO MARGEN DERECHO"
            break;
        default:
            break;
    }
    calle_total += bis;
    calle_total += ", "

    //BLOQUE_FINCA
    if (domicilio.BLOQUE_FINCA) calle_total += 'BLOQUE  ' + domicilio.BLOQUE_FINCA + ',';

    //IDENTIFICADOR FINCA
    if (domicilio.IDENTIFICADOR_FINCA) calle_total += 'FINCA ' + domicilio.IDENTIFICADOR_FINCA + ',';

    //LETRA FINCA
    if (domicilio.LETRA_FINCA) calle_total += 'LETRA FINCA ' + domicilio.LETRA_FINCA + ',';

    //PLANTA
    let planta = ' '
    switch (domicilio.PLANTA) {
        case "AL":
            planta += "ALTILLO"
            break;
        case "AM":
            planta += "ALMACEN"
            break;
        case "AS":
            planta += "ASCENSOR"
            break;
        case "AT":
            planta += "ATICO"
            break;
        case "BA":
            planta += "BAJO"
            break;
        case "BU":
            planta += "BUNGALOW"
            break;
        case "CH":
            planta += "CHALET"
            break;
        case "DU":
            planta += "DUPLEX"
            break;
        case "EN":
            planta += "ENTRESUELO"
            break;
        case "ET":
            planta += "ENTREPLANTA"
            break;
        case "GA":
            planta += "GARAJE"
            break;
        case "KI":
            planta += "KIOSCO"
            break;
        case "MO":
            planta += "MODULO"
            break;
        case "LO":
            planta += "LOCAL"
            break;
        case "NA":
            planta += "NAVE"
            break;
        case "OF":
            planta += "OFICINA"
            break;
        case "PR":
            planta += "PRINCIPAL"
            break;
        case "PT":
            planta += "PUESTO"
            break;
        case "SA":
            planta += "SOBREATICO"
            break;
        case "SE":
            planta += "SEMISOTANO"
            break;
        case "SO":
            planta += "SOTANO"
            break;
        case "SS":
            planta += "SEMISOTANO"
            break;
        case "S1":
            planta += "SOTANO 1"
            break;
        case "S2":
            planta += "SOTANO 2"
            break;
        case "S3":
            planta += "SOTANO 3"
            break;
        case "S4":
            planta += "SOTANO 4"
            break;
        case "S5":
            planta += "SOTANO 5"
            break;
        case "S6":
            planta += "SOTANO 6"
            break;
        case "S7":
            planta += "SOTANO 7"
            break;
        case "S8":
            planta += "SOTANO 8"
            break;
        case "S9":
            planta += "SOTANO 9"
            break;
        case "TE":
            planta += "TERRAZA"
            break;
        case "TI":
            planta += "TIENDA"
            break;
        case "X1":
            planta += "SEMISOTANO 1"
            break;
        case "X2":
            planta += "SEMISOTANO 2"
            break;
        case "X3":
            planta += "SEMISOTANO 3"
            break;
        case "X4":
            planta += "SEMISOTANO 4"
            break;
        case "X5":
            planta += "SEMISOTANO 5"
            break;
        case "X6":
            planta += "SEMISOTANO 6"
            break;
        case "X7":
            planta += "SEMISOTANO 7"
            break;
        case "X8":
            planta += "SEMISOTANO 8"
            break;
        case "X9":
            planta += "SEMISOTANO 9"
            break;
        default:
            if (!isNaN(domicilio.PLANTA)) {
                calle_total += 'PLANTA: ' + parseInt(domicilio.PLANTA);
            } else {
                calle_total += 'PLANTA: ' + domicilio.PLANTA;
            }
            break;
    }
    calle_total += planta;

    //Datos adicionales
    if (domicilio.MANO_1) calle_total += ' ' + domicilio.MANO_1;
    if (domicilio.MANO_2) calle_total += ' ' + domicilio.MANO_2;
    calle_total += ", ";

    //Portal doble
    if (domicilio.FLAG_DUMMY && domicilio.FLAG_DUMMY != 0) calle_total += 'PORTAL DOBLE, ';

    //Escalera
    let escalera = ''
    switch (domicilio.ESCALERA) {
        case '':
            escalera += '';
            break;
        case 'V':
            escalera += 'ESC. IZQUIERDA, ';
            break;
        case 'W':
            escalera += 'ESC. DERECHA, ';
            break;
        case 'X':
            escalera += 'ESC. CENTRO, ';
            break;
        case 'Y':
            escalera += 'ESC. INFERIOR, ';
            break;
        case 'Z':
            escalera += 'ESC. EXTERIOR, ';
            break;
        default:
            if (domicilio.ESCALERA) escalera += 'ESC. ' + domicilio.ESCALERA + ', '
            break;
    }
    calle_total += escalera;
    //POBLACION
    calle_total += ' ' + domicilio.POBLACION

    //PROVINCIA
    calle_total += ' (' + domicilio.PROVINCIA + ')';

    domicilio.callemontada = calle_total;

    return calle_total;
}


export function generateRandomIntegerInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//El tipo puede ser excel o csv
export function crearYdescargar(buffer, name, tipo = 'excel') {
    let nombre = name + '.xlsx';
    let dataType = 'data:txt/plain;base64;charset=utf-8,';
    if (tipo == 'csv') {
        nombre = name + '.csv';
        dataType = 'data:txt/plain;charset=utf-8,';

    }
    var element = document.createElement('a');
    element.setAttribute('href', dataType + encodeURIComponent(buffer));
    element.setAttribute('download', nombre);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}


// Convert a CSV string to XLXS buffer 
// change from xlxs/xls and other formats by going through sheetsjs documentation.
import * as XLSX from 'xlsx';


function jsonFitToColumn(listaElementos) {
    // get maximum character of each column
    let keylengths = {};
    listaElementos.map((a) => {
        Object.keys(a).forEach(key => {
            let klength = a[key].toString().length
            keylengths[key] ? klength > keylengths[key] ? keylengths[key] = klength : 0 : keylengths[key] = klength;
        })
    });
    return Object.keys(keylengths).map(e => ({ wch: Math.max(keylengths[e], e.length) + 2 }))
}
export const jsonToExcelBuffer = (jsonItem) => {

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(jsonItem);
    ws['!cols'] = jsonFitToColumn(jsonItem);
    XLSX.utils.book_append_sheet(wb, ws);
    const rawExcel = XLSX.write(wb, { type: 'base64' })
    return rawExcel
}

export const convertCsvToExcelBuffer = (csvString, delimiter = ',') => {
    const arrayOfArrayCsv = csvString.split("\n").map((row) => {
        return row.split(delimiter)
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.write(wb, { type: 'base64' })
    return rawExcel
}
export const scrollToTop = () => {
    document.getElementsByClassName("Panel")[0].scrollTop = 0;
}