//Clase fecha custom
class fecha {
    constructor(nuevaFecha) {
        if (nuevaFecha) {
            this.fechaActual = new Date(nuevaFecha)
        } else {
            this.fechaActual = new Date();
        }


        this.segundos = ('0' + this.fechaActual.getSeconds()).substr(-2)
        this.minutos = ('0' + this.fechaActual.getMinutes()).substr(-2);
        this.hora = ('0' + this.fechaActual.getHours()).substr(-2)
        this.dia = String(this.fechaActual.getDate()).padStart(2, '0');
        this.mes = String(this.fechaActual.getMonth() + 1).padStart(2, '0');
        this.anyo = this.fechaActual.getFullYear();

    }

    //Get dd-mm-yyyy
    get ddmmyyyy() {
        return `${this.dia}-${this.mes}-${this.anyo}`;
    }

    //Get dd-mm-yyyy
    get yyyymmdd() {
        return `${this.anyo}-${this.mes}-${this.dia}`;
    }

    //Get yyyy-mm-dd hh:mm:ss
    get yyyymmddhhmmss() {
        return `${this.anyo}-${this.mes}-${this.dia} ${this.hora}:${this.minutos}:${this.segundos}`;
    }

    //Get dd-mm-yyyy hh:mm:ss
    get fechaTotal() {
        return `${this.dia}-${this.mes}-${this.anyo} ${this.hora}:${this.minutos}:${this.segundos}`;
    }

    //Get dd-mm-yy hh:mm
    get ddmmyyhhmm() {
        return `${this.dia}-${this.mes}-${this.anyo.toString().slice(-2)} ${this.hora}:${this.minutos}`;
    }
}

class fechaUTC {
    constructor(nuevaFecha) {
        if (nuevaFecha) {
            this.fechaActual = new Date(nuevaFecha)
        } else {
            this.fechaActual = new Date();
        }

        console.log(this.fechaActual);

        this.segundos = ('0' + this.fechaActual.getUTCSeconds()).substr(-2)
        this.minutos = ('0' + this.fechaActual.getUTCMinutes()).substr(-2);
        this.hora = ('0' + this.fechaActual.getUTCHours()).substr(-2)
        this.dia = String(this.fechaActual.getUTCDay()).padStart(2, '0');
        this.mes = String(this.fechaActual.getUTCMonth() + 1).padStart(2, '0');
        this.anyo = this.fechaActual.getUTCFullYear();
    }


    //Get dd-mm-yyyy
    get yyyymm() {
        return `${this.anyo}-${this.mes}`;
    }

    //Get dd-mm-yyyy
    get ddmmyyyy() {
        return `${this.dia}-${this.mes}-${this.anyo}`;
    }

    //Get dd-mm-yyyy
    get yyyymmdd() {
        return `${this.anyo}-${this.mes}-${this.dia}`;
    }

    //Get dd-mm-yyyy
    get yyyymmddhhmmss() {
        return `${this.anyo}-${this.mes}-${this.dia} ${this.hora}:${this.minutos}:${this.segundos}`;
    }

    //Get dd-mm-yyyy hh:mm:ss
    get fechaTotal() {
        return `${this.dia}-${this.mes}-${this.anyo} ${this.hora}:${this.minutos}:${this.segundos}`;
    }
}
module.exports = { fecha, fechaUTC };