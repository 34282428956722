<template>
    <!-- Contenido -->
    <div class="m-auto">
      <b-overlay :show="cargando" class="m-2 p-2 shadow-lg">
      <div class="table-responsive">
        <table :id="'datatable' + indice" class="table table-striped table-tiny">
            <thead>
              <tr class="noselect">
                <th scope="col" class="align-middle" style="min-width: 10rem;">DIRECCION COMPLETA</th>
                <th scope="col" class="align-middle">ID_DOMICILIO</th>
                <th scope="col" class="align-middle">HOGAR_POTENCIAL</th>
                <th scope="col" class="align-middle">CODIGO_POSTAL</th>
                <th scope="col" class="align-middle">PROVINCIA</th>
                <th scope="col" class="align-middle">POBLACION</th>
                <th scope="col" class="align-middle">TIPO_VIA</th>
                <th scope="col" class="align-middle">NOMBRE_VIA</th>
                <th scope="col" class="align-middle">ID_TECNICO</th>
                <th scope="col" class="align-middle">NUMERO</th>
                <th scope="col" class="align-middle">BIS</th>
                <th scope="col" class="align-middle">BLOQUE_FINCA</th>
                <th scope="col" class="align-middle">IDENTIFICADOR_FINCA</th>
                <th scope="col" class="align-middle">LETRA_FINCA</th>
                <th scope="col" class="align-middle">ESCALERA</th>
                <th scope="col" class="align-middle">PLANTA</th>
                <th scope="col" class="align-middle">MANO_1</th>
                <th scope="col" class="align-middle">MANO_2</th>
                <th scope="col" class="align-middle">OBSERVACIONES</th>
                <th scope="col" class="align-middle">FLAG_DUMMY</th>
                <th scope="col" class="align-middle">CODIGO_INE</th>
                <th scope="col" class="align-middle">CODIGO_CENSAL</th>
                <th scope="col" class="align-middle">CODIGO_PAI</th>
                <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                <th scope="col" class="align-middle">CODIGO_CTO</th>
                <th scope="col" class="align-middle">TIPO_CTO</th>
                <th scope="col" class="align-middle">DIRECCION_CTO</th>
                <th scope="col" class="align-middle">TIPO_PERMISO</th>
                <th scope="col" class="align-middle">TIPO_CAJA_DERIVACION</th>
                <th scope="col" class="align-middle">NUMERO_UI</th>
                <th scope="col" class="align-middle">NUMERO_VIVIENDAS</th>
                <th scope="col" class="align-middle">FECHA_ALTA</th>
                <th scope="col" class="align-middle">CODIGO_CAJA_DERIVACION</th>
                <th scope="col" class="align-middle">UBICACION_CAJA_DERIVACION</th>
                <th scope="col" class="align-middle" style="min-width: 13rem;">GESCAL_37</th>
              </tr>
            </thead>
            <tbody class="align-middle">                   
            </tbody>
        </table>
      </div>
      </b-overlay>                
    </div>
</template>


<script>
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import { montarNombreCalle } from '../../helpers/utils';

export default {
  name: 'DatatableCambiosHogares',
  data() {
    return {   
      listaDatos: '',
      tabla: '',
      cargando: true
    }
  },  
  props: ['item', 'indice'],
  methods:{
    initDatatable(){
      this.tabla = $('#datatable' + this.indice).DataTable({
              autoWidth: true,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                { data: 'DIRECCION COMPLETA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex flex-column text-start">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'ID_DOMICILIO',
                 render: (data, type, rowData) => {
                      return !rowData.ELIMINADO ? data : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data}</strike>
                      </div>
                      `
                    } 
                },
                { data: 'HOGAR_POTENCIAL',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },                
                { data: 'CODIGO_POSTAL',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PROVINCIA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'POBLACION',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_VIA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NOMBRE_VIA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'ID_TECNICO',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NUMERO',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'BIS',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'BLOQUE_FINCA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'IDENTIFICADOR_FINCA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'LETRA_FINCA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'ESCALERA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PLANTA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'MANO_1',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'MANO_2',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'OBSERVACIONES',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'FLAG_DUMMY',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_INE',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CENSAL',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_PAI',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_OLT',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CTO',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_CTO',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'DIRECCION_CTO',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_PERMISO',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_CAJA_DERIVACION',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NUMERO_UI',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NUMERO_VIVIENDAS',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'FECHA_ALTA',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CAJA_DERIVACION',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },  
                { data: 'UBICACION_CAJA_DERIVACION',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },  
                { data: 'GESCAL_37',
                    render: (data, type, rowData) => {
                      if(!data) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },                                                  
              ]    
        });
      //A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
      setTimeout(() => {
        $('#datatable' + this.indice).wrap('<div class="table-responsive"></div>')
      }, 500);
    },
    ponerDatos(){
      this.tabla.clear();
      this.tabla.rows.add(this.listaDatos).draw();
      this.cargando = false;

    },
    procesarDatos(){
      this.listaDatos = JSON.parse(this.item);
      //Montamos el nombre de la calle para ayudar visualmente
      for (const iterator of this.listaDatos) {
        let domicilioAntiguo = {}
        let domicilioEditado = {}
        //Reconstruimos los domicilios antiguos y los editados
        for (const key of Object.keys(iterator)) {
          if(iterator[key].hasOwnProperty('OLD')){
            domicilioAntiguo[key] = iterator[key].OLD
            domicilioEditado[key] = iterator[key].NEW
          }else{
            domicilioAntiguo[key] = iterator[key]
            domicilioEditado[key] = iterator[key]
          }
        }
        iterator['DIRECION COMPLETA'] = {};
        iterator['DIRECION COMPLETA'] = {};
        iterator['DIRECION COMPLETA'].OLD = montarNombreCalle(domicilioAntiguo)
        iterator['DIRECION COMPLETA'].NEW = montarNombreCalle(domicilioEditado)
      }
    }
  },
  created() {
    this.procesarDatos()
  },
  computed: {

  },
  mounted() {
    this.initDatatable()
    this.ponerDatos()    
  },

}
</script>

<style scoped>
 /* .table-responsive {
  max-width: 95vw;
  width: 100%;
 } */
</style>
