<template>
    <!-- Contenido -->
    <div class="m-3" :class="{ 'card': !fullwidth, 'shadow': !fullwidth}">
      <ModalResultadoSincronizacion ref="ModalSync" :item="item"></ModalResultadoSincronizacion>
      <ModalCambioCTO ref="ModalCambioCTO" :cambiar_cto_padre="modificar_cto_tarjeta"></ModalCambioCTO>
      <div class="text-start" :class="{'card-body': !fullwidth}">
        <!-- ID_ADMINISTRATIVO -->
        <div class="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <div class="d-flex align-items-center">
                <span class="mx-2 fs-6 fw-bold align-middle">ID_ADMINISTRATIVO: </span>
                <span class="mx-2 fs-6 align-middle">{{item.ID_ADMINISTRATIVO}} </span>
            </div>
            <button v-show="fullwidth && item.tipo == 'servicio' && esInterno()" class="btn-icon btn btn-sm btn-primary align-middle" :class="{ 'disabled': esperando_sincronizacion }" @click="sincronizar_servicio()" title="Sincronizar y visualizar datos del servicio">Sincronizar 
              <i v-if="!esperando_sincronizacion" class="fa-solid fa-rotate clickable align-middle"></i>
              <div v-else class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Cargando sincronizacion...</span>
              </div>
            </button>
        </div>

          <hr class="my-1">
      
        <!-- DIRECCION -->
        <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
          <span class="mx-2 fs-6 fw-bold align-middle">DIRECCIÓN: </span>
          <span class="mx-2 fs-6 align-middle">{{item.direccion}} </span>
          <button class="btn-icon btn btn-sm btn-primary" @click="buscar_Domicilio_maps()"><i class="fa-solid fa-map-location-dot clickable"></i></button>
        </div>
        <hr class="my-1">   

        <!-- ID_DOMICILIO -->
        <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
          <span class="mx-2 fs-6 fw-bold align-middle ">ID_DOMICILIO: </span>
          <span class="mx-2 fs-6 align-middle">{{item.ID_DOMICILIO}} </span>
        </div>
        <hr class="my-1">   

        <!-- REMOTE_ID -->
        <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
          <span class="mx-2 fs-6 fw-bold align-middle ">REMOTE_ID: </span>
          <span class="mx-2 fs-6 align-middle">{{item.REMOTE_ID}} </span>
          <button v-show="item.servidor_acs" :disabled="refrescando_acs" class="btn-icon btn btn-sm btn-primary" @click="refrescar_acs()">                      
            <div v-show="refrescando_acs" class="spinner-border spinner-border-sm" role="status"></div>
            Refrescar ACS
          </button>

        </div>
        <hr class="my-1">            

        <!-- PERFIL -->
        <div>
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle">PERFIL: </span>
            <span class="mx-2 fs-6 align-middle">{{item.PERFILVELOCIDAD}} </span>
          </div>
          <hr class="my-1">     
        </div>
        <!-- TEMPLATE -->
        <div>
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle">TEMPLATE: </span>
            <span class="mx-2 fs-6 align-middle">{{item.TEMPLATE}} </span>
          </div>
          <hr class="my-1">     
        </div>

        <!-- FECHA CREACIÓN -->
        <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
          <span class="mx-2 fs-6 fw-bold align-middle ">FECHA DE CREACIÓN: </span>
          <span class="mx-2 fs-6 align-middle">{{fechaComputada(item.fechaCreacion)}} </span>                                     
        </div>
        <hr class="my-1">    

        <!-- CADUCIDAD -->
        <div v-if="item.caducidad">
            <span class="mx-2 fs-6 fw-bold align-middle">CADUCIDAD: </span>
            <span class="mx-2 fs-6 align-middle" v-if="item.caducidad.segundosRestantes > 0" > 
              <span class="badge mx-2 my-1 align-middle" :class="badgecolorFecha(item.caducidad.days)">{{`${item.caducidad.days} días, ${item.caducidad.hours} horas y ${item.caducidad.minutes} minutos`}}</span>
            </span>
            <span class="mx-2 fs-6 align-middle" v-else>
              <span class="badge mx-2 my-1 bg-secondary align-middle">Reserva caducada</span>
            </span>                                          
          <hr class="my-1"> 
        </div>
        <!-- PROVINCIA -->
        <div v-if="item.PROVINCIA" >
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">PROVINCIA: </span>
            <span class="mx-2 fs-6 align-middle text-capitalized">{{item.PROVINCIA}} </span>
          </div>
          <hr class="my-1">  
        </div>
        <!-- POBLACION -->
        <div v-if="item.POBLACION">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">POBLACIÓN: </span>
            <span class="mx-2 fs-6 align-middle  text-capitalized">{{item.POBLACION}} </span>
          </div>
          <hr class="my-1">  
        </div>

        <!-- CODIGO_OLT -->
        <div v-if="item.CODIGO_OLT">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">CODIGO_OLT: </span>
            <span class="mx-2 fs-6 align-middle">{{item.CODIGO_OLT}} </span>
          </div>
          <hr class="my-1">  
        </div>

        <!-- CODIGO_CTO -->
        <div v-if="item.CODIGO_CTO">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">CODIGO_CTO: </span>
            <span class="mx-2 fs-6 align-middle">{{item.CODIGO_CTO}} </span>
            <button v-show="fullwidth && item.tipo == 'servicio'" class="btn-icon btn btn-sm btn-primary align-middle" @click="cambio_cto()" title="Cambiar la CTO del servicio o sus puertos">Cambio CTO </button>            
          </div>
          <hr class="my-1">  
        </div>

        <!-- NUMERO_CTO -->
        <div v-if="item.CODIGO_CTO">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">NUMERO_CTO: </span>
            <span class="mx-2 fs-6 align-middle">{{numeroCTO(item.CODIGO_CTO)}} </span>
            <div class="px-0 text-start align-self-center" title="Número CTO">
                <i class="fa-solid fa-circle-question clickable" @click="infoNumeroCTO()"></i>
            </div>     
          </div>
          <hr class="my-1">  
        </div>

        <!-- DIRECCION_CTO -->
        <div v-if="item.DIRECCION_CTO">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">DIRECCION_CTO: </span>
            <span class="mx-2 fs-6 align-middle">{{item.DIRECCION_CTO}} </span>
            <button class="btn-icon btn btn-sm btn-primary" @click="buscar_cto_Maps()"><i class="fa-solid fa-map-location-dot clickable"></i></button>

          </div>
          <hr class="my-1">  
        </div>
        <!-- no_facturable -->
        <div v-if="item.no_facturable">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">FACTURACIÓN: </span>
            <span class="mx-2 fs-6 align-middle">
              <span class="badge bg-secondary align-middle">Servicio no facturable</span>
            </span>     
            <div class="px-0 text-start align-self-center" title="Servicio no facturable">
                <i class="fa-solid fa-circle-question clickable" @click="infoNofacturable()"></i>
            </div>                

          </div>
          <hr class="my-1">  
        </div>     
        <!-- Comentario -->
        <div v-if="item.comentario">
          <div class="d-flex justify-content-start align-items-center flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">COMENTARIO: </span>
            <span class="mx-2 fs-6 align-middle">{{item.comentario}} </span>
          </div>
          <hr class="my-1">  
        </div>           


      </div>                              
    </div>
</template>


<script>
import { fecha } from "../classes/fecha.class"
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert'
import axios from "axios";
import ModalResultadoSincronizacion from "./ModalResultadoSincronizacion.vue"
import ModalCambioCTO from "./ModalCambioCTO.vue"
import { isInternoAsteo } from '../helpers/JWT';


export default {
  name: 'TarjetaServicio',
  props: ['item', 'fullwidth'],
  components: {
    ModalResultadoSincronizacion,
    ModalCambioCTO,
  },
  data() {
    return {   
      domicilio: null,
      esperando_sincronizacion: false,
      refrescando_acs: false
    }
  },  
  methods: {
    infoNumeroCTO(){
      infoAlert('Numero CTO', 'El <strong>número de la CTO</strong>, se compone de <strong>3</strong> dígitos, no puede ser 0 y se pega en una <strong>etiqueta</strong> en las CTO.', require("@/assets/etiqueta_cto.png"), 'Etiqueta CTO')
    },
    infoNofacturable(){
      infoAlert('Cliente no facturable', 'Este cliente se ha marcado como <strong>NO FACTURABLE</strong>, esto significa que el cliente no se incluirá en los reportes de facturación. Esto puede ser porque sea un <strong>cliente de prueba</strong> o un <strong>cliente piloto</strong> por ejemplo.')
    },    
    esInterno(){
      return isInternoAsteo()
    },    
    modificar_cto_tarjeta(nueva_cto){
      let copia_item = this.item;
      copia_item.CODIGO_CTO = nueva_cto;
      this.$emit("actualizar_servicio", copia_item);
    } ,  
    async cambio_cto(){
      if (this.$refs.ModalCambioCTO.show) return;
        this.$refs.ModalCambioCTO.abrirModal(this.item)
        localStorage.setItem('servicioActivo', JSON.parse(this.item))
    },
    async sincronizar_servicio(){
        try{
          this.esperando_sincronizacion = true;
          let response = await axios({
              method: 'PUT',
              url: '/servicios/sync',
              params: { ID_ADMINISTRATIVO: this.item.ID_ADMINISTRATIVO },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });              
            console.log('Sincronización: ',response);
            this.$refs.ModalSync.abrirModal(response.data.cliente)
          
          let servicio_hydra = await axios({
            method: 'GET',
            url: '/servicios/servicio',
            params: { ID_ADMINISTRATIVO: this.item.ID_ADMINISTRATIVO },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });                       
         this.$emit("actualizar_servicio", servicio_hydra.data.data.servicio)

          this.esperando_sincronizacion = false;
        } catch (error) {
            this.esperando_sincronizacion = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log(error)
              errorAlert('No sincronizando', error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              noResponseAlert();
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              serverErrorAlert(error.message);
            }
        }
    }, 
    async refrescar_acs(){
        try{
          this.refrescando_acs = true;
          let response = await axios({
              method: 'POST',
              url: '/acs/refresh_ont',
              params: { OPID: this.item.id_operador, remote_id: this.item.REMOTE_ID },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });              
            console.log('refrescand acs: ',response);

            successAlert("Proceso completado", response.data.msg == "OK" ? "Se han actualizado los parametros" : response.data.msg )

          this.refrescando_acs = false;
        } catch (error) {
            this.refrescando_acs = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log(error)
              errorAlert('No se pudo refrescar el ACS', error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              noResponseAlert();
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              serverErrorAlert(error.message);
            }
        }
    },     
    async get_domicilio(){
         try{
            let response = await axios({
                method: 'GET',
                url: '/hogares/domicilio',
                params: { ID_DOMICILIO: this.item.ID_DOMICILIO },
                headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
              });              
              this.domicilio = response.data.data.domicilio[0];
              
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log("error controlado", error.response);
              return;
            } else if (error.request) {
              console.log("Servidor no responde", error.request);
              return;
            } else {
              console.log('error', error);
              return;
            }
        }
    },
    buscar_cto_Maps(){
      let street_address = this.item.DIRECCION_CTO + ", " + this.item.POBLACION + ", " + this.item.PROVINCIA+ ", España"
      this.buscarDireccionMaps(street_address);
    },
    async buscar_Domicilio_maps(){
      let street_address = this.item.direccion;
      if(!this.domicilio){
        await this.get_domicilio()
      }
      
      if(this.domicilio){
        street_address = `${this.domicilio.TIPO_VIA} ${this.domicilio.NOMBRE_VIA}, ${parseInt(this.domicilio.NUMERO)}, ${this.domicilio.CODIGO_POSTAL} ${this.domicilio.POBLACION}, ${this.domicilio.PROVINCIA}, España`
      }
      this.buscarDireccionMaps(street_address);
    },
    buscarDireccionMaps(street_address){
      const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(street_address)}`;
      window.open(url, '_blank');
    }
  },
  computed: {
    numeroCTO() {
      return (CODIGO_CTO) => CODIGO_CTO.slice(-3)
    },
    //Fecha con formato propio
    fechaComputada () {
      return (fechanueva) => new fecha(fechanueva).fechaTotal;
    },
    badgecolorFecha () {
      return (days) => {
          if(days >= 2){
             return 'bg-success' 
          }else if(days >= 1){
             return 'bg-warning'
          } else{ 
             return 'bg-danger'
          }
      }
    }
  }
}
</script>

<style scoped>
.btn-icon{
  padding: .2rem .4rem;
  font-size: .8rem;
}
</style>