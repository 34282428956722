import axios from 'axios'

/*
    Registra en la base de datos la operación realicada.
    @OPERACION - Nombre de la operación realizada
    @ID_ADMINISTRATIVO - Identificador del servicio sobre el que se realiza la operación
    @DATOS - Información adicional de la operación, (ID_DOMICILIO, TEMPLATE...)
*/
export async function registrarEnHistorial(OPERACION, ID_ADMINISTRATIVO, DATOS = "", DATOSTABLA = "") {
    try {
        let respuesta = await axios({
            method: 'POST',
            url: '/historial',
            data: {
                OPERACION,
                ID_ADMINISTRATIVO,
                DATOS,
                DATOSTABLA
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }

        })

    } catch (error) {

        if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.error(error.response.data.msg);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No se ha recibido respuesta del servidor');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error(error.message);
        }

    }
}



/*
    Registra en la base de datos la operación realicada.
    @OPERACION   - Nombre de la operación realizada
    @OPERADOR    - Operador sobre el que se realiza la acción
    @ID_OPERADOR - El id del operador al qeu se le realiza la acción
    @DATOS - Información adicional de la operación, (ID_DOMICILIO, TEMPLATE...)
*/
export async function registrarOperacionAdmin(OPERACION, OPERADOR, ID_OPERADOR, DATOS = "", DATOSTABLA = "") {
    try {
        let respuesta = await axios({
            method: 'POST',
            url: '/historial/admin',
            data: {
                OPERACION,
                OPERADOR,
                ID_OPERADOR,
                DATOS,
                DATOSTABLA
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        })

    } catch (error) {

        if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log(error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('No se ha recibido respuesta del servidor');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message);
        }

    }
}