import { render, staticRenderFns } from "./ServiciosDescartables.vue?vue&type=template&id=561b57be&scoped=true"
import script from "./ServiciosDescartables.vue?vue&type=script&lang=js"
export * from "./ServiciosDescartables.vue?vue&type=script&lang=js"
import style0 from "./ServiciosDescartables.vue?vue&type=style&index=0&id=561b57be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561b57be",
  null
  
)

export default component.exports