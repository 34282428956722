<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Servicios y test</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>
            
            <!-- Servicios -->
            <ServiciosAdmin></ServiciosAdmin>
            
            <!-- TEST -->
            <TestDirecto></TestDirecto>


          </div>

      </div>

      
  </div>
</template>

<script>
import TestDirecto from '@/components/TestDirecto';
import ServiciosAdmin from '@/components/ServiciosAdmin';


export default {
  /*
    Dos componentes:
      ServiciosAdmin, que permite buscar los servicios de cualquier operador.
      TestDirecto, que permite realizar test o diagnósticos sobre un servicio y mostrar los resultados en una tabla.
  */
  name: 'ServiciosTest',
  components: {
    TestDirecto,
    ServiciosAdmin
  },
  data() {
    return {
    
    } 
  },

  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Servicios y test', 'En esta página se podrá buscar servicios y realizar test diagnósticos si dispone del <strong>ID ADMINISTRATIVO</strong> del servicio.')
    },   

  },
  created(){
  }
}
</script>

<style scoped>

.dwnld-btn{
  width: 12rem;
}

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
    inline-size: auto;
  }
}

.tablaScrolleable{
  overflow: auto;
  max-height: 30rem;
}
</style>
