<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Operadores</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Nueva reserva-->
              <div>
                  <router-link :to="{name: 'NuevoUsuario'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fas fa-user-plus"></i> Nuevo usuario</router-link>
              </div>
            </div>
            
            
            <div class="card-body">
              <!-- Carga -->
              <div v-if="cargandoOperadores" class="my-4">
                <b-spinner></b-spinner>
                <h4 >Cargando datos...</h4>
              </div>
              
              <!-- Error cargando -->
              <div v-else-if="operadores.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>  
                  <div class="d-flex flex-column">
                    <div v-if="mensajedeError" class="text-danger">
                      {{mensajedeError}}
                    </div>  
                    <div v-if="operadores.length < 1" class="text-danger align-self-center">
                      No se han encontrado operadores.
                    </div>           
                  </div>
                </div>
              </div>

              <!-- Contenido -->
              <div v-else>
           
                    <div class="table-responsive list-limited-height">
                    <table class="table table-striped table-bordered">
                      
                        <thead class="text-start align-middle">
                          <tr class="noselect">
                            <th scope="col" class="firstColumn d-table-cell line sorting" @click="ordenarTabla($event, 'operador')">OPERADOR</th>
                            <th scope="col" class="d-table-cell sorting" @click="ordenarTabla($event, 'id_operador')">ID OPERADOR</th>
                            <th scope="col" class="d-table-cell">
                                <div class="d-flex justify-content-between align-items-start">
                                  PERFILES ASIGNADOS
                                  <i class="fa-solid fs-4 fa-circle-question clickable" @click="infoPerfiles()"></i>
                                </div>
                            </th>
                            <th scope="col" class="d-table-cell">
                                <div class="d-flex justify-content-between align-items-start">
                                  COBERTURAS ASIGNADAS
                                  <i class="fa-solid fs-4 fa-circle-question clickable" @click="infoCoberturas()"></i>
                                </div>
                            </th>
                            <th scope="col" class="d-table-cell text-center">Acción</th>
                          </tr>
                        </thead>
                        <tbody class="align-middle text-start">
                          <template v-for="(item, index) in operadores" >
                              <tr :key="index" :id="item.id_operador" class="accordion searchable">
                                <!-- OPERADOR -->
                                <td scope="row">
                                  <div class="d-flex justify-content-between">
                                    <span class="align-self-center fw-bold searchable-text">{{item.operador}}</span>
                                    <b-button type="button" variant="primary" class="btn btn-primary btn-circle border-0 shadow-sm expand-btn" @click="animarCollapse('acordeon'+item.id_operador); toggleIcon(item.id_operador)"><i class="expand-icon fa-solid fa-plus fs-6" :id="'boton'+item.id_operador"></i></b-button>
                                  </div>
                                </td>
                                <td class="d-table-cell searchable-text">{{item.id_operador}}</td>
                                <td class="text-center" :class="{ 'text-danger' : !item.numPerfiles }">{{perfilComputed(item.numPerfiles)}}</td>
                                <td class="d-table-cell text-center" :class="{ 'text-danger' : !item.numPueblos }">{{coberturaComputed(item.numPueblos)}}</td>
                                <td class="d-table-cell">
                                  <div class="d-flex justify-content-around ">
                                    <i type="button" class="fa-solid fa-gauge-high fs-4 mx-2 icon-btn text-primary" title="Asignar perfiles" @click="asignarPerfiles(item)"></i>
                                    <i type="button" class="fa-solid fa-map-location-dot fs-4 mx-2 icon-btn text-primary" title="Asignar cobertura" @click="asignarCoberturas(item)"></i>
                                    <i type="button" class="fa-solid fa-bullhorn fs-4 mx-2 icon-btn text-primary" title="Enviar notificación al operador" @click="iraNotificar(item.id_operador)"></i>
                                  </div>
                                </td>
                              </tr>
                              
                              <!-- COLLAPSABLE INFO EXTRA -->
                              <tr :key="'c'+index">
                                <td colspan="7" class="p-0 overflow-hidden">
                                  <div class="acordeon"  :id="'acordeon'+item.id_operador" accordion="my-accordion">
                                      <div class="acordeon-content">
                                          <!-- No hay perfiles o coberturas -->
                                          <div class="m-4">
                                            <div v-if="item.perfiles.length < 1 || item.pueblos.length < 1" class="alert alert-warning d-flex align-items-center" role="alert">
                                              <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                                              <div class="d-flex flex-column text-start">
                                                <div v-if="mensajedeError" class="text-danger">
                                                  {{mensajedeError}}
                                                </div>  
                                                <div v-if="item.perfiles.length < 1" class="text-danger">
                                                  ¡El operador no tiene perfiles asignados y no podrá trabajar!
                                                </div>     
                                                <div v-if="item.pueblos.length < 1" class="text-danger">
                                                  ¡El operador no tiene pueblos asignados y no podrá trabajar!
                                                </div>   
                                              </div>
                                            </div>
                                          </div>
                                          <!-- Tarjeta de operador -->
                                          <TarjetaOperador :item="item" :fullwidth="true" @recalcular="recalcularHeight" @finalizado="finalizarRecalcularHeight" @reiniciar="reiniciarPagina" @asignarPerfiles="asignarPerfiles" @asignarCoberturas="asignarCoberturas" @actualizarOperador="actualizarOperador" @refrescar_voip="actualizarservidorVOIP"></TarjetaOperador>     
                                          <!-- Botones de acción -->
                                          <div class="d-flex  justify-content-center my-3 ">
                                                <button type="button" class="btn btn-primary mx-3 accion-btn" @click="iraNotificar(item.id_operador)" title="Enviar notificación al operador">
                                                  <i type="button" class="fa-solid fa-bullhorn fa-1x mx-1"></i>
                                                  Enviar notificación
                                                </button>                                            
                                          </div>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                      
                          </template>
                        </tbody>
                    </table>
                    </div>
              </div>

            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { infoAlert } from '../helpers/fastAlert';
import { desexpandirTodo, procesarCoberturas, recalcularAltura } from '../helpers/utils';
import axios from "axios";
import TarjetaOperador from "@/components/TarjetaOperador"

export default {
  /*
    Muestra una lista con los operadores existentes e información relevante sobre ellos.
    Muestra sus perfiles y coberturas y permite asignarlos.
    Además permite eliminar usuarios siempre y cuando el último no le queden perfiles y coberturas.
  */
  name: 'Operadores',
  components: {
    TarjetaOperador
  },
  data() {
    return {
      cargandoOperadores: false,
      cargandoPerfiles: false,
      cargandoCoberturas: false,
      mensajedeError: '',
      operadores: [],               //Lista de operadores
      usuarios: [],                 //Lista de usuarios  
      perfiles: [],                 //Perfiles de velocidad
      coberturas: [],                //Coberturas asignadas
      servidores_acs: [],               
      servidores_voip: [],               
      rolesDisponibles: [],
      rolesAsignados: [],
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Operadores', 'Encuentra una lista con los operadores que trabajan con Asteo, aquí les puedes asignar <strong>perfiles de velocidad</strong> y <strong>coberturas contratadas.</strong>')
    },   
    infoPerfiles(){
      infoAlert('Perfiles asignados', 'Son los perfiles de velocidad asignados a un operador. <br><br> ¡Si no le has asignado ninguno, el operador no podrá trabajar!')
    },
    infoCoberturas(){
      infoAlert('Coberturas asignadas', 'Son los pueblos que se le han asignado al operador, los pueblos contratados con los que puede trabajar. <br><br> ¡Si no le has asignado ninguno, el operador no podrá trabajar!')
    },
    ordenarTabla(event, columna){
      let elemento = event.target;
      let ascOrder = true;
      if(elemento.classList.contains('sorting_asc')){
        ascOrder = false;
      }
      if(elemento && elemento.parentElement){
        let ordenables = elemento.parentElement.querySelectorAll('.sorting');
        for (const iterator of ordenables) {
          iterator.classList.remove('sorting_desc', 'sorting_asc');
        }
        if(ascOrder){
          elemento.classList.add('sorting_asc');
          this.operadores.sort((a, b) => a[columna].toString().localeCompare(b[columna].toString()))
        }else{
          elemento.classList.add('sorting_desc');
          this.operadores.sort((a, b) => b[columna].toString().localeCompare(a[columna].toString()));
        }  
        desexpandirTodo();
      }
      
    },
    //Crea una animación al expandir la información de un operador
    animarCollapse(id){       
      let colapsable = document.getElementById(id);    
      let expandido = colapsable.classList.contains('show');

      for (const iterator of document.getElementsByClassName('acordeon')) {
        iterator.classList.remove('show');
        iterator.style.height = 0 + 'px';
      }
      if(!expandido) {
        colapsable.classList.add('show');
        colapsable.style.height = colapsable.scrollHeight + 'px';
      }
    },
    //Pone los botones para expandir en rojo con - o verdes con +
    toggleIcon(id){
      let icono = document.getElementById('boton'+id);
      let expandido = document.getElementById('acordeon'+id).classList.contains('show');
      for (const iterator of document.getElementsByClassName('expand-icon')) {
        iterator.parentElement.classList.remove('bg-danger')
        iterator.parentElement.classList.add('bg-primary')
        iterator.classList.remove('fa-minus')
        iterator.classList.add('fa-plus')
      }

      if(expandido){
        icono.classList.remove('fa-plus')
        icono.classList.add('fa-minus')
        icono.parentElement.classList.remove('bg-primary')
        icono.parentElement.classList.add('bg-danger')
      }
    },
    //Descarga los operadores existentes de la api
    async cargarRolesAsignados(){
      try {
        this.cargandoRolesAsignados = true;

        let respuestaPandora = await axios({
          method: 'GET',
          url: '/roles/asignados',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.rolesAsignados = respuestaPandora.data.data.roles;
        this.cargandoRolesAsignados = false;
      } catch (error) {
        this.cargandoRolesAsignados = false;
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }

    },
    async cargarOperadores(){
      try {
        this.cargandoOperadores = true;
        await this.cargarPerfiles();
        await this.cargarCoberturas();
        await this.cargarServidorACS();
        await this.cargarServidoresVoIP();
        await this.cargarRolesAsignados();

        let respuestaPandora = await axios({
          method: 'GET',
          url: '/operadores/usuarios',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.usuarios = respuestaPandora.data.data.listaOperadores;
        this.procesarOperadores();
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } finally {
        this.cargandoOperadores = false;
      }
    },
    //Carga de la API todos los servidores acs
    async cargarServidorACS(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/acs/servidor',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.servidores_acs = respuestaPandora.data.data.servidores;
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },    
    //Carga de la API todos los servidores voip
    async cargarServidoresVoIP(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/acs/servidor_voip',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.servidores_voip = respuestaPandora.data.data.servidores;
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },        
    //Carga de la API todos los perfiles de velocidad
    async cargarPerfiles(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/tarifas',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });

        this.perfiles = respuestaPandora.data.data.tarifas;
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },
    //Carga de la API todas las coberturas asignadas
    async cargarCoberturas(){
      try {
        let respuestaPandora = await axios({
          method: 'GET',
          url: '/coberturas',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        
        this.coberturas = respuestaPandora.data.data.coberturas;

      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      } 
    },
    //Reinicia la página
    reiniciarPagina(){
      this.mensajedeError='';
      this.operadores=[];
      this.usuarios=[];
      this.perfiles=[];
      this.coberturas=[];
      this.cargarOperadores();
    },   
    //Procesa los operadores dejandolos con el formato:
    //this.operadores = [ id_operador, operador, numPerfiles, perfiles: [perfil1, perfil2 ...], numPueblos, pueblos: [pueblo1, pueblo2 ...], usuarios: [usuario1, usuario2 ...]]
    //De este modo cada operador tiene toda su información, y es ordenador por id_operador
    procesarOperadores(){
      try {
        this.operadores = []
        for (const iterator of this.usuarios) {
          iterator.roles = this.rolesAsignados.filter(e => e.user == iterator.user)
          let encontrado = this.operadores.find(dato => dato.id_operador == iterator.id_operador && dato.operador == iterator.operador);

          let { id_operador, operador } = iterator;
          if(!encontrado){
            let tarifas = this.perfiles.filter(element => element.id_operador == id_operador);
            let numPerfiles = tarifas.length;

            let pueblos = this.coberturas.filter(element => element.id_operador == id_operador);
            let numPueblos = pueblos.length;
            pueblos = procesarCoberturas(pueblos);

            let servidor_acs = this.servidores_acs.find(element => element.id_operador == id_operador);
            let servidores_voip = this.servidores_voip.filter(element => element.id_operador == id_operador);

            this.operadores.push({id_operador, operador, numPerfiles, perfiles: tarifas, numPueblos, pueblos, servidor_acs: servidor_acs ? servidor_acs.ip : '', servidores_voip, usuarios: [iterator]})
          }else{
            encontrado.usuarios.push(iterator);
          }
        }
        this.operadores.sort(function(a, b) {
          return a.id_operador - b.id_operador;
        });
      } catch (error) {
        console.log(error);
      }
    },
    //Expande un item de la lista si se ha guardado en la localStorage como 'focusedItem': IUA
    //El objetivo es mostrar un elemento destacado al cargar la página si esque existe. (Por ejemplo al crear una nueva reserva)
    openitemifFocus(){
      let id = localStorage.getItem('focusedOperator');
      let element = document.getElementById('acordeon'+id);
      if(element){
        this.animarCollapse('acordeon'+id);
        this.toggleIcon(id);
        element.scrollIntoView({ behavior: 'smooth', block: 'start'})
        localStorage.removeItem('focusedOperator');
      }
    },
    //Recalcula la altura del acordeon cuando cambia el tamaño del desplegable añadiendole el parametro height
    recalcularHeight(height){      
      for (const iterator of document.querySelectorAll('.acordeon.show')) {
        iterator.style.height = (iterator.offsetHeight + height) + 'px';
      }
    },
    //Recalcula el tamaño del acordeon cuand termina de desplegarse para asegurarnos que el tamaño final es el correcto ya que si se lana recalcularHeight de manera descuidada puede estar mal calculado
    finalizarRecalcularHeight(){  
        for (const iterator of document.querySelectorAll('.acordeon.show')) {          
          let contenedor = iterator.querySelector('.acordeon-content')
          if(contenedor){
            iterator.style.height = (contenedor.offsetHeight+ 39) + 'px';
          }
        }
    },
    //Carga la página AsignarPerfiles pasando el operador por ruta
    asignarPerfiles(item){
      this.$router.push({
        name: 'AsignarPerfiles',
        params: {datosOperador: item}
      })
    },
    //Carga la página de AsignarCoberturas pasando el operador por ruta
    asignarCoberturas(item){
      this.$router.push({
          name: 'AsignarCoberturas',
          params: {datosOperador: item}
      })    
    },
    //Carga la página AsignarPerfiles pasando el operador por ruta
    asignarPerfiles(item){
      this.$router.push({
        name: 'AsignarPerfiles',
        params: {datosOperador: item}
      })
    },    
    iraNotificar(OPID){
      this.$router.push({ name: 'Notificar', params: { OPID}});
    },
    actualizarOperador(item){
      let operador_encontrado = this.operadores.find(e => e.id_operador == item.id_operador)
      operador_encontrado = item;
    },
    async actualizarservidorVOIP(){
      await this.cargarServidoresVoIP()
      this.procesarOperadores()
    }
    
  },
  created(){
    this.cargarOperadores();
  },
  updated(){
    this.openitemifFocus();
  },
  computed:{
    perfilComputed(){
      return (numPerfiles) => {
        if(numPerfiles > 1){
          return numPerfiles + ' perfiles'
        }else if(numPerfiles == 1){
          return numPerfiles + ' perfil'
        }
        return '¡Ningún perfil asignado!'
      }
    },
    coberturaComputed(){
      return (numPueblos) => {
        if(numPueblos > 1){
          return numPueblos + ' pueblos'
        }else if(numPueblos == 1){
          return numPueblos + ' pueblo'
        }
        return '¡Ningún pueblo asignado!'
      }
    }
  }
}
</script>

<style scoped>
.firstColumn {
  width: 14rem;
}
.acciones {
  width: 9rem;
}
.accion-btn {
  width: 12rem;
}

</style>
