
<template>
  <Transition name="modal" >
    <div v-if="show" class="modal-mask" >
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h4 name="header">Editando <strong>{{listaTrazados.length}}</strong> trazados{{listaTrazados.length > 1 ? 's' : ''}}</h4>
            <i type="button" class="close xxl-font" title="Cerrar y descartar" data-dismiss="modal" aria-label="Close" @click="cerrarModal()" >
              <span aria-hidden="true">&times;</span>
            </i>
          </div>
          <div class="modal-body">
            <form class="user d-flex flex-column" @submit.prevent novalidate>
              <div class="row">
                <div class="row">
                  <!-- CODIGO_OLT -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="CODIGO_OLT" class="form-control-label noselect mx-1 d-flex align-items-center">CODIGO_OLT</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.CODIGO_OLT)">
                                <input id="CODIGO_OLT"  class="form-control w-100 m-0 pointerHover" type="text" list="listaOLT" :disabled="!trazadoEditado.CODIGO_OLT.editado" maxlength="23" :placeholder="trazadoEditado.CODIGO_OLT.valorInicial" v-model="trazadoEditado.CODIGO_OLT.valor" :class="{ 'is-invalid': trazadoEditado.CODIGO_OLT.editado && $v.trazadoEditado.CODIGO_OLT.valor.$invalid}" autocomplete="off" @change="$event.target.blur();" v-input-upper>
                                <datalist id="listaOLT">
                                  <option v-for="(item, index) in lista_olt" :key="index" :value="item.CODIGO_OLT">{{item.POBLACION}}</option> 
                                </datalist> 
                              </div>
                              <div v-if="trazadoEditado.CODIGO_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.CODIGO_OLT)"></i>
                              </div>
                              <div v-if="!trazadoEditado.CODIGO_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.CODIGO_OLT)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">     
                          <small v-show="trazadoEditado.CODIGO_OLT.editado && !$v.trazadoEditado.CODIGO_OLT.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.CODIGO_OLT.editado && !$v.trazadoEditado.CODIGO_OLT.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.CODIGO_OLT.editado && !$v.trazadoEditado.CODIGO_OLT.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                          <small v-show="trazadoEditado.CODIGO_OLT.editado && !$v.trazadoEditado.CODIGO_OLT.valor.formatoOLT" class="text-danger">
                            El código OLT debe de ser 888-XX-YY-ZZ-OLT donde XX son los primeros 2 digitos del codigo postal, YY el código de población y ZZ el número de OLT
                          </small>    
                        </div>
                      </div>
                  </div>

                  <!-- MODELO_OLT -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="MODELO_OLT" class="form-control-label noselect mx-1 d-flex align-items-center">MODELO_OLT</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.MODELO_OLT, false, false); setConfig()">
                                <select id="MODELO_OLT" class="form-select w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.MODELO_OLT.editado" maxlength="18" :placeholder="trazadoEditado.MODELO_OLT.valorInicial" v-model="trazadoEditado.MODELO_OLT.valor" :class="{ 'is-invalid': trazadoEditado.MODELO_OLT.editado && $v.trazadoEditado.MODELO_OLT.valor.$invalid}" @change="setConfig()">
                                  <option v-for="(item, index) in modelos_olt" :key="index" :value="item.modelo">{{item.modelo}} ({{item.distribuidor}})</option>
                                </select>
                              </div>
                              <div v-if="trazadoEditado.MODELO_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.MODELO_OLT); setConfig()"></i>
                              </div>
                              <div v-if="!trazadoEditado.MODELO_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.MODELO_OLT, false, false); setConfig()"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                          <small v-show="trazadoEditado.MODELO_OLT.editado && !$v.trazadoEditado.MODELO_OLT.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.MODELO_OLT.editado && !$v.trazadoEditado.MODELO_OLT.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.MODELO_OLT.editado && !$v.trazadoEditado.MODELO_OLT.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- FRAME_OLT -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="FRAME_OLT" class="form-control-label noselect mx-1 d-flex align-items-center">FRAME_OLT</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.FRAME_OLT)">
                                <input id="FRAME_OLT"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.FRAME_OLT.editado" maxlength="1" :placeholder="trazadoEditado.FRAME_OLT.valorInicial" v-model="trazadoEditado.FRAME_OLT.valor" :class="{ 'is-invalid': trazadoEditado.FRAME_OLT.editado && $v.trazadoEditado.FRAME_OLT.valor.$invalid}" autofocus autocomplete="off" v-input-number>
                              </div>
                              <div v-if="trazadoEditado.FRAME_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.FRAME_OLT)"></i>
                              </div>
                              <div v-if="!trazadoEditado.FRAME_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.FRAME_OLT)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column"> 
                          <small v-show="trazadoEditado.FRAME_OLT.editado && !$v.trazadoEditado.FRAME_OLT.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small> 
                          <small v-show="trazadoEditado.FRAME_OLT.editado && !$v.trazadoEditado.FRAME_OLT.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>    
                          <small v-show="trazadoEditado.FRAME_OLT.editado && !$v.trazadoEditado.FRAME_OLT.valor.numeric" class="text-danger">
                            Solo puede introducir números
                          </small>                          
                          <small v-show="trazadoEditado.FRAME_OLT.editado && !$v.trazadoEditado.FRAME_OLT.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                          <small v-show="(!$v.trazadoEditado.FRAME_OLT.valor.minValue || !$v.trazadoEditado.FRAME_OLT.valor.maxValue)" class="text-danger">
                            El <strong>frame</strong> debe de valer {{config.minFrame == config.maxFrame ? config.minFrame : config.minFrame + '-' + config.maxFrame}} en la OLT {{trazadoEditado.MODELO_OLT.valor}}
                          </small> 
                        </div>
                      </div>
                  </div>

                  <!-- TARJETA_OLT -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="TARJETA_OLT" class="form-control-label noselect mx-1 d-flex align-items-center">TARJETA_OLT</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.TARJETA_OLT)">
                                <input id="TARJETA_OLT"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.TARJETA_OLT.editado" maxlength="8" :placeholder="trazadoEditado.TARJETA_OLT.valorInicial" v-model="trazadoEditado.TARJETA_OLT.valor" :class="{ 'is-invalid': trazadoEditado.TARJETA_OLT.editado && $v.trazadoEditado.TARJETA_OLT.valor.$invalid}" autofocus autocomplete="off" @input="trazadoEditado.TARJETA_OLT.valor = $event.target.value.toUpperCase()" v-input-number>
                              </div>
                              <div v-if="trazadoEditado.TARJETA_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.TARJETA_OLT)"></i>
                              </div>
                              <div v-if="!trazadoEditado.TARJETA_OLT.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.TARJETA_OLT)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                          <small v-show="trazadoEditado.TARJETA_OLT.editado && !$v.trazadoEditado.TARJETA_OLT.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.TARJETA_OLT.editado && !$v.trazadoEditado.TARJETA_OLT.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>     
                          <small v-show="trazadoEditado.TARJETA_OLT.editado && !$v.trazadoEditado.TARJETA_OLT.valor.numeric" class="text-danger">
                            Solo puede introducir números
                          </small>                       
                          <small v-show="trazadoEditado.TARJETA_OLT.editado && !$v.trazadoEditado.TARJETA_OLT.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                          <small v-show="(!$v.trazadoEditado.TARJETA_OLT.valor.minValue || !$v.trazadoEditado.TARJETA_OLT.valor.maxValue)" class="text-danger">
                            El <strong>slot</strong> debe de estar entre {{config.minSlot}}-{{config.maxSlot}} en la OLT {{trazadoEditado.MODELO_OLT.valor}}
                          </small> 
                        </div>
                      </div>
                  </div>

                  <!-- PUERTO_GPON -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="PUERTO_GPON" class="form-control-label noselect mx-1 d-flex align-items-center">PUERTO_GPON</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.PUERTO_GPON)">
                                <input id="PUERTO_GPON"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.PUERTO_GPON.editado" maxlength="8" :placeholder="trazadoEditado.PUERTO_GPON.valorInicial" v-model="trazadoEditado.PUERTO_GPON.valor" :class="{ 'is-invalid': trazadoEditado.PUERTO_GPON.editado && $v.trazadoEditado.PUERTO_GPON.valor.$invalid}" autofocus autocomplete="off" v-input-number>
                              </div>
                              <div v-if="trazadoEditado.PUERTO_GPON.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.PUERTO_GPON)"></i>
                              </div>
                              <div v-if="!trazadoEditado.PUERTO_GPON.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.PUERTO_GPON)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">  
                          <small class="text-muted" v-html="mensajePuerto">
                          </small>
                          <small v-show="trazadoEditado.PUERTO_GPON.editado && !$v.trazadoEditado.PUERTO_GPON.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.PUERTO_GPON.editado && !$v.trazadoEditado.PUERTO_GPON.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                      
                          <small v-show="trazadoEditado.PUERTO_GPON.editado && !$v.trazadoEditado.PUERTO_GPON.valor.numeric" class="text-danger">
                            Solo puede introducir números
                          </small>                                                     
                          <small v-show="trazadoEditado.PUERTO_GPON.editado && !$v.trazadoEditado.PUERTO_GPON.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                         <small v-show="(!$v.trazadoEditado.PUERTO_GPON.valor.minValue || !$v.trazadoEditado.PUERTO_GPON.valor.maxValue)" class="text-danger">
                            El <strong>puerto</strong> debe de estar entre {{config.minPort}}-{{config.maxPort}} en la OLT {{trazadoEditado.MODELO_OLT.valor}}
                          </small> 
                        </div>
                      </div>
                  </div>

                  <!-- CAPACIDAD_GPON -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="CAPACIDAD_GPON" class="form-control-label noselect mx-1 d-flex align-items-center">CAPACIDAD_GPON</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.CAPACIDAD_GPON)">
                                <input id="CAPACIDAD_GPON"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.CAPACIDAD_GPON.editado" maxlength="3" :placeholder="trazadoEditado.CAPACIDAD_GPON.valorInicial" v-model="trazadoEditado.CAPACIDAD_GPON.valor" :class="{ 'is-invalid': trazadoEditado.CAPACIDAD_GPON.editado && $v.trazadoEditado.CAPACIDAD_GPON.valor.$invalid}" autofocus autocomplete="off" v-input-number>
                              </div>
                              <div v-if="trazadoEditado.CAPACIDAD_GPON.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.CAPACIDAD_GPON)"></i>
                              </div>
                              <div v-if="!trazadoEditado.CAPACIDAD_GPON.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.CAPACIDAD_GPON)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">    
                          <small v-show="trazadoEditado.CAPACIDAD_GPON.editado && !$v.trazadoEditado.CAPACIDAD_GPON.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.CAPACIDAD_GPON.editado && !$v.trazadoEditado.CAPACIDAD_GPON.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>  
                          <small v-show="trazadoEditado.CAPACIDAD_GPON.editado && !$v.trazadoEditado.CAPACIDAD_GPON.valor.numeric" class="text-danger">
                            Solo puede introducir números
                          </small>                           
                          <small v-show="trazadoEditado.CAPACIDAD_GPON.editado && !$v.trazadoEditado.CAPACIDAD_GPON.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- CODIGO_CTO -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="CODIGO_CTO" class="form-control-label noselect mx-1 d-flex align-items-center">CODIGO_CTO</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.CODIGO_CTO)">
                                <input id="CODIGO_CTO"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.CODIGO_CTO.editado" maxlength="13" :placeholder="trazadoEditado.CODIGO_CTO.valorInicial" v-model="trazadoEditado.CODIGO_CTO.valor" :class="{ 'is-invalid': trazadoEditado.CODIGO_CTO.editado && $v.trazadoEditado.CODIGO_CTO.valor.$invalid}" autofocus autocomplete="off" v-input-upper>
                              </div>
                              <div v-if="trazadoEditado.CODIGO_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.CODIGO_CTO)"></i>
                              </div>
                              <div v-if="!trazadoEditado.CODIGO_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.CODIGO_CTO)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">      
                          <small v-show="trazadoEditado.CODIGO_CTO.editado && !$v.trazadoEditado.CODIGO_CTO.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.CODIGO_CTO.editado && !$v.trazadoEditado.CODIGO_CTO.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.CODIGO_CTO.editado && !$v.trazadoEditado.CODIGO_CTO.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                          <small v-show="trazadoEditado.CODIGO_CTO.editado && !$v.trazadoEditado.CODIGO_CTO.valor.minLength" class="text-danger">
                            El formato debe de ser 888XXYYYNNZZZ donde XX son los dos primeros dígitos del codigo postal, YYY el código de población, NN el número de OLT y ZZZ el número de CTO
                          </small>  
                        </div>
                      </div>
                  </div>

                  <!-- TIPO_CTO -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="TIPO_CTO" class="form-control-label noselect mx-1 d-flex align-items-center">TIPO_CTO</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.TIPO_CTO)">
                                <input id="TIPO_CTO"  class="form-control w-100 m-0 pointerHover" type="text" list="listaCTO" :disabled="!trazadoEditado.TIPO_CTO.editado" maxlength="40" :placeholder="trazadoEditado.TIPO_CTO.valorInicial" v-model="trazadoEditado.TIPO_CTO.valor" :class="{ 'is-invalid': trazadoEditado.TIPO_CTO.editado && $v.trazadoEditado.TIPO_CTO.valor.$invalid}" autofocus autocomplete="off" @change="$event.target.blur();" v-input-upper>
                                  <datalist id="listaCTO">
                                    <option v-for="(item, index) in tipos_cto" :key="index" :value="item"></option> 
                                  </datalist>
                              </div>
                              <div v-if="trazadoEditado.TIPO_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.TIPO_CTO)"></i>
                              </div>
                              <div v-if="!trazadoEditado.TIPO_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.TIPO_CTO)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">   
                          <small v-show="trazadoEditado.TIPO_CTO.editado && !$v.trazadoEditado.TIPO_CTO.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.TIPO_CTO.editado && !$v.trazadoEditado.TIPO_CTO.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.TIPO_CTO.editado && !$v.trazadoEditado.TIPO_CTO.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- DIRECCION_CTO -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="DIRECCION_CTO" class="form-control-label noselect mx-1 d-flex align-items-center">DIRECCION_CTO</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.DIRECCION_CTO)">
                                <input id="DIRECCION_CTO"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.DIRECCION_CTO.editado" maxlength="255" :placeholder="trazadoEditado.DIRECCION_CTO.valorInicial" v-model="trazadoEditado.DIRECCION_CTO.valor" :class="{ 'is-invalid': trazadoEditado.DIRECCION_CTO.editado && $v.trazadoEditado.DIRECCION_CTO.valor.$invalid}" autofocus autocomplete="off" v-input-upper>
                              </div>
                              <div v-if="trazadoEditado.DIRECCION_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.DIRECCION_CTO)"></i>
                              </div>
                              <div v-if="!trazadoEditado.DIRECCION_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.DIRECCION_CTO)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">    
                          <small v-show="trazadoEditado.DIRECCION_CTO.editado && !$v.trazadoEditado.DIRECCION_CTO.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.DIRECCION_CTO.editado && !$v.trazadoEditado.DIRECCION_CTO.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.DIRECCION_CTO.editado && !$v.trazadoEditado.DIRECCION_CTO.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- SPLITTER_ID -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="SPLITTER_ID" class="form-control-label noselect mx-1 d-flex align-items-center">SPLITTER_ID</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.SPLITTER_ID)">
                                <input id="SPLITTER_ID"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.SPLITTER_ID.editado" maxlength="15" :placeholder="trazadoEditado.SPLITTER_ID.valorInicial" v-model="trazadoEditado.SPLITTER_ID.valor" :class="{ 'is-invalid': trazadoEditado.SPLITTER_ID.editado && $v.trazadoEditado.SPLITTER_ID.valor.$invalid}" autofocus autocomplete="off" v-input-upper>
                              </div>
                              <div v-if="trazadoEditado.SPLITTER_ID.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.SPLITTER_ID)"></i>
                              </div>
                              <div v-if="!trazadoEditado.SPLITTER_ID.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.SPLITTER_ID)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">    
                          <small v-show="trazadoEditado.SPLITTER_ID.editado && !$v.trazadoEditado.SPLITTER_ID.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.SPLITTER_ID.editado && !$v.trazadoEditado.SPLITTER_ID.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.SPLITTER_ID.editado && !$v.trazadoEditado.SPLITTER_ID.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- TIPO_SPLITTER -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="TIPO_SPLITTER" class="form-control-label noselect mx-1 d-flex align-items-center">TIPO_SPLITTER</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.TIPO_SPLITTER, false, false)">
                                <select id="TIPO_SPLITTER"  class="form-select w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.TIPO_SPLITTER.editado" maxlength="15" :placeholder="trazadoEditado.TIPO_SPLITTER.valorInicial" v-model="trazadoEditado.TIPO_SPLITTER.valor" :class="{ 'is-invalid': trazadoEditado.TIPO_SPLITTER.editado && $v.trazadoEditado.TIPO_SPLITTER.valor.$invalid}">
                                  <option v-for="(item, index) in tipos_splitter" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                              <div v-if="trazadoEditado.TIPO_SPLITTER.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.TIPO_SPLITTER)"></i>
                              </div>
                              <div v-if="!trazadoEditado.TIPO_SPLITTER.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.TIPO_SPLITTER, false, false)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">    
                          <small v-show="trazadoEditado.TIPO_SPLITTER.editado && !$v.trazadoEditado.TIPO_SPLITTER.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.TIPO_SPLITTER.editado && !$v.trazadoEditado.TIPO_SPLITTER.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.TIPO_SPLITTER.editado && !$v.trazadoEditado.TIPO_SPLITTER.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- PUERTO_CTO -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="PUERTO_CTO" class="form-control-label noselect mx-1 d-flex align-items-center">PUERTO_CTO</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.PUERTO_CTO)">
                                <input id="PUERTO_CTO"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.PUERTO_CTO.editado" maxlength="5" :placeholder="trazadoEditado.PUERTO_CTO.valorInicial" v-model="trazadoEditado.PUERTO_CTO.valor" :class="{ 'is-invalid': trazadoEditado.PUERTO_CTO.editado && $v.trazadoEditado.PUERTO_CTO.valor.$invalid}" autofocus autocomplete="off" v-input-number>
                              </div>
                              <div v-if="trazadoEditado.PUERTO_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.PUERTO_CTO)"></i>
                              </div>
                              <div v-if="!trazadoEditado.PUERTO_CTO.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.PUERTO_CTO)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">   
                          <small v-show="trazadoEditado.PUERTO_CTO.editado && !$v.trazadoEditado.PUERTO_CTO.valor.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="trazadoEditado.PUERTO_CTO.editado && !$v.trazadoEditado.PUERTO_CTO.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small> 
                          <small v-show="trazadoEditado.PUERTO_CTO.editado && (!$v.trazadoEditado.PUERTO_CTO.valor.minValue || !$v.trazadoEditado.PUERTO_CTO.valor.maxValue)" class="text-danger">
                            El valor debe de estar entre 1 y 128
                          </small> 
                          <small v-show="trazadoEditado.PUERTO_CTO.editado && !$v.trazadoEditado.PUERTO_CTO.valor.numeric" class="text-danger">
                            Solo puede introducir números
                          </small>                           
                          <small v-show="trazadoEditado.PUERTO_CTO.editado && !$v.trazadoEditado.PUERTO_CTO.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- GESTOR_VERTICAL -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="GESTOR_VERTICAL" class="form-control-label noselect mx-1 d-flex align-items-center">GESTOR_VERTICAL</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.GESTOR_VERTICAL)">
                                <input id="GESTOR_VERTICAL"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.GESTOR_VERTICAL.editado" maxlength="3" :placeholder="trazadoEditado.GESTOR_VERTICAL.valorInicial" v-model="trazadoEditado.GESTOR_VERTICAL.valor" :class="{ 'is-invalid': trazadoEditado.GESTOR_VERTICAL.editado && $v.trazadoEditado.GESTOR_VERTICAL.valor.$invalid}" autofocus autocomplete="off" v-input-upper>
                              </div>
                              <div v-if="trazadoEditado.GESTOR_VERTICAL.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.GESTOR_VERTICAL)"></i>
                              </div>
                              <div v-if="!trazadoEditado.GESTOR_VERTICAL.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.GESTOR_VERTICAL)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                          <small v-show="trazadoEditado.GESTOR_VERTICAL.editado && (!$v.trazadoEditado.GESTOR_VERTICAL.valor.maxLength || !$v.trazadoEditado.GESTOR_VERTICAL.valor.minLength)" class="text-danger">
                            El GESTOR_VERTICAL debe de tener 3 caracteres
                          </small>                           
                          <small v-show="trazadoEditado.GESTOR_VERTICAL.editado && !$v.trazadoEditado.GESTOR_VERTICAL.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- MUTUALIZADO -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="MUTUALIZADO" class="form-control-label noselect mx-1 d-flex align-items-center">MUTUALIZADO</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11" @click="editarInput($event, trazadoEditado.MUTUALIZADO)">
                                <input id="MUTUALIZADO"  class="form-control w-100 m-0 pointerHover" type="text" :disabled="!trazadoEditado.MUTUALIZADO.editado" maxlength="255" :placeholder="trazadoEditado.MUTUALIZADO.valorInicial" v-model="trazadoEditado.MUTUALIZADO.valor" :class="{ 'is-invalid': trazadoEditado.MUTUALIZADO.editado && $v.trazadoEditado.MUTUALIZADO.valor.$invalid}" autofocus autocomplete="off" v-input-upper>
                              </div>
                              <div v-if="trazadoEditado.MUTUALIZADO.editado" class="col-1 px-0 text-start align-self-center" title="Restaurar valor">
                                <i class="fa-solid fa-rotate-left clickable fs-5" @click="restaurarValorInicial(trazadoEditado.MUTUALIZADO)"></i>
                              </div>
                              <div v-if="!trazadoEditado.MUTUALIZADO.editado" class="col-1 px-0 text-start align-self-center" title="Editar campo">
                                <i class="fa-solid fa-pencil-alt clickable fs-5" @click="editarInput($event, trazadoEditado.MUTUALIZADO)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row justify-content-end text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">   
                          <small v-show="trazadoEditado.MUTUALIZADO.editado && !$v.trazadoEditado.MUTUALIZADO.valor.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="trazadoEditado.MUTUALIZADO.editado && !$v.trazadoEditado.MUTUALIZADO.valor.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>
                </div>

                  <hr class="mt-4">
                  <div class="d-flex justify-content-center">
                      <button type="button" class="btn btn-secondary boton-generar mx-2" @click="resetearFormulario()" title="Reiniciar trazado">        
                        Reiniciar
                      </button>
                      <button type="button" class="btn btn-primary boton-generar mx-2" @click="ponerEnCola()">
                            <i  v-if="!cargandoPeticion" class="fa-solid fa-magnifying-glass mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando trazado...</span>
                            </div>
                            Actualizar trazado
                      </button>
                  </div>	

              </div>
            </form>          
          </div>

        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { required, minLength, maxLength, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
import { ponerTrazadosEnCola } from '../../helpers/EditorInfluencia';
import { errorAlert } from '../../helpers/fastAlert';
import Swal from 'sweetalert2'
import axios from "axios";

//Comprueba si se contiene los caracteres *, |, ', `, ", {, }
const caracterProhibido = (value) => {
  return !/[\*\|\'\`\"\;\{\}]/g.test(value)
}
const formatoOLT = (value) => {
  let splitted = value.split('-');
  if(!splitted || !splitted.length || splitted.length != 5 || splitted[0] != '888' || splitted[splitted.length - 1] != 'OLT') return false;
  return true;  
}

const formatoCTO = (value) => {
  if(value.length < 11) return false
  let operador = value.substring(0,3);
  // let cp = value.substring(3,4);
  if(operador != '888') return false;
  return true;  
}
const defaultConfig = 
{
  minFrame: 0,
  maxFrame: 1,
  minSlot: 0,
  maxSlot: 7,
  minPort: 0,
  maxPort: 16
}
const defaultportMSG = 'Recuerda que las OLT Huawei tienen puertos de <strong class="line">0-15</strong> y las ZTE de <strong class="line">1-16</strong>'

export default {
  name: "ModalEditarTrazados",
  props: ['lista_olt'],
   data() {
    return {
      listaTrazados: '',
      trazadoEditado: {},
      show: false,
      cargandoPeticion: false,
      tipos_cto: [],
      tipos_splitter: ['1:02', '1:04', '1:08', '1:16', '1:32', '1:64', '1:128'],
      modelos_olt: [
        {
          modelo: 'MA5608T',
          distribuidor: 'Huawei',
          config: {
              minFrame: 0,
              maxFrame: 0,
              minSlot: 0,
              maxSlot: 2,
              minPort: 0,
              maxPort: 15
          }
        },
        {
          modelo: 'MA5800-X2',
          distribuidor: 'Huawei',
          config: {
              minFrame: 0,
              maxFrame: 0,
              minSlot: 1,
              maxSlot: 2,
              minPort: 0,
              maxPort: 15
          }
        },
        {
          modelo: 'MA5800-X7',
          distribuidor: 'Huawei',
          config: {
              minFrame: 0,
              maxFrame: 0,
              minSlot: 1,
              maxSlot: 7,
              minPort: 0,
              maxPort: 15
          }
        },
        {
          modelo: 'ZTEC600',
          distribuidor: 'ZTE',
          config: {
              minFrame: 1,
              maxFrame: 1,
              minSlot: 1,
              maxSlot: 7,
              minPort: 1,
              maxPort: 16
          }
        },
        {
          modelo: 'C-620',
          distribuidor: 'ZTE',
          config: {
              minFrame: 1,
              maxFrame: 1,
              minSlot: 1,
              maxSlot: 2,
              minPort: 1,
              maxPort: 16
          }
        },
        
      ],
      config: defaultConfig,
      mensajePuerto: defaultportMSG
    }
  },
  validations: {
    trazadoEditado: {
      CODIGO_OLT: {
        valor: {
          required,
          maxLength: maxLength(23),
          caracterProhibido,
          formatoOLT
        }  
      },
      MODELO_OLT: {
        valor: {
          required,
          maxLength: maxLength(18),
          caracterProhibido
        }
      },
      FRAME_OLT: {
        valor: {
          required,
          maxLength: maxLength(1),
          minValue: minValue(defaultConfig.minFrame),
          maxValue: maxValue(defaultConfig.maxFrame),
          numeric,
          caracterProhibido
        }
      },
      TARJETA_OLT: {
        valor: {
          required,
          maxLength: maxLength(8),
          minValue: minValue(defaultConfig.minSlot),
          maxValue: maxValue(defaultConfig.maxSlot),
          numeric,
          caracterProhibido
        }
      },
      PUERTO_GPON: {
        valor: {
          required,
          maxLength: maxLength(8),
          minValue: minValue(defaultConfig.minPort),
          maxValue: maxValue(defaultConfig.maxPort),
          numeric,
          caracterProhibido
        }
      },
      CAPACIDAD_GPON: {
        valor: {
          required,
          maxLength: maxLength(3),
          numeric,
          caracterProhibido
        }
      },
      CODIGO_CTO: {
        valor: {
          required,
          maxLength: maxLength(13),
          minLength: minLength(11),
          caracterProhibido,
          // formatoCTO
        }
      },
      TIPO_CTO: {
        valor: {
          required,
          maxLength: maxLength(40),
          caracterProhibido
        }
      },  
      DIRECCION_CTO: {
        valor: {
          required,
          maxLength: maxLength(255),
          caracterProhibido
        }
      },     
      SPLITTER_ID: {
        valor: {
          required,
          maxLength: maxLength(15),
          caracterProhibido
        }
      },        
      TIPO_SPLITTER: {
        valor: {
          required,
          maxLength: maxLength(5),
          caracterProhibido
        }
      },       
      PUERTO_CTO: {
        valor: {
          required,
          maxLength: maxLength(8),
          minValue: minValue(1),
          maxValue: maxValue(128),
          numeric,
          caracterProhibido
        }
      },  
      GESTOR_VERTICAL: {
        valor: {
          minLength: minLength(3),
          maxLength: maxLength(3),
          caracterProhibido
        }
      },     
      MUTUALIZADO: {
        valor: {
          maxLength: maxLength(255),
          caracterProhibido: (value) => { return !/[\*\'\`\"\;\{\}]/g.test(value) } //En este caso se permite el símbolo |
        }
      },   
    }
  },
  methods: {
    async obtenerTiposCTO(){
       try {
          const respuesta = await axios({
              method: 'GET',
              url: '/hogares/tipos_cto', 
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.tipos_cto = respuesta.data.data.tipos
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },
    cargarTrazados(listaTr){
      this.abrirModal();
      if(!listaTr.length) {
        this.cerrarModal()
        return;
      }
      this.listaTrazados = listaTr;
      let valoresMultiples = ''
      if(listaTr.length>1){
        valoresMultiples = 'Múltiples valores'
      }
      for (const key of Object.keys(listaTr[0])) {
          let valores = {
              valor: valoresMultiples || listaTr[0][key],
              editado: false,
              valorInicial: valoresMultiples || listaTr[0][key],
          }
          this.$set(this.trazadoEditado, key, valores)
      }
      this.setConfig()
    },
    cerrarModal(){
      this.show = false;
    },
    abrirModal(){
      this.show = true;
    },
    editarInput(event, key, focus = true, cleanValue = true) {
      if(!event.target) return;
      let input = event.target.firstChild ? event.target.firstChild : event.target
      if(!key.editado){
        key.editado = true;
        if(cleanValue) key.valor = ''
      }
      if(focus){
        setTimeout(() => {
          input.focus()
        }, 100);
      }
    },
    restaurarValorInicial(key){
      if(key.editado){
        key.editado = false;
        key.valor = key.valorInicial
      }
      this.$forceUpdate();
    },
    resetearFormulario(){
      for (const key of Object.keys(this.trazadoEditado)) {        
          this.trazadoEditado[key].valor = this.trazadoEditado[key].valorInicial;
          this.trazadoEditado[key].editado = false;
      }
      this.setConfig()
      this.$forceUpdate();
    },
    ponerEnCola(){
      //Validaciones
      for (const key of Object.keys(this.trazadoEditado)) {        
        if(this.$v.trazadoEditado[key] && this.trazadoEditado[key].editado && this.$v.trazadoEditado[key].$invalid){
          errorAlert('¡No se pudieron guardar los cambios!', `El campo ${key}, es inválido`)
          return;
        }
      }
      if(!this.hayCambios()) {
        Swal.fire({
          title: "No has realizado ningún cambio",
          icon: 'error',
          confirmButtonText: 'Vale'
        })
        return;
      }
      ponerTrazadosEnCola(this.trazadoEditado, this.listaTrazados)
      Swal.fire({
        title: "Cambio guardado",
        icon: 'success',
        confirmButtonText: 'Vale'
      })
      this.$emit('refrescar')
      this.cerrarModal()
    },
    hayCambios(){
      for (const key of Object.keys(this.trazadoEditado)) {        
        if(this.trazadoEditado[key].editado && this.trazadoEditado[key].valorInicial != this.trazadoEditado[key].valor){
          return true;
        }
      }
      return false;
    },
    setConfig(){
      if(!this.trazadoEditado) return
      let config = defaultConfig;
      this.mensajePuerto = defaultportMSG
      let modeloEncontrado = this.modelos_olt.find( e => e.modelo == this.trazadoEditado.MODELO_OLT.valor);
      if(modeloEncontrado){
        config = modeloEncontrado.config;
        if(modeloEncontrado.distribuidor == 'Huawei') this.mensajePuerto = 'Recuerda que las OLT Huawei tienen puertos de <strong class="line">0-15</strong>'
        if(modeloEncontrado.distribuidor == 'ZTE') this.mensajePuerto = 'Recuerda que las OLT ZTE tienen puertos de <strong class="line">1-16</strong>'
      }
        //FRAME
        this.$options.validations.trazadoEditado.FRAME_OLT.valor.minValue = minValue(config.minFrame)
        this.$options.validations.trazadoEditado.FRAME_OLT.valor.maxValue = maxValue(config.maxFrame)
        //SLOT
        this.$options.validations.trazadoEditado.TARJETA_OLT.valor.minValue = minValue(config.minSlot)
        this.$options.validations.trazadoEditado.TARJETA_OLT.valor.maxValue = maxValue(config.maxSlot)
        //PORT
        this.$options.validations.trazadoEditado.PUERTO_GPON.valor.minValue = minValue(config.minPort)
        this.$options.validations.trazadoEditado.PUERTO_GPON.valor.maxValue = maxValue(config.maxPort)
        this.config = config
    }
  },
  
  created() {
    this.obtenerTiposCTO();
  },

}
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  max-height: 90vh;
  margin: 2rem auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>