<template>

    <div class="card page-card border-0 shadow-lg my-5">

       
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Reportes de recursos</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>

      <!-- Reporte de operaciones -->
      <div class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Action card header -->
          <div class="d-flex align-items-baseline justify-content-start">
            <h4>Reporte de operaciones</h4>
            <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoOperaciones()"></i>
          </div>

            <!-- Algoritmo -->
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                  <div class="row">
                   
                  <!-- Provincia -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="ProvinciaOperaciones" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="ProvinciaOperaciones" class="form-control  m-0" maxlength="255" v-model="operaciones.PROVINCIA" :disabled="operaciones.generandoReporte" @change="mostrarPueblosDisponiblesOperaciones()">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in areaInfluencia" :key="index" :value="item.PROVINCIA">{{item.PROVINCIA}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>            
                  
                  <!-- Pueblos -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="PuebloOperaciones" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="PuebloOperaciones" class="form-control  m-0" maxlength="255" v-model="operaciones.POBLACION" :disabled="operaciones.generandoReporte || !operaciones.PROVINCIA">                                
                                  <option value="" selected>Sin especificar</option>
                                  <option v-for="(item, index) in operaciones.pueblosDisponibles" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>                  
                  <!-- FECHA -->
                  <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="datepicker-desde-operaciones" class="line mx-1 fs-5 d-flex align-items-center">Fecha</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row align-items-center">
                              <div class="col-10 col-md-5 text-start">
                                <b-form-datepicker :disabled="operaciones.generandoReporte" today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-desde-operaciones" placeholder="Desde" locale="es" v-model="operaciones.DESDE"></b-form-datepicker>
                              </div>
                              <div class="col-10 col-md-5 mt-3 mt-md-0 text-start">
                                <b-form-datepicker :disabled="operaciones.generandoReporte" today-button label-today-button="Hoy" reset-button label-reset-button="Borrar" class="g-0 date-picker" id="datepicker-hasta-operaciones" placeholder="Hasta" locale="es" v-model="operaciones.HASTA"></b-form-datepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <hr class="mt-4">

                   <div class="d-flex justify-content-center" title="Generar reporte">
                      <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-secondary boton-generar mx-2" @click="limpiarOperaciones()" title="Limpiar filtros operaciones">        
                        Limpiar
                      </button>
                      <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-primary boton-generar line my-1 mx-2" @click="generarInformeOperaciones()" title="Generar informe de operaciones">
                            <i  v-if="!operaciones.generandoReporte" class="fa-solid fa-file-circle-plus mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                            </div>
                            Generar reporte
                      </button>
                      <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-success boton-generar line my-1 mx-2" @click="generarInformeOperaciones(true)" title="Generar informe de operaciones y descargar excel">
                            <i  class="fa-solid fa-file-excel mx-1"></i>
                            Generar y descargar
                      </button>
                   </div>	

                  </div>

                </form>
              <!-- Tabla -->
            <div v-show="operaciones.table" class="mt-4 p-4 shadow">

                <table id="tablaOperaciones" class="table table-striped w-100" width="100%">
                    <thead>
                      <tr class="noselect">
                        <th scope="col" class="align-middle">PROVINCIA</th>
                        <th scope="col" class="align-middle">POBLACIÓN</th>
                        <th scope="col" class="align-middle">OPERADOR</th>
                        <th scope="col" class="align-middle">FECHA OPERACION</th>
                        <th scope="col" class="align-middle">ID ADMINISTRATIVO</th>
                        <th scope="col" class="align-middle">OPERACION</th>
                        <th scope="col" class="align-middle">TEMPLATE</th>
                        <th scope="col" class="align-middle" style="width: 15rem;">DIRECCIÓN</th>
                        <th scope="col" class="align-middle">DETALLE</th>

                      </tr>
                    </thead>
                    <tbody class="align-middle text-start">                             
                    </tbody>
                </table>
                <button type="button" :disabled="operaciones.generandoReporte" class="btn btn-success boton-generar line mt-2 mx-2" @click="descargarInformeOperaciones()" title="Descargar excel del informe de operaciones">
                      <i  class="fa-solid fa-file-excel mx-1"></i>
                      Descargar
                </button> 
            </div>
            </div>
        </div>
      </div>

    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert';
import { crearYdescargar, jsonToExcelBuffer } from '../helpers/utils';
import axios from "axios";
import { fecha } from '../classes/fecha.class';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from "sweetalert2";
import { getJWTpayload } from '../helpers/JWT';

/*
  Página en la que el usuario puede descargar un reporte de operaciones.
  Se le asigna el id_operador automaticamente ya que no puede elegir el operador del que se quiere sacar el informe
*/
export default {
  name: 'ReportesOperador',
  data() {
    return {
      operaciones: {
        generandoReporte: false,
        PROVINCIA: '',
        POBLACION: '',
        DESDE: '',
        HASTA: '',
        pueblosDisponibles: [],
        informeOperaciones: '',
        table: '',
      },
      mensajedeError: '',
      areaInfluencia: []
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Reportes de recursos', 'En esta página se podrán generar reportes de facturación.')
    },
    infoOperaciones(){
      infoAlert('Reporte de operaciones', 'El informe de operaciones contiene <strong>todos los movimientos</strong> que se han realizado para la gestión de servicios (<strong>Altas, bajas y cambios de servicio</strong>).')
    },
    limpiarOperaciones(){
      this.operaciones.PROVINCIA = '';
      this.operaciones.POBLACION = '';
      this.operaciones.DESDE = '';
      this.operaciones.HASTA = '';
      if(this.operaciones.table) {
        this.operaciones.table.clear().draw();
      }
    },
    async generarInformeOperaciones(descargar = false){
        try {
          //Obtener los datos del reporte a través del servidor
          this.operaciones.generandoReporte = true;
          let HASTA = this.operaciones.HASTA;
          if(HASTA) HASTA += ' 23:59:59' //Se añaden tiempo en el parametro hasta para que sea el dia completo y no desde el principio
          let respuesta = await axios({
            method: 'GET',
            url: '/reportes/operaciones',
            params: {
              ID_OPERADOR: this.operaciones.id_operador,
              PROVINCIA: this.operaciones.PROVINCIA,
              POBLACION: this.operaciones.POBLACION,
              DESDE: this.operaciones.DESDE,
              HASTA,
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
          let reportData = respuesta.data.data.reporte;
          //Si no hay datos notificamos con una alerta
          if(!reportData || !reportData.length){
            throw 'No se encontraron datos.';
          }
          this.operaciones.informeOperaciones = reportData;
          if(this.operaciones.table) this.operaciones.table.destroy();
          this.operaciones.table = $('#tablaOperaciones').DataTable({
            data: this.operaciones.informeOperaciones,
            responsive: true,
            language: { 
              
              "oPaginate": {
                "sPrevious": "&#10094;&#10094;",
                "sNext":     "&#10095;&#10095;",
              },
            },
            columns: [
              { data: 'PROVINCIA' },
              { data: 'POBLACION' },
              { data: 'OPERADOR' },
              { data: 'FECHA OPERACION' },
              { data: 'ID_ADMINISTRATIVO' },
              { data: 'OPERACION' },
              { data: 'TEMPLATE' },
              { data: 'DIRECCION' },
              { data: 'DETALLE' },
            ]
          });
          
          this.operaciones.table.draw();

          if(descargar){
            this.descargarInformeOperaciones();
          }


          this.operaciones.generandoReporte = false;
        } catch (error) {
          this.operaciones.generandoReporte = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log('Error controlado', error.response);
            errorAlert('¡No se pudo generar el reporte!', error.response.data.msg);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            errorAlert('¡No se pudo generar el reporte!', error.message || error);
          }
        }
    },    
    async cargarAreadeInfluencia(){
      try {
        let respuestaPandora = await axios.get('/historial/poblaciones', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.areaInfluencia = respuestaPandora.data.data.poblaciones;
        
      } catch (error) {
         if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          this.mensajedeError = error.response.data.msg;
        } else if (error.request) {
          // The request was made but no response was received
          this.mensajedeError = 'No se ha recibido respuesta del servidor';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.mensajedeError = error.message;
        }
      }
    },
    mostrarPueblosDisponiblesOperaciones(){
      this.operaciones.POBLACION = '';
      let provincia = this.areaInfluencia.find(e => e.PROVINCIA == this.operaciones.PROVINCIA);
      if(provincia && provincia.PUEBLOS){
        this.operaciones.pueblosDisponibles = provincia.PUEBLOS;
      }else{
        this.operaciones.pueblosDisponibles = [];
      }
    },
    descargarInformeOperaciones(){
        //Crear excel y exportarlo
        let excel = jsonToExcelBuffer(this.operaciones.informeOperaciones);
        let inicioFecha = new fecha(this.operaciones.DESDE || '2020-01-01').yyyymmdd
        let finFecha = new fecha(this.operaciones.HASTA).yyyymmdd
        let nombre = 'Reporte de operaciones - ' + inicioFecha + '_' + finFecha;
        crearYdescargar(excel, nombre, 'excel')
    },
  },
  created() {
    this.cargarAreadeInfluencia();
  },
  mounted(){
  }
}
</script>

<style scoped>
.boton-generar{
  min-width: 10rem;
}

.alert .notification-items{
  max-height: 10rem;
  overflow: auto;
}
@media (min-width: 1200px) {
  .boton-generar {
    max-width: 12rem;
    inline-size: auto;
  }
}
</style>
