<template>

    <div class="card page-card border-0 shadow-lg my-5">
       
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>OLTs</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
        <!--Nueva olt-->
        <div>
            <button v-if="esEditorInfluencia()" class="btn btn-sm btn-primary shadow-sm d-none d-sm-block" @click="toggleCreator()"><i class="fas fa-plus"></i> Nueva OLT</button>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>

      <!-- ######### -->
      <!-- Nueva OLT -->
      <!-- ######### -->
      <b-collapse id="creator" v-model="showCreator">
        <div  class="m-3 card shadow">
            <div class="card-body"> 
            <!-- header -->
            <div class="d-flex align-items-baseline justify-content-start">
                <h4>Nueva OLT</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoNuevaOLT()"></i>
            </div>
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                <div class="row">
                    <!-- codigo_municipio -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="codigo_municipio" class="mx-1 d-flex align-items-center">Municipio</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10">
                                   <input id="codigo_municipio"  class="form-control w-100 m-0" type="text" list="listaMunicipios" :disabled="cargandoPeticion" maxlength="100" v-model.lazy="municipioElegido" placeholder="Municipio del Nodo" autocomplete="off">
                                    <datalist id="listaMunicipios">
                                      <option v-for="(item, index) in listaMunicipios" :key="index" :value="item.uid">{{item.municipio}}, {{item.provincia}} - 888-{{("0" + item.codigo_provincia).slice(-2)}}-{{("0" + item.codigo_municipio).slice(Math.max(item.codigo_municipio.toString().length, 2) * -1)}}-XX-OLT</option> 
                                    </datalist> 
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>  
                    <!-- IP_GESTION -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="IP_GESTION" class="mx-1 d-flex align-items-center">IP Gestión</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10">
                                   <input id="IP_GESTION"  class="form-control w-100 m-0" v-model="nuevaOLT.IP_GESTION" type="text" required maxlength="255" placeholder="IP de Gestión GPON">
                                </div>
                            </div>
                            </div>
                        </div>
                    </div> 
                    <!-- olt - resumen -->
                    <div v-if="nuevaOLT" class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                               <label for="nombre_municipio" class="mx-1 d-flex align-items-center">Nueva OLT</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10 text-start">
                                    <small>{{nuevaOLT.CODIGO_OLT}}</small>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row justify-content-end text-start">
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">     
                            <small v-show="nuevaOLT.numeroOLT > 1" class="text-danger">
                               El municipio ya tiene una OLT, la OLT nueva sería la número <strong>{{nuevaOLT.numeroOLT}}</strong> del municipio.
                            </small> 
                          </div>
                        </div>
                    </div>    

                    <hr class="mt-4">
                    <div class="d-flex flex-column flex-md-row  justify-content-center">
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarFormulario()" title="Borra todos los datos escritos en el formulario">        
                        Limpiar
                        </button>
                        <button type="button" :disabled="cargandoPeticion || !nuevaOLT" class="btn btn-primary boton-generar line my-1 mx-2" @click="crearOLT()" title="Añade el municipio nuevo">
                        <i  v-if="!cargandoPeticion" class="fa-solid fa-file-circle-plus mx-1"></i>
                        <div v-else class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                        Añadir
                        </button>
                    </div>

                </div>

                </form>
            
            </div>
            </div>
        </div>
      </b-collapse>

      <!-- ############ -->
      <!-- Editar olts -->
      <!-- ############ -->
      <b-collapse id="creator" v-model="showEditor">
        <div  class="m-3 card shadow">
            <div class="card-body"> 
            <!-- header -->
            <div class="d-flex align-items-baseline justify-content-start">
                <h4>Editar OLT</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoEditarOLT()"></i>
            </div>
            <div class="mt-3">
                <form class="user d-flex flex-column" novalidate>
                <div class="row">  
                    <!-- CODIGO OLT -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                            <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                <label for="" class="line mx-1 d-flex align-items-center">CODIGO OLT</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                <div class="row d-flex flex-row">
                                <div class="col-10 text-start">
                                    <small>{{CODIGO_OLT}}</small>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>            
                    
                    <!-- IP_GESTION -->
                    <div class="col-sm-12 col-xxl-6 my-2">
                        <div class="row d-flex align-items-center">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="IP_GESTION_EDICION" class="mx-1 d-flex align-items-center">IP GESTION GPON</label>
                            </div>
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                                <div class="col-10 text-start">
                                      <input id="IP_GESTION_EDICION" class="form-control w-100 m-0" v-model="IP_GESTION" type="text" required maxlength="255" placeholder="IP de Gestión GPON">
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>  

                    <hr class="mt-4">
                    <div class="d-flex flex-column flex-md-row  justify-content-center">
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-secondary boton-generar line my-1 mx-2" @click="limpiarFormulario()" title="Borra todos los datos escritos en el formulario">        
                         Limpiar
                        </button>
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-danger boton-generar line my-1 mx-2" @click="cancelarEditar()" title="Cancela la asignación de OLTs">        
                          Cancelar
                        </button>                        
                        <button type="button" :disabled="cargandoPeticion" class="btn btn-primary boton-generar line my-1 mx-2" @click="enviarPeticionEdicion()" title="Asignar OLTs">
                        <i  v-if="!cargandoPeticion" class="fa-solid fa-floppy-disk mx-1"></i>
                        <div v-else class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                          Guardar
                        </button>
                    </div>

                </div>

                </form>
            
            </div>
            </div>
        </div>
      </b-collapse>

      <!-- ##################### -->
      <!-- OLTs existentes -->
      <!-- ##################### -->
      <div v-show="listaOlts && listaOlts.length" class="m-3 card shadow">
        <div class="card-body"> 
          <!-- Header -->
          <div class="d-flex align-items-baseline justify-content-start">
             <h4>Lista de OLTs</h4>
          </div>
          <b-overlay :show="cargandoDatatable">
          <div class="mt-3">
            <div class="m-2 p-2 shadow-lg">
                <table id="datatableOLTs"  class="table table-striped table-bordered w-100 table-tiny" width="100%">
                    <thead class="text-start">
                        <tr class="noselect">
                        <th scope="col" class="d-table-cell text-center" style="max-width: 3.5rem;">ACCION</th>
                        <th scope="col" class="d-table-cell">CODIGO_OLT</th>
                        <th scope="col" class="d-table-cell">IP GESTION GPON</th>
                        </tr>
                    </thead>
                    <tbody class="align-middle text-start">                              
                    </tbody>
                </table>
            </div>
          </div>
          </b-overlay>
        </div>
      </div>


    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert, warningAlert } from '../../helpers/fastAlert';
import { required, maxLength, numeric } from 'vuelidate/lib/validators';
import axios from "axios";
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from 'sweetalert2'
import { isEditorInluencia } from '../../helpers/JWT';


export default {
  name: 'OLTs',
  data() {
    return {
      listaOlts: [],
      listaMunicipios: [],
      mensajedeError: '',
      municipioElegido: '',
      nuevaOLT: '',

      cargandoPeticion: false,
      cargandoDatatable: false,
      showCreator: false,
      showEditor: false,
      CODIGO_OLT: '',
      IP_GESTION: '',

      tabla: ''
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('OLTs', 'Aquí podrás encontrar las OLTs registradas por Asteo. A través de esta informacion se podrán crear nuevos trazados y realizar operaciones de mantenimiento.')
    },
    infoNuevaOLT(){
      infoAlert('Nueva OLT', 'Aquí puedes crear una nueva OLT y asignarle su IP de gestión GPON, la OLT se asignará automaticamente al municipio seleccionado como OLT Nodo.')
    },    
    infoEditarOLT(){
      infoAlert('Editar OLT', 'Al editar un OLT puedes cambiar su <strong>IP de gestión GPON</strong>.')
    },
    toggleCreator(){        
        this.showCreator = !this.showCreator
        this.showEditor = !this.showCreator && this.showEditor
        document.getElementsByClassName("Panel")[0].scrollTop = 0;
    },
    initDatatable(){
      this.cargandoDatatable = true;
      this.tabla = $('#datatableOLTs').DataTable({
              autoWidth: false,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                {
                  data: null,
                  defaultContent: `
                    <i type="button" class="fa-solid fa-pencil fa-2x mx-1 icon-btn text-primary editar-item" title="Editar OLT"></i>
                    <i type="button" class="fa-solid fa-trash-alt fa-2x mx-1 icon-btn text-danger eliminar-item" title="Eliminar OLT"></i>
                  `,
                  className: 'dt-body-center',
                  width: '3rem',
                  visible: isEditorInluencia()
                },
                { data: 'CODIGO_OLT' },
                { data: 'IP_GESTION' },
              ]    
        });
      if(isEditorInluencia()){
        $('#datatableOLTs tbody').on('click', 'i.eliminar-item', (event) => {   
          let rowData = this.tabla.row($(event.target).parents('tr')).data();
          this.eliminarOLT(rowData);
        });
        $('#datatableOLTs tbody').on('click', 'i.editar-item', (event) => {   
          let rowData = this.tabla.row($(event.target).parents('tr')).data();
          this.editarOLT(rowData);
        });
      }
    },
    async crearOLT(){
       try {
          this.cargandoPeticion = true;
          let respuesta = await axios({
            method: 'POST',
            url: '/olts',
            params: { CODIGO_OLT: this.nuevaOLT.CODIGO_OLT },
            data: { IP_GESTION: this.nuevaOLT.IP_GESTION },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          successAlert('Proceso completado', respuesta.data.msg)
          this.limpiarFormulario()
          this.getOlts();
          this.getMunicipios();
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            console.log("error controlado", error.response);
            errorAlert('Error creando codigo de municipio', error.response.data.msg)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
          }
        }
    },
    async eliminarOLT(rowData){
      this.cargandoPeticion = true;
      await Swal.fire({
            title: '¿Está seguro?',
            html: `Se borrará la OLT y la relación con los municipios que la consuman`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText:  '<i class="fa-solid fa-trash-alt"></i> Eliminar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {

                return axios({
                  method: 'DELETE',
                  url: '/olts',
                  params: { CODIGO_OLT: rowData.CODIGO_OLT},
                  headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
                })
                .then(resp => {})
                .catch(err => {
                  console.log("error", err);
                  if (err.response) {
                    //Request made and server responded
                    console.log(' manejado', err.response);
                    Swal.showValidationMessage(`Error en el proceso. ${err.response.data.msg}`);
                  } else if (err.request) {
                    // The request was made but no response was received
                    Swal.showValidationMessage('No se ha recibido respuesta del servidor');
                  } else {
                    // Something happened in setting up the request that triggered an err
                    console.log('err no manejado', err.message);
                    Swal.showValidationMessage(err.message);
                  }  
                })

            },
            backdrop: true,
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                successAlert('Proceso completado', 'Se ha eliminado la OLT')
                this.getMunicipios();
              }
            })
            this.limpiarFormulario()
            this.getOlts()
            this.getMunicipios()
            this.showCreator = false;
            this.showEditor = false;
      this.cargandoPeticion = false;
    },
    editarOLT(rowData){
      this.CODIGO_OLT = rowData.CODIGO_OLT;
      this.IP_GESTION = rowData.IP_GESTION;
      this.showEditor = true;
      document.getElementsByClassName("Panel")[0];

      this.showCreator = false;
    },
    cancelarEditar(){
      this.limpiarFormulario()
      this.showEditor = false;
    },
    async enviarPeticionEdicion(){
        try {
          this.cargandoPeticion = true;
          let respuesta = await axios({
            method: 'PUT',
            url: '/olts',
            params: { CODIGO_OLT: this.CODIGO_OLT},
            data: {
              IP_GESTION: this.IP_GESTION
            },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
            this.limpiarFormulario()
            this.getOlts()
            this.getMunicipios()
            this.showCreator = false;
            this.showEditor = false;
            successAlert('¡Proceso completado!', respuesta.data.msg)
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            //Request made and server responded
            errorAlert('No se pudo asignar OLTs', error.response.data.msg)
            console.log(error.response);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            serverErrorAlert(error.message);
          }
        }
    },
    ponerDatosDatatable(){
      this.tabla.clear();
      this.tabla.rows.add(this.listaOlts).draw();
      this.cargandoDatatable = false;
    },
    limpiarFormulario(){
      this.nuevaOLT = '';
      this.IP_GESTION = '';
      this.municipioElegido = '';
    },
    quitarOLT(CODIGO_OLT){
        this.oltsElegidas = this.oltsElegidas.filter( e => e.CODIGO_OLT != CODIGO_OLT)
    },
    async getMunicipios(){
        try {
          let respuesta = await axios.get('/municipios', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          let listaMunicipios = respuesta.data.data.listaMunicipios; 
          //Comprobamos si los municipios tienen una OLT y ponemos la que sería la nueva OLT
          for (const municipio of listaMunicipios) {
            let numeroNuevaOLT = 1;
            for (const olt of municipio.olts) {
              let splitted = olt.CODIGO_OLT.split('-')
              let codigo_provincia = splitted[1]
              let codigo_municipio = splitted[2]
              let numeroOLT = splitted[3]
              if(codigo_provincia == municipio.codigo_provincia && codigo_municipio == municipio.codigo_municipio && numeroOLT >= numeroNuevaOLT) 
              {numeroNuevaOLT = numeroOLT + 1;}
            }
            municipio.posibleNuevaOLT = `888-${municipio.codigo_provincia}-${municipio.codigo_municipio}-${("0" + numeroNuevaOLT).slice(-2)}-OLT`
            municipio.numeroNuevaOLT = numeroNuevaOLT;
          }
          this.listaMunicipios = listaMunicipios;
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    async getOlts(){
        try {
          this.cargandoPeticion = true;
          let respuesta = await axios.get('/olts', { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.listaOlts = respuesta.data.data.listaOlts;  
          this.ponerDatosDatatable()        
          this.cargandoPeticion = false;
        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log('Error controlado', error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
              console.log('Error servidor', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error genérico', error.message);
              this.mensajedeError = error.message;
            }
        }
    },
    esEditorInfluencia(){
      return isEditorInluencia();
    },
  },
  created() {
    this.getMunicipios();
    this.getOlts();
  },
  mounted(){
    this.initDatatable()
  },
  watch: {
    'municipioElegido'(newVal, oldVal){
      let encontrado = this.listaMunicipios.find( e => e.uid == newVal);
      if(encontrado){
        this.nuevaOLT = {}
        //Buscamos todas las OLT que son de este municipio y provincia
        let olts_municipio = this.listaOlts.filter( e => {
          let splitedOLT = e.CODIGO_OLT.split('-')
          if(splitedOLT[1] == encontrado.codigo_provincia && splitedOLT[2] == encontrado.codigo_municipio) return true;
          return false;
        })
        //Calculamos el número de la nueva OLT
        let nuevoNumeroOLT = 1
        for (const olt of olts_municipio) {
          //Numero de OLT
          let numOLT = olt.CODIGO_OLT.split('-')[3]
          if(parseInt(numOLT) >= nuevoNumeroOLT) nuevoNumeroOLT = parseInt(numOLT) + 1;
        }
        this.nuevaOLT.CODIGO_OLT = `888-${("0" + encontrado.codigo_provincia).slice(-2)}-${("00" + encontrado.codigo_municipio).slice(Math.max(encontrado.codigo_municipio.toString().length, 2) * -1)}-${("0" + nuevoNumeroOLT).slice(-2)}-OLT`;
        this.nuevaOLT.numeroOLT = nuevoNumeroOLT;
      }else{
        this.NuevaOLT = ''
      }
      this.municipioElegido = ''
    },
  }  
}
</script>

<style scoped>
.action-column{
  width: 2rem;
}
.tabla-olts{
    max-width: 30rem;
}
</style>
