<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">
            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Cambio de servicio</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Volver a mis Servicios-->
              <div>
                  <router-link :to="{name: item.editorAdmin ? 'ServiciosTest' : 'Servicios'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fa-solid fa-coins mx-1"></i> Servicios</router-link>
              </div>
            </div>
            
            <div class="card-body">
              <!-- Tarjeta informativa -->
              <TarjetaServicio :item="item"></TarjetaServicio>

              <!-- Nueva tarifa -->
              <b-overlay :show="cargandoTarifas">
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <!-- Action card header -->
                    <div class="d-flex align-items-baseline">
                      <h4>Nuevo perfil</h4>
                      <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoNuevoPerfil()"></i>
                    </div>
                    <!-- Select PERFIL -->
                    <div v-if="cargandoTarifas || tarifas.length>0">
                      <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                          <div class="col-12 col-sm-8 col-md-9 col-lg-10">
                            <select id="tarifaid" :disabled="cargandoPeticionTarifas" class="form-select" aria-label="Seleccionar perfil" maxlength="128" v-model.lazy="$v.perfilElegido.$model">                                
                              <option value="" disabled selected>Seleccione el perfil</option>
                              <option v-show="item.TEMPLATE != tarifaItem.template" v-for="(tarifaItem, index) in tarifas" :key="index" :value="tarifaItem.template">{{tarifaItem.descripcion}}</option>
                            </select>
                          </div>
                          <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-2 my-sm-0">
                              <button :disabled="$v.perfilElegido.$invalid || cargandoPeticionTarifas" class="btn btn-primary text-white w-100 launch-btn" @click="cambiarTarifa()">
                                <i v-if="!cargandoPeticionTarifas" type="button" class="fa-solid fa-save fa-1x mx-1" title="Cambiar perfil"></i>
                                <div v-else class="spinner-border spinner-border-sm" role="status">
                                  <span class="visually-hidden">Cargando petición...</span>
                                </div>
                                Guardar
                              </button>
                          </div>
                      </div>
                    </div>
                    <div v-else class="alert alert-warning d-flex align-items-center" role="alert">
                        <i class="fa-solid fs-5 fa-triangle-exclamation text-warning mx-2"> </i>                
                      <div>
                        No se ha encontrado ningún perfil disponible en tu cuenta.
                      </div>
                    </div>
                    
                  </div>
                </div>
              </b-overlay>



              <!-- Cambio comentario -->
              <div class="m-3 card shadow">
                <div class="card-body"> 
                  <!-- Action card header -->
                  <div class="d-flex align-items-baseline">
                    <h4>Cambiar comentario</h4>
                    <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoCambioComentario()"></i>
                  </div>
                  <!-- Descripción del comentario -->
                  <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                      <div class="col-12">
                        <div class="form-group">
                          <textarea class="form-control" :class="{ 'is-invalid': $v.comentarioNuevo.$invalid}" id="comentario" rows="3" aria-label="Comentario del cliente" maxlength="1024" v-model="$v.comentarioNuevo.$model" placeholder="Descripción del cliente u otros detalles..."></textarea>
                          <div v-show="$v.comentarioNuevo.$invalid" class="invalid-feedback text-start">
                            El comentario no puede superar los 1024 caracteres.
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2">
                          <button :disabled="$v.comentarioNuevo.$invalid || cargandoPeticionComentario" class="btn btn-primary text-white" @click="cambiarComentario()">
                            <i v-if="!cargandoPeticionComentario" type="button" class="fa-solid fa-save fa-1x mx-1" title="Cambiar comentario"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando petición...</span>
                            </div>
                            Guardar
                          </button>
                      </div>
                  </div>
                 
                  
                </div>
              </div>
              <!-- Cambio facturación -->
              <div v-if="esRolInterno() || contieneRol(['PERMISONOFACTURABLES'])" class="m-3 card shadow">
                <div class="card-body"> 
                  <!-- Action card header -->
                  <div class="d-flex align-items-baseline">
                    <h4>Facturar</h4>
                    <i class="fa-solid fs-5 fa-circle-question ms-2 clickable" @click="infoFacturacion()"></i>
                  </div>
                  <!-- Select opción -->
                  <div class="row d-flex align-items-center justify-content-center my-3 flex-column flex-sm-row container-fluid">
                      <div class="col-12 col-sm-8 col-md-9 col-lg-10">
                        <select id="facturar" :disabled="cargandoPeticionFacturacion" class="form-select" :class="{ 'is-invalid': $v.facturacion.$invalid}" aria-label="Facturar el cliente" maxlength="1" v-model="$v.facturacion.$model">                                
                          <option :value="0">SI</option>
                          <option :value="1">NO</option>
                        </select>
                        <div v-show="$v.facturacion.$invalid" class="invalid-feedback text-start">
                          Los valores de facturacion son inválidos.
                        </div>                          
                      </div>
                      <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-2 my-sm-0">
                          <button :disabled="$v.facturacion.$invalid || cargandoPeticionFacturacion" class="btn btn-primary text-white w-100 launch-btn" @click="cambiarFacturacion()">
                            <i v-if="!cargandoPeticionFacturacion" type="button" class="fa-solid fa-save fa-1x mx-1" title="Cambiar perfil"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando petición...</span>
                            </div>
                            Guardar
                          </button>
                      </div>
                  </div>
                  
                </div>
              </div>

            </div>


          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert, successAlert, confirmAlert } from '../helpers/fastAlert';
import { empezarEsperaCambioServicio, necesitaEsperar } from '../helpers/bloqueosPorTiempo';

import Swal from 'sweetalert2'
import axios from "axios";
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TarjetaServicio from '@/components/TarjetaServicio';
import { getJWTpayload, verificarJWT, desconectar, isInternoAsteo, contieneAlgunRolPermitido } from '../helpers/JWT';

export default {
  /*
    En esta página se le puede asignar un nuevo perfil de velocidad a un servicio.
    Se descarga de la API los perfiles de velocidad disponibles y se ponen en el selector.
  */
  name: 'CambioServicio',
  components: {
    TarjetaServicio
  },
  data() {
    return {
      perfilElegido: '',
      comentarioNuevo: '',
      facturacion: '',
      cargandoPeticionTarifas: false,
      cargandoPeticionComentario: false,
      cargandoPeticionFacturacion: false,
      cargandoTarifas: false,
      tarifas: []               //Lista de perfiles de velocidad disponibles
    } 
  },
  validations: {
    perfilElegido: {
      required,
      maxLength: maxLength(128)
    },
    comentarioNuevo: {
      maxLength: maxLength(1024)
    },
    facturacion: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(1)
    }
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Cambio de servicio', 'En esta página se puede realizar modificaciones del cliente como <strong>cambiar el perfil de velocidad</strong> o el <strong>comentario</strong>.')
    },   
    infoNuevoPerfil(){
      infoAlert('Nuevo perfil de velocidad', 'Elija en el desplegable el perfil de velocidad deseado y haga clic en guardar para cambiar la tarifa del cliente.')
    },
    infoCambioComentario(){
      infoAlert('Cambiar el comentario', 'Puede agregar una descripción del cliente para proporcionar información adicional, como su dirección o si se trata de una prueba, entre otros detalles.')
    },    
    infoFacturacion(){
      infoAlert('Facturar', 'Se puede marcar un cliente como <strong>no facturable</strong> si se trata de un <strong>cliente de prueba</strong>. Esto hará que no se incluya el cliente en el sistema de reportes.')
    },   
    contieneRol(allowedRols){
      return contieneAlgunRolPermitido(allowedRols);
    },       
    //Cambiar el perfil seleccionado
    async cambiarTarifa(){
      try {
        this.cargandoPeticionTarifas = true;
        if(necesitaEsperar(this.item.ID_ADMINISTRATIVO)) return;
        
        //Peticion de verificacion de seguridad
        let verificado =  await verificarJWT();
        if (!verificado) {
            errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
            desconectar();
            return;
        }

        let perfilNuevo = this.tarifas.find(dato => dato.template == this.perfilElegido)
        let respuesta = await axios({
          method: 'POST',
          url: '/cambioServicio',
          data: { 'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO, 'TEMPLATE': perfilNuevo.template, 'PERFILVELOCIDAD': perfilNuevo.descripcion },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
     
        this.cargandoPeticionTarifas = false;
        empezarEsperaCambioServicio(this.item.ID_ADMINISTRATIVO);

        await successAlert("Proceso ejecutado correctamente", respuesta.data.msg);
        localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
        this.$router.push({name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});
        
      } catch (error) {
        this.cargandoPeticionTarifas = false;
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
      }
    },
    //Cambiar el comentario del cliente
    async cambiarComentario(){
      try {
        this.cargandoPeticionComentario = true;        
        let respuesta = await axios({
          method: 'PUT',
          url: '/servicios/cambioComentario',
          data: { 'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO, 'comentario': this.comentarioNuevo },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.cargandoPeticionComentario = false;
     

        await successAlert("Proceso ejecutado correctamente", respuesta.data.msg);
        localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
        this.$router.push({name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});

      } catch (error) {
        this.cargandoPeticionComentario = false;
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert("Error en la operación", error.response.data.msg)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
      }
    },    
    //Cambiar facturacion
    async cambiarFacturacion(){
      try {
        if(!await confirmAlert("¡Esto hará que el cliente no se facture!")) return;
        this.cargandoPeticionFacturacion = true;        
        let respuesta = await axios({
          method: 'PUT',
          url: '/servicios/cambioFacturacion',
          data: { 'ID_ADMINISTRATIVO': this.item.ID_ADMINISTRATIVO, 'no_facturable': this.facturacion },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        this.cargandoPeticionFacturacion = false;
     

        await successAlert("Proceso ejecutado correctamente", respuesta.data.msg);
        localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
        this.$router.push({name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});

      } catch (error) {
        this.cargandoPeticionFacturacion = false;
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert("Error en la operación", error.response.data.msg)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
      }
    },        
    //Recuperar el servicio actual de la localStorage
    getServicio(){
      if(this.$route.params.item){
        localStorage.setItem('servicioActivo', JSON.stringify(this.$route.params.item));
      }
      if(localStorage.getItem('servicioActivo')){
        this.item = JSON.parse(localStorage.getItem('servicioActivo'));
      }      
      if(!this.item){
        this.$router.push({ name: this.item.editorAdmin ? 'ServiciosTest' : 'Servicios'});
      }
      this.comentarioNuevo = this.item.comentario;
      this.facturacion = this.item.no_facturable;
      localStorage.setItem('focusedItem', this.item.ID_ADMINISTRATIVO);
    },
    //Descargar los perfiles de velocidad de la api
    async getTarifas(){
        try {
          this.cargandoTarifas = true;
          let OPID = this.item.editorAdmin ? this.item.id_operador : getJWTpayload().OPID;
          let respuesta = await axios.get('/tarifas?OPID=' + OPID, { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.tarifas = respuesta.data.data.tarifas;          
          this.cargandoTarifas = false;
        } catch (error) {
          this.cargandoTarifas = false;

            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    },
    esRolInterno(){
      return isInternoAsteo()
    },       
  },
  created(){
    this.getServicio();
    this.getTarifas();
  }
}
</script>

<style scoped>

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
  }
}

.tablaScrolleable{
  overflow: auto;
  max-height: 30rem;
}
</style>
