<template>



    <!-- Contenido -->
    <div class="m-3" :class="{ 'card': !fullwidth, 'shadow': !fullwidth}">
      <div class="text-start" :class="{'card-body': !fullwidth}">
        <!-- OPERADOR -->
        <div class="d-flex justify-content-start flex-column flex-sm-row">
            <span class="mx-2 fs-6 fw-bold align-middle ">OPERADOR: </span>
            <span class="mx-2 fs-6 align-middle">{{item.operador}} </span>
        </div>
          <hr class="my-1">
      
        <!-- ID OPERADOR -->
        <div class="d-flex justify-content-start flex-column flex-sm-row">
          <span class="mx-2 fs-6 fw-bold align-middle">ID OPERADOR: </span>
          <span class="mx-2 fs-6 align-middle">{{item.id_operador}} </span>
        </div>
        <hr class="my-1">   
 
        <!-- Perfiles -->
        <div v-if="!justInfo" class="d-flex flex-column justify-content-center">
          <div>
            <span class="mx-2 fs-6 fw-bold align-middle ">PERFILES ASIGNADOS ({{item.numPerfiles}}): </span>
          </div>
          <!-- HAY PERFILES -->
          <div v-if="item.numPerfiles" class="mx-2 my-2">
            <button class="btn btn-secondary text-white fw-bold fit-content mx-2 mb-2 perfiles action-width" type="button" v-b-toggle="'perfiles'+item.id_operador" @click="disableWhileCollapsing($event)">
            </button>
            <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mb-2 mx-2 action-width" @click="$emit('asignarPerfiles', item)">
              <i type="button" class="fa-solid fa-gauge-high fa-1x mx-1" title="Asignar perfiles"></i>
              Asignar perfiles
            </button>
              <!-- Collapse Perfiles -->
              <b-collapse class="shadow" :id="'perfiles'+item.id_operador" @show="$emit('recalcular', getThisHeight('perfiles'+item.id_operador))" @hide="$emit('recalcular', getThisHeight('perfiles'+item.id_operador)*-1)" @hidden="$emit('finalizado')" @shown="$emit('finalizado')">
                <ul class="list-group ">
                  <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(dato, undex) in item.perfiles" :key="undex">
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">{{dato.descripcion}}</div>
                      <small class="text-muted mx-1">{{dato.template}}</small>
                    </div>
                  </li>
                </ul>
              </b-collapse>
          </div>
          <!-- NO HAY PERFILES -->
          <div v-else class="d-flex align-items-center mx-2 my-2">
              <span class="text-start text-danger action-width mx-2">¡Ningún perfil asignado!</span>
              <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mx-2 action-width" @click="$emit('asignarPerfiles', item)">
                <i type="button" class="fa-solid fa-gauge-high fa-1x mx-1" title="Asignar perfiles"></i>
                Asignar perfiles
              </button>
          </div>
        </div>
        <hr v-if="!justInfo" class="my-1">   

        <!-- PUEBLOS ASIGNADOS -->
        <div v-if="!justInfo" class="d-flex flex-column justify-content-center">
          <div>
            <span class="mx-2 fs-6 fw-bold align-middle">COBERTURA ASIGNADA ({{item.numPueblos}}): </span>
          </div>
          <!-- HAY PUEBLOS -->
          <div v-if="item.numPueblos" class="mx-2 my-2">
            <button class="btn btn-secondary text-white fw-bold fit-content mx-2 mb-2 coberturas action-width" type="button" v-b-toggle="'poblaciones'+item.id_operador" @click="disableWhileCollapsing($event)">
            </button>
            <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mb-2 mx-2 action-width" @click="$emit('asignarCoberturas', item)">
              <i type="button" class="fa-solid fa-map-location-dot fa-1x mx-1" title="Asignar coberturas"></i>
              Asignar coberturas
            </button>
              <!-- Collapse Poblaciones -->
              <b-collapse class="shadow" :id="'poblaciones'+item.id_operador" @show="$emit('recalcular', getThisHeight('poblaciones'+item.id_operador))" @hide="$emit('recalcular', getThisHeight('poblaciones'+item.id_operador)*-1)" @hidden="$emit('finalizado')" @shown="$emit('finalizado')">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(dato, undex) in item.pueblos" :key="undex">
                    <div class="ms-2 me-auto text-capitalized">
                      <div class="fw-bold">{{dato.provincia.toLowerCase()}}</div>
                      <li class="list-unstyled" v-for="(puebloProvincia, i) in dato.poblaciones" :key="i">
                       <small class="text-muted mx-1" >{{puebloProvincia.toLowerCase()}}</small>
                      </li>
                    </div>
                  </li>
                </ul>
              </b-collapse>
          </div>
          <!-- NO HAY PUEBLOS -->
          <div v-else class="d-flex align-items-center mx-2 my-2">
              <span class="text-start text-danger action-width mx-2">¡No hay poblaciones asignadas!</span>
              <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mx-2 action-width" @click="$emit('asignarCoberturas', item)">
                <i type="button" class="fa-solid fa-map-location-dot fa-1x mx-1" title="Asignar perfiles"></i>
                Asignar coberturas
              </button>
          </div>
        </div>
        <hr v-if="!justInfo" class="my-1">  

        <!-- SERVIDOR ACS -->
        <div v-if="!justInfo" class="d-flex flex-column justify-content-center">
          <div>
            <span class="mx-2 fs-6 fw-bold align-middle ">SERVIDOR ACS: </span>
          </div>
          <!-- HAY SERVIDOR -->
          <div v-if="item.servidor_acs" class="mx-2 my-2 d-flex">
            <div class="action-width mx-2 align-self-center text-center">
              <span class="text-start text-secondary ">{{item.servidor_acs}}</span>
            </div>

            <!-- <span class="badge badge-primary fw-bold ">{{item.servidor_acs}}</span> -->
            <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mb-2 mx-2 action-width" @click="asignarServidorACS()">
              <i type="button" class="fa-solid fa-circle-nodes fa-1x mx-1" title="Asignar servidor ACS"></i>
              Asignar servidor
            </button>
            <button v-if="esAdmin" type="button" class="btn btn-danger fw-bold mb-2 mx-2" @click="borrarServidorACS()">
              <i type="button" class="fa-solid fa-xmark fa-1x mx-1" title="Eliminar servidor ACS"></i>
            </button>            

          </div>
          <!-- NO HAY SERVIDOR -->
          <div v-else class="d-flex align-items-center mx-2 my-2">
              <span class="text-start text-danger action-width mx-2">¡El operador no tiene servidor ACS asignado!</span>
              <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mx-2 action-width" @click="asignarServidorACS()">
                <i type="button" class="fa-solid fa-circle-nodes fa-1x mx-1" title="Asignar servidor"></i>
                Asignar servidor
              </button>

          </div>
        </div>

        <hr v-if="!justInfo" class="my-1">   
        <!-- Servidores VOIP -->
        <div v-if="!justInfo" class="d-flex flex-column justify-content-center">
          <div>
            <span class="mx-2 fs-6 fw-bold align-middle ">Servidores VoIP: </span>
          </div>
          <!-- HAY servidores -->
          <div v-if="item.servidores_voip && item.servidores_voip.length" class="mx-2 my-2">
            <button class="btn btn-secondary text-white fw-bold fit-content mx-2 mb-2 action-width" type="button" v-b-toggle="'servidores_acs'+item.id_operador" @click="disableWhileCollapsing($event)">
              Ver servidores VoIP
            </button>
            <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mb-2 mx-2 action-width" @click="asignarServidorVOIP(item)">
              <i type="button" class="fa-solid fa-phone fa-1x mx-1" title="Asignar servidor VoIP"></i>
              Asignar servidor VoIP
            </button>
              <!-- Collapse Perfiles -->
              <b-collapse class="shadow" :id="'servidores_acs'+item.id_operador" @show="$emit('recalcular', getThisHeight('servidores_acs'+item.id_operador))" @hide="$emit('recalcular', getThisHeight('servidores_acs'+item.id_operador)*-1)" @hidden="$emit('finalizado')" @shown="$emit('finalizado')">
                <ul class="list-group ">
                  <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(dato, undex) in item.servidores_voip" :key="undex">
                    <div class="d-flex ms-2 me-auto">
                      <div class="fw-bold">{{dato.ip}}</div>
                      <i v-show="esAdmin" type="button" class="fa-solid fa-xmark fs-5 ms-2 me-1 icon-btn text-danger" title="Borrar servidor voip" @click="eliminarServidorVOIP(dato.uid)"></i>
                    </div>
                  </li>
                </ul>
              </b-collapse>
          </div>
          <!-- NO HAY servidores VOIP -->
          <div v-else class="d-flex align-items-center mx-2 my-2">
              <span class="text-start text-danger action-width mx-2">¡Ningún servidor VoIP asignado!</span>
              <button v-if="esAdmin" type="button" class="btn btn-primary fw-bold mx-2 action-width" @click="asignarServidorVOIP(item)">
                <i type="button" class="fa-solid fa-gauge-high fa-1x mx-1" title="Asignar servidor VoIP"></i>
                Asignar servidor VoIP
              </button>
          </div>
        </div>
        <hr v-if="!justInfo" class="my-1">  

        <!-- USUARIOS -->
          <div v-if="!justInfo" class="d-flex justify-content-start flex-column">
            <span class="mx-2 fs-6 fw-bold align-middle ">USUARIOS: </span>
            <div class="container-fluid table-responsive d-flex justify-content-center">
              <table class="table table-bordered table-striped tabla-usuarios m-2">
                  <thead class="text-start align-middle">
                    <tr>
                      <th scope="col" class="d-table-cell">Correo</th>
                      <th scope="col" class="d-table-cell lastSession">Último inicio de sesión</th>
                      <th v-if="esAdmin" scope="col" class="d-table-cell">Acceso a pandora</th>
                      <th scope="col" class="d-table-cell">Roles</th>
                      <th scope="col" class="d-table-cell">Acción</th>
                    </tr>
                  </thead>
                  <tbody class="text-start align-middle">
                    <tr v-for="(dato, i) in item.usuarios" :key="i">
                      <td class="fw-bold">{{dato.user}}</td>
                      <td>{{fechaComputada(dato.LastLogin)}}</td>
                      <td v-if="esAdmin">{{dato.soloHydra ? 'Solo Hydra' : 'Permitido'}}</td>
                      <td>
                        <ul class="list-group m-auto list-inline">
                          <li v-for="(rol, index) in dato.roles" :key="'RA'+index"  data-bs-toggle="tooltip" data-bs-placement="top" :title="rol.descripcion" data-bs-trigger='hover' class="list-group-item line">
                            {{rol.nombre}}
                          </li>
                        </ul>
                      </td>
                      <td class="text-center accion-usuario">
                        <div class="d-flex">
                          <i v-if="!editandoRoles" type="button" class="fa-solid fa-user-pen fs-4 ms-1 me-2 icon-btn text-dark" title="Editar roles" @click="editarroles(dato)"></i>
                          <i type="button" class="fa-solid fa-key fs-4 ms-1 me-2 icon-btn text-primary" title="Regenerar contraseña" @click="regenerarPassword(item, dato.user)"></i>
                          <i type="button" class="fa-solid fa-lock-open fs-4 ms-1 me-2 icon-btn text-warning" title="Forzar cambio de contraseña manual" @click="forzarCambioPassword(item, dato.user)"></i>
                          <i type="button" class="fa-solid fa-trash-alt fs-4 ms-2 me-1 icon-btn text-danger" title="Borrar usuario" @click="eliminarUsuario(item, dato.user)"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>

      </div>                              
    </div>
</template>


<script>
import axios from "axios";
import Swal from 'sweetalert2'
import { fecha } from "../classes/fecha.class";
import { verificarJWT, desconectar, isSuperAdmin } from '../helpers/JWT';
import { registrarOperacionAdmin } from '../helpers/registrarEnHistorial';
import { Tooltip } from "bootstrap";
import { confirmAlert, errorAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert';

export default {
  name: 'TarjetaOperador',
  props: ['item', 'fullwidth', 'justInfo'],
  data() {
    return {   
      editandoRoles: false,
      esAdmin: this.esSuperAdmin()
    }
  },
  methods: {
    getThisHeight(id){
      return document.getElementById(id).scrollHeight;
    },
    //Se desactiva el boton mientras se expande el elemento
    disableWhileCollapsing(event){
      let element = event.target;
      if(element){
        element.style.pointerEvents = 'none';
        setTimeout(() => {
         element.style.pointerEvents = '';
        }, 400);
      }
    },
    async forzarCambioPassword(item, email){
         //Alerta de confirmación
         let pass1 = '';
         let pass2 = '';
         await Swal.fire({
          title: 'Cambiar contraseña manualmente',
          html: `
            <label for="pass1-input" class="swal2-input-label justify-content-start mx-3 mt-3 mb-1">Contraseña</label>
            <input value="${pass1}" class="swal2-input form-control mt-1" id="pass1-input" placeholder="Introduzca contraseña" type="password" maxlength="65" style="margin-left: auto; margin-right: auto; width: 95%">
            <small id="pass1-error" class="text-danger d-flex justify-content-start ms-4 d-none"> Debe de introducir una contraseña de como mínimo 6 caracteres</small>

            <label for="pass2-input" class="swal2-input-label justify-content-start mx-3 mt-3 mb-1">Repita la contraseña</label>
            <input value="${pass2}" class="swal2-input form-control mt-1" id="pass2-input" placeholder="Confirme contraseña" type="password" maxlength="65" style="margin-left: auto; margin-right: auto; width: 95%">
            <small id="pass2-error" class="text-danger d-flex justify-content-start ms-4 d-none"> Debe de introducir una contraseña de como mínimo 6 caracteres</small>
            <small id="pass2-error2" class="text-danger d-flex justify-content-center m-1 d-none"> Las contraseñas no coinciden </small>

            `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  'Cambiar',
          cancelButtonText:   'Cancelar',
          showLoaderOnConfirm: true,
          didOpen: () => {
            let e = document.getElementById('pass1-input');
            if (e) e.focus();
          },
          preConfirm: () => {
            //Validaciones
            let pw1 = document.getElementById('pass1-input')
            let pw1error = document.getElementById('pass1-error')
            let pw2 = document.getElementById('pass2-input')
            let pw2error = document.getElementById('pass2-error')
            let pw2error2 = document.getElementById('pass2-error2')
            if (pw1) pass1 = pw1.value;
            if (pw2) pass2 = pw2.value;

            pw1.classList.remove('is-invalid');
            pw1error.classList.add('d-none');
            pw2.classList.remove('is-invalid');
            pw2error.classList.add('d-none');
            pw2error2.classList.add('d-none');
            let okay = true;
            if (!pass1 || pass1.length < 6 || pass1.length > 65) {
                pw1.classList.add('is-invalid');
                pw1error.classList.remove('d-none');
                pw1.focus();
                okay = false;
            }
            if (!pass2 || pass2.length < 6 || pass2.length > 65) {
                pw2.classList.add('is-invalid');
                pw2error.classList.remove('d-none');
                okay = false;
            }
            if (pass1 && pass2 && pass1 != pass2) {
                pw2.classList.add('is-invalid');
                pw2error2.classList.remove('d-none');
                okay = false;
            }
            if(!okay) return false;

            //Confirmar identidad
            verificarJWT().then( verificado => {
                if (!verificado) {
                    errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
                    desconectar();
                    return;
                }
            });
            //Solicitud de regenerar contraseña
            return axios({
              method: 'PUT',
              url: '/auth/forcePassword',
              data: {
                email: email,
                newpassword: pass1,
                newpassword2: pass2 
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            }).then(res =>{              
              registrarOperacionAdmin("Contraseña regenerada", this.item.operador.toUpperCase(), this.item.id_operador, { usuario: email });
              Swal.fire({
                title: 'Contraseña regenerada',
                html: `Se ha enviado la nueva contraseña a <strong>${email}</strong>. <br><br> El usuario deberá de cambiarla cuando inicie sesión`,
                icon: 'success',
                confirmButtonText: 'Vale'
              });
            }).catch(error => {
              if (error.response && error.response.data && error.response.data.msg) {
                //Request made and server responded
                Swal.showValidationMessage(error.response.data.msg);
              } else if (error.request) {
                // The request was made but no response was received
                Swal.showValidationMessage('No se ha recibido respuesta del servidor');
              } else {
                // Something happened in setting up the request that triggered an Error
                Swal.showValidationMessage(error.message);
              }              
            })
            
          },
          backdrop: true,
          allowOutsideClick: () => !Swal.isLoading()
        });
        
      
    },
    async regenerarPassword(item, email){
         //Alerta de confirmación
         await Swal.fire({
          title: '¿Está seguro de que quiere generar una nueva contraseña?',
          html: `Se creará una contraseña aleatoria para el usuario <strong>${email}</strong> de <strong>${item.operador}</strong> y se le notificará por correo electrónico.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  'Regenerar',
          cancelButtonText:   'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            //Confirmar identidad
            verificarJWT().then( verificado => {
                if (!verificado) {
                    errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
                    desconectar();
                    return;
                }
            });
            //Solicitud de regenerar contraseña
            return axios({
              method: 'PUT',
              url: '/auth/regenPass',
              data: {email: email},
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            }).then(res =>{              
              registrarOperacionAdmin("Contraseña regenerada", this.item.operador.toUpperCase(), this.item.id_operador, { usuario: email });
              Swal.fire({
                title: 'Contraseña regenerada',
                html: `Se ha enviado la nueva contraseña a <strong>${email}</strong>. <br><br> El usuario deberá de cambiarla cuando inicie sesión`,
                icon: 'success',
                confirmButtonText: 'Vale'
              });
            }).catch(error => {
              if (error.response && error.response.data && error.response.data.msg) {
                //Request made and server responded
                Swal.showValidationMessage(error.response.data.msg);
              } else if (error.request) {
                // The request was made but no response was received
                Swal.showValidationMessage('No se ha recibido respuesta del servidor');
              } else {
                // Something happened in setting up the request that triggered an Error
                Swal.showValidationMessage(error.message);
              }              
            })
            
          },
          backdrop: true,
          allowOutsideClick: () => !Swal.isLoading()
        });
        
      
    },
    async eliminarUsuario(operadorEntero, email){
        //Si el usuario es el último y aun tiene perfiles y pueblos no deja borrarlo
        if(operadorEntero.usuarios.length == 1 && (operadorEntero.numPerfiles || operadorEntero.numPueblos)){
          await Swal.fire({
            title: 'Borrar Usuario',
            html: "Estás borrando el <strong>último usuario</strong>, por lo tanto se <strong>eliminará el operador</strong>, antes de hacer esto, <strong>elimina los perfiles y coberturas</strong> asignados",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText:  'Vale'
          })
          return;
        }
         //Alerta de confirmación
         await Swal.fire({
          title: '¿Borrar usuario?',
          text: "¡El usuario será borrado para siempre y no se podrá recuperar!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  '<i class="fa-solid fa-trash-alt"></i> Borrar',
          cancelButtonText:   'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            //Confirmar identidad
            verificarJWT().then( verificado => {
                if (!verificado) {
                    errorAlert('Error verificando usuario', 'Los datos de usuario están caducados o son incorrectos');
                    desconectar();
                    return;
                }
            });
            //Petición para borrar el usuario
            return axios.delete('/operadores', {
              params: {email: email},
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            }).then(res =>{

            }).catch(error => {
              if (error.response && error.response.data && error.response.data.msg) {
                //Request made and server responded
                Swal.showValidationMessage(error.response.data.msg);
              } else if (error.request) {
                // The request was made but no response was received
                Swal.showValidationMessage('No se ha recibido respuesta del servidor');
              } else {
                // Something happened in setting up the request that triggered an Error
                Swal.showValidationMessage(error.message);
              }              
            })
            
          },
          backdrop: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            registrarOperacionAdmin("Usuario borrado", this.item.operador.toUpperCase(), this.item.id_operador, { email })
            Swal.fire({
              title: 'Usuario eliminado',
              text: 'El usuario ha sido eliminado de la base de datos',
              icon: 'success',
              confirmButtonText: 'Vale'
            }
            ).then(() => {
              localStorage.setItem('focusedOperator', operadorEntero.id_operador);
              this.$emit('reiniciar');
            });
          }
        })
      
    },
    editarroles(usuario){
      this.$router.push({
        name: 'CambioRol',
        params: { item: usuario }
      })
    },
    async asignarServidorACS(){
      const { value: ipAddress } = await Swal.fire({
        title: "Introduzca el servidor ACS",
        input: "text",
        inputLabel: "IP del servidor",
        inputValue: this.item.servidor_acs || '',
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        inputValidator: (value) => {
          if (!value) {
            return "¡Tienes que introducir el servidor!";
          }
        }
      });
      if (ipAddress) {
         try {
            let respuestaPandora = await axios({
              method: 'PUT',
              url: '/acs/servidor',
              data: {
                ip: ipAddress,
                id_operador: this.item.id_operador
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
            successAlert("Proceso completado", respuestaPandora.data.msg)
            let copia_item = this.item;
            copia_item.servidor_acs = ipAddress
            this.$emit("actualizarOperador", copia_item)
          } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              errorAlert(error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              noResponseAlert()
            } else {
              // Something happened in setting up the request that triggered an Error
              serverErrorAlert(error.message)
            }
          } 
      }
    },
    async borrarServidorACS(){
      let confirm = await confirmAlert("¡El operador no tendrá ningún servidor ACS asignado!")
      if (!confirm) return;
         try {
            let respuestaPandora = await axios({
              method: 'DELETE',
              url: '/acs/servidor',
              params: {
                id_operador: this.item.id_operador
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
            console.log(respuestaPandora);
            successAlert("Proceso completado", respuestaPandora.data.msg)
            let copia_item = this.item;
            copia_item.servidor_acs = ""
            this.$emit("actualizarOperador", copia_item)
          } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              errorAlert(error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              noResponseAlert()
            } else {
              // Something happened in setting up the request that triggered an Error
              serverErrorAlert(error.message)
            }
          } 
    },    
    async asignarServidorVOIP(){
      const { value: ipAddress } = await Swal.fire({
        title: "Introduzca el servidor VoIP",
        input: "text",
        inputLabel: "IP del servidor",
        inputValue: '',
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        inputValidator: (value) => {
          if (!value) {
            return "¡Tienes que introducir el servidor!";
          }
        }
      });
      if (ipAddress) {
         try {
            let respuestaPandora = await axios({
              method: 'POST',
              url: '/acs/servidor_voip',
              data: {
                ip: ipAddress,
                id_operador: this.item.id_operador
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
            successAlert("Proceso completado", respuestaPandora.data.msg)
            this.$emit("refrescar_voip")
          } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              errorAlert(error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              noResponseAlert()
            } else {
              // Something happened in setting up the request that triggered an Error
              serverErrorAlert(error.message)
            }
          } 
      }
    },
    async eliminarServidorVOIP(uid){
      let confirm = await confirmAlert("¡Se eliminará el servidor VoIP del operador!")
      if (!confirm) return;
         try {
            let respuestaPandora = await axios({
              method: 'DELETE',
              url: '/acs/servidor_voip',
              params: {
                uid
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            });
            console.log(respuestaPandora);
            successAlert("Proceso completado", respuestaPandora.data.msg)
            this.$emit("refrescar_voip")
          } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              errorAlert(error.response.data.msg)
            } else if (error.request) {
              // The request was made but no response was received
              noResponseAlert()
            } else {
              // Something happened in setting up the request that triggered an Error
              serverErrorAlert(error.message)
            }
          } 
    },      
    esSuperAdmin(){
      return isSuperAdmin();
    }
  },
  computed: {
    fechaComputada () {
      return (fechanueva) => fechanueva ? new fecha(fechanueva).fechaTotal : 'Nunca';
    },
  },
  mounted() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new Tooltip(tooltipTriggerEl)
        })
  },
}
</script>

<style scoped>
small {
  font-size: 0.8rem;
}

button[aria-expanded='true'].perfiles:before{
  content: "Ocultar perfiles";
}
button[aria-expanded='false'].perfiles:before{
  content: "Ver perfiles";
}
button[aria-expanded='true'].coberturas:before{
  content: "Ocultar poblaciones";
}
button[aria-expanded='false'].coberturas:before{
  content: "Ver poblaciones";
}

.action-width{
  width: 14rem !important;
}

.tabla-usuarios{
  max-width: 75%;
}
.lastSession{
  min-width: 7rem;
}
.accion-usuario{
  width: 1.5rem;
}
</style>


