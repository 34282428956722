<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

              <!-- Header -->
            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <div class="d-flex align-items-baseline">
                <h1 >Cambiar Roles</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>
            </div>
            <!-- Contenido -->
            <div class="card-body">
              <div class="m-3 card shadow">
                  <div class="text-start card-body">
                      <!-- Usuario -->
                      <div class="d-flex justify-content-start flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle">Usuario: </span>
                        <span class="mx-2 fs-6 align-middle">{{item.user}} </span>
                      </div>
                      <hr class="my-1">   

                      <!-- ID_OPERADOR -->
                      <div class="d-flex justify-content-start flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle">ID Operador: </span>
                        <span class="mx-2 fs-6 align-middle">{{item.id_operador}} </span>
                      </div>
                      <hr class="my-1">   
                      
                      <!-- Última conexión -->
                      <div class="d-flex justify-content-start flex-column flex-sm-row">
                        <span class="mx-2 fs-6 fw-bold align-middle">Última conexión: </span>
                        <span class="mx-2 fs-6 align-middle">{{fechaComputada(item.LastLogin)}} </span>
                      </div>
                      <hr class="my-1">    

    
                  </div>
              </div>
              <b-overlay :show="cargandoRoles">
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <div class="d-flex align-items-baseline justify-content-start">
                      <h4>Cambio de rol</h4>
                    </div>
                    <!-- Información -->
                    
                    <Roles :usuario="item"></Roles>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>

      </div>

      
  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert } from '../helpers/fastAlert';
import Roles from "@/components/Roles"
import axios from "axios";
import { verificarJWT, desconectar, isSuperAdmin } from '../helpers/JWT';
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';
import { fecha } from '../classes/fecha.class';
/*
  Página que permite asignar roles a los usuarios.
  Si el usuario es super administrador podrá asignar cualquier rol,
  si es operador administrador, solo podrá asignar roles de operador
*/
export default {
  name: 'CambioRol',
  components:{
    Roles
  },
  data() {
    return {
      cargandoRoles: false,
      item: '',
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Cambiar Roles', 'En esta página se pueden asignar los roles del usuario');
    },
    getUsuario(){
      if(this.$route.params.item){
        localStorage.setItem('usuarioActivo', JSON.stringify(this.$route.params.item));
      }
      if(localStorage.getItem('usuarioActivo')){
        this.item = JSON.parse(localStorage.getItem('usuarioActivo'));
      }      
      if(!this.item){
        this.$router.push({ name: 'Operadores'});
      }
    },
  },
  computed: {
    fechaComputada () {
      return (fechanueva) => fechanueva ? new fecha(fechanueva).fechaTotal : 'Nunca';
    },
  },
  created(){
    this.getUsuario();
  },
  mounted() {

  },
}
</script>

<style scoped>


 


</style>
