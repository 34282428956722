<template>

    <div class="card page-card border-0 shadow-lg my-5">

      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Cambio de perfil masivo</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
        </div>
      </div>
      <!-- Cargando -->
      <div v-if="cargandoServicios || cargandoPerfilesDisponibles" class="my-4">
        <b-spinner></b-spinner>
        <h4 >Cargando datos...</h4>
      </div>

      <!-- Error cargando -->
      <div v-else-if="servicios.length < 1 || perfilesDisponibles.length < 1" class="alert alert-warning d-flex align-items-center mx-3" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
          <div v-if="servicios.length < 1" class="text-danger">
            No hay ningún servicio al que cambiarle el perfil de velocidad
          </div>     
          <div v-if="perfilesDisponibles.length < 1" class="text-danger">
            No tienes ningún perfil de velocidad asignado
          </div>   
        </div>
      </div>
      <div v-else>
        <div class="m-3 card shadow">
          <div class="card-body"> 
            <!-- Action card header -->
            <div class="d-flex align-items-baseline">
              <h4>Perfiles serviciados</h4>
            </div>
            
            <div class="m-2">
                <div v-if="perfilesDistintos && perfilesDistintos.length">
                      <!-- Resumen perfiles -->
                      <div class="m-2">                            
                        <div class="table-responsive tabla-cola">
                          <table class="table table-bordered text-start small-font">
                            <thead>
                              <tr>
                                <th scope="col">Perfil</th>
                                <th scope="col">Cantidad</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in perfilesDistintos" :key="index">
                                  <td>
                                    {{item.perfil}}
                                  </td>
                                  <td>
                                    {{item.cantidad}}
                                  </td>
                              </tr>
                              <tr class="table-active">
                                <th>Total</th>
                                <th>{{servicios.length}}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
            </div>
          </div>
        </div>

        <div v-if="servicios.length" class="m-3 card shadow">
          <div class="card-body"> 
              <!-- Action card header -->
              <div class="d-flex align-items-baseline justify-content-start">
                <h4>Cambio de perfil</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoCriterios"></i>
              </div>
              <!-- Select PERFIL -->
              <div class="col-12 mt-2 mb-4">
                <div class="row d-flex align-items-center">
                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end">
                    <label for="PERFIL" class="line mx-1 fs-5 d-flex align-items-center">Nuevo perfil</label>
                  </div>
                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                    <div class="row d-flex flex-row">
                      <div class="col-10">
                        <select id="PERFIL" :disabled="cargandoPerfilesDisponibles || cargandoServicios || algoritmoEnEjecucion" class="form-select my-1" aria-label="Seleccionar perfil" maxlength="128" v-model="perfilElegido" @change="encontrarPerfil();  resetCriterios()">                                
                          <option value="" disabled selected>Seleccione el nuevo perfil</option>
                          <option v-for="(item, index) in perfilesDisponibles" :key="index" :value="item.template">{{item.descripcion}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-4">

              <!-- Criterios -->
              <div class="d-flex align-items-baseline justify-content-start">
                <h4>Criterios</h4>
              </div>
              <div class="mx-2">
                  <form class="user d-flex flex-column" novalidate>
                    <div class="row">
                      <!-- Todos los servicios -->
                      <div class="col-12">
                        <div class="row d-flex align-items-center justify-content-center">
                          <div class="col-10 d-flex align-items-center justify-content-center">
                            <label class="line mx-1 fs-5 d-flex align-items-center" for="todoslosservicios">Todos los servicios</label>
                            <div class="form-check form-switch d-flex align-items-center justify-content-start p-0 my-0 mx-2">
                              <input class="form-check-input m-0 p-0" type="checkbox" id="todoslosservicios" :disabled="algoritmoEnEjecucion || !perfilElegido" v-model="criterios.todos" @click="todosServiciosSeleccionado(); filtrarServicios();">
                            </div>
                          </div>
                        </div>
                      </div>
                    
                      <!-- Provincia -->
                      <div class="col-12 col-sm-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-xxl-3 d-flex align-items-center justify-content-end">
                            <label for="PROVINCIA" class="line mx-1 fs-5 d-flex align-items-center">Provincia</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="PROVINCIA" :disabled="cargandoServicios || algoritmoEnEjecucion || criterios.todos || !perfilElegido" class="form-select my-1" aria-label="Seleccionar provincia" maxlength="128" v-model="criterios.provincia" @change="criterios.poblacion = ''; filtrarServicios(); mostrarPueblosDisponibles();">                                
                                  <option value="" selected>Sin establecer</option>
                                  <option v-for="(item, index) in provinciasDistintas" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Población -->
                      <div class="col-12 col-sm-6 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-xxl-3 d-flex align-items-center justify-content-end">
                            <label for="POBLACION" class="line mx-1 fs-5 d-flex align-items-center">Población</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="POBLACION" :disabled="cargandoServicios || algoritmoEnEjecucion || criterios.todos || !criterios.provincia || !perfilElegido" class="form-select my-1" aria-label="Seleccionar población" maxlength="128" v-model="criterios.poblacion" @change="filtrarServicios()">                                
                                  <option value="" selected>Sin establecer</option>
                                  <option v-for="(item, index) in pueblosDistintos" :key="index" :value="item">{{item}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Perfil de velocidad -->
                      <div class="col-12 my-2">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end">
                            <label for="PERFILANTIGUO" class="line mx-1 fs-5 d-flex align-items-center">Perfil actual</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <select id="PERFILANTIGUO" :disabled="cargandoServicios || algoritmoEnEjecucion || criterios.todos || !perfilElegido" class="form-select my-1" aria-label="Seleccionar perfil" maxlength="128" v-model="criterios.perfil" @change="filtrarServicios()">                                
                                  <option value="" selected>Sin establecer</option>
                                  <option :disabled="item.perfil == perfilElegidoCompleto.descripcion" v-for="(item, index) in perfilesDistintos" :key="index" :value="item.perfil">{{item.perfil}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    <hr class="mt-4">
                    <!-- Carga -->
                    <div v-if="algoritmoEnEjecucion">
                      <Carga :show="algoritmoEnEjecucion" :fullscreen="false" class="logocarga"></Carga>
                      
                      <span class="badge bg-secondary font-italic fit-content m-auto fs-6 my-3">
                        {{registroActual}} de {{serviciosFiltrados.length}} ➜ {{EstadoCarga}}               
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <div v-if="algoritmoEnEjecucion" class="progress barra-progreso">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="porcentajeCompletado" aria-valuemin="0" aria-valuemax="100" :style="{ width: porcentajeCompletado + '%' }">{{porcentajeCompletado + '%'}}</div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button v-if="!algoritmoEnEjecucion || algoritmoDebeParar" type="button" :disabled="cargandoServicios || servicios.length < 1 || serviciosFiltrados.length < 1 || algoritmoEnEjecucion || !perfilElegido || (!criterios.todos && !criterios.provincia && !criterios.poblacion && !criterios.perfil)" class="btn btn-primary mx-2" @click="ejecutarAlgoritmo()">
                          <i class="fa-solid fa-rocket mx-1"></i>
                          Cambiar perfiles
                        </button>
                        <button v-else type="button" :disabled="!algoritmoEnEjecucion || algoritmoDebeParar" class="btn btn-danger form-btn mx-2" @click="pararAlgoritmo()">
                          <i class="fa-solid fa-hand mx-1"></i>
                          Parar
                        </button>
                      </div>	

                    </div>
                  </form>
                  
              </div>
          </div>
        </div>
        <div v-if="serviciosFiltrados.length" class="m-3 card shadow">
          <div class="card-body"> 
            <div class="d-flex align-items-baseline justify-content-start">
              <h4>Resumen</h4>
            </div>
                <!-- Resumen -->
                <div class="m-2">                            
                  <div class="table-responsive fit-content m-auto">
                    <table class="table table-bordered text-start">
                      <thead>
                        <tr>
                          <th scope="col">Por cambiar</th>
                          <th scope="col">Cambiados</th>
                          <th scope="col">Fallidos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td>{{serviciosFiltrados.filter(item => item.ESTADO === 'pendiente').length}}</td>       
                            <td>{{serviciosFiltrados.filter(item => item.ESTADO === 'completado').length}}</td>       
                            <td>{{serviciosFiltrados.filter(item => item.ESTADO === 'fallido').length}}</td>       
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            <!-- Fallidos -->
            <div v-if="servicios.length && listaFallidos && listaFallidos.length">
              <div class="d-flex align-items-baseline justify-content-start">
                <h4>Cambios fallidos</h4>
              </div>
              <div class="m-2">
                <div>
                      <div class="m-2">                            
                        <!-- Tabla resultado test -->
                        <div class="table-responsive tabla-cola">
                          <table class="table table-bordered text-start small-font">
                            <!-- Cabeceras tabla -->
                            <thead>
                              <tr>
                                <th scope="col" class="first-column">ID ADMINISTRATIVO</th>
                                <th scope="col">Calle</th>
                                <th scope="col">Causa</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in listaFallidos" :key="index" class="table-danger">
                                <td>{{item.ID_ADMINISTRATIVO}}</td>
                                <td>{{item.CALLE}}</td>
                                <td>{{item.CAUSA}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>


</template>

<script>
import { infoAlert } from '../helpers/fastAlert';
import { sleep, montarNombreCalle } from '../helpers/utils';
import { desconectar, verificarJWT, getJWTpayload } from '../helpers/JWT';
import axios from "axios";
import Swal from "sweetalert2";
import Carga from '../components/Carga';
import { registrarEnHistorial } from '../helpers/registrarEnHistorial';

export default {
  /*

  */
  name: 'CambioPerfilMasivo',
  components: {
    Carga
  },
  data() {
    return {
      servicios: [],
      serviciosFiltrados: [],
      mensajedeError: '',
      algoritmoEnEjecucion: false,
      algoritmoDebeParar: false,
      EstadoCarga: '',
      porcentajeCompletado: 0,
      cargandoServicios: false,
      cargandoPerfilesDisponibles: false,
      perfilesDisponibles: '',
      perfilElegido: '',
      perfilElegidoCompleto: '',
      criterios: {
        todos: false,
        provincia: '',
        poblacion: '',
        perfil: '',
      },
      perfilesDistintos: '',
      provinciasDistintas: '',
      pueblosDistintos: '',
      listaFallidos: []
    } 
  },
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Cambio de perfil masivo', 'En esta página se puede realizar un cambio masivo de perfiles de velocidad a uno nuevo bajo unos criterios establecidos. <br><br>Recuerda que hacer un cambio de perfil provoca que las cabeceras tarden hasta 60 segundos en volver responder.')
    }, 
    infoCriterios(){
      infoAlert('Cambiar perfil de velocidad', 'Seleccione un <strong>nuevo perfil de velocidad</strong> el cual se asignará a los servicios que correspondan con los <strong>criterios</strong> que indique a continuación.')
    },     
    infoResultado(result){
      let fallidosText = this.listaFallidos.length ? '<br>Hubo errores en el cambio de perfil masivo, mira en <strong>Cambios fallidos</strong> para más información.' : '';
      Swal.fire({
        title: "Ejecución completada",
        html: `
            <p>El cambio de perfiles de velocidad ha finalizado</p>
            <table class="table table-bordered text-start">
              <tbody>
                <tr>
                    <th style="min-width: 9rem;">Pendientes</th>
                    <td class="table-light">
                        ${result.Pendientes}
                    </td>
                </tr>
                <tr>
                    <th style="min-width: 9rem;">Completados</th>
                    <td class="table-light">
                        ${result.Completados}
                    </td>
                </tr>
                <tr>
                    <th style="min-width: 9rem;">Fallidos</th>
                    <td class="table-light">
                        ${result.Fallidos}
                    </td>
                </tr>                                        
              </tbody>
            </table>
            ${fallidosText}`,
        icon: 'info',
        confirmButtonText: 'Vale'
     })
    },
    async cargarServicios(){
        try{
          this.servicios = []
          this.cargandoServicios = true;
            let response = await axios({
                method: 'GET',
                url: '/servicios',
                headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
              });              
              this.servicios = response.data.data.listaServicios.filter(e => e.tipo == 'servicio');
              this.procesarServicios();
              this.cargandoServicios = false;
        } catch (error) {
            this.cargandoServicios = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              console.log("error controlado", error.response);
              this.mensajedeError = error.response.data.msg;
              return;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = error.request;
              return;
            } else {
              // Something happened in setting up the request that triggered an Error
               this.mensajedeError = error.message || error;
              return;
            }
        }
    },
    //Descargar los perfiles de velocidad de la api
    async getPerfilesDisponibles(){
        try {
          this.cargandoPerfilesDisponibles = true;
          let OPID = getJWTpayload().OPID;
          let respuesta = await axios.get('/tarifas?OPID=' + OPID, { headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }});
          this.perfilesDisponibles = respuesta.data.data.tarifas;          
          this.cargandoPerfilesDisponibles = false;
        } catch (error) {
            this.cargandoPerfilesDisponibles = false;
            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        }
    },
    encontrarPerfil(){
      if(this.perfilElegido){
        this.perfilElegidoCompleto = this.perfilesDisponibles.find(e => e.template == this.perfilElegido)
      }
    },
    procesarServicios(){
      this.perfilesDistintos = [];
      for (const iterator of this.servicios) {
        iterator.ESTADO = 'pendiente';
        let encontrado = this.perfilesDistintos.find(e => e.perfil == iterator.PERFILVELOCIDAD);
        if(encontrado){
          encontrado.cantidad += 1;
        }else{
          this.perfilesDistintos.push({perfil: iterator.PERFILVELOCIDAD, cantidad: 1});
        }
      }
      this.provinciasDistintas = this.servicios.map(item => item.PROVINCIA).filter((value, index, self) => self.indexOf(value) === index);
    },
    mostrarPueblosDisponibles(){
      if(this.criterios.provincia){
        this.pueblosDistintos = this.servicios.filter(e => e.PROVINCIA == this.criterios.provincia).map(item => item.POBLACION).filter((value, index, self) => self.indexOf(value) === index);
      }
    },
    todosServiciosSeleccionado(){
      this.criterios.provincia = '';
      this.criterios.poblacion = '';
      this.criterios.perfil = '';
    },
    resetCriterios(){
      this.criterios.todos = false;
      this.criterios.provincia = '';
      this.criterios.poblacion = '';
      this.criterios.perfil = '';
    },
    async cambiarServicioOrquestador(datos){
      let MENSAJEERROR = '';
        //Se cambia el servicio en el orquestador
        try {
            console.log('[Cambio servicio - Orquestador]');
            this.EstadoCarga = '[Cambio servicio - Orquestador]';
            await sleep(200)
            let respuestaOrquestador = await axios({
              method: 'POST',
              url: '/cambioServicio',
              data: {
                ID_ADMINISTRATIVO: datos.ID_ADMINISTRATIVO,
                TEMPLATE: this.perfilElegidoCompleto.template,
                PERFILVELOCIDAD: this.perfilElegidoCompleto.descripcion,
              },
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            })
            datos.ESTADO = 'completado';
        } catch (error) {
          if (error.response && error.response.data && error.response.data.msg) {
              console.log('Error controlado', error.response); 
              MENSAJEERROR += `[Cambio servicio - Orquestador] ${error.response.data.msg} - ${error.response.data.error ? error.response.data.error.descResultado : ''}`;
            } else if (error.request) {
              console.log('Error servidor', error.request); 
              MENSAJEERROR += `[Cambio servicio - Orquestador] Error servidor - ${error.request}`;
            } else {
              console.log('Error genérico', (error.message || error)); 
              MENSAJEERROR += `[Cambio servicio - Orquestador] Error genérico - ${(error.message || error)}`;
            }
        }
        return MENSAJEERROR;
    },
    filtrarServicios(){      
      Object.assign(this.serviciosFiltrados, this.servicios);
      if(this.criterios.todos) return;
      if(this.criterios.provincia){                
        this.serviciosFiltrados = this.serviciosFiltrados.filter(e => e.PROVINCIA.toLowerCase() == this.criterios.provincia.toLowerCase());
      }
      if(this.criterios.poblacion){
        this.serviciosFiltrados = this.serviciosFiltrados.filter(e => e.POBLACION.toLowerCase() == this.criterios.poblacion.toLowerCase());
      }
      if(this.criterios.perfil){        
        this.serviciosFiltrados = this.serviciosFiltrados.filter(e => e.PERFILVELOCIDAD == this.criterios.perfil);
      }      
    },
    async registrarEnHistorial(result){
        await registrarEnHistorial("Cambio de perfil masivo", "Varios", { 'Nuevo perfil': this.perfilElegidoCompleto.descripcion, 'Nuevo template': this.perfilElegidoCompleto.template }, result );
    },
    async ejecutarAlgoritmo(){ 
      //Confirmación
      let resultado = await Swal.fire({
          title: '¿Está seguro?',
          html: `¡Se cambiará el perfil de velocidad de <strong>${this.serviciosFiltrados.length>1 ? this.serviciosFiltrados.length + ' clientes' : '1 cliente'}</strong> a '<strong>${this.perfilElegidoCompleto.descripcion}</strong>'!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  'Continuar',
          cancelButtonText:   'Cancelar',
      });
      if(!resultado.isConfirmed) return;

      this.filtrarServicios();
      this.algoritmoDebeParar = false;      //Variable que hace parar el algoritmo
      this.algoritmoEnEjecucion = true;     //Variable que indica si el algoritmo está ejecutando
      this.porcentajeCompletado = 0;
      this.listaFallidos = [];
      for (let index = 0; index < this.serviciosFiltrados.length && !this.algoritmoDebeParar; index++) {
        const element = this.serviciosFiltrados[index];
            this.registroActual = index + 1; //Indicamos el registro actual
            //Solo se trabaja con los que no están completados
            if(element.ESTADO != 'completado'){
              let MENSAJEERROR = '';
              
              //Verificación Pandora
              this.EstadoCarga = '[Verificando Plataforma]';
              await sleep(200)
              let verificado =  await verificarJWT();
              if (!verificado) {
                  MENSAJEERROR = 'No se pudo verificar el usuario de Pandora';
                  desconectar();
                  return;
              }

              //CAMBIAR EN ORQUESTADOR (Si no hay error previo)
              if(!MENSAJEERROR){
                MENSAJEERROR = await this.cambiarServicioOrquestador(element);
              }

            
              //Si hay errores los mostramos
              if(MENSAJEERROR) {
                this.listaFallidos.push({ID_ADMINISTRATIVO: element.ID_ADMINISTRATIVO, CALLE: element.direccion, CAUSA: MENSAJEERROR});
                element.ESTADO = 'fallido';
              }
            }
        
        this.porcentajeCompletado = Math.round(((index+1) / this.serviciosFiltrados.length)*100); //actualizamos el porcentaje
      }
      //Ejecución finalizada
      let result = this.procesarResultado();
      await this.registrarEnHistorial(result);
      await sleep(2000);
      this.infoResultado(result);
      await this.cargarServicios();
      this.resetCriterios();
      this.algoritmoEnEjecucion = false;
    },
    pararAlgoritmo(){
      //Bloqueamos los botones de ejecutar y form durante 4 segundos para que no se pueda duplicar la función de ejecución si se vuelve a lanzar rapidamente
      this.algoritmoDebeParar = true;
    },
    procesarResultado(){
      let result = { 'Pendientes': (this.serviciosFiltrados.filter(e => e.ESTADO == 'pendiente').map(e => e.ID_ADMINISTRATIVO).join(", ") || 'Ninguno'), 'Completados': (this.serviciosFiltrados.filter(e => e.ESTADO == 'completado').map(e => e.ID_ADMINISTRATIVO).join(", ") || 'Ninguno') };
      if (this.listaFallidos && this.listaFallidos.length) {
        result["Fallidos"] = this.listaFallidos.map(e => e.ID_ADMINISTRATIVO).join(", ");
        for (const iterator of this.listaFallidos) {
          result[iterator.ID_ADMINISTRATIVO] = iterator.CAUSA;
        }
      }else{
        result["Fallidos"] = 'Ninguno';
      }
      return result;
    }
  },
  created() {
    this.cargarServicios();
    this.getPerfilesDisponibles();
  },
}
</script>

<style scoped>
.logocarga{
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.barra-progreso{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding: 0;
  width: 80%;
}
.tabla-cola{
  max-height: 20rem;
}
.first-column{
  min-width: 10rem;
}

</style>
