
<template>
 <div class="row justify-content-center loginForm">
            <b-overlay :show="cargando" rounded="sm">
            <div class="card border-0 shadow-lg my-5">
                 
                    <div class="p-4">
                        <h1 class="h4 mb-4">¡Bienvenido!</h1>
                        <form class="user d-flex flex-column text-start gap-2" @submit.prevent="login" novalidate>
                               
                                <!-- Email -->
                                <input type="email" required maxlength="65" v-model.lazy="$v.usuario.email.$model" placeholder="Correo electrónico" class="form-control" :class="{ 'is-invalid': $v.usuario.email.$error}" >
                                  <small v-show="$v.usuario.email.$dirty && !$v.usuario.email.required" class="text-danger">
                                    Debe de introducir su email
                                  </small>
                                  <small v-show="$v.usuario.email.$dirty && !$v.usuario.email.email" class="text-danger">
                                    El formato del email es incorrecto
                                  </small>
                                  <small v-show="$v.usuario.email.$dirty && !$v.usuario.email.minLength" class="text-danger">
                                    Debe introducir como mínimo 6 caracteres
                                  </small>
                                  <small v-show="$v.usuario.email.$dirty && !$v.usuario.email.maxLength" class="text-danger">
                                    No puede superar los 65 caracteres
                                  </small>

                                <!-- Contraseña -->
                                <input type="password" required maxlength="65" v-model="$v.usuario.password.$model" placeholder="Contraseña" class="form-control" :class="{ 'is-invalid': $v.usuario.password.$error}" >
                                  <small v-show="$v.usuario.password.$dirty && !$v.usuario.password.required" class="text-danger">
                                    Debe de introducir su contraseña
                                  </small>
                                  <small v-show="$v.usuario.password.$dirty && !$v.usuario.password.maxLength" class="text-danger">
                                    No puede superar los 65 caracteres
                                  </small>

                                <b-form-checkbox id="checkbox-1" v-model="recordar" name="checkbox-1" class="ms-3">
                                <label for="checkbox-1" class="ms-1">Recuerdame</label>
                                </b-form-checkbox>       
                                  <button type="submit" :disabled="$v.$invalid || cargando || (mostrarCaptcha && !captchaOK)" class="btn btn-primary btn-user btn-block mt-3">
                                    <div v-if="cargando" class="spinner-border spinner-border-sm" role="status">
                                      <span class="visually-hidden">Cargando login...</span>
                                    </div>
                                    Iniciar sesión
                                  </button>
                              
                              <vue-recaptcha v-if="mostrarCaptcha"
                              ref="recaptcha" 
                              sitekey="6LfIJ9YfAAAAABGpNsWcyOhBD5W9LXCSpBa8D3nw" 
                              @verify="verificacion"
                              class="mt-2 align-self-center">
                              </vue-recaptcha>
                              <a href="javascript:;" class="text-center mt-3" @click="passForgotten()"> He olvidado mi contraseña </a>
                        </form>
                    </div>

            </div>
            </b-overlay>
    </div>
</template>


<script>

import axios from "axios";
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { errorAlert, noResponseAlert, serverErrorAlert, successAlert } from '../helpers/fastAlert';
import { checkAccesoPandora, desconectar, setJWT } from '../helpers/JWT';
import { mapActions } from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
import Swal from 'sweetalert2'

export default {
  components: { VueRecaptcha },
  name: 'loginForm',
  data() {
    return {
      recordar: localStorage.getItem('email') ? true : false,
      cargando: false,
      usuario: {
        email: '' || localStorage.getItem('email'),
        password: ''
      },
      mostrarCaptcha: false,
      captchaOK: false
    }
  },
  validations: {
    usuario: {
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(65)
      },
      password: {
        required,
        maxLength: maxLength(65)
      }
    }
  },
  methods: {
    ...mapActions(['setUsuario']),
    verificacion(respuesta){      
      this.captchaOK = true;
    },
    async login() {
      try {
            if(this.mostrarCaptcha && !this.captchaOK){
              errorAlert('¡Error de seguridad!', 'No se superó la verificación ReCAPTCHA');
              return;
            } 

            this.cargando = true;
            let respuesta = await axios.post('/auth/login', this.usuario, { headers: { 'Content-Type': 'application/json' }});
            let token = respuesta.data.data.token;
            if(!checkAccesoPandora(token)){
              errorAlert('No se pudo iniciar sesión', "Tu usuario solo tiene acceso a Hydra");
              this.incrementarAccesosFallidos();
              return;
            }
            setJWT(token);
            this.recordarEmail();
            this.setUsuario();
            this.reiniciarAccesosFallidos();
            this.$router.push( { name: 'Welcome', params: {mostrarCarga: true}} );
          } catch (error) {
              this.incrementarAccesosFallidos();
              if (error.response && error.response.data && error.response.data.msg) {
                //Request made and server responded
                errorAlert('No se pudo iniciar sesión', error.response.data.msg)
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                noResponseAlert();
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                serverErrorAlert(error.message);
              }
              this.usuario.password = '';
              this.$v.$reset();
              if(this.mostrarCaptcha){
                this.$refs.recaptcha.reset();
                this.captchaOK = false;
              }
              this.checkFallos();
          } finally {
            this.cargando = false;
          }
    },
    /*
      Si ha checkeado la casilla de recuerdame guardamos el email en la local storage
    */
    recordarEmail(){
      if(this.recordar){
        localStorage.setItem('email', this.usuario.email.toLowerCase());
      }else{
        localStorage.removeItem('email');
      }
    },
    incrementarAccesosFallidos(){
      let fa = localStorage.getItem('fa') || 0;
      localStorage.setItem('fa', parseInt(fa) + 1);
    },
    reiniciarAccesosFallidos(){
      localStorage.setItem('fa', 0);
    },
    checkFallos(){
      let fa = localStorage.getItem('fa');
      if(fa && parseInt(fa) > 2){
        this.mostrarCaptcha = true;
      }else{
        this.mostrarCaptcha = false;
      }
    },
    async passForgotten(){
         let result = await Swal.fire({
          title: '¿Ha olvidado su contraseña?',
          html: `Introduzca su correo electrónico y comenzaremos el proceso para reiniciarla.`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  'Enviar',
          cancelButtonText:   'Cancelar',
          input: 'email',
          inputPlaceholder: 'Correo electrónico',
          showLoaderOnConfirm: true,
          preConfirm: async(email) => {
            return await axios({
              method: 'POST',
              url: '/auth/pwdResetReq',
              data: {
                email: email
              }
            }).catch( error => {
              Swal.showValidationMessage(error);
            })
          }
         });
         if(result.isConfirmed && result.value){           
           successAlert('¡Solicitud enviada!', `Si el correo existe, se habrá enviado un enlace para cambiar tu contraseña.`)
         }
    }

    
  },
  created() {
    this.checkFallos();
  }
}
</script>

<style scoped>
input {
  margin-top: 0.75rem;
}
</style>

