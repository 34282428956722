<template>
  <div class="row">
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-5">

            <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
              <!-- Header -->
              <div class="d-flex align-items-baseline">
                <h1 >Asignar perfiles</h1>
                <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
              </div>

              <!--Volver a mis Servicios-->
              <div>
                  <router-link :to="{name: 'Operadores'}" class="btn btn-lg btn-primary shadow-sm d-none d-sm-block"><i class="fa-solid fa-users-gear mx-1"></i> Operadores</router-link>
              </div>
            </div>
            
            <div class="card-body">
              <!-- Tarjeta informativa -->
              <TarjetaOperador :item="datosOperador" :justInfo="true"></TarjetaOperador>
              <!-- Perfiles -->
                <div class="m-3 card shadow">
                  <div class="card-body"> 
                    <!-- Action card header -->
                    <div class="d-flex align-items-baseline">
                      <h4>Gestor de perfiles</h4>
                    </div>
                      <!-- Select PERFIL -->
                      <form @submit.prevent="addProfile()">
                        <div class="row d-flex align-items-center justify-content-center my-3 mx-0 flex-column flex-sm-row container-fluid">
                            
                            <div class="col-12 col-sm-8 col-md-9 col-lg-10">
                                <!-- TEMPLATE -->
                                <div class="row d-flex flex-column flex-sm-row align-items-center my-2">
                                  <div class="col-12 col-sm-5 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-start justify-content-sm-end p-0">
                                    <label for="template" class="line mx-1 fs-5 d-flex align-items-center">Template</label>
                                  </div>
                                  <div class="col-12 col-sm-7 col-md-9 col-lg-10 col-xxl-9 ">
                                        <input id="template" class="form-control w-100 m-0" type="text" required maxlength="50" v-model="$v.template.$model" placeholder="300M_300M_RES_XPG-DHCP_OPERADORXX" :class="{ 'is-invalid': $v.template.$error}" autocomplete="off" autofocus>            
                                  </div>
                                </div>
                                <!-- DESCRIPCION -->
                                <div class="row d-flex flex-column flex-sm-row align-items-center my-2">
                                  <div class="col-12 col-sm-5 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-start justify-content-sm-end p-0">
                                    <label for="descripcion" class="line mx-1 fs-5 d-flex align-items-center">Descripción</label>
                                  </div>
                                  <div class="col-12 col-sm-7 col-md-9 col-lg-10 col-xxl-9 ">
                                        <input id="descripcion" class="form-control w-100 m-0" type="text" required maxlength="65" v-model="$v.descripcion.$model" placeholder="300MB↓ 300MB↑ Operador X" :class="{ 'is-invalid': $v.descripcion.$error}" autocomplete="off">             
                                  </div>
                                </div>
                            </div>
                           
                            <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                <button type="submit" :disabled="$v.$invalid || cargandoPeticion" class="btn btn-primary text-white w-100 launch-btn">
                                  <i v-if="!cargandoPeticion" type="button" class="fa-solid fa-save fa-1x mx-1" title="Test"></i>
                                  <div v-else class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Añadiendo...</span>
                                  </div>
                                  Añadir
                                </button>
                            </div>
                        </div>
                      </form>
                      <!-- ACTIONCARD -->
                      <b-overlay :show="cargandoPerfiles">
                      <div class="card border-dark border-3 mx-4 mt-2 bg-light action-card flex-shrink-1">
                         <div class="container mb-2 mt-4">
                            <span class="text-black-50 fw-bold">Perfiles asignados: </span>
                            <br><br>
                            <!-- Sin perfiles -->
                            <div v-if="(!perfiles || perfiles.length < 1) && !cargandoPerfiles" class="alert alert-warning d-flex align-items-center" role="alert">
                                <i class="fa-solid fs-5 fa-triangle-exclamation text-warning mx-2"> </i>  
                              <div v-if="mensajedeError" class="text-danger">
                                {{mensajedeError}}
                              </div>            
                              <div v-else>
                                Este operador no tiene ningún perfil asignado
                              </div>
                            </div>
                            <div v-else>
                              <div class="table-responsive">
                                  <table class="table table-striped table-bordered text-start">
                                    <thead>
                                      <tr>
                                        <th scope="col" class="d-table-cell line">TEMPLATE</th>
                                        <th scope="col" class="d-table-cell line">DESCRIPCIÓN</th>
                                        <th scope="col" class="d-table-cell line">ACCIÓN</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item, index) in perfiles" :key="index">
                                        <td>
                                            {{item.template}}
                                        </td>
                                        <td>
                                            {{item.descripcion}}
                                        </td>
                                        <td class="text-center accion">
                                            <i type="button" class="fa-solid fa-trash-alt fs-4 mx-1 icon-btn text-danger" title="Borrar perfil" @click="eliminarPerfil(item.uid)"></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                              </div>
                            </div>
                         </div>
                      </div>    
                      </b-overlay>                
                  </div>
                </div>

            </div>


          </div>

      </div>

  </div>
</template>

<script>
import { errorAlert, noResponseAlert, serverErrorAlert, infoAlert } from '../helpers/fastAlert';
import { registrarOperacionAdmin } from '../helpers/registrarEnHistorial';
import { required, maxLength } from 'vuelidate/lib/validators';

import Swal from 'sweetalert2'
import axios from "axios";
import TarjetaOperador from '@/components/TarjetaOperador';

export default {
  /*
    El objetivo de la página es asignar perfiles de velocidad al operador elegido, también se pueden eliminar.
    Primero se recupera el operador de la LocalStorage y luego se descargan sus perfiles de la API
    Con un formulario se puede elgir uno nuevo que no esté ya asignado y añadirlo, y se muestra un listado de los perfiles donde se pueden eliminar
  */
  name: 'AsignarPerfiles',
  components: {
    TarjetaOperador
  },
  data() {
    return {
      datosOperador: '',
      template: '',
      descripcion: '',
      perfiles: [],
      mensajedeError: '',
      cargandoPeticion: false,
      cargandoPerfiles: false,
    } 
  },  
  //Validaciones de formulario
  validations: {
    template: {
      required,
      maxLength: maxLength(50)
    },
    descripcion: {
      required,
      maxLength: maxLength(65)
    },
  },
  methods: {
    //Informacion botones interrogación
    infoPage(){
      infoAlert('Asignar perfiles', 'En esta página se puede asignar o eliminar los perfiles de velocidad que puede utilizar el operador.')
    },
    //Descargar los perfiles del operador
    async getPerfiles(){
        try {
          this.cargandoPerfiles = true;
          let respuesta = await axios({
            method: 'GET',
            url: '/tarifas',
            params: { 'OPID': this.datosOperador.id_operador },
            headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.perfiles = respuesta.data.data.tarifas;          
        } catch (error) {

            if (error.response && error.response.data && error.response.data.msg) {
              //Request made and server responded
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              // The request was made but no response was received
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              // Something happened in setting up the request that triggered an Error
              this.mensajedeError = error.message;
            }
        } finally {
            this.cargandoPerfiles = false;
        }
    },
    //Añadir un perfil nuevo al operador
    async addProfile(){
      try {
        this.cargandoPeticion = true;
        let respuestaPandora = await axios({
          method: 'POST',
          url: '/tarifas',
          data: { 'template': this.template, 'descripcion': this.descripcion, 'id_operador': this.datosOperador.id_operador },
          headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
        });
        await Swal.fire({
          title: 'Proceso ejecutado correctamente',
          text:  respuestaPandora.data.msg,
          icon: 'success',
          confirmButtonText: 'Vale'
        })
        await registrarOperacionAdmin("Asignar perfil", this.datosOperador.operador, this.datosOperador.id_operador, { 'Nuevo perfil': this.descripcion, 'Template': this.template})
        this.getPerfiles();
        this.resetForm();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          //Request made and server responded
          console.log("error controlado", error.response);
          errorAlert(error.response.data.msg, error.response.data.error ? error.response.data.error.descResultado : undefined)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          noResponseAlert();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message || error);
          serverErrorAlert(error.message || error);
        }
      } finally {
        this.cargandoPeticion = false;
      }
    },
    //Eliminar un perfil existente
    async eliminarPerfil(uid){
         await Swal.fire({
          title: '¿Borrar perfil?',
          text: "¡El perfil será borrado para siempre y no se podrá recuperar!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  '<i class="fa-solid fa-trash-alt"></i> Borrar',
          cancelButtonText:   'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            //Petición para borrar el usuario
            return axios.delete('/tarifas', {
              params: {uid},
              headers: { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
            }).then(res =>{
            }).catch(error => {
              if (error.response && error.response.data && error.response.data.msg) {
                //Request made and server responded
                Swal.showValidationMessage(error.response.data.msg);
              } else if (error.request) {
                // The request was made but no response was received
                Swal.showValidationMessage('No se ha recibido respuesta del servidor');
              } else {
                // Something happened in setting up the request that triggered an Error
                Swal.showValidationMessage(error.message);
              }              
            })
            
          },
          backdrop: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            let perfilEliminado = this.perfiles.find( e => e.uid = uid);
            registrarOperacionAdmin("Eliminar perfil", this.datosOperador.operador, this.datosOperador.id_operador, { 'Perfil eliminado': perfilEliminado.descripcion, 'Template': perfilEliminado.template})
            Swal.fire({
              title: 'Perfil borrado',
              text: 'El perfil ha sido eliminado de la lista de perfiles del perador',
              icon: 'success',
              confirmButtonText: 'Vale'
            }
            ).then(() => {
              this.getPerfiles();
            });
          }
        })
    },
    //Recuperar operador actual de la localStorage
    getOperador(){
      if(this.$route.params.datosOperador){
        localStorage.setItem('operadorActivo', JSON.stringify(this.$route.params.datosOperador));
      }
      if(localStorage.getItem('operadorActivo')){
        this.datosOperador = JSON.parse(localStorage.getItem('operadorActivo'));
      }      
      if(!this.datosOperador){
        this.$router.push({ name: 'Operadores'});
      }
      localStorage.setItem('focusedOperator', this.datosOperador.id_operador);
    },
    resetForm(){
        this.template = '';
        this.descripcion = `XXXMB↓ XXXMB↑ ${this.datosOperador.operador}`;
        this.$v.$reset();
    }
  },
  created(){
    this.getOperador();
    this.getPerfiles();
    this.resetForm();
  }
}
</script>

<style scoped>

@media (min-width: 1200px) {
  .launch-btn {
    max-width: 12rem;
  }
}

.tablaScrolleable{
  overflow: auto;
  max-height: 30rem;
}
.accion{
  width: 2rem;
}
</style>
