
<template>
  <Transition name="modal" >
    <div v-if="show" class="modal-mask" >
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h4 name="header">Documentación de CTO</h4>
            <i type="button" class="close xxl-font" title="Cerrar y descartar" data-dismiss="modal" aria-label="Close" @click="cerrarModal()" >
              <span aria-hidden="true">&times;</span>
            </i>
          </div>
          <div class="modal-body">
            <small class="text-start my-2">La CTO que has indicado no se ha encontrado en la base de datos. Como medida para asegurar el alta se generará un registro con la información indicada.</small>
            <br>
            <small class="text-danger my-2">
              MUY IMPORTANTE. Asegurese de que la CTO indicada coincide con el número de la CTO en la que está realizando la instalación. En caso contrario el alta podría fallar e incluso impedir una posterior provisión.
            </small>
            <form class="user d-flex flex-column" @submit.prevent novalidate>
              <div class="row">

                  <!-- DIRECCION_CTO -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-center justify-content-md-end">
                            <label for="DIRECCION_CTO" class="form-control-label noselect mx-1 d-flex align-items-center break-word">DIRECCION_CTO</label>
                          </div>
                          <div class="col-12 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11">
                                <input id="DIRECCION_CTO"  class="form-control w-100 m-0" type="text" maxlength="255" placeholder="Dirección en la que se encuentra la CTO" v-model="DIRECCION_CTO" :class="{ 'is-invalid': $v.DIRECCION_CTO.$invalid}" autocomplete="off" v-input-upper>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">    
                          <small v-show="!$v.DIRECCION_CTO.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="!$v.DIRECCION_CTO.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="!$v.DIRECCION_CTO.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <!-- TIPO_SPLITTER -->
                  <div class="col-sm-12 col-xxl-6 my-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-center">
                            <label for="TIPO_SPLITTER" class="form-control-label noselect mx-1 d-flex align-items-center break-word">TIPO_SPLITTER</label>
                          </div>
                          <div class="col-12 col-sm-12 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-11">
                                <select id="TIPO_SPLITTER"  class="form-select w-100 m-0" type="text" maxlength="15" placeholder="Tipo de splitter" v-model="TIPO_SPLITTER" :class="{ 'is-invalid': $v.TIPO_SPLITTER.$invalid}">
                                  <option :value="4">1:04</option>
                                  <option selected :value="8">1:08</option>
                                  <option :value="16">1:16</option>
                                </select>
                              </div>
                              <div class="col-1 px-0 text-end d-flex gap-2 align-self-center">
                                <!-- AYUDA -->
                                <div title="TIPO DE SPLITTER">
                                  <i class="fa-solid fa-circle-question clickable fs-5" @click="infoTIPO_SPLITTER()"></i>
                                </div>  
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="row text-start">
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">    
                          <small v-show="!$v.TIPO_SPLITTER.required" class="text-danger">
                            El campo debe de tener valor
                          </small>
                          <small v-show="!$v.TIPO_SPLITTER.maxLength" class="text-danger">
                            No se pueden poner tantos caracteres
                          </small>                           
                          <small v-show="!$v.TIPO_SPLITTER.caracterProhibido" class="text-danger">
                            Los caracteres <strong>* | ' ` " ; { }</strong> están prohibidos
                          </small>
                        </div>
                      </div>
                  </div>

                  <hr class="mt-4">
                  <div class="d-flex justify-content-center">
                      <button type="button" class="btn btn-secondary boton-generar mx-2" @click="resetearFormulario()" title="Reiniciar trazado">        
                        Reiniciar
                      </button>
                      <button type="button" class="btn btn-primary boton-generar mx-2" @click="enviar_documentacion()">
                            Enviar
                      </button>
                  </div>	

              </div>
            </form>          
          </div>

        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { required, minLength, maxLength, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
import { ponerTrazadosEnCola } from '../../helpers/EditorInfluencia';
import { errorAlert, infoAlert } from '../../helpers/fastAlert';
import Swal from 'sweetalert2'
import axios from "axios";

//Comprueba si se contiene los caracteres *, |, ', `, ", {, }
const caracterProhibido = (value) => {
  return !/[\*\|\'\`\"\;\{\}]/g.test(value)
}


export default {
  name: "ModalDocumentarNuevaCTO",
  props: ['lista_olt'],
   data() {
    return {
      show: false,
      cargandoPeticion: false,
      DIRECCION_CTO: '',
      TIPO_SPLITTER: 8
    }
  },
  validations: {
      DIRECCION_CTO: {
          required,
          maxLength: maxLength(255),
          caracterProhibido
      },     
      TIPO_SPLITTER: {
          required,
          maxLength: maxLength(5),
          caracterProhibido
      },       
  },
  methods: {
    infoTIPO_SPLITTER(){
      infoAlert('TIPO_SPLITTER', 'El tipo de splitter hace referencia a el número de puertos que tiene la CTO. Por ejemplo una CTO de <strong>8 puertos</strong> es <strong>1:08</strong>')
    },    
    cerrarModal(){
      this.show = false;
    },
    abrirModal(){
      this.show = true;
    },
    resetearFormulario(){
      this.DIRECCION_CTO = ''
      this.TIPO_SPLITTER = 8
      this.$forceUpdate();
    },
    enviar_documentacion(){
      this.$emit('enviar_documentacion', this.DIRECCION_CTO, this.TIPO_SPLITTER)
      this.cerrarModal()
    },
  },
  
  

}
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  max-height: 90vh;
  margin: 2rem auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>